<div class="p-5">
  <div>
    <drdp-title title="Viewing Children"></drdp-title>
  </div>

  <drdp-container additionClasses="w-full mt-4">
    <drdp-container color="lightBlue">
      <form [formGroup]="searchForm" #ngForm="ngForm">
        <div
          class="flex grid grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-cols-5 p-2 gap-2 md:gap-3 flex-row content-center">
          <div class="col-span-1 self-end">
            <drdp-select-state [initialStateId]="initStateId" (state)="handleState($event)" [required]="true"
              [clear]="clearState" [disabled]="!isSuperUser" [invalid]="true">
            </drdp-select-state>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-agency (agency)="handleChosenAgency($event)" [stateId]="stateId.value"
              [initialAgencyId]="initAgencyId" [clear]="clearAgency">
            </drdp-select-agency>
          </div>
          <div class="col-span-1 self-end">
            <drdp-filter-select #ratingPeriodSelect label="Rating Period" [options]="ratingPeriodList"
              [selectType]="select.RatingPeriods" (onSelected)="handleChosenRatingPeriod($event)">
            </drdp-filter-select>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-site (site)="handleChosenSite($event)" [agencyId]="agencyId.value" [multiSelect]="false"
              [multiSelectInitVals]="false" [clear]="clearSite">
            </drdp-select-site>
          </div>

          <div class="col-span-1 self-end">
            <drdp-filter-select #classroomSelect label="Classroom" [options]="classroomList" [selectType]="select.Class"
              (onSelected)="handleChosenClassroom($event)">
            </drdp-filter-select>
          </div>
          <div class="hidden lg:block lg:col-span-4 xl:col-span-4 self-end"></div>
          <div class="flex flex-row justify-evenly lg:col-span-2 xl:col-span-1 md:self-end xl:my-auto mt-2 md:mt-0">
            <div class="col-span-1">
              <drdp-btn type="submit">
                <span class="px-2" (click)="search()">Search</span>
              </drdp-btn>
            </div>
            <div class="col-span-1">
              <drdp-btn color="outline" (click)="clear()"> Clear </drdp-btn>
            </div>
          </div>
        </div>
      </form>
    </drdp-container>


    <div>
      <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
        (actionIconClicked)="onIconClick($event)">
      </drdp-add-edit-table>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData"
        [pageIndex]="pageIndex" (page)="paginateRatings()">
      </mat-paginator>
    </div>
  </drdp-container>
</div>
