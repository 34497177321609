<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
        #providerSelect
        label="Providers" 
        [multiSelect]="multiSelect" 
        [options]="providerOptions" 
        [selectType]="select.Default"
        [required]="required" 
        [disabled]="disabled"
        [isValid]="isValid"
        (onSelected)="onProviderSelect($event)"
        [initialValues]="initialProviderId">
    </drdp-filter-select>
</div>
