<div class="w-max">
  <div class="flex justify-between border-b">
      <h1 class="text-[25px] font-bold">Previously Saved Evidence</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
      <div class="mt-2">
        Evidence for the below child/children is saved in the Evidence Library. <br>
        To view evidence saved, click on the child's name below. <br>
        Or if you would like to add new evidence, click on the Add New Evidence button below.
      </div>
      <ul class="student-list">
        <li *ngFor="let student of studentList" (click)="redirectToEvidence(student)">
          <a>{{student.name}}</a>
        </li>
      </ul>
  </div>
  <div mat-dialog-actions class="flex justify-end">
      <drdp-btn type="submit" mat-dialog-close color="outline" class="mr-2">Cancel</drdp-btn>
      <drdp-btn type="button" (click)="goToNextPage()">Add New Evidence</drdp-btn>
  </div>
</div>
