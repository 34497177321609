import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import { SelectType } from '@core/enums/select';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { IPortfolioClassesRequest } from '@core/interfaces/iportfolio';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { PortfolioService } from '@core/services/portfolio.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @ViewChild('ageGradeInstrumentSelect') ageGradeInstrumentSelect:
    | FilterSelectComponent
    | undefined;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  pageSizeOptions = [5, 10, 25];
  pageSize = 10;
  totalData = 0;
  tableData?: any;
  pageIndex: number = 0;

  tableColumns = [
    { columnDef: 'siteName', header: 'Site', type: 'text' },
    { columnDef: 'ratingPeriodName', header: 'Rating Period', type: 'text' },
    { columnDef: 'classroomName', header: 'Classroom', type: 'text' },
    { columnDef: 'View Students', header: 'View Students', type: 'view' },
  ];

  user = this.auth.getCurrentUser();
  selectForm: FormGroup | any;
  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  classroomList: IClassroomAssignment[] = [];
  ageGroupInstrumentList: IAgeGradeInstrument[] = [];
  ratingPeriodList: IRatingPeriod[] = [];
  select = SelectType;
  clearState = false;
  clearAgency = false;
  clearSite = false;
  clearRatingPeriod = false;
  cleardropDown = false;
  currentSchoolYear = 0;
  isPilot = false;
  previousIsPilot: boolean = false;
  isSuperUser: boolean = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );

  get stateId() {
    return this.selectForm.get('stateId');
  }

  get agencyId() {
    return this.selectForm.get('agencyId');
  }
  get siteId() {
    return this.selectForm.get('siteId');
  }
  get ratingPeriodId() {
    return this.selectForm.get('ratingPeriodId');
  }
  get classroomId() {
    return this.selectForm.get('classroomId');
  }
  get ageGroupInstrumentId() {
    return this.selectForm.get('ageGroupInstrumentId');
  }

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private ratingPeriodService: RatingPeriodService,
    private classService: ClassService,
    private lookup: LookupService,
    private portfolioService: PortfolioService,
    private router: Router,
    private toast: ToastService,
    private permissionService: PermissionService,
    private schoolyearService: LookupService
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.initializeForm();
    this.schoolyearService
      .getCurrentSchoolYear()
      .pipe(take(1))
      .subscribe((year) => {
        this.currentSchoolYear = year.id;
      });
  }

  initializeForm(): void {
    this.selectForm = this.fb.group({
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null, [Validators.required]],
      ratingPeriodId: [null, [Validators.required]],
      siteId: [null, [Validators.required]],
      classroomId: [null],
      ageGroupInstrumentId: [null, [Validators.required]],
    });

  }

  paginateTable(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.searchClasses();
  }

  viewStudents(event: any) {
    if (event && event.data)
      this.router.navigate([
        `/portfolio/class/${event.data.classId}/ageGradeInstrument/${this.ageGroupInstrumentId.value}`,
      ]);
  }

  updateClassroomDropdown(): void {
    this.classroomList = [];
    const payload: any = {};
    payload.siteIds = this.siteId.value ?? null;
    payload.ratingPeriodId = this.ratingPeriodId.value ?? '';
    payload.itemsPerPage = 10;
    payload.useClassId = false;

    if (this.siteId.value?.length > 0 && payload.ratingPeriodId > 0) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomList = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id)
          );
        });
    } else {
      this.classroomList = [];
    }
  }

  getRatingPeriod(agencyId: number): void {
    this.ratingPeriodService
      .getByAgency(agencyId)
      .pipe(take(1))
      .subscribe((req) => {
        this.ratingPeriodList = req;
      });
  }

  handleState(state: any) {
    this.stateId.setValue(state?.id);
    this.agencyId.setValue(null);
    this.ageGroupInstrumentId.setValue(null);
    this.isPilot = false;
  }

  handleChosenAgency(agency: any): void {
    this.agencyId.setValue(agency?.id);
    this.classroomId.setValue(null);
    if (this.isPilot) {
      this.ageGroupInstrumentId.setValue(null);
      this.ageGradeInstrumentSelect?.clearSelected();
      this.isPilot = false;
    }
    this.classroomList = [];
    if (agency) this.getRatingPeriod(agency.id);
    else this.ratingPeriodList = [];
  }

  handleChosenSite(site: any): void {
    if(site) {
      this.siteId.setValue([site.id]);
    } else {
      this.siteId.setValue(null);
    }

    this.updateClassroomDropdown();
  }

  handleChosenClassroom(classroom: any): void {
    this.classroomId.setValue(classroom?.id);
    this.isPilot = this.classroomList.find((x) => x.id === classroom?.id)?.isPilot ?? false;
    if (this.previousIsPilot !== this.isPilot) {
      this.ageGroupInstrumentId.setValue(null);
      this.ageGradeInstrumentSelect?.clearSelected();
    }
    this.previousIsPilot = this.isPilot;
  }

  handleChosenRatingPeriod(ratingPeriod: any): void {
    this.ratingPeriodId.setValue(ratingPeriod?.id);
    this.updateClassroomDropdown();
  }

  handleAgeGroupInstrument(ageGroupInstrument: any): void {
    this.ageGroupInstrumentId.setValue(ageGroupInstrument?.id);
  }

  clear() {
    this.classroomSelect?.clearSelected();
    this.ageGradeInstrumentSelect?.clearSelected();
    this.sitesSelect?.clearSelected();
    this.cleardropDown = true;
    this.classroomList = [];
    this.ratingPeriodId.setValue(null);
    this.isPilot = false;
    this.clearTable();

    if (!this.stateSelect?.disabled) {
      this.initStateId = 0;
      this.stateId.setValue(null);
      this.clearState = true;
    }

    if (!this.agencySelect?.disabled) {
      this.initAgencyId = null;
      this.agencyId.setValue(null);
      this.clearAgency = true;
      this.ratingPeriodList = [];
    }

  }

  initializeTableData(data: any): void {
    this.dataSource = new MatTableDataSource(data.items);
    this.tableData = data.items;
    this.totalData = data.totalData;
  }

  clearTable(): void {
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
  }

  submit() {
    if (!this.selectForm.valid) {
      this.toast.error('Please enter all required fields.');
      return;
    }
    this.searchClasses();
  }

  searchClasses() {
    const payload: IPortfolioClassesRequest = {
      stateId: this.stateId.value,
      ageGradeInstrumentId: this.ageGroupInstrumentId.value,
      classRoomId: this.classroomId.value,
      ratingPeriodId: this.ratingPeriodId.value,
      siteId: this.siteId.value[0] || null,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize,
    };
    this.portfolioService
      .searchClasses(payload)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.initializeTableData(res);
        }
      });
  }
}
