import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { Subscription, take } from 'rxjs';
import { UserSessionComponent } from '../user-session/user-session.component';
import { ReEnrollmentRequestsComponent } from '../pending-requests/re-enrollment-requests/re-enrollment-requests.component';
import { Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import { PermissionService } from '@core/services/permission.service';
import { RatingService } from '@core/services/rating.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { PortfolioMessageService } from '@core/services/portfolio-message.service';
import { IPortfolioMessageResponse, IPortfolioMessageSearchRequest } from '@core/interfaces/iportfolio-message';
import { MessageStatus } from '@core/enums/message-status';
import { UserService } from '@core/services/user.service';
@Component({
  selector: 'drdp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private subscriptions = new Subscription();
  user = this.authService.getCurrentUser();
  isAdmin = this.authService.isAdmin();
  isSuperAdmin = this.authService.isSuperAdmin();
  isTeacher = this.authService.isTeacher();
  ratingCount = '0/0';
  hasPendingTransfers: boolean = false;
  hasPendingUnEnrollments: boolean = false;
  hasPendingReEnrollments: boolean = false;
  hasPendingChildReEnrollments: boolean = false;
  hasPendingReleaseRequest: boolean = false;
  hasNewMessages: boolean = false;
  newMessagesIcon = 'assets/images/misc/message-notification.svg';
  icon = 'assets/images/misc/message.svg';
  isSignedUp: boolean = false;
  canEditLanguage: boolean = this.permissionService.checkPermission(
    Permission.EditLanguageSettings
  );
  canViewLanguage: boolean = this.permissionService.checkPermission(
    Permission.ViewLanguageSettings
  );

  canViewNewsItem: boolean = this.permissionService.checkPermission(
    Permission.ViewNewsItem
  );

  canViewLockedChildren: boolean = this.permissionService.checkPermission(
    Permission.ViewTotalLockedChildren
  );

  isPendingIndicatorHidden: boolean = this.permissionService.checkPermission(
    Permission.HidePendingIndicator
  );

  canViewPortfolioMessages: boolean = this.permissionService.checkPermission(
    Permission.AccessToPortfolioMessages
  ) || this.permissionService.checkPermission(
    Permission.AccessToAllPortfolioMessageBoards
  )

  constructor(
    public modal: MatDialog,
    private authService: AuthService,
    private router: Router,
    private permissionService: PermissionService,
    private ratingService: RatingService,
    private studentRequestService: StudentRequestService,
    private portfolioMessageService: PortfolioMessageService,
    private userService: UserService
  ) {}

  myDashboard = [
    {
      label: 'Classrooms',
      icon: 'uil uil-diary',
      link: '/classrooms',
      access: Permission.ViewDashboardClassrooms,
    },
    {
      label: 'Children',
      icon: 'uil uil-diary',
      link: '/children',
      access: Permission.AccessToChildrenPAge,
    },
    {
      label: 'My Ratings',
      icon: 'uil uil-comment-add',
      link: '/ratings',
      access: Permission.ViewMyRatings,
    },
    {
      label: 'Portfolio',
      icon: 'uil uil-telescope',
      link: '/portfolio',
      access: Permission.ViewObservations,
    },
    {
      label: 'Pending Transfers',
      icon: 'uil uil-sign-in-alt',
      link: '/pending-transfers',
      access: Permission.ViewPendingTransfers,
      hasPendingRequest: this.hasPendingTransfers,
    },
    {
      label: 'Pending Withdraws',
      icon: 'uil uil-user-minus',
      link: '/pending-withdraws',
      access: Permission.ViewPendingExits,
      hasPendingRequest: this.hasPendingUnEnrollments,
    },
    {
      label: 'Pending Classroom Re-Enrollments',
      icon: 'uil uil-file-redo-alt',
      link: '/pending-re-enrollment',
      access: Permission.ViewPendingReEnrollments,
      hasPendingRequest: this.hasPendingReEnrollments,
    },
    {
      label: 'Pending Child Re-Enrollments',
      icon: 'uil uil-redo',
      link: '/pending-child-re-enrollment',
      access: Permission.ViewPendingReEnrollments,
      hasPendingRequest: this.hasPendingChildReEnrollments,
    },
    {
      label: 'Pending Release Requests',
      icon: 'uil uil-sign-out-alt',
      link: '/pending-releases',
      access: Permission.ViewPendingReleaseRequests,
      hasPendingRequest: this.hasPendingReleaseRequest,
    },
    {
      label: 'Child Requests',
      icon: 'uil uil-sign-alt',
      link: '/child-requests',
      access: Permission.AccessChildRequestsPage,
    },
    {
      label: 'Pending Portfolio Evidence Requests',
      icon: 'uil uil-image-v',
      link: '/portfolio-evidence-requests',
      access: Permission.PendingPortfolioRequests,
    },
  ];

  languageSettingsInfo = [
    {
      label: 'I want to view site in Spanish',
      access: true,
    },
    {
      label: 'I want to complete Rating Sheet in Spanish',
      access: false,
    },
  ];

  ngOnInit(): void {
    this.userService.getSignUpStatus().pipe(take(1)).subscribe((result: boolean) => {
        this.isSignedUp = result;
      });

    if (this.user.sessionCount > 1) {
      this.openSessionModel();
    }
    this.getRatingCount();

    if (!this.isPendingIndicatorHidden) this.getPendingRequest();

    if (this.canViewPortfolioMessages) this.checkNewMessages();
  }
  

  checkNewMessages(): void {
    const payload: IPortfolioMessageSearchRequest = {
      itemsPerPage: 100,
      pageIndex: 1
    }
    this.portfolioMessageService
      .getPortfolioMessagesByUserId(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.hasNewMessages = res.hasNewMessages;
        this.icon = this.hasNewMessages ? this.newMessagesIcon : this.icon;
      })
  }

  toggleSwitchAction(info: any) {}

  openSessionModel(): void {
    const modalRef = this.modal.open(UserSessionComponent);
    this.subscriptions.add(modalRef.afterClosed().subscribe());
  }

  buttonClick(value: string) {}

  navigate(component: any) {
    this.router.navigate([component]);
  }

  getRatingCount() {
    if (this.canViewLockedChildren) {
      this.ratingService
        .getLockCount()
        .pipe(take(1))
        .subscribe((result) => {
          this.ratingCount = result.count;
        });
    }
  }

  getPendingRequest() {
    const pendingRequests = [
      {
        reason: StudentRequestReason.ReEnrollStudent,
        label: 'Pending Child Re-Enrollments',
      },
      {
        reason: StudentRequestReason.ReEnroll,
        label: 'Pending Classroom Re-Enrollments',
      },
      {
        reason: StudentRequestReason.Release,
        label: 'Pending Release Requests',
      },
      { reason: StudentRequestReason.Transfer, label: 'Pending Transfers' },
      { reason: StudentRequestReason.Drop, label: 'Pending Withdraws' },
    ];

    pendingRequests.forEach((request) => {
      this.studentRequestService
        .getHasPendingRequest(request.reason)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            const dashboardItem = this.myDashboard.find(
              (item) => item.label === request.label
            );
            if (dashboardItem) {
              dashboardItem.hasPendingRequest = result;
            }
          }
        });
    });
  }

  onCheckboxChange(): void {
    this.isSignedUp = !this.isSignedUp;
    this.userService.updateSignUpStatus(this.isSignedUp).pipe(take(1)).subscribe();
  }
}
