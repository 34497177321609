import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ClassService } from '@core/services/class.service';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectType } from '@core/enums/select';
import { take } from 'rxjs';
import { IClassSearchRequest } from '@core/interfaces/iclasses';
import { mapClassResToDRDPSelect } from '@core/services/helper.service';
import { ClassroomService } from '@core/services/classroom.service';

@Component({
  selector: 'drdp-select-classroom',
  templateUrl: './select-classroom.component.html',
  styleUrls: ['./select-classroom.component.scss'],
})
export class SelectClassroomComponent implements OnInit {
  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @Output() classroom = new EventEmitter<any>();
  @Input() initialClassroomIds?: number[] | null;
  @Input() ratingPeriodId?: number | null;
  @Input() siteIds?: number[] | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = true;
  @Input() multiSelectInitVals: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = 'Classroom';
  @Input() useClassroomId?: boolean = false;
  @Input() isPilot?: boolean = false;
  classroomOptions: any[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private classroomService: ClassroomService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear || this.initialClassroomIds === null) {
      if (this.multiSelect) {
        this.classroomSelect?.clearAll();
      } else {
        this.classroomSelect?.clearSelected();
      }
    }

    if (changes['siteIds'] && this.siteIds) {
      this.siteIds = Array.isArray(this.siteIds)
        ? this.siteIds
        : [Number(this.siteIds)];
    }

    if (this.siteIds && this.siteIds.length > 0) {
      this.getClasses();
    } else {
      this.classroomOptions = [];
    }
  }

  clearSelected() {
    if (!this.disabled) this.classroomSelect?.clearSelected();
  }

  getClasses(): void {
    this.classroomService
      .getClassroomsBySites(this.createClassSearchPayload())
      .pipe(take(1))
      .subscribe((res: any) => {
        this.classroomOptions = mapClassResToDRDPSelect(
          res,
          this.useClassroomId,
          this.isPilot
        );
      });
  }

  createClassSearchPayload(): IClassSearchRequest {
    return {
      siteIds: this.siteIds?.length ? this.siteIds : null,
      ratingPeriodId: this.ratingPeriodId ? this.ratingPeriodId : null,
      itemsPerPage: 10,
    };
  }

  onClassroomSelect(event: number[]): void {
    this.classroom.emit(event);
  }
}
