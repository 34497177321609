<div class="p-5">
  <div>
    <drdp-title title="Group Detail Report"></drdp-title>
    <div>
      <p>
        Select rating period and age group to create your cohort/group. (Site, Cohort Classroom(s), and additional
        filters are optional.) Any rating options other than developmental levels, will not be calculated in the report
        and will affect the number of children reflected. All classes are automatically included based on the sites
        selected. To select a subset of classes, click on the appropriate classes in the Classes drop down menu. Only
        choose specific classes if you do not want “all classes.” The report will show all domain scaled scores for the
        selected group within the current year (to date).
      </p>
    </div>
  </div>
  <drdp-report-search [multiSelectClassrooms]="true" [multiSelectSites]="true" [hasSubfilters]="true"
    (showReportClicked)="showReport($event)" (downloadReportClicked)="downloadReport($event)"
    (clearClicked)="handleClear()" [requiredFields]="requiredFields"></drdp-report-search>
  <div *ngIf="!!reportData" [innerHtml]="reportData! | safeHtml"></div>
</div>
