<div class="p-5">
  <div>
    <drdp-title title="2025 Download Demographics Include Ratings"></drdp-title>
    <div>
      <p> Select a rating period and classroom to generate a report for all the children in the classroom. The report
        will display demographic information for the students. </p>
    </div>
  </div>
  <drdp-download-search (searchClicked)="downloadDemographics($event)" [isPilot]="true"></drdp-download-search>
</div>
