<div class="mt-4 shadow-lg shadow-drdpblue-100 rounded-[30px] py-4 px-6 bg-white flex-col justify-between">
  <div class="flex items-center justify-between">
    <p id="message" class="w-5/6">{{ message.message }}</p>
    <button *ngIf="!isArchived" class="self-start" (click)="emitArchiveMessage()">
      <i class="uil uil-times-circle text-red-500 text-lg"></i>
    </button>
  </div>
  <div class="flex items-center pt-4">
    <div id="date">
      <p class=" text-[#757575]">{{ message.createdOn | localDate | date: 'longDate' }} by {{ senderName }}</p>
    </div>
    <div id="newBadge" 
      *ngIf="message.messageRecipients?.length && message.messageRecipients[0].messageStatusId === MessageStatus.New" 
      class="bg-drdpblue-300 text-white text-sm rounded-full px-2 py-1 ml-2">
      NEW
    </div>
  </div>
</div>
