<div>
  <label *ngIf="label.length > 0" class="font-bold" [class]="additionalClasses" [ngClass]="{'required': required}">
    {{ label }} <span *ngIf="tooltip" class="uil uil-info-circle" [matTooltip]="tooltip"></span>
  </label>

  <mat-form-field appearance="fill" class="drdp-input filter">
    <mat-select panelClass="filter-select" [compareWith]="compareWith" [formControl]="selectedOptionControl"
      [ngClass]="{'border-drdpblue-300': isValid, 'invalid': !isValid}" [multiple]="multiSelect"
      [placeholder]="selectLabel" [value]="selected" [disabled]="disabled" (closed)="onClose()">

      <div class="flex flex-row m-3 mb-5 px-2 rounded-lg border-drdpblue-300 border">
        <label for="filterInput">Filter: </label>
        <input matInput type="text" [(ngModel)]="filterText" (keydown)="$event.stopPropagation()" (keyup)="onFilterChange($event)">
      </div>

      <div *ngIf="multiSelect" class="m-3 mb-5 flex flex-row justify-between">
        <drdp-btn color="defaultNoMargins" (click)="toggleOption(true)" type="button">Check All</drdp-btn>
        <drdp-btn color="defaultNoMargins" (click)="toggleOption(false)" type="button">Clear All</drdp-btn>
      </div>

      <mat-select-trigger *ngIf="multiSelect">
        Selected {{overrideLabel ? overrideLabel : label}}: {{selected.length}}
      </mat-select-trigger>

      <mat-option *ngFor="let item of itemsInPage[pageIndex]" [value]="item" (click)="handleOption(item)"
        class="smaller">
        <span [matTooltip]="tooltipActive ? item.name : ''">
          {{ item.name }}</span>
      </mat-option>

      <div class="w-60">
        <mat-paginator [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex" [hidePageSize]="true"
          (page)="page()">
        </mat-paginator>
      </div>

    </mat-select>
  </mat-form-field>
</div>
