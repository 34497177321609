<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
      #typeSelect
      label="Type" 
      [options]="typeOptions" 
      [selectType]="select.SiteType"
      [required]="required" 
      [disabled]="disabled"
      [isValid]="isValid"
      (onSelected)="onTypeSelect($event)"
      [initialValues]="initialTypeId">
  </drdp-filter-select>
</div>