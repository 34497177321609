import { Injectable } from '@angular/core';
import { IMenuLinks } from '@core/interfaces/side-menu';


@Injectable({
  providedIn: 'root',
})
export class UserDropMenuService {
  constructor() {}

  headerBtns: IMenuLinks[] = [
    {
      label: 'Dashboard',
      routeLink: '/dashboard',
      icon: 'uil uil-dashboard',
    },
    {
      label: 'User Profile',
      routeLink: '/user-profile',
      icon: 'uil uil-user-circle',
    },
  ];

}
