import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MyClassesService } from '@core/services/my-classes.service';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  IUserClassesDto,
  IUserClassSearchRequestDto,
} from '@core/interfaces/iclasses';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { SelectType } from '@core/enums/select';
import { IAgency } from '@core/interfaces/iagency';
import { ISites } from '@core/interfaces/isites';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ReEnrollClassroomComponent } from './re-enroll-classroom/re-enroll-classroom.component';
import { MatDialog } from '@angular/material/dialog';
import { setIconDisplayObject } from '@core/services/helper.service';
import { IState } from '@core/interfaces/istate';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { Permission } from '@core/enums/permissions';
import { ToastService } from '@core/services/toast.service';
import { formatStringToStringArr } from '@core/services/helper.service';

@Component({
  selector: 'drdp-my-classes',
  templateUrl: './my-classes.component.html',
  styleUrls: ['./my-classes.component.scss'],
})
export class MyClassesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('stateSelect') stateSelect!: SelectStateComponent;
  @ViewChild('agencySelect') agencySelect!: SelectAgencyComponent;

  private subscriptions = new Subscription();
  classroomsForm: FormGroup | any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  totalData = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  tableData?: any;
  payload: IUserClassSearchRequestDto = {};
  accessToAllSites = this.PermissionService.checkPermission(
    Permission.AccessAllSites
  );
  accessToAllAgencies = this.PermissionService.checkPermission(
    Permission.AccessAllAgencies
  );
  accessToClassroomSearch = this.PermissionService.checkPermission(
    Permission.ClassroomSearch
  );
  accessToClassReenroll = this.PermissionService.checkPermission(
    Permission.ClassReenroll
  );

  tableColumns = [
    {
      columnDef: 'viewStudents',
      header: 'View Students',
      icon: 'file-alt',
      type: 'icon',
      colorClass: 'text-drdpblue-300',
      cursorClass: 'cursor-pointer',
    },
    { columnDef: 'name', header: 'Classroom', type: 'text', isSortable: true },
    { columnDef: 'siteName', header: 'Site', type: 'text', isSortable: true },
    {
      columnDef: 'agencyName',
      header: 'Agency',
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'teachersNames',
      header: 'Teacher',
      type: 'bulletList',
      isSortable: true,
    },
    {
      columnDef: 'teacherOfRecordName',
      header: 'Teacher of Record',
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'ratingPeriodName',
      header: 'Rating Period',
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'studentCount',
      header: 'Children Count',
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'lockedStudents',
      header: 'Locked',
      type: 'text',
      isSortable: true,
    },
    this.accessToClassReenroll ?
    {
      columnDef: 'reEnroll',
      header: 'Re-Enroll',
      icon: 'file-medical text-drdpblue-300',
      type: 'icon',
      colorClass: 'text-drdpblue-300',
      cursorClass: 'cursor-pointer',
      tooltip:
        'You must have the new enrollment classroom and rating period created prior to submitting the re-enroll.',
    } : null
  ].filter(Boolean);
  user = this.authService.getCurrentUser();
  receivedPayload?: any;
  clearDropdowns = false;

  get stateId() {
    return this.classroomsForm.get('stateId');
  }
  get agencyId() {
    return this.classroomsForm.get('agencyId');
  }
  get siteId() {
    return this.classroomsForm.get('siteId');
  }
  get ratingPeriodId() {
    return this.classroomsForm.get('ratingPeriodId');
  }
  get classroomId() {
    return this.classroomsForm.get('classroomId');
  }
  get selectType() {
    return SelectType;
  }

  constructor(
    private fb: FormBuilder,
    private myClasses: MyClassesService,
    private route: Router,
    private authService: AuthService,
    public PermissionService: PermissionService,
    private toastService: ToastService,
    public modal: MatDialog
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.loadClasses();
  }

  loadClasses(resetPage?: boolean) {
    if (!this.accessToClassroomSearch) {
      this.getMyClasses();
    } else {
      this.search(resetPage);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  getMyClasses(): void {
    this.payload.stateId = this.user.stateId;
    this.payload.agencyId = this.user.agencyId;
    this.payload.userId = this.user.userId;

    if (this.paginator) {
      this.payload.pageIndex = this.paginator.pageIndex + 1;
      this.payload.itemsPerPage = this.paginator.pageSize;
    } else {
      this.payload.pageIndex = this.pageIndex;
      this.payload.itemsPerPage = this.pageSize;
    }
    this.myClasses
      .getUserClasses(this.payload)
      .subscribe((res: IUserClassesDto) => {
        if (res) {
          this.initializeTableData(res);
        }
      });
  }

  initializeForm(): void {
    this.classroomsForm = this.fb.group({
      stateId: [this.user.stateId, Validators.required],
      agencyId: [this.user.agencyId],
      siteId: [null],
      ratingPeriodId: [null],
      classroomId: [null],
    });

    if (!this.accessToAllAgencies) {
      this.agencyId.setValidators([Validators.required]);
      this.agencyId.updateValueAndValidity();
    }
  }

  initializeTableData(res: any): void {
    res.classes = res.classes.items.map((data: any) => ({
      ...data,
      displayIcons: setIconDisplayObject(this.tableColumns, data),
      teachersNames: formatStringToStringArr(data.teachersNames),
    }));
    this.dataSource = new MatTableDataSource(res.classes);
    this.totalData = res.totalData;
    this.tableData = res.classes;
  }

  paginateTable(resetPage?: boolean): void {
    if (this.classroomsForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    this.payload = this.classroomsForm.value;
    this.payload.userId = this.user.userId;
    if (this.paginator) {
      this.payload.pageIndex = this.paginator.pageIndex + 1;
      this.payload.itemsPerPage = this.paginator.pageSize;
    } else {
      this.payload.pageIndex = this.pageIndex;
      this.payload.itemsPerPage = this.pageSize;
    }

    if (Object.keys(this.payload).length == 2) {
      this.dataSource = new MatTableDataSource();
      this.totalData = 0;
      this.tableData = [];
    } else {
      this.myClasses
        .getUserClasses(this.payload)
        .subscribe((res: IUserClassesDto) => {
          if (res) {
            if (resetPage) this.paginator.firstPage();
            this.initializeTableData(res);
          }
        });
    }
  }

  onIconClick(event: any): void {
    switch (event.type) {
      case 'viewStudents':
        this.route.navigateByUrl(`view-students/${event.data.id}`);
        break;
      case 'reEnroll':
        this.openReEnrollModal(event);
        break;
      default:
        break;
    }
  }

  handleState(state: IState) {
    if (!state) {
      this.onClear();
      return;
    }

    this.stateId.setValue(state.id);
    this.payload.stateId = this.stateId.value;
  }

  handleAgency(agency: IAgency) {
    if (!agency) {
      this.payload = {};
      this.agencyId.setValue(null);
      return;
    }

    this.agencyId.setValue(agency.id);
    this.ratingPeriodId.setValue(null);
    this.payload.agencyId = this.agencyId.value;
    this.payload.ratingPeriodId = null;
  }

  handleSites(site: ISites): void {
    if (!site) {
      this.siteId.setValue(null);
      this.payload.siteId = null;
      this.payload.ratingPeriodId = null;
      this.payload.classroomId = null;
      return;
    }

    this.siteId.setValue(site.id);
    this.payload.siteId = this.siteId.value;
  }

  handleRatingPeriod(ratingPeriod: IRatingPeriod): void {
    if (!ratingPeriod) {
      this.payload.ratingPeriodId = null;
      this.payload.classroomId = null;
      this.ratingPeriodId.setValue(null);
      this.classroomId.setValue(null);
      return;
    }

    this.ratingPeriodId.setValue(ratingPeriod.id);
    this.payload.ratingPeriodId = this.ratingPeriodId.value;
  }

  handleClassroom(classroom: any): void {
    if (!classroom) {
      this.classroomId.setValue(null);
      this.payload.classroomId = null;
      return;
    }

    this.classroomId.setValue(classroom.id);
    this.payload.classroomId = this.classroomId.value;
  }

  search(resetPage?: boolean): void {
    if (this.classroomsForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    this.paginateTable(resetPage);
  }

  onClear(): void {
    this.dataSource = new MatTableDataSource<any>();
    this.clearDropdowns = true;
    this.totalData = 0;
    this.payload.itemsPerPage = 10;
    this.paginator.pageSize = 10;

    if (!this.stateSelect?.disabled) {
      this.stateId.setValue(null);
      this.payload.stateId = this.stateId.value;
    }

    if (!this.agencySelect?.disabled) {
      this.agencyId.setValue(null);
      this.payload.agencyId = this.agencyId.value;
    }

    this.classroomsForm.patchValue({
      siteId: null,
      ratingPeriodId: null,
      classroomId: null,
    });
  }

  openReEnrollModal(event: any): void {
    if (event.data.activeStudentCount == 0) {
      this.toastService.error('No Students To Re-Enroll.');
      return;
    }
    const modalRef = this.modal.open(ReEnrollClassroomComponent, {
      data: event ? event.data : undefined,
    });
    modalRef.afterClosed().subscribe((success) => {
      if (success) {
        this.loadClasses();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
