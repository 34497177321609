<div class="p-5">
  <div class="flex justify-between">
    <drdp-title title="Assignment of Alternate Rating View"></drdp-title>
  </div>
  <drdp-container>
    <drdp-container additionClasses="min-h-[6rem] w-full" color="lightBlue">
      <form
        class="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 p-5 gap-2 md:gap-3 flex-row content-center my-auto lg:items-end">
        <div class="col-span-1">
          <drdp-select-rating-period [initialRatingPeriodId]="selectedRatingPeriodId" (ratingPeriod)="handleRatingPeriod($event)" [agencyId]="agencyId"
              [clear]="clearDropdowns" [required]="true">
          </drdp-select-rating-period>
        </div>
        <div class="col-span-1">
          <drdp-select-site (site)="handleSites($event)" [agencyId]="agencyId" [multiSelect]="false"
            [multiSelectInitVals]="false" [clear]="clearDropdowns">
          </drdp-select-site>
        </div>

        <div class="col-span-1">
          <drdp-filter-select #classroomSelect label="Classroom" [options]="classroomList"
            [selectType]="selectType.Class" (onSelected)="handleClassroom($event)">
          </drdp-filter-select>
        </div>
        <div class="flex flex-row col-span-1 justify-evenly content-center mt-5 lg:justify-start md:col-span-2 ">
          <div class="mb-0 lg:mr-2">
            <drdp-btn (click)="search()">
              <span class="px-2">Search</span>
            </drdp-btn>
          </div>
          <div>
            <drdp-btn color="outline" (click)="onClear()">Clear</drdp-btn>
          </div>
        </div>
      </form>
    </drdp-container>
  </drdp-container>

  <drdp-container *ngFor="let result of results" additionClasses="mt-4">
    <div class="grid grid-cols-4">
      <div class="font-bold">Select All From Site</div>
      <div class="font-bold">Site Name</div>
      <div class="font-bold">Classrooms</div>
      <div class="font-bold">View Site</div>

      <div>
        <mat-checkbox class="font-medium" [checked]="result.isSelected" [(ngModel)]="result.isSelected"
          [ngModelOptions]="{standalone: true}" (change)="toggleSite(result)">
        </mat-checkbox>
      </div>
      <div>{{result.siteName}}</div>
      <div>{{result.classroomCount}}</div>
      <div><a class="font-bold text-blue-600 cursor-pointer"
          (click)="result.showClassrooms = !result.showClassrooms">Open Site List <i
            [class]="result.showClassrooms ? 'uil uil-angle-right' : 'uil uil-angle-down'"></i></a></div>
    </div>
    <hr class="my-4" *ngIf="result.showClassrooms">
    <div class="grid grid-cols-5" *ngIf="result.showClassrooms">
      <div></div>
      <div class="font-bold">Select Entire Classroom</div>
      <div class="font-bold">Classroom</div>
      <div class="font-bold">Rating Period</div>
      <div class="font-bold">View Students</div>

      <ng-container *ngFor="let classroom of result.classroomDetails">
        <div></div>
        <div class="text-center">
          <mat-checkbox class="font-medium" [checked]="classroom.isSelected" [(ngModel)]="classroom.isSelected"
            [ngModelOptions]="{standalone: true}" (change)="toggleClassroom(result, classroom)">
          </mat-checkbox>
        </div>

        <div> {{classroom.classroomName }}</div>
        <div> {{classroom.ratingPeriodName }}</div>
        <div><a class="font-bold text-blue-600 cursor-pointer"
            (click)="classroom.showStudents = !classroom.showStudents">Open Student
            List <i [class]="classroom.showStudents ? 'uil uil-angle-right' : 'uil uil-angle-down'"></i></a>
          <div class="student-box" *ngIf="classroom.showStudents">
            <div *ngFor="let student of classroom.students">
              <mat-checkbox class="font-medium" [checked]="student.isSelected" [(ngModel)]="student.isSelected"
                [ngModelOptions]="{standalone: true}" (change)="checkIsClassroomSelected(result, classroom, student)">
                {{student.name}}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </drdp-container>

  <div class="mt-4 mb-2 flex justify-end">
    <drdp-btn color="outline" (click)="cancel()">Cancel</drdp-btn>
    <drdp-btn (click)="submit()" class="ml-4" *ngIf="results.length > 0"> Save </drdp-btn>
  </div>

</div>