<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
        #vendorSelect
        label="Vendors" 
        [multiSelect]="multiSelect" 
        [options]="vendorOptions" 
        [selectType]="select.Vendor"
        [required]="required" 
        [disabled]="disabled"
        (onSelected)="onVendorSelect($event)"
        [isValid]="isValid"
        [initialValues]="initialVendorId || initialVendorIds">
    </drdp-filter-select>
</div>