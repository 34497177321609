import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modal } from '@core/enums/modal';

@Component({
  selector: 'drdp-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  buttonColor?: string;
  buttonLabel?: string;
  showCancel: boolean = false;
  title: any;
  name?: any;
  message: any;
  cancelText: string = 'Cancel';
  wideContent: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any) {
      dialogRef.disableClose = true
    }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo(){
    this.title = this.info.data.modalInfo.title
    this.message = this.info.data.modalInfo.message
    this.name = this.info.data.modalInfo.name || ''
    this.buttonColor = this.info.data.modalInfo.primaryBtnClass;
    this.buttonLabel = this.info.data.modalInfo.primaryBtnText;
    this.showCancel = this.info.data.modalInfo.showCancel;
    this.cancelText = this.info.data.modalInfo.cancelText || 'Cancel';
    this.wideContent = this.info.data.modalInfo.wideContent;
  }

  onSubmit(value: any){
    if (this.buttonLabel == Modal.Delete) {
      let payload = {
        index: this.info.index,
        id: this.info.data.id
      }
      return payload;
    }

    return value;
  }

}
