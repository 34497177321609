<div class="p-5">
  <div>
    <drdp-title title="Download Demographics Include Scores"></drdp-title>
    <div>
      <p>Select a rating period and classroom to generate a report for all the children in the classroom. 
        The report will display demographic and scores information for the children. 
        The file will be sent to the email associated with the user account.</p>
    </div>
  </div>
  <drdp-download-search (searchClicked)="downloadDemographics($event)"></drdp-download-search>
</div>