<div class="input-rating-sheet" *ngIf="studentInfo">
  <div *ngFor="let domain of studentDomains">
    <drdp-container additionClasses="min-h-[15rem] mt-4">
      <div class="md:flex place-content-between place-items-center self-center">
        <div class="font-semibold text-xl">
          <img class="domain-icon" src="assets/images/domains/{{domain.iconName}}" [alt]="domain.domainCode">
          {{domain.domainCode}} - {{domain.domainName}}
        </div>
      </div>

      <div class="mt-4 overflow-x-auto">
        <table class="table-auto min-w-full" aria-label="Input Ratings Table">
          <thead>
            <tr>
              <th class="border border-gray-200 font-medium text-xs px-4 py-3">
                Measure
              </th>
              <th class="border border-gray-200 font-medium text-xs px-4 py-3" *ngIf="domain.notYet">
                {{domain.notYet.measureConditionName}}
              </th>

              <th class="border border-gray-200 font-medium text-xs px-4 py-3"
                *ngFor="let level of domain.domainLevels">
                {{level.levelName}}
              </th>
              <th class="border border-gray-200 font-medium text-xs px-4 py-3" *ngIf="domain.conditional">
                {{domain.conditional.description}}
              </th>
              <th class="border border-gray-200 font-medium text-xs px-4 py-3" *ngIf="domain.EM">
                {{domain.EM.measureConditionCode}}</th>
              <th class="border border-gray-200 text-xs px-4 py-3" *ngIf="domain.UR"
                [ngClass]="{'text-left': isKinder}">
                <span class="font-medium "> {{domain.UR.measureConditionCode}} </span>
                <p class="font-light" *ngIf="isKinder">In the rare circumstance that you are unable to
                  rate a measure:</p>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr *ngFor="let measure of domain.measures">
              <td class="p-3 border-t border-b border-r border-l border-gray-200">
                <p class="font-bold text-xs">{{measure.code}}</p>
                <p class="text-xs w-36">{{measure.name}}</p>
              </td>

              <!-- Not Yet -->
              <td class="p-3 border-t border-b border-r border-l border-gray-300" *ngIf="domain.notYet"
                [ngClass]="{'bg-gray-300': !measure.notYet}">
                <div class="flex justify-center" *ngIf="measure.notYet">
                  <mat-checkbox class="font-medium" [id]="measure.code + 'NY'" [name]="measure.code + 'NY'"
                    [checked]="measure.isNotYet" [(ngModel)]="measure.isNotYet" [ngModelOptions]="{standalone: true}"
                    (change)="toggleMeasureNotYet(domain, measure, measure.notYet)"
                    [disabled]="studentInfo.isLocked || measure.isUR">
                    <label class="sr-only" [for]="measure.code + 'NY'">{{measure.notYet.measureConditionName}}</label>
                  </mat-checkbox>
                </div>
              </td>

              <!-- Levels -->
              <td class="p-3 border-t border-b border-r border-l border-gray-300"
                [ngClass]="{'bg-gray-300': level.isDisabled}" *ngFor="let level of measure.displayLevels">
                <div class="flex justify-center" *ngIf="!level.isDisabled"
                  (click)="selectLevel(domain, measure, level)">
                  <label class="sr-only" [for]="measure.code + level.value">{{level.name}} </label>
                  <input type="radio" [id]="measure.code + level.value" [name]="measure.code + level.value"
                    [value]="level.value" [(ngModel)]="measure.ratingValue"
                    [disabled]="studentInfo.isLocked || measure.isUR || measure.isNotYet" />
                </div>
              </td>

              <!-- Conditional -->
              <td class="p-3 border-t border-b border-r border-l border-gray-300" *ngIf="domain.conditional"
                [ngClass]="{'bg-gray-300': !measure.conditional}">
                <div class="flex justify-center" *ngIf="measure.conditional"
                  (click)="selectCondition(domain, measure, measure.conditional)">
                  <label class="sr-only" [for]="measure.code + 'C'">{{measure.conditional.measureConditionName}}
                  </label>
                  <input type="radio" [id]="measure.code + 'C'" [name]="measure.code + 'C'"
                    [value]="measureCondition.Conditional" [(ngModel)]="measure.measureConditionId"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="studentInfo.isLocked || measure.isUR || measure.isNotYet" />
                </div>
              </td>

              <!-- EM -->
              <td class="p-3 border-t border-b border-r border-l border-gray-300" *ngIf="domain.EM"
                [ngClass]="{'bg-gray-300': !measure.EM}">
                <div class="flex justify-center" *ngIf="measure.EM">
                  <mat-checkbox class="font-medium" [id]="measure.code + 'EM'" [name]="measure.code + 'EM'"
                    [checked]="measure.isEM" [(ngModel)]="measure.isEM" [ngModelOptions]="{standalone: true}"
                    (change)="toggleMeasureEM(domain, measure)"
                    [disabled]="!measure.canEmerging || studentInfo.isLocked || measure.isNotYet">
                    <label class="sr-only" [for]="measure.code + 'EM'">{{measure.EM.measureConditionName}} </label>
                  </mat-checkbox>
                </div>
              </td>

              <!-- UR -->
              <td class="p-3 border-t border-b border-r border-l border-gray-300" *ngIf="domain.UR"
                [ngClass]="{'bg-gray-300': !measure.UR}">
                <div class="flex" [ngClass]="{'justify-center': !isKinder}" *ngIf="measure.UR">
                  <mat-checkbox class="font-medium" [id]="measure.code + 'UR'" [name]="measure.code + 'UR'"
                    [checked]="measure.isUR" [(ngModel)]="measure.isUR" [ngModelOptions]="{standalone: true}"
                    (change)="toggleMeasureUR(domain, measure, measure.UR)"
                    [disabled]="studentInfo.isLocked || measure.isNotYet">
                    <label class="sr-only" [for]="measure.code + 'UR'">{{measure.UR.measureConditionName}} </label>
                  </mat-checkbox>
                  <ng-container *ngIf="measure.measureConditionId == measureCondition.Unratable && isKinder">
                    <div class="ml-4 w-36">
                      <label class="font-normal text-sm m-0" for="reason">Reason</label>
                      <mat-select placeholder="Reason" [(ngModel)]="measure.childAssessmentRatingReasonId" id="reason"
                        [disabled]="studentInfo.isLocked">
                        <mat-option *ngFor="let reason of carReasons" [value]="reason.id"
                          (click)="selectReason(domain, measure, reason)">
                          {{ reason.reasonComment }}
                        </mat-option>
                      </mat-select>
                    </div>

                    <div *ngIf="measure.showComment">
                      <div class="ml-4 w-36">
                        <label class="font-normal text-sm m-0" for="comment">Comment</label>
                        <mat-form-field appearance="fill">
                          <input matInput [(ngModel)]="measure.comment" placeholder="Add your comment here"
                            maxlength="100" id="comment" autocomplete="off" type="text"
                            [disabled]="studentInfo.isLocked"
                            (blur)="emitMeasureChange(domain, measure)" />
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </drdp-container>
  </div>
</div>
