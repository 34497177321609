import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DownloadRequestService } from '@core/services/download-request.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-download-demographics-scores',
  templateUrl: './download-demographics-scores.component.html',
  styleUrls: ['./download-demographics-scores.component.scss'],
})
export class DownloadDemographicsScoresComponent implements OnInit {
  downloadService = inject(DownloadRequestService);
  dialog = inject(MatDialog);
  constructor() {}

  ngOnInit(): void {}

  downloadDemographics(searchValues: any) {
    this.downloadService
      .saveRequestForScores(searchValues)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.displaySuccessMessage();
        }
      });
  }

  displaySuccessMessage() {
    const modal = {
      data: {
        modalInfo: {
          title: 'Success',
          message:
            'Your request is being processed. You will receive an email at the address associated with your account as soon as the report is ready',
          primaryBtnClass: 'blue',
          primaryBtnText: 'OK',
          showCancel: false,
        },
      },
    };

    this.dialog.open(ConfirmationModalComponent, {
      data: modal,
    });
  }
}
