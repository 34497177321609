<div class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{ !editData ? 'Add New Site' : 'Edit Site' }}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <div mat-dialog-content>
    <form class="grid grid-cols-2 gap-x-10" [formGroup]="siteForm">

      <div *ngIf="hasFullOptions" class="input-container col-span-full md:col-span-1 z-50">
        <drdp-select-state (state)="onDropDownSelect($event, select.States)" [clear]="clearDropdowns"
          [initialStateId]="state.value">
        </drdp-select-state>
      </div>

      <div *ngIf="hasFullOptions" class="input-container col-span-full md:col-span-1 z-50">
        <drdp-select-agency (agency)="onDropDownSelect($event, select.Agencies)" [stateId]="state.value"
          [initialAgencyId]="adminAgencyDropdown.value" [clear]="clearDropdowns">
        </drdp-select-agency>
      </div>

      <div class="col-span-full">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="font-normal" for="SiteName">Site Name <span class="required"></span></label>
          <input matInput placeholder="Site Name" autocomplete="off" type="text" formControlName="siteName"
            maxlength="100">
        </mat-form-field>
      </div>

      <div class="col-span-full">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="font-normal" for="SiteCode">Site Code</label>
          <input matInput placeholder="Site Code" autocomplete="off" type="text" formControlName="siteCode"
            maxlength="25">
        </mat-form-field>
      </div>

      <div class="select-input col-span-full md:col-span-1">
        <drdp-select-site-code [initialCodeId]="siteCodeType.value" (code)="onDropDownSelect($event, select.CodeType)"
          [isValid]="!siteCodeType.touched || siteCodeType.valid" label="Site Code Type">
        </drdp-select-site-code>
      </div>

      <div class="select-input col-span-full md:col-span-1">
        <drdp-select-type [initialTypeId]="siteType.value" (type)="onDropDownSelect($event, select.SiteType)"
          [required]="true" [isValid]="!siteType.touched || siteType.valid">
      </drdp-select-type>
      </div>

      <div class="col-span-full md:col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="font-normal required" for="siteAddress">Site Address</label>
          <input matInput placeholder="Site Address" autocomplete="off" type="text" formControlName="siteAddress"
            maxlength="100">
        </mat-form-field>
      </div>

      <div class=" col-span-full md:col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="font-normal required" for="siteCity">Site City</label>
          <input matInput placeholder="Site City" autocomplete="off" type="text" formControlName="siteCity"
            maxlength="25">
        </mat-form-field>
      </div>

      <div class="select-input col-span-full md:col-span-1" *ngIf="viewCountyDropdown">
        <drdp-select-county [initialCountyIds]="siteCounty.value" (county)="onDropDownSelect($event, select.County)"
            [stateId]="state.value" [multiSelect]="false" [multiSelectInitVals]="false"
            [isValid]="!siteCounty.touched || siteCounty.valid" (initCounties)="handleView($event)">
        </drdp-select-county>
      </div>

      <div class="select-input col-span-full md:col-span-1">
        <drdp-select-state (state)="onDropDownSelect($event, select.States)" [clear]="clearDropdowns"
          [initialStateId]="state.value" [required]="true">
        </drdp-select-state>
      </div>

      <div class="col-span-full md:col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="font-normal required" for="SiteZip">Site Zip</label>
          <input matInput placeholder="Site Zip" autocomplete="off" type="text" formControlName="siteZip"
            maxlength="10">
        </mat-form-field>
      </div>

      <div class="col-span-full md:col-span-1 mt-3" >
        <div>
          <label class="inline">Dual Enrollment Site</label>
          <i class="uil uil-info-circle text-lg ml-2" matTooltip="State Preschool, Head Start, Family Childcare, After School Program"></i>
        </div>
        <mat-checkbox color="primary" formControlName="isDualEnrollment">
        </mat-checkbox>
      </div>
    </form>

  </div>
  <div mat-dialog-actions class="flex justify-end">
    <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn mat-button [disabled]="siteForm.invalid" cdkFocusInitial (click)="onSubmit()">Save</drdp-btn>

  </div>
</div>
