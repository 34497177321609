<div class="table-responsive pt-5">
  <mat-table [id]="tableId" [dataSource]="dataSource" class="w-[100%]" matSort (matSortChange)="sortTable($event)">

    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <ng-container [ngSwitch]="column.type">

        <ng-container *ngSwitchCase="Table.view">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <a [ngClass]="viewIcon ? viewIcon : 'uil uil-file'" class="text-xl cursor-pointer"
                (click)="viewEmitter(row, i, column.header)"></a>
            </td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef>{{ column.header }} <span *ngIf="column?.tooltip"
              class="uil uil-info-circle" [matTooltip]="column?.tooltip"></span></th>
            <td mat-cell *matCellDef="let row; let i = index">
              <a [ngClass]="viewIcon ? viewIcon : 'uil uil-file'" class="text-xl cursor-pointer"
                (click)="viewEmitter(row, i, column.header)"></a>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.icon">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef>{{ column.header ? column.header : '' }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <p *ngIf="row.displayIcons[column.columnDef]"
                [ngClass]="['uil uil-' + column?.icon, column?.colorClass, column?.cursorClass]" class="text-xl"
                (click)="iconEmitter(row, i, column.columnDef)"></p>
            </td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef>{{ column.header ? column.header : '' }} <span *ngIf="column?.tooltip"
                class="uil uil-info-circle" [matTooltip]="column?.tooltip"></span></th>
            <td mat-cell *matCellDef="let row; let i = index">
              <p *ngIf="row.displayIcons[column.columnDef]"
                [ngClass]="['uil uil-' + column.icon, column?.colorClass, column?.cursorClass]" class="text-xl"
                (click)="iconEmitter(row, i, column.columnDef)"></p>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.boolIcon">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef>{{ column.header ? column.header : '' }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <p *ngIf="lodash.get(row, column.columnDef)"
                [ngClass]="['uil uil-' + column.truthyClass + ' ' + column?.cursorClass]" class="text-xl"
                (click)="actionIconSelected(row, i, column.action)">
                <img *ngIf="column.truthyIconUrl" [src]="column.truthyIconUrl" alt="truthy icon">
              </p>
              <p *ngIf="!lodash.get(row, column.columnDef)"
                [ngClass]="['uil uil-' + column.falsyClass + ' ' + column?.cursorClass]" class="text-xl"
                (click)="actionIconSelected(row, i, column.action)">
                <img *ngIf="column.falsyIconUrl" [src]="column.falsyIconUrl" alt="falsy icon">
              </p>
            </td>
          </div>

          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef>{{ column.header ? column.header : '' }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <p *ngIf="lodash.get(row, column.columnDef)"
                [ngClass]="['uil uil-' + column.truthyClass + ' ' + column?.cursorClass]" class="text-xl"
                (click)="actionIconSelected(row, i, column.action)">
                <img *ngIf="column.truthyIconUrl" [src]="column.truthyIconUrl" alt="truthy icon">
              </p>
              <p *ngIf="!lodash.get(row, column.columnDef)"
                [ngClass]="['uil uil-' + column.falsyClass + ' ' + column?.cursorClass]" class="text-xl"
                (click)="actionIconSelected(row, i, column.action)">
                <img *ngIf="column.falsyIconUrl" [src]="column.falsyIconUrl" alt="falsy icon">
              </p>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.actionIcon">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef>{{ column.header ? column.header : '' }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <p [ngClass]="['uil uil-' + column.icon, column?.colorClass, column?.cursorClass]" class="text-xl"
                (click)="actionIconSelected(row, i, column.action)"></p>
            </td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef>{{ column.header ? column.header : '' }}<span *ngIf="column?.info"
              class="uil uil-info-circle" [matTooltip]="column?.info"></span></th>
            <td mat-cell *matCellDef="let row; let i = index">
              <p [ngClass]="['uil uil-' + column.icon, column?.colorClass, column?.cursorClass, lodash.get(row, column.columnDef) == true ? 'invisible' : '']"
              class="text-xl text-center"
                [matTooltip]="column?.tooltip"
                (click)="actionIconSelected(row, i, column.action)"></p>
            </td>
          </div>
        </ng-container>


        <ng-container *ngSwitchCase="Table.text">
          <div *ngIf="column.isSortable" class="w-10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row" class="text-start w-2 truncate">
              <ng-container *ngIf="!column.applyDecimalPipe; else applyPipe">{{ lodash.get(row, column.columnDef) }}</ng-container> 
              <ng-template #applyPipe>{{ lodash.get(row, column.columnDef) | number:'1.2-2'}}</ng-template>
            </td>
          </div>
          <div #notSortable class="w-10">
            <th mat-header-cell *matHeaderCellDef class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row" class="w-2 align-top truncate">
              <ng-container *ngIf="!column.applyDecimalPipe; else applyPipe">{{ lodash.get(row, column.columnDef) }}</ng-container> 
              <ng-template #applyPipe>{{ lodash.get(row, column.columnDef) | number:'1.2-2'}}</ng-template>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.textToInput">
          <div *ngIf="column.isSortable" class="w-10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="text-start w-2 truncate">
              <span *ngIf="!row.isSelect">
                {{ lodash.get(row, column.columnDef) }}
              </span>
              <span *ngIf="row.isSelect">
                <mat-form-field class="bg-white" appearance="fill">
                  <input placeholder="Comment" matInput type="text" [maxLength]="column.maxLength ?? 1000"
                    [value]="lodash.get(row, column.columnDef)" (change)="editEmitter($event, i, column?.action)">
                </mat-form-field>
              </span>
            </td>
          </div>
          <div #notSortable class="w-10">
            <th mat-header-cell *matHeaderCellDef class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="w-2 align-top truncate">
              <span *ngIf="!row.isSelect">
                {{ lodash.get(row, column.columnDef) }}
              </span>
              <span *ngIf="row.isSelect">
                <mat-form-field class="bg-white" appearance="fill">
                  <input placeholder="Comment" matInput type="text" [value]="lodash.get(row, column.columnDef)"
                    (change)="editEmitter($event, i, column?.action)" [maxLength]="column.maxLength ?? 1000">
                </mat-form-field>
              </span>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.textInput">
          <div *ngIf="column.isSortable" class="w-10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="text-start w-2 truncate">
              <mat-form-field class="bg-white w-full" appearance="fill">
                <input placeholder="Add Comment" matInput type="text" maxlength="150"
                  [value]="lodash.get(row, column.columnDef) ?? ''" (change)="editEmitter($event, i, column?.action)">
              </mat-form-field>
            </td>
          </div>
          <div #notSortable class="w-10">
            <th mat-header-cell *matHeaderCellDef class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="w-2 align-top truncate">
              <mat-form-field class="bg-white w-full" appearance="fill">
                <input placeholder="Add Comment" matInput type="text" maxlength="150"
                  [value]="lodash.get(row, column.columnDef) ?? ''" (change)="editEmitter($event, i, column?.action)">
              </mat-form-field>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.fullDate">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
            <td mat-cell *matCellDef="let row">{{ lodash.get(row, column.columnDef) | localDate }} </td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
            <td mat-cell *matCellDef="let row">{{ lodash.get(row, column.columnDef) | localDate }} </td>
          </div>
        </ng-container>
        
        <ng-container *ngSwitchCase="Table.date">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="break">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row" class="break">{{ lodash.get(row, column.columnDef) | date:
              column.dateFormat ? column.dateFormat : 'MM/dd/yy' }}</td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef class="break">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row">{{ lodash.get(row, column.columnDef) | date: column.dateFormat ?
              column.dateFormat : 'MM/dd/yy' }}</td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.bulletList">
          <div *ngIf="column.isSortable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
            <td mat-cell *matCellDef="let row">
              <ul class="list-disc">
                <ng-container *ngFor="let items of lodash.get(row, column.columnDef) ">
                  <li>{{items}}</li>
                </ng-container>
              </ul>
            </td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
            <td mat-cell *matCellDef="let row">
              <ul class="list-disc">
                <ng-container *ngFor="let items of lodash.get(row, column.columnDef)">
                  <li>{{items}}</li>
                </ng-container>
              </ul>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.checkBox">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{'center-header' : column?.centerHeader}">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <label [for]="labelId" [attr.aria-label]="label ? label : 'label'"
              [ngClass]="{ 'mb-4' : labelSpace, 'required' : requiredLabel }">
              {{ label }}
            </label>
            <input class="accent-drdpgrey-200" [id]="labelId" [attr.aria-label]="label ? label : 'input'"
              [ngClass]="column?.checkClass ?? 'checkbox-round'" type="checkbox"
              [checked]="lodash.get(row, column.columnDef)" (change)="checkEmitter(row, i, column.columnDef, $event)"
              [disabled]="column.disabledWhen || column.readOnly ? column.disabledWhen(row) : false">
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="Table.select">
          <th mat-header-cell *matHeaderCellDef>{{column.header}}</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a *ngIf="lodash.get(row, column.columnDef)" (click)="editEmitter(row, i)"
              [ngClass]="column.readOnly ? 'disabled-link' : '' " class="text-xl uil uil-pen cursor-pointer"></a>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="Table.datepicker">
          <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <span *ngIf="row.isSelect">
              <drdp-date-picker label="" [initialDate]="lodash.get(row, column.columnDef)"
                (emitDate)="dateChangeEmitter($event, i, column.columnDef, column?.action, row)">
              </drdp-date-picker>
            </span>
            <span *ngIf="!row.isSelect" [innerText]="lodash.get(row, column.columnDef) | date:'MM/dd/yy'">
            </span>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="Table.textToSelect">
          <div *ngIf="column.isSortable" class="w-10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="text-start w-2 truncate">
              <div *ngIf="!row.isSelect">
                {{ lodash.get(row, column.columnDef) }}
              </div>
              <div *ngIf="row.isSelect">
                <mat-select [placeholder]="lodash.get(row, column.columnDef)">
                  <mat-option *ngFor="let item of row.items" [value]="item.id"
                    (click)="editEmitter(item, i, column?.action)">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </div>
            </td>
          </div>
          <div #notSortable class="w-10">
            <th mat-header-cell *matHeaderCellDef class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="w-2 align-top truncate">
              <div *ngIf="!row.isSelect">
                {{ lodash.get(row, column.columnDef) }}
              </div>
              <div *ngIf="row.isSelect">
                <mat-select [placeholder]="lodash.get(row, column.columnDef)">
                  <mat-option *ngFor="let item of row.items" [value]="item.id"
                    (click)="editEmitter(item, i, column?.action)">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </div>
            </td>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="Table.textToDrdpSelect">
          <div *ngIf="column.isSortable">
            <th id="selectColHeader" mat-header-cell *matHeaderCellDef mat-sort-header class="w-2">{{ column.header }}
            </th>
            <td id="selectColCell" mat-cell *matCellDef="let row; let i = index" class="text-start dropdown-width">
              <div *ngIf="!row.isSelect">
                {{ lodash.get(row, column.columnDef) }}
              </div>
              <div *ngIf="row.isSelect">
                {{column.optionKey}}
                <drdp-filter-select [options]="row[column.optionKey]" [selectType]="column.selectType"
                  (onSelected)="editEmitter($event, i, column?.action)" [initialValues]="row[column.initialSelectId]">
                </drdp-filter-select>
              </div>
            </td>
          </div>
          <div #notSortable>
            <th mat-header-cell *matHeaderCellDef class="w-2">{{ column.header }}</th>
            <td mat-cell *matCellDef="let row; let i = index" class="text-start dropdown-width">
              <div *ngIf="!row.isSelect">
                {{ lodash.get(row, column.columnDef) }}
              </div>
              <div *ngIf="row.isSelect">
                <drdp-filter-select [options]="row[column.optionKey]" [selectType]="column.selectType"
                  (onSelected)="editEmitter($event, i, column?.action)" [initialValues]="row[column.initialSelectId]">
                </drdp-filter-select>
              </div>
            </td>
          </div>
        </ng-container>

        <!-- table action buttons -->
        <ng-container *ngSwitchCase="Table.toggleSwitch">
          <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <mat-slide-toggle color="primary" [disabled]="column.readOnly" [ngModel]="lodash.get(row, column.columnDef)"
              [ngModelOptions]="{ standalone: true }" aria-label="Slider Toggle"
              (change)="toggleSwitchEmitter($event, row, i, column.columnDef)"></mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="Table.add">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a (click)="addEmitter(row, i)" class="text-drdpblue-300 pl-4 text-xl uil uil-plus cursor-pointer"></a>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="Table.edit">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a (click)="editEmitter(row, i)" class="text-xl uil cursor-pointer"
              [ngClass]="row.colorClass + ' ' + (row.isSelect ? 'uil-save' : 'uil-pen')"></a>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="Table.delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a (click)="deleteEmitter(row, i)" class="text-red-700 pl-4 text-xl uil uil-trash-alt cursor-pointer"></a>
          </td>
        </ng-container>

        <ng-container>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a *ngIf="!row.hideIcon" title="{{row.tooltipText}}" (click)="iconEmitter(row, i, column.columnDef)"
              class="text-drdpblue-300 pl-4 text-xl uil {{row.actionIcon}} cursor-pointer"></a>
          </td>
        </ng-container>

      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </mat-table>

  <div *ngIf="dataSource.data?.length == 0" class="w-full p-5 flex justify-center">
    <h1>
      {{noDataMessage ? noDataMessage : "There are no records to display"}}
    </h1>
  </div>


</div>
