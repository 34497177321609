import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { States } from '@core/enums/states';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { IState } from '@core/interfaces/istate';
import { ToastService } from '@core/services/toast.service';
import { IStateOsepSearch } from '../interfaces/ireport-search';
import { ReportService } from '@core/services/report.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-osep-exception',
  templateUrl: './osep-exception.component.html',
  styleUrls: ['./osep-exception.component.scss']
})
export class OsepExceptionComponent implements OnInit {
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  reportService = inject(ReportService);  
  startingSchoolYear: string = '2024';
  
  searchForm: FormGroup | any;
  initStateId: number = States.newHampshire;
  
  get stateId() { return this.searchForm.get('stateId'); }
  get schoolYearId() { return this.searchForm.get('schoolYearId'); }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: new FormControl<number | null>(this.initStateId, Validators.required),
      schoolYearId: new FormControl<number | null>(null, Validators.required),
    });
  }

  handleState(state: IState | null) {
    this.stateId.setValue(state?.id);
  }

  handleSchoolYear(schoolYear: ISchoolYear | null): void {
    this.schoolYearId.setValue(schoolYear?.id);
  }

  handleDownload(): void {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    const searchValues: IStateOsepSearch = this.searchForm.value;
    if (searchValues.stateId !== States.newHampshire) {
      this.toastService.error('Only supported for New Hampshire state.');
      return;
    }
    
    this.reportService.generateOSEPExceptionReport(searchValues).pipe(take(1)).subscribe(res => {
      const fileName = 'OSEPExceptionDetail.xlsx';
      const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }
}
