import { Component, OnInit } from '@angular/core';
import { CompanionDocuments, UploadTypes } from '@core/enums/upload-types';
import { ICompanionDocument } from '@core/interfaces/icompanion-document';
import { LookupService } from '@core/services/lookup.service';
import { ToastService } from '@core/services/toast.service';
import { environment } from '@env/environment';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-companion-documents',
  templateUrl: './companion-documents.component.html',
  styleUrls: ['./companion-documents.component.scss']
})
export class CompanionDocumentsComponent implements OnInit {
  private storageUrl = environment.storageUrl;
  public get companionTypes() { return CompanionDocuments }
  constructor(public lookupService: LookupService, public toastService: ToastService) { }

  ngOnInit(): void {
  }

  downloadDocument(companionType: number) {
    this.lookupService.getCompanionDocument(companionType)
      .pipe(
        take(1),
        tap((res: ICompanionDocument) => {
          const fileName = res.fileName;
          const fileUrl = `${this.storageUrl}${res.documentLink}`;
  
          this.fetchAndDownloadFile(fileUrl, fileName);
        })
      )
      .subscribe();
  }
  
  private fetchAndDownloadFile(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.style.display = 'none';
  
    fetch(fileUrl)
      .then(response => {
        if (!response.ok) {
          this.toastService.error('Failed to download file.');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        this.toastService.error('Failed to download file.');
      })
      .finally(() => {
        document.body.removeChild(link);
      });
  
    document.body.appendChild(link);
  }  
}
