import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Output() emitDate = new EventEmitter<any>();
  @Input() label: string = 'datePicker';
  @Input() placeholder: string = 'mm/dd/yyyy';
  @Input() isReadOnly?: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() labelClasses: string = '';
  @Input() show = true;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() resetDate?: boolean = false;
  dateForm: FormGroup | any;

  private _initialDate: string | number | Date = '';

  @Input()
  set initialDate(value: string | number | Date) {
    this._initialDate = value;
    if (value) {
      this.date?.setValue(new Date(value));
    } else {
      this.date?.setValue(null);
    }
  }
  get inputValue(): string | number | Date {
    return this._initialDate;
  }

  get date() {
    return this.dateForm?.get('date');
  }
  constructor(private fb: FormBuilder, private toastService: ToastService) {
    this.initializeForm();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resetDate']) {
      this.initializeForm();
    }
  }

  initializeForm(): void {
    this.dateForm = this.fb.group({
      date: [this.initialDate],
    }, { validator: [this.dateLessThan('date')]} as AbstractControlOptions);
    this.dateForm.markAsPristine();
    const dateValidators = this.isRequired ? [Validators.required] : [];
    this.date.setValidators(dateValidators);
    this.date.updateValueAndValidity();
  }

  dateLessThan(dateControl: string) {
    return (group: FormGroup) => {
      if (this.minDate && this.isRequired) {
        const dateValue = new Date(this.date.value).getTime();
        const minDateValue = new Date(this.minDate).getTime();
        const formControl = group.controls[dateControl];
        if ( minDateValue > dateValue) {
          return formControl.setErrors({invalidDates: true});
        }
      }
      return {}
     }
  }

  handleDate(event: MatDatepickerInputEvent<Date>) {
    if (!event) {
      this.date.setValue(null);
      this.emitDate.emit(null);
      return;
    }

    this.date.setValue(event);
    const formattedDate = this.formatDate(new Date(this.date.value));
    this.emitDate.emit(formattedDate);
  }

  private formatDate(date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    if (year.toLocaleString().length !== 2 && year.toLocaleString().length !== 5) {
      this.toastService.error(`Enter a valid year. Ex: 'YY' or 'YYYY`);
      this.date.reset();
      return '';
    }
    return `${this.padNumber(month)}/${this.padNumber(day)}/${year}`;
  }

  private padNumber(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
