import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { take } from 'rxjs';
import { isDateBetween } from '@core/services/helper.service';

@Component({
  selector: 'app-sped-exit-date',
  templateUrl: './sped-exit-date.component.html',
  styleUrls: ['./sped-exit-date.component.css'],
})
export class SpedExitDateComponent implements OnInit {
  spedExitDateForm: FormGroup | any;
  child: any;
  isProcessing = false;
  validDate = false;
  constructor(
    public toastService: ToastService,
    private fb: FormBuilder,
    public modal: MatDialog,
    public dialogRef: MatDialogRef<SpedExitDateComponent>,
    public studentService: StudentService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.child = this.editData.data;
    this.initializeForms();
  }

  initializeForms(): void {
    this.spedExitDateForm = this.fb.group({
      studentId: [this.child.id, Validators.required],
      classId: [this.child.classId, Validators.required],
      spedEntryDate: [this.child.spedEntryDate, Validators.required],
      spedExitDate: [this.child.spedExitDate, Validators.required],
    });
  }

  handleExitDate(event: any) {
    this.spedExitDateForm.get('spedExitDate').setValue(event);
    this.checkDates();
  }

  handleEntryDate(event: any) {
    this.spedExitDateForm.get('spedEntryDate').setValue(event);
    this.checkDates();
  }

  checkDates() {
    const entryDate = this.spedExitDateForm.get('spedEntryDate').value;
    const exitDate = this.spedExitDateForm.get('spedExitDate').value;
    const gpMinStartDate = new Date(this.child.gpMinStartDate);
    const gpMaxEndDate = new Date(this.child.gpMaxEndDate);

    if (entryDate && exitDate) {
      // Extract date parts from the date strings and compare
      const startDate = new Date(entryDate);
      const endDate = new Date(exitDate);

      const startOnlyDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      const endOnlyDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      );

      this.validDate = false;
      if (startOnlyDate > endOnlyDate) {
        this.toastService.error('Exit date should be greater than entry date');
      } else if (!isDateBetween(gpMinStartDate, gpMaxEndDate, endOnlyDate) ) {
        this.toastService.error(`The Exit Date must fall within the current enrollment Rating Period.
        If the Exit Date needs to be a past date that falls within a past Rating Period,
        please go to that Rating Period Classroom's page.`);
      } else {
        this.validDate = true;
      }
    }

  }

  onSubmit() {
    if (this.spedExitDateForm.invalid || !this.validDate) {
      this.toastService.error('Please enter all required fields. ');
      return;
    }
    this.isProcessing = true;
    const formValues = this.spedExitDateForm.value;
    const payload = {
      studentIds: [formValues.studentId],
      spedExitDate: formValues.spedExitDate,
      spedEntryDate: formValues.spedEntryDate,
      classId: formValues.classId,
      schoolYearId: this.child.schoolYearId,
    };
    this.studentService
      .updateStudentSpedDates(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.isProcessing = false;
        if (res.isSuccess) {
          this.toastService.success('SPED Dates updated successfully');
          this.dialogRef.close({ success: true });
        } else if (res.incompleteCOSStudents.length > 0) {
          this.toastService.error('The Outcome data on the Demographics page is not complete. All 3 outcomes must be chosen and saved. Cannot proceed with an Exit Date.');
        }
        else if (res.missingScoresStudents.length > 0) {
          this.toastService.error('No scores found for this child. Cannot proceed with an Exit Date');
        }
      });
  }
}
