<drdp-container additionClasses="mb-4">
  <div class="w-full">
    <h1 class="text-xl font-semibold my-2">Report Usage</h1>
    <drdp-container color="lightBlue" additionClasses="p-6">
      <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-2 my-auto" [formGroup]="ratingCompletionSearch"
        (ngSubmit)="handleDownload()">
        <div class="lg:col-span-5 md:col-span-2 col-span-1">
          <label>
            <mat-checkbox color="primary" (change)="handleGlobal()">
            </mat-checkbox>
            Search All Agencies
          </label>
        </div>

        <drdp-select-report-type #reportTypeSelect (onSelected)="handleDropdown($event, selectType.ReportType)"
          [clear]="clearDropdowns" [required]="true" [isReportTracking]="true">
        </drdp-select-report-type>

        <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleDropdown($event, selectType.States)"
          [clear]="clearDropdowns" [required]="true">
        </drdp-select-state>

        <drdp-select-school-year #schoolYearSelect (schoolYear)="handleDropdown($event, selectType.SchoolYear)"
          [clear]="clearDropdowns" [required]="false" [currentSchoolYear]="true">
        </drdp-select-school-year>

        <drdp-select-agency #agencySelect [hidden]="allAgencies" [stateId]="stateId.value"
          [required]="false" [initialAgencyId]="agencyId.value" [clear]="clearDropdowns" (agency)="handleDropdown($event, selectType.Agencies)">
        </drdp-select-agency>

        <drdp-select-rating-period (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)"
          [clear]="clearDropdowns" [schoolYear]="schoolYearId.value" [multiSelect]="true" [agencyId]="agencyId.value">
        </drdp-select-rating-period>

        <drdp-select-age-grade #ageGroupInstrumentSelect (ageGrade)="handleDropdown($event, selectType.AgeInstrument)"
          [stateId]="stateId.value" [multiSelect]="true" [clear]="clearDropdowns">
        </drdp-select-age-grade>

        <drdp-select-report-action #reportActionSelect (onSelected)="handleDropdown($event, selectType.ReportAction)"
          [clear]="clearDropdowns" [required]="false">
        </drdp-select-report-action>

        <div [ngClass]="btnClass" class="flex flex-row md:flex-col-2 gap-5 items-end lg:flex-row justify-end">
          <div class="mb-0 md:mb-2 lg:mb-0">
            <drdp-btn type="submit" class="lg:mr-2" [disabled]="!ratingCompletionSearch.valid">
              Download Report Usage
            </drdp-btn>
          </div>
        </div>
      </form>
    </drdp-container>
  </div>
</drdp-container>



