<div class="p-5">
  <div class="flex justify-between">
    <drdp-title [title]="label"></drdp-title>
    <span *ngIf="id.value">
      <drdp-btn *drdpCheckPermission="Permission.IntegrationManagement" color="outline"
        (click)="generateAPIKey()">Generate API Key</drdp-btn>
    </span>
  </div>

  <drdp-container [additionClasses]="'min-h-[22rem]'">
    <form class="grid grid-cols-2 gap-x-8 gap-x-10 pt-5" [formGroup]="vendorForm">

      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required" for="name">Vendor Name</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Vendor Name" autocomplete="off" id="vendorName" type="text"
            formControlName="name" maxlength="50" required>
        </mat-form-field>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <div class="grid grid-cols-2 gap-x-4 gap-x-10">

          <div class="col-span-1 items-center w-[100%]">
            <label class="font-normal" for="name">Vendor ID</label>
            <p *ngIf="id.value">{{id.value}}</p>
          </div>

          <div class="col-span-1 items-center w-[100%]">
            <label class="font-normal" for="name">Visibility</label>
            <mat-checkbox formControlName="isVisible" color="primary">
            </mat-checkbox>
          </div>

        </div>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required" for="name">Contact Email</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Enter Contact Email" autocomplete="off" id="email" type="text"
            formControlName="emailName" maxlength="100">
        </mat-form-field>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal" for="name">Contact Phone</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Enter Contact Phone" autocomplete="off" id="phone" type="text"
            mask="(000) 000-0000" formControlName="phoneNumber" maxlength="">
        </mat-form-field>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal" for="permissions">Permissions</label>
        <div class="grid grid-cols-2 gap-x-4 gap-x-10">

          <div class="col-span-1 items-center w-[100%]">
            <mat-checkbox formControlName="permissions_hasSingleScore" color="primary">
              Single Score
            </mat-checkbox>
          </div>
          <div class="col-span-1 items-center w-[100%]">
            <mat-checkbox formControlName="permissions_hasAgencyExtract" color="primary">
              Agency Extract
            </mat-checkbox>
          </div>

        </div>
      </div>
    </form>
    <div class=" flex grow"></div>
    <div mat-dialog-actions class="flex justify-end ">
      <drdp-btn type="button" color="cancel" class="mr-2" [routerLink]="'/config/integrations'"
        mat-button>Cancel</drdp-btn>
      <drdp-btn type="submit" [disabled]="vendorForm.invalid" (click)="submit()">Save & Exit</drdp-btn>
    </div>
  </drdp-container>
</div>