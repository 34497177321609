import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Permission } from '@core/enums/permissions';
import { IAgency } from '@core/interfaces/iagency';
import { AgencyService } from '@core/services/agency.service';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() loggedIn?: boolean;
  agencyFooter?: string;
  footerText: string = '';
  user = this.auth.getCurrentUser();
  public get Permission() {
    return Permission;
  }

  // Default footer pieces
  defaultFooter: string = `<p>State of California Department of Social Services, Copyright 2024. Joint funding provided by 
  the California Department of Social Services, and the California Department of Education.</p><p>For assistance, please contact
  <a class="text-drdpblue-300 hover:underline" href="tel:1-800-770-6339">1-800-770-6339</a> or email
  <a class="text-drdpblue-300 hover:underline" href="mailto:DRDPOnline@wested.org">DRDPOnline@wested.org</a></p>`;

  constructor(
    private auth: AuthService,
    private permission: PermissionService,
    private agency: AgencyService
  ) {}

  ngOnInit(): void {
    if (this.loggedIn) {
      this.checkAgencyFooter();
    }
  }

  checkAgencyFooter() {
    let agencyId = this.user.agencyId ? this.user.agencyId : 0;
    let hasPermission = this.permission.checkPermission(
      Permission.ViewAgencies
    );

    if (hasPermission && agencyId > 0) {
      this.agency
        .getAgency(agencyId)
        .pipe(
          take(1),
          tap((agency: any) => {
            this.agencyFooter = agency.footer;
          })
        )
        .subscribe();
    }
  }
}
