import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { ICounty } from '@core/interfaces/icounty';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-county',
  templateUrl: './select-county.component.html',
  styleUrls: ['./select-county.component.scss']
})
export class SelectCountyComponent implements OnInit {
  @ViewChild('countySelect') countySelect: FilterSelectComponent | undefined;
  @Output() county = new EventEmitter<any>();
  @Output() initCounties = new EventEmitter<boolean>();
  @Input() initialCountyIds?: number[] | null;
  @Input() stateId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = true;
  @Input() multiSelectInitVals: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = 'County';
  countyOptions: ICounty[] = [];
  public get select() {return SelectType }
  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    if (this.stateId) this.getCounties(this.stateId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.countySelect?.clearSearch(true);
    };

    if (this.stateId == 0) this.lookupService.getCountyList();
    else if (this.stateId) this.getCounties(this.stateId);
    else this.countyOptions = [];
  }

  clearSelected() {
    if (!this.disabled)
      this.countySelect?.clearSelected();
  }

  getCounties(stateId: number): void {
    this.lookupService.getCountyByStateId(stateId).pipe(
      take(1)
    ).subscribe((res: any) => {
      this.initCounties.emit(res.length > 0);
      this.countyOptions = res;
    });
  }

  onCountySelect(event: number[]): void {
    this.county.emit(event);
  }
}
