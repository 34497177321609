import { Component, OnInit } from '@angular/core';
import { StudentService } from '@core/services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from '@core/services/toast.service';
import { IStudentSharedProviderDto } from '@core/interfaces/istudent';
import { take } from 'rxjs';
import { AddChildSharedProviderComponent } from './add-child-shared-provider/add-child-shared-provider.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteChildSharedProviderComponent } from './delete-child-shared-provider/delete-child-shared-provider.component';
import { TableColumnType } from '@core/enums/table';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-view-child-shared-providers',
  templateUrl: './view-child-shared-providers.component.html',
  styleUrls: ['./view-child-shared-providers.component.scss'],
})
export class ViewChildSharedProvidersComponent implements OnInit {
  dataSource: MatTableDataSource<IStudentSharedProviderDto> = new MatTableDataSource();
  tableData?: any;
  studentId: number = 0;
  canEdit = this.permissionService.checkPermission(
    Permission.EditStudents
  );
  constructor(
    private studentService: StudentService,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    public modal: MatDialog,
    private permissionService: PermissionService) { }

  tableColumns = [
    {
      columnDef: 'teacherName',
      header: 'Current Providers',
      type: TableColumnType.text
    },
    {
      columnDef: 'workTitle',
      header: 'Work Title',
      type: TableColumnType.text
    },
    {
      columnDef: 'emailAddress',
      header: 'Email Address',
      type: TableColumnType.text
    },
    {
      columnDef: 'userRole',
      header: 'User Role',
      type: TableColumnType.text
    },
    this.canEdit ?
    {
      columnDef: 'delete',
      header: 'Delete',
      type: TableColumnType.delete
    } : null
  ].filter(Boolean);

  ngOnInit(): void {
    const studentId = this.route.snapshot.paramMap.get('id');

    if (!studentId) {
      this.toastService.error('StudentId parameter is missing.');
      return;
    }

    this.studentId = +studentId;

    this.getStudentSharedProviders();
  }

  getStudentSharedProviders() {
    this.studentService
      .getStudentSharedProviders(this.studentId)
      .pipe(take(1))
      .subscribe((res: IStudentSharedProviderDto[]) => {
        this.dataSource = new MatTableDataSource(res);
        this.tableData = res;
      });
  }

  openModal(): void {
    const dialogRef = this.modal.open(AddChildSharedProviderComponent, {
      data: { studentId: this.studentId }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.success) {
        this.getStudentSharedProviders();
      }
    });
  }

  deleteModal(id: number): void {
    const dialogRef = this.modal.open(DeleteChildSharedProviderComponent, {
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.success) {
        this.getStudentSharedProviders();
      }
    });
  }
}
