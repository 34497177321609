import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,  } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  take, tap } from 'rxjs';
import { IClassroom, IClassroomSearchRequest, IUpdateClass } from '@core/interfaces/iclasses';
import { ClassroomService } from '@core/services/classroom.service';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { Role } from '@core/enums/roles';
import { SelectType } from '@core/enums/select';
import { AgencyService } from '@core/services/agency.service';
import { IAgency } from '@core/interfaces/iagency';
import { LookupService } from '@core/services/lookup.service';
import { ClassService } from '@core/services/class.service';
import { mapClassResToDRDPSelect } from '@core/services/helper.service';

@Component({
  selector: 'drdp-add-edit-classes',
  templateUrl: './add-edit-classes.component.html',
  styleUrls: ['./add-edit-classes.component.scss'],
})

export class AddEditClassesComponent implements OnInit {
  classroomForm: FormGroup | any;
  user = this.authService.getCurrentUser();
  options: any;
  label?: string;
  currentSchoolYear?: number;
  isNewClassroom?: boolean = true;
  hasAccessToAgencies?: boolean;
  siteId?: number | null;
  payload: IUpdateClass = {};
  clearDropdown = false;
  canEditRatingPeriods: boolean = false;
  isPilotClassroom: boolean = false;

  selectedState?: number | null;
  selectedAgency?: number | null;
  selectedRatingPeriodId?: number | null;
  selectedTeachers?: number[] | null;
  selectedTeacherOfRecordId?: number | null;
  selectedClassrooomId?: number | null;
  selectedClassroomName?: string | null;
  select = SelectType;


  get selectType() { return SelectType; }
  get role() { return Role; }

  get ratingPeriodId() { return this.classroomForm.get('ratingPeriodId'); }
  get teacherOfRecordId() { return this.classroomForm.get('teacherOfRecordId'); }
  get teachers() { return this.classroomForm.get('teachers'); }
  get agencyId() { return this.classroomForm.get('agencyId'); }
  get stateId() { return this.classroomForm.get('stateId'); }
  get classroomId() { return this.classroomForm.get('classroomId'); }

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public dialogRef: MatDialogRef<AddEditClassesComponent>,
    private fb: FormBuilder,
    public toast: ToastService,
    private classroomService: ClassroomService,
    private classService: ClassService,
    private authService: AuthService,
    private agency: AgencyService,
    private lookup: LookupService) {  dialogRef.disableClose = true }

  ngOnInit(): void {
    this.hasAccessToAgencies = this.authService.isSuperUser() || this.authService.isWestEdPilot();
    this.initializeForm();
    this.getClassroomNames();

    this.lookup.getCurrentSchoolYear().pipe(
      take(1),
      tap((schoolYear: any) => this.currentSchoolYear = schoolYear.id)
    ).subscribe();

    if (!this.editData) {
      this.selectedState = this.user.stateId;
      this.selectedAgency = this.user.agencyId;
      this.label = "Add Teacher Assignments and Rating Periods";
      this.canEditRatingPeriods = true;
    } else {
      this.label = "Edit Teacher Assignments and Rating Periods";
      this.canEditRatingPeriods = this.canEditRatingPeriod(this.editData.data.minStartDate);
    }
  }

  initializeForm(): void {
    this.classroomForm = this.fb.group({
      classroomId: [null, Validators.required],
      ratingPeriodId: [null, Validators.required],
      teacherOfRecordId: [null, Validators.required],
      teachers: [null],
      agencyId: [this.user.agencyId, Validators.required],
      stateId: [this.user.stateId, Validators.required]
    });

    if (this.editData) {
      const edit = this.editData.data;

      this.selectedState = edit.stateId;
      this.selectedAgency = edit.agencyId;
      this.selectedRatingPeriodId = edit.ratingPeriodId;
      this.selectedTeachers = edit.teacherIds;
      this.selectedTeacherOfRecordId = edit.teacherOfRecordId;
      this.selectedClassrooomId = edit.classroomId;
      this.selectedClassroomName = edit.name;

      this.classroomForm.patchValue({
        classroomName: edit.name,
        classroomId: this.selectedClassrooomId,
        ratingPeriodId: this.selectedRatingPeriodId,
        teacherOfRecordId: this.selectedTeacherOfRecordId,
        teachers: this.selectedTeachers,
        agencyId: this.selectedAgency,
        stateId: this.selectedState
      });
      this.siteId = edit.siteId;
    }
  }

  canEditRatingPeriod(minStartDate: Date): boolean {
    const today = new Date();
    const dateWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return new Date(minStartDate) > dateWithoutTime;
  }

  getClassroomNames():void {
      let req: IClassroomSearchRequest = {
          stateId: this.stateId.value,
          agencyId: this.agencyId.value
      }
      this.classroomService.getClassroomByUserAccess((req)).pipe(take(1))
      .subscribe((res: IClassroom[]) => {
        if (res) {
          this.options = mapClassResToDRDPSelect(res, true, true);
        }
      });

  }

  handleDropdown(data: any, type: string | number): void {
    switch (type) {
      case this.selectType.States:
        this.selectedState = data ? data.id : null;
        this.stateId.setValue(this.selectedState);
        this.stateId.markAsTouched();

        if (this.selectedState) {
          this.agency.getAgenciesByState([this.selectedState]).pipe(
            take(1),
            tap((agency: IAgency[]) => {
              this.selectedAgency = agency[0] ? agency[0].id : null;
              this.agencyId.setValue(this.selectedAgency);
            }),
            tap(_ => {
              this.clearDropdown = true;
              this.stateId.setValue(this.selectedState);
            })
          ).subscribe();
        }
        break;
      case this.selectType.Agencies:
        this.selectedAgency = data ? data.id : null;
        this.agencyId.setValue(this.selectedAgency);
        this.agencyId.markAsTouched();
        this.getClassroomNames();
        break;
      case this.selectType.Class:
        this.siteId = null;
        this.selectedClassrooomId = data ? data.id : null;
        this.selectedClassroomName = data ? data.name : null;
        this.classroomId.setValue(this.selectedClassrooomId);
        if (data?.id) {
          const classroom = this.options.find((c:any) => c.id === data.id);
          this.siteId = classroom?.siteId ?? null;
        }
        this.classroomId.markAsTouched();
        this.isPilotClassroom = data.isPilot;
        if (this.isPilotClassroom) this.ratingPeriodId.setValue(null);
        break;
      case this.selectType.RatingPeriods:
        this.selectedRatingPeriodId = data ? data.id : null;
        this.ratingPeriodId.setValue(this.selectedRatingPeriodId);
        this.ratingPeriodId.markAsTouched();
        break;
      case Role.TeacherOfRecord:
        this.selectedTeacherOfRecordId = data ? data.id : null;
        this.teacherOfRecordId.setValue(this.selectedTeacherOfRecordId);
        this.teacherOfRecordId.markAsTouched();
        break;
      case Role.Teacher:
        this.selectedTeachers = data ? data.map((teacher: any) => teacher.id) : null;
        this.teachers.setValue(this.selectedTeachers);
        this.teachers.markAsTouched();
        break;
      default:
        break;
    }
  }

  onSubmit(): any {
    if (this.classroomForm.invalid) {
      this.toast.error("Form not complete");
      return;
    }
    this.payload = {
      id: this.editData?.data?.id ?? 0,
      ...this.classroomForm.value
    }

    var message = '';

    if (this.payload.id == 0) {
      message =  `Added ${this.selectedClassroomName} Assignments and Terms`;
    } else {
      message = `Updated ${this.selectedClassroomName} Assignments and Terms`;
    }

    this.classService.saveAll(this.payload, message).pipe(take(1)).subscribe((res:boolean) => {
      if (res) {
        this.dialogRef.close();
      }
    });

  }

}
