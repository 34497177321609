<div *ngIf="studentInfo">
  <div class="grid grid-cols-1 md:grid-cols-3 gap-3 mt-5 px-0">
    <div class="flex flex-wrap">
      <div class="w-2/5">Child:</div>
      <div class="w-3/5 font-bold">{{studentInfo.studentName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">District/Agency:</div>
      <div class="w-3/5 font-bold">{{studentInfo.agencyName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">Classroom Enrollment Date:</div>
      <div class="w-3/5 font-bold">{{studentInfo.enrollmentDate | date: 'longDate'}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">Class:</div>
      <div class="w-3/5 font-bold">{{studentInfo.classroomName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">School or Site:</div>
      <div class="w-3/5 font-bold">{{studentInfo.siteName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">Assessment Date:</div>
      <div class="w-3/5 font-bold">{{studentInfo.assessmentDate | date: 'longDate'}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">Teacher:</div>
      <div class="w-3/5 font-bold">{{studentInfo.teacherName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5">Age Group Instrument:</div>
      <div class="w-3/5 font-bold">{{studentInfo.ageGradeInstrumentName}} <span
          *ngIf="studentInfo.isCustom">(Custom)</span></div>
    </div>
    <div class=" flex flex-wrap">
      <div class="w-2/5">Lock Date:</div>
      <div class="w-3/5 font-bold">{{studentInfo.lockingDate | localDate | date: 'longDate'}}</div>
    </div>

  </div>

  <div class="md:flex place-content-between place-items-center self-center mt-5"
    *ngIf="hasMeasures && !studentInfo?.isPilotEnrollment">
    <p class="text-sm">
      <ng-container *ngIf="studentInfo.templateUrl">The <a class="text-blue-700 font-bold"
          [href]=" studentInfo.templateUrl" target="_blank">DRDP instrument</a>
        must be used
        with this rating
        record to ensure
        accuracy when rating.
      </ng-container>
    </p>
  </div>

  <div class="flex flex-wrap justify-end items-end space-x-2 mt-2">
    <div><a class="text-sm cursor-pointer" (click)="goBack()"><i
      class="uil uil-angle-left-b text-base"></i>Back to View Class</a></div>
    <drdp-btn *ngIf="isScantron && !studentInfo.isLocked && canSaveRatings"
      (click)="onActionClick(InputRatingAction.Save)" color="outline">
      <i class="text-drdpblue-300 text-xl uil uil-save"></i>
      Save</drdp-btn>
    <drdp-btn (click)="onActionClick(InputRatingAction.Previous)" color="outline"> <i
        class="text-drdpblue-300 text-xl uil uil-previous"></i>
      Previous</drdp-btn>
      <drdp-btn (click)="onActionClick(InputRatingAction.Next)" color="outline"> <i
        class="text-drdpblue-300 text-xl uit uil-step-forward"></i>
      Next</drdp-btn>
    <drdp-btn (click)="onActionClick(InputRatingAction.Print)" color="outline"> <i
        class="text-drdpblue-300 text-xl uil uil-print"></i>
      Print</drdp-btn>
    <drdp-btn *ngIf="!studentInfo.isLocked && canLockRatings"
      (click)="onActionClick(InputRatingAction.Lock)" color="outline">
      <i class="text-drdpblue-300 text-xl uil uil-lock"></i>Lock</drdp-btn>

    <drdp-btn *ngIf="studentInfo.isLocked && canUnlockRatings"
      (click)="onActionClick(InputRatingAction.Unlock)" color="outline">
      <i class="text-drdpblue-300 text-xl uil uil-unlock"></i>
      Unlock</drdp-btn>
  </div>
</div>
