<drdp-container>
  <div class="pl-1 w-1/5">
    <h1 class="text-xl font-bold border-b" *ngIf="currentPeriods.length == 0">
      Add Term Dates
    </h1>
    <h1 class="text-xl font-bold border-b" *ngIf="currentPeriods.length">
      Term Dates
    </h1>
  </div>
  <div *ngIf="currentPeriods.length == 0">
    <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="formTableColumns"
      (editActionItem)="dateChange($event)">
    </drdp-add-edit-table>
  </div>
  <div *ngIf="currentPeriods.length">
    <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="formTableColumns">
    </drdp-add-edit-table>
  </div>
  <div class="flex justify-end items-en mt-5">
    <div *ngIf="currentPeriods.length == 0">
      <drdp-btn [disabled]="!(validForm$ | async)" (click)="confirmSave()">Save</drdp-btn>
    </div>
    <div *ngIf="currentPeriods.length && !agenciesApplied">
      <drdp-btn [disabled]="noSubmit" (click)="confirmSubmit()">Submit</drdp-btn>
    </div>
  </div>
</drdp-container>