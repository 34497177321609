import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StudentService } from "@core/services/student.service";
import { ToastService } from "@core/services/toast.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from "rxjs";

@Component({
    selector: 'drdp-delete-child-shared-provider',
    templateUrl: './delete-child-shared-provider.component.html',
    styleUrls: ['./delete-child-shared-provider.component.scss'],
})

export class DeleteChildSharedProviderComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DeleteChildSharedProviderComponent>,
        public toast: ToastService,
        private studentService: StudentService
    ) {
        dialogRef.disableClose = true
    }

    ngOnInit(): void { }


    onDelete() {
        const childSharedProviderId = this.data?.id;

        if (!childSharedProviderId) {
            this.toast.error('Id cannot be empty.');
            return;
        }

        this.studentService
            .deleteStudentSharedProvider(childSharedProviderId)
            .pipe(take(1))
            .subscribe((res: any) => {
                if (res) {
                    this.toast.success('Shared provider deleted successfully.');
                    this.dialogRef.close({ success: res });
                }
            });
    }

}