<div class="p-5">
  <div class="flex justify-between items-center">
    <drdp-title title="Features"></drdp-title>
  </div>

  <div class="flex flex-wrap">
    <div class="w-full mt-5">
      <!-- Features and Feature Hierarchy Tab -->
      <drdp-features-tabs></drdp-features-tabs>
    </div>
  </div>
</div>
