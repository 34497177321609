import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { IRatingBaseTemplate } from '@views/user/configuration/rating-view-setup/interfaces/irating-base-template';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import { ICustomRatingTemplateForm } from '@views/user/configuration/rating-view-setup/interfaces/icustom-rating-template-form';
import { ICustomRatingTemplate } from '@views/user/configuration/rating-view-setup/interfaces/icustom-rating-template';

@Injectable({
  providedIn: 'root',
})
export class AgeGradeTemplateService {
  constructor(private data: DataService) {}

  getBaseTemplates(stateId: number): Observable<IRatingBaseTemplate[]> {
    return this.data.get(`agegradetemplate/base-templates/${stateId}`);
  }

  getAvailBaseTemplates(): Observable<IRatingBaseTemplate[]> {
    return this.data.get(`agegradetemplate/avail-base-templates`);
  }

  getBaseMeasures(
    ageGradeInstrumentId: number,
    ageGradeTemplateId: number
  ): Observable<IBaseTemplateDomain[]> {
    return this.data.get(
      `agegradetemplate/${ageGradeTemplateId}/base-measures/${ageGradeInstrumentId}`
    );
  }

  getBaseMeasuresByInstrument(
    ageGradeInstrumentId: number
  ): Observable<IBaseTemplateDomain[]> {
    return this.data.get(
      `agegradetemplate/base-measures/${ageGradeInstrumentId}`
    );
  }

  getCustomTemplateById(id: number): Observable<ICustomRatingTemplateForm> {
    return this.data.get(`agegradetemplate/custom/${id}`);
  }

  getCustomTemplatesByAgencyId(
    agencyId: number
  ): Observable<ICustomRatingTemplate[]> {
    return this.data.get(`agegradetemplate/custom/agency/${agencyId}`);
  }

  saveCustomTemplate(request: any): Observable<any> {
    return this.data.post(
      `agegradetemplate/custom`,
      request,
      'Alternate Ratings View Template succesfully saved.'
    );
  }

  saveBaseTemplate(request: any): Observable<any> {
    return this.data.post(
      `agegradetemplate/base-template`,
      request,
      'Base Template succesfully saved.'
    );
  }

  deleteAgeGradeTemplate(
    id: number
  ): Observable<any> {
    return this.data.post(`agegradetemplate/delete/${id}`, id);
  }
}
