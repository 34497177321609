<div class="p-5">
  <div>
    <drdp-title title="State Group Detail Report"></drdp-title>
    <div>
      <p>
        Select age group and rating period to create your state cohort/group. Additional filters are optional. To select a subset of 
        agencies, click on the appropriate agencies in the agency drop down menu or if running for whole state, check all agencies. Any 
        rating options other than developmental levels, will not be calculated in the report and will affect the number of children 
        reflected. The report will show all domain scaled scores for the selected group.
      </p>
    </div>
  </div>
  <drdp-report-search [hasSubfilters]="true" [multiSelectAgencies]="true" [allAgenciesCheckbox]="true" 
    [displaySite]="false" [displayClassroom]="false" [displayDownload]="false" [requiredFields]="requiredFields"
    ratingPeriodLabel="Cohort Rating Period" showReportBtnText="Send Request" (showReportClicked)="showReport($event)">
  </drdp-report-search>
</div>
