import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadTypes } from '@core/enums/upload-types';
import { AuthService } from '@core/services/auth.service';
import { DownloadTemplateService } from '@core/services/downloadTemplate.service';
import { ToastService } from '@core/services/toast.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-upload-templates',
  templateUrl: './upload-templates.component.html',
  styleUrls: ['./upload-templates.component.scss'],
})
export class UploadTemplatesComponent implements OnInit {
  stateId?: number;
  stateCode = '';

  public get uploadTypes() { return UploadTypes }
  constructor(
    private downloadTemplateService: DownloadTemplateService,
    private authService: AuthService,
    private toast: ToastService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.stateId = this.authService.getUserStateId();
    this.stateCode = this.authService.getUserStateCode();
  }

  downloadTemplate(uploadType: number) {
    let fileURL = '';
    if (this.stateId) {
      this.downloadTemplateService.downloadTemplate(this.stateId, uploadType, { responseType: 'blob', observe: 'response' }).pipe(
        take(1),
        tap((res: any) => {
          if (res) {
            let fileName = this.getFileName(uploadType);            
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(res.body);
            link.download = fileName;
            link.click();
          } else {
            this.toast.error('Template Unavailable')
          }
        })
      ).subscribe();
    }
  }

  getFileName(uploadType: number): string {
    if (uploadType === this.uploadTypes.user) return `User-Upload-${this.stateCode}.xlsx`;
    if (uploadType === this.uploadTypes.class) return `Class-Upload-${this.stateCode}.xlsx`;
    if (uploadType === this.uploadTypes.student) return `All-Instrument-Upload-${this.stateCode}.xlsx`;
    return 'Upload.xlsx'
  }
}
