<div *ngIf="newsItems" class="w-full flex flex-col">
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngIf="canSeeNews && user.stateId != 30 && isBeforeEndDate()">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill pillClass="WestEd">
            Agency
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold">
          Winter Rating Period Available
        </p>
      </div>
      <div class="overflow-x-auto scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class="relative overflow-x-auto">
          <div class="w-[91rem]">
            <p class="break-words mb-3">Winter Rating Period Now Open to Add Classes and Re-enroll Children.</p>
            <br>
            <p>The winter rating period is ideal for programs rating assessments 3-4 times per program year. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngFor="let news of newsItems">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill *ngIf="news.newsItemType.newsItemTypeName" [pillClass]="news.newsItemType.newsItemTypeName">
            {{ news.newsItemType.newsItemTypeName }}
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold">
          {{ news.newsItemTitle }}
          <drdp-btn *ngIf="news.createdBy === user.userId" class="mx-2 text-sm" (click)="editNewsItem(news)"
            cdkFocusInitial>
            <i class="uil uil-pen cursor-pointer"></i> Edit News Item
          </drdp-btn>
        </p>
      </div>
      <div class="overflow-x-auto scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class="relative overflow-x-auto">
          <div class="w-[91rem]">
            <p class=" break-words mb-3">{{ news.newsItemBody }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!newsItems">
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngIf="canSeeNews && user.stateId != 30 && isBeforeEndDate()">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill pillClass="WestEd">
            Agency
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold">
          Winter Rating Period Available
        </p>
      </div>
      <div class="overflow-x-auto scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class="relative overflow-x-auto">
          <div class="w-[91rem]">
            <p class="break-words mb-3">Winter Rating Period Now Open to Add Classes and Re-enroll Children.</p>
            <br>
            <p>The winter rating period is ideal for programs rating assessments 3-4 times per program year. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #noNewsMessage>
    <p> Nothing new to see here!</p>
  </ng-template>
  
</div>