<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-1/2">
      <h1 class="pb-2 text-3xl">Roles</h1>
    </div>
    <div class="basis-1/2">
      <div class="flex justify-end items-end">
        <drdp-btn *ngIf="canCreateRoles || isAdmin" (click)="openModal()"><i class="uil uil-plus"></i> Add New Role</drdp-btn>
      </div>
    </div>
  </div>

  <drdp-container>

    <div class="p-5 w-full">
      <drdp-add-edit-table 
        [dataSource]="dataSource"
        [tableData]="tableData" 
        [columns]="tableColumns"
        (editActionItem)="openModal($event)">
      </drdp-add-edit-table>

    </div>
  </drdp-container>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
  </mat-paginator>


</div>