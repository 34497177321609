import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EvidenceTab } from '@core/enums/evidence-tab';
import { MeasureCondition } from '@core/enums/measure-condition';
import { PortfolioEvidenceType } from '@core/enums/portfolio';
import { IEvidenceTabState } from '@core/interfaces/ievidence-tab-state';
import {
  IPortfolioEvidenceResponse,
  IPortfolioRatingSheet,
  IPortfolioUpdateEvidence,
} from '@core/interfaces/iportfolio';
import { PortfolioService } from '@core/services/portfolio.service';
import { ToastService } from '@core/services/toast.service';
import { IRatingMeasureDto } from '@views/user/input-rating/interfaces/irating-measure';
import { of, switchMap, take, tap } from 'rxjs';
import { PortfolioInputRatingModalComponent } from '../../../views/user/dashboard/portfolio/portfolio-class/portfolio-input-rating-modal/portfolio-input-rating-modal.component';
import { AgeGradeInstrumentCode } from '@core/enums/age-grade-instruments';
import { Permission } from '@core/enums/permissions';
import { AssignEnrollmentComponent } from '@views/user/dashboard/portfolio/portfolio-class/modals/assign-enrollment/assign-enrollment.component';
import { IStudentActiveEnrollmentInfo } from '@views/user/input-rating/interfaces/istudent-enrollment-info';

@Component({
  selector: 'drdp-portfolio-evidence-management',
  templateUrl: './portfolio-evidence-management.component.html',
  styleUrls: ['./portfolio-evidence-management.component.scss'],
})
export class PortfolioEvidenceManagementComponent implements OnInit, OnChanges {
  @Output() prelimRatingChange = new EventEmitter();
  @Output() rating = new EventEmitter();
  @Output() refreshEvidences = new EventEmitter();
  @Input() evidences?: IPortfolioEvidenceResponse[];
  @Input() domainGroupings?: any;
  @Input() domainId?: number;
  @Input() measureId?: number;
  @Input() ageGradeId?: number;
  @Input() ageGradeInstrumentCode?: string;
  @Input() isPortfolio = false;
  @Input() isDomain = true;
  @Input() description = '';
  @Input() ratingSheet!: IPortfolioRatingSheet;
  @Input() dialogData!: IRatingMeasureDto;
  @Input() isFutureEvidence: boolean = false;
  @Input() ratingIcon: string = '';
  @Input() availableClasses?: IStudentActiveEnrollmentInfo[];
  @Input() assignmentPayload?: any;
  @Input() hasStructuredPrompt?: boolean = false;
  @Input() isPilot?: boolean = true;
  containerName: string = 'portfolio-evidences';
  sasToken?: any;
  isActive: IEvidenceTabState = {
    rating: true,
    photo: false,
    video: false,
    audio: false,
    note: false,
    structuredPrompt: false,
  };
  count = {
    photo: 0,
    video: 0,
    audio: 0,
    note: 0,
    structurePrompt: 0
  };
  isEdit = false;
  isEvidenceEmpty = false;
  evidenceUpdateList: IPortfolioUpdateEvidence[] = [];
  get evidenceTab() {
    return EvidenceTab;
  }
  public get permission() {return Permission }
  constructor(
    private portfolioService: PortfolioService,
    public modal: MatDialog,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.getSasToken();
    if (this.isPortfolio) {
      this.isActive.rating = false;
      this.isActive.photo = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.evidences?.length) {
      this.count.photo = this.evidences?.filter(
        (photo: IPortfolioEvidenceResponse) =>
          photo.evidenceTypeID === PortfolioEvidenceType.Photo &&
          (this.domainId == undefined || photo.domainId === this.domainId) &&
          (this.measureId == undefined || photo.measureId === this.measureId)
      ).length;
      this.count.video = this.evidences?.filter(
        (video: IPortfolioEvidenceResponse) =>
          video.evidenceTypeID === PortfolioEvidenceType.Video &&
          (this.domainId == undefined || video.domainId === this.domainId) &&
          (this.measureId == undefined || video.measureId === this.measureId)
      ).length;
      this.count.audio = this.evidences?.filter(
        (audio: IPortfolioEvidenceResponse) =>
          audio.evidenceTypeID === PortfolioEvidenceType.Audio &&
          (this.domainId == undefined || audio.domainId === this.domainId) &&
          (this.measureId == undefined || audio.measureId === this.measureId)
      ).length;
      this.count.note = this.evidences?.filter(
        (note: IPortfolioEvidenceResponse) =>
          note.evidenceTypeID === PortfolioEvidenceType.Note &&
          (this.domainId == undefined || note.domainId === this.domainId) &&
          (this.measureId == undefined || note.measureId === this.measureId)
      ).length;
      this.count.structurePrompt = this.evidences?.filter(
        (note: IPortfolioEvidenceResponse) =>
          note.evidenceTypeID === PortfolioEvidenceType.StructuredPrompt &&
          (this.domainId == undefined || note.domainId === this.domainId) &&
          (this.measureId == undefined || note.measureId === this.measureId)
      ).length;
      this.evidences?.filter(
        (evidence: IPortfolioEvidenceResponse) =>
          evidence.domainId == this.domainId
      ).length
        ? (this.isEvidenceEmpty = false)
        : (this.isEvidenceEmpty = true);
    }
  }

  getSasToken(): void {
    this.portfolioService
      .getBlobSasToken(this.containerName)
      .pipe(
        take(1),
        tap((res: any) => (this.sasToken = res))
      )
      .subscribe();
  }

  handleTab(tab: string): void {
    for (let key in this.isActive) {
      this.isActive[key] = false;
    }
    this.isActive[tab] = true;
  }

  handleEdit(): void {
    this.isEdit = !this.isEdit;
  }

  handleSave(): void {
    this.isEdit = !this.isEdit;
    this.portfolioService
      .updateEvidence(this.evidenceUpdateList, 'Evidence Updated')
      .pipe(take(1))
      .subscribe();
  }

  handleEvidenceUpdateList(event: IPortfolioEvidenceResponse): any {
    let updatedEvidence: IPortfolioUpdateEvidence = {
      measureEvidenceId: event.id,
      evidenceId: event.evidenceId,
      notes: event.notes,
      selectedLevel: event.selectedLevel ?? null,
      measureValue: event.measureValue ?? null,
      selectedLevelName: event.selectedLevelName ?? null
    };
    if (
      this.evidenceUpdateList.some(
        (evidence) => evidence.measureEvidenceId === event.id
      )
    ) {
      let index = this.evidenceUpdateList.findIndex(
        (evidence: IPortfolioUpdateEvidence) =>
          evidence.measureEvidenceId === event.id
      );
      this.evidenceUpdateList[index] = updatedEvidence;
    } else {
      this.evidenceUpdateList.push(updatedEvidence);
    }
  }

  openInputRatingModal(evidence: IPortfolioEvidenceResponse) {
    if (this.ageGradeId && evidence) {
      this.portfolioService
        .getRatingMeasures(this.ageGradeId, evidence.measureId ?? 0)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            let data = {
              options: result.levels,
              selectedLevel: evidence.selectedLevel,
              selectedMeasureValue: evidence.measureValue,
              measureCode: evidence.measureCode,
            };

            const modalRef = this.modal.open(
              PortfolioInputRatingModalComponent,
              {
                data: data,
              }
            );

            modalRef
              .afterClosed()
              .pipe(
                take(1),
                tap((result: any) => {
                  if (result && result.success) {
                    evidence.measureValue = result.data.selectedId;
                    evidence.selectedLevel = result.data.selectedLevel;
                    evidence.selectedLevelName = result.data.selectedName;
                    this.handleEvidenceUpdateList(evidence);
                  }
                }),
                switchMap((_) => {
                  if (this.evidenceUpdateList.length > 0) {
                    return this.portfolioService.updateEvidence(
                      this.evidenceUpdateList,
                      'Rating updated.'
                    );
                  } else {
                    return of(false);
                  }
                }),
                tap((result: boolean) => {
                  if (result) {
                    this.prelimRatingChange.emit(evidence);
                  }
                })
              )
              .subscribe();
          }
        });
    }
  }

  updateEvidence() {
    this.refreshEvidences.emit();
  }

  updateRatingSheet(event: any): void {
    this.ratingSheet = event;
  }

  emitSave(): void {
    if (
      this.ratingSheet.measureConditionId == MeasureCondition.Emerging &&
      !this.ratingSheet.selectedValue
    ) {
      this.toast.error('Level is required if emerging is selected.');
      return;
    } else if (
      this.ageGradeInstrumentCode == AgeGradeInstrumentCode.kindergarten &&
      this.ratingSheet.measureConditionId == MeasureCondition.Unratable &&
      !this.ratingSheet.reasonId
    ) {
      this.toast.error('Reason is required if "Unable to Rate" is selected.');
      return;
    } else if (
      !this.ratingSheet.measureConditionId &&
      !this.ratingSheet.selectedValue
    ) {
      this.toast.error('Choose a valid option to save. ');
      return;
    }
    this.dialogData.selectedValue = this.ratingSheet.selectedValue;
    this.dialogData.measureConditionId = this.ratingSheet.measureConditionId;
    this.dialogData.measureConditionName =
      this.ratingSheet.measureConditionName;
    this.dialogData.childAssessmentRatingReasonId = this.ratingSheet.reasonId;
    this.dialogData.comment = this.ratingSheet.comment;

    if (this.ratingSheet.selectedValue) {
      this.dialogData.selectedLevelName =
        this.ratingSheet.measureRatingLevel?.levels.find(
          (l) => l.id == this.dialogData?.selectedValue
        )?.name;
    } else if (this.ratingSheet.measureConditionId) {
      this.dialogData.selectedLevelName =
        this.ratingSheet.measureRatingLevel?.conditionLevels.find(
          (l) => l.id == this.dialogData.measureConditionId
        )?.name;
    }
    this.dialogData.measureRevisionId = this.ratingSheet.measureRatingLevel?.measureRevisionId;
    this.rating.emit(this.dialogData);
  }

  toggleAssignmentModal(evidence: IPortfolioEvidenceResponse): void {
    const modalRef = this.modal.open(AssignEnrollmentComponent, {
      data: { availableClasses: this.availableClasses, evidenceId: evidence.evidenceId,info: this.assignmentPayload},
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.toast.success("Evidence assigned successfully.");
        this.refreshEvidences.emit();
      }
    })
  }
}
