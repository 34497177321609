<div class="px-5">
  <div class="flex justify-between pt-5">
    <drdp-title title="Pending Release Request"></drdp-title>
  </div>
  <drdp-container class="p-5">
    <div class="rounded-lg">
      <div>
        <drdp-container color="lightBlue" additionClasses="w-full p-4">
          <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4"
            [formGroup]="releaseRequestSearchForm" (ngSubmit)="search()">
            <drdp-select-state #stateSelect [initialStateId]="stateId?.value" (state)="handleState($event)" [clear]="clearDropdowns">
            </drdp-select-state>
            <drdp-select-agency #agencySelect (agency)="handleAgency($event)" [stateId]="stateId?.value"
            [initialAgencyId]="agencyId?.value" [clear]="clearDropdowns">
            </drdp-select-agency>
            <div class="col-span-1 select-input h-full grid space-between">
              <drdp-select-site #siteSelect label="Site" (site)="handleSites($event)"
              [agencyId]="agencyId.value" [clear]="clearDropdowns">
              </drdp-select-site>
            </div>
            <div class="col-span-1 select-input h-full grid space-between">
              <drdp-filter-select #classSelect label="Classroom" [options]="classrooms" [selectType]="selectClass"
                (onSelected)="handleClassroom($event)">
              </drdp-filter-select>
            </div>
            <div class="col-span-1 h-full grid space-between">
              <label class="font-bold">First Name</label>
              <div class="basis-1/2 border-b border-black">
                <input matInput placeholder="First Name" formControlName="firstName"
                  class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300">
              </div>
            </div>
            <div class="col-span-1 h-full grid space-between">
              <label class="font-bold">Last Name</label>
              <div class="basis-1/2 border-b border-black">
                <input matInput placeholder="Last Name" formControlName="lastName"
                  class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300">
              </div>
            </div>
            <div class="flex flex-row w-full">
              <drdp-btn color="outline" (click)="onClear()" class="self-end" type="button">
                Clear
              </drdp-btn>
              <drdp-btn class="flex-grow self-end pl-3" type="submit" additionalClasses="w-full">
                <span class="px-2">Search</span>
              </drdp-btn>
            </div>
          </form>
        </drdp-container>

        <div class="overflow-x-auto">
          <div *ngFor="let item of tableData; let i = index" class="grid grid-flow-col gap-2 border-b pt-5 pb-2">
            <div class="w-48">
              <div class="font-bold">Child Name</div>
              <p class="pt-2 break-words">{{ item.studentName }}</p>
            </div>
            <div class="w-32" *drdpCheckPermission="Permission.ViewSensitiveStudentTransferInfo">
              <div class="font-bold">DOB</div>
              <p class="pt-2 break-words">{{ item.dob | date:'shortDate' }}</p>
            </div>
            <div class="w-48" *drdpCheckPermission="Permission.ViewSensitiveStudentTransferInfo">
              <div class="font-bold">Site</div>
              <p class="pt-2 break-words">{{ item.fromSiteName }}</p>
            </div>
            <div class="w-48" *drdpCheckPermission="Permission.ViewSensitiveStudentTransferInfo">
              <div class="font-bold">Classroom</div>
              <p class="pt-2 break-words">{{ item.fromClassroomName }}</p>
            </div>
            <div class="w-64">
              <div class="font-bold">Release Request Reason</div>
              <div class="pt-2 break-words">{{ item.classReleaseReasonName }}</div>
            </div>
            <div class="w-48">
              <div class="font-bold">Release Request Date</div>
              <div class="pt-2 break-words">{{ item.createdOn | date:'shortDate' }}</div>
            </div>
            <div class="w-64" *drdpCheckPermission="Permission.ViewSensitiveStudentTransferInfo">
              <div class="font-bold">Requested By</div>
              <p class="pt-2 break-words">{{ item.requestedBy }}</p>
            </div>
            <div class="w-64" *drdpCheckPermission="Permission.ViewSensitiveStudentTransferInfo">
              <div class="font-bold">Requester Agency</div>
              <p class="pt-2 break-words">{{ item.requesterAgencyName }}</p>
            </div>
            <div class="w-64">
              <div class="font-bold">Requester Comment</div>
              <p class="pt-2 break-words">{{ item.comment }}</p>
            </div>
            <div class="w-48">
              <div class="font-bold">Decline Reason</div>
              <div class="pt-2 break-words">{{ item.declineReleaseReasonName }}</div>
            </div>
            <div>
              <div class="font-bold">Decline</div>
              <input class="accent-drdpgrey-200" type="checkbox"
              [checked]="item.decline" (change)="onDeclineChange($event,i)">
            </div>
            <div class="pl-3">
              <div class="font-bold">Accept</div>
              <input class="accent-drdpgrey-200" type="checkbox"
              [checked]="item.accept" (change)="onAcceptChange($event,i)">
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions class="flex justify-end align-bottom pt-8">
        <drdp-btn color="cancel" class="mr-3" mat-button type="button" (click)="onCancel()">
          Cancel
        </drdp-btn>
        <drdp-btn mat-button type="submit" (click)="onSubmit()" cdkFocusInitial>
          Save
        </drdp-btn>
      </div>
    </div>
  </drdp-container>
</div>
