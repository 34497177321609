<div class="p-5">
  <div>
    <drdp-title title="State Cohort Report"></drdp-title>
    <div>
      <p>
        Select all agencies, one agency, or multiple agencies. Select at least two (2) rating periods and age group. The Sites and Classrooms 
        within the selected agencies will automatically be included. Additional filters are optional. The report will show all domain scaled 
        scores for the selected group, to date.
      </p>
    </div>
  </div>
  <drdp-report-search [hasSubfilters]="true" [multiSelectAgencies]="true" [allAgenciesCheckbox]="true" [multiSelectRatingPeriods]="true"
    [displaySite]="false" [displayClassroom]="false" [displayDownload]="false" [displayClear]="false" [requiredFields]="requiredFields"
    showReportBtnText="Send Request" (showReportClicked)="showReport($event)">
  </drdp-report-search>
</div>