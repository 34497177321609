import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  IPermission,
  IPermissionGroupAssignment,
  IPermissionGroupAssignmentRequest,
} from '@core/interfaces/ipermission';
import { PermissionService } from '@core/services/permission.service';
import { ToastService } from '@core/services/toast.service';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-super-features-edit',
  templateUrl: './super-features-edit.component.html',
  styleUrls: ['./super-features-edit.component.scss'],
})
export class SuperFeaturesEditComponent implements OnInit {
  @ViewChild('currentSub') currentSub?: AddEditComponent;
  @ViewChild('addSub') addSub?: AddEditComponent;
  currentSubTableData?: any = [];
  currentSubDataSource: MatTableDataSource<any> = new MatTableDataSource();
  addSubTableData?: any = [];
  addSubDataSource: MatTableDataSource<any> = new MatTableDataSource();

  currentSubColumns = [
    { columnDef: 'permissionName', header: 'Name', type: 'text' },
    { columnDef: 'permissionDescription', header: 'Description', type: 'text' },
    { columnDef: 'delete', header: 'Delete', type: 'delete' },
  ];

  addSubColumns = [
    { columnDef: 'permissionName', header: 'Name', type: 'text' },
    { columnDef: 'permissionDescription', header: 'Description', type: 'text' },
    { columnDef: 'add', header: 'Add', type: 'add' },
  ];
  constructor(
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<SuperFeaturesEditComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getUncategorizedFeatures();
    this.getCurentFeatures();
  }

  getUncategorizedFeatures(): void {
    this.permissionService
      .getUncategorizedPermission()
      .pipe(
        take(1),
        tap((permissions: IPermission[]) => {
          this.addSubTableData = permissions;
        }),
        tap((_) => this.initializeAddSubTableData(this.addSubTableData))
      )
      .subscribe();
  }

  getCurentFeatures(): void {
    this.permissionService.getCurrentPermission(this.editData.groupId).pipe(
      take(1),
      tap((permissions: IPermission[]) => {this.currentSubTableData = permissions}),
      tap(_ => this.initializeCurrentSubTableData(this.currentSubTableData))
    ).subscribe();
  }

  initializeCurrentSubTableData(currentSub?: IPermission[]): void {
    if (currentSub) {
      this.currentSubDataSource = new MatTableDataSource(currentSub);
      this.currentSub?.reRender(currentSub);
    }
  }

  initializeAddSubTableData(addSub?: IPermission[]): void {
    if (addSub) {
      this.addSubDataSource = new MatTableDataSource(addSub);
      this.addSub?.reRender(addSub);
    }
  }

  addPermission(permission: any): void {
    const { data } = permission;
    const payload: IPermissionGroupAssignmentRequest = {
      superPermissionId: this.editData.groupId,
      subPermissionIds: [data.id],
    };

    this.permissionService
      .addPermissionGroupAssignment(payload)
      .pipe(
        take(1),
        tap(() => {
          this.currentSubTableData?.push(permission.data);
          this.addSubTableData?.splice(permission.index, 1);
          this.initializeCurrentSubTableData(this.currentSubTableData);
          this.initializeAddSubTableData(this.addSubTableData);
        }),
        tap(() => this.toastService.success('Sub Feature Added'))
      )
      .subscribe();
  }

  deletePermission(permission: any): void {
    const { data } = permission;
    const payload: IPermissionGroupAssignment = {
      superPermissionId: this.editData.groupId,
      subPermissionId: data.id,
    };

    this.permissionService
      .updatePermissionGroupAssignment(payload)
      .pipe(
        take(1),
        tap(() => {
          this.currentSubTableData?.splice(permission.index, 1);
          this.addSubTableData?.push(permission.data);
          this.initializeCurrentSubTableData(this.currentSubTableData);
          this.initializeAddSubTableData(this.addSubTableData);
        }),
        tap(() => this.toastService.warn('Sub Feature Removed'))
      )
      .subscribe();
  }

  closeModal(): IPermission[] | any {
    if (this.currentSubTableData) {
      return this.currentSubTableData;
    }
  }
}
