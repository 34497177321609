import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, Subject, Subscription, switchMap, take } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { LookupService } from '@core/services/lookup.service';
import { ClassService } from '@core/services/class.service';
import { ToastService } from '@core/services/toast.service';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { SelectType } from '@core/enums/select';
import { IState } from '@core/interfaces/istate';
import { IAgency } from '@core/interfaces/iagency';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ISites } from '@core/interfaces/isites';
import { ISelectable } from '@core/interfaces/iselectable';
import { IReportSearch, IViewStudentByClassModalData, ViewStudentByClassModalComponent } from '@views/user/reports';
import { AdditionalFilters } from '@core/enums/additional-filters';
import { ILanguage } from '@core/interfaces/ilanguage';
import { IEthnicity } from '@core/interfaces/iethnicity';
import { IAgeGradeEnrollmentProgramFilter } from '@core/interfaces/iage-grade-instrument';
import { IGender } from '@core/interfaces/igender';
import { IRequiredReportFields } from '../interfaces/irequired-report-fields';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
@Component({
  selector: 'drdp-report-search',
  templateUrl: './report-search.component.html',
  styleUrls: ['./report-search.component.scss']
})
export class ReportSearchComponent implements OnInit, OnDestroy {
  @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;
  @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
  private selectedClassroomAssignments: IClassroomAssignment[] = [];
  private selectedRatingPeriods: IRatingPeriod | IRatingPeriod[] | null = null;
  private schoolYearId: number | undefined = undefined;
  private ratingPeriodName: string | undefined = undefined;

  private classroomParamsSubject = new Subject<void>();
  private classroomParams$ = this.classroomParamsSubject.asObservable();
  private subscription: Subscription | null = null;

  @Input() displaySite: boolean = true;
  @Input() displayClassroom: boolean = true;
  @Input() multiSelectAgencies: boolean = false;
  @Input() allAgenciesCheckbox: boolean = false;
  @Input() multiSelectSites: boolean = false;
  @Input() multiSelectClassrooms: boolean = false;
  @Input() multiSelectRatingPeriods: boolean = false;
  @Input() showAllRatingPeriods: boolean = true;
  @Input() ratingPeriodLabel: string = 'Rating Period';
  @Input() isDownloadDisabled: boolean = false;
  @Input() displayDownload: boolean = true;
  @Input() displayClear: boolean = true;
  @Input() showReportBtnText: string = 'Show Report';
  @Input() showLocale: boolean = false;
  @Input() showStudentSelect: boolean = false;
  @Input() hasSubfilters: boolean = false;
  @Input() onlyLocked: boolean = false;
  @Input() onlyActive: boolean = false;
  @Input() canSelectAll: boolean = false;
  @Input() includePilot: boolean = false;
  @Input() requiredFields?: IRequiredReportFields = {
    state: true,
    agency: true,
    globalRatingPeriod: true,
    site: true,
    classroom: true,
    ageGroup: true,
    requireMultipleRatingPeriods: false,
    requireRpInSameSchoolYear: true
  };
  @Output() showReportClicked: EventEmitter<IReportSearch> = new EventEmitter<IReportSearch>();
  @Output() downloadReportClicked: EventEmitter<IReportSearch> = new EventEmitter<IReportSearch>();
  @Output() clearClicked: EventEmitter<void> = new EventEmitter<void>();

  get stateId() {
    return this.searchForm.get('stateId');
  }

  get agencyId() {
    return this.searchForm.get('agencyId');
  }

  get agencyIds() {
    return this.searchForm.get('agencyIds');
  }

  get siteIds() {
    return this.searchForm.get('siteIds');
  }

  get globalRatingPeriodIds() {
    return this.searchForm.get('globalRatingPeriodIds');
  }

  get classIds() {
    return this.searchForm.get('classIds');
  }

  get ageGradeInstrumentId() {
    return this.searchForm.get('ageGradeInstrumentId');
  }

  get additionalFilter() {
    return this.searchForm.get('additionalFilter');
  }

  get dobStart() {
    return this.searchForm.get('dobStart');
  }

  get dobEnd() {
    return this.searchForm.get('dobEnd');
  }

  get gender() {
    return this.searchForm.get('gender');
  }

  get latino() {
    return this.searchForm.get('latino');
  }

  get race() {
    return this.searchForm.get('race');
  }

  get reducedLunch() {
    return this.searchForm.get('reducedLunch');
  }

  get iep() {
    return this.searchForm.get('iep');
  }

  get programType() {
    return this.searchForm.get('programType');
  }

  get language() {
    return this.searchForm.get('language');
  }

  get eld() {
    return this.searchForm.get('eld');
  }

  public get subFilter() {
    return AdditionalFilters;
  }


  auth = inject(AuthService);
  fb = inject(FormBuilder);
  ratingPeriodService = inject(RatingPeriodService);
  lookup = inject(LookupService);
  classService = inject(ClassService);
  toastService = inject(ToastService);
  modal = inject(MatDialog);
  ref = inject(ChangeDetectorRef);

  user = this.auth.getCurrentUser();
  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  searchForm: FormGroup | any;
  classroomAssignments: IClassroomAssignment[] = [];
  clearDropdown: boolean = false;
  select = SelectType;
  isDownloadMenuOpen: boolean = false;
  isPilotClassroom: boolean = false;
  isGlobalAgencies: boolean = false;
  hasAccessToAssignedChildren = this.permissionService.checkPermission(
    Permission.AccessToAssignedChildrenReporting
  );
  constructor(private permissionService: PermissionService, private lookupService: LookupService) { }

  ngOnInit(): void {
    this.initializeForm();
    if (this.displayClassroom) this.initializeClassroom();
  }

  initializeClassroom() {
    this.subscription =
      this.classroomParams$.pipe(
        switchMap(() => {
          this.classIds.setValue(null);
          this.classroomAssignments = [];
          const payload: any = {};
          payload.siteIds = this.siteIds.value ?? null;
          if (this.multiSelectRatingPeriods) {
            payload.globalRatingPeriodIds = this.globalRatingPeriodIds.value ?? null;
          } else {
            payload.globalRatingPeriodIds = this.globalRatingPeriodIds.value ? [this.globalRatingPeriodIds.value[0]] : [];
          }
          payload.useClassId = true;
          if (payload.siteIds?.length > 0 && (payload.globalRatingPeriodIds > 0 || payload.globalRatingPeriodIds?.length > 0)) {
            return this.classService
              .getClassesBySitesAndGlobalRatingPeriods(payload)
          } else {
            return of([]);
          }
        })
      ).subscribe((res: any) => {
        this.classroomAssignments = res.filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.classroomId === value.classroomId) &&
            (this.includePilot || !value.isPilot)
        );
      });
  }

  handleState(state: IState | null) {
    this.stateId.setValue(state?.id);
    this.agencyId.setValue(null);
    this.agencyIds.setValue(null);
    this.ageGradeInstrumentId.setValue(null);
  }

  handleAgency(agencies: IAgency | IAgency[] | null): void {
    if (!!agencies && !('length' in (agencies))) {
      this.agencyId.setValue(agencies.id);
    } else {
      const selectedAgencyIds = !!agencies && agencies?.length > 0 ? agencies?.map((c: { id: number }) => c.id) : null;
      this.agencyIds.setValue(selectedAgencyIds);
    }

    this.classIds.setValue(null);
    this.classroomAssignments = [];
    this.selectedClassroomAssignments = [];
  }

  handleRatingPeriod(event: IRatingPeriod | IRatingPeriod[] | null): void {
    this.selectedRatingPeriods = null;
    if (!!event && !('length' in (event))) {
      this.selectedRatingPeriods = [event];
    } else if (!!event) {
      this.selectedRatingPeriods = event;
    }
    const selectedRatingPeriodIds = !!this.selectedRatingPeriods && this.selectedRatingPeriods?.length > 0 ? this.selectedRatingPeriods?.map(ca => ca.id) : null;
    this.globalRatingPeriodIds.setValue(selectedRatingPeriodIds);

    if (this.selectedRatingPeriods && this.selectedRatingPeriods.length > 0) {
      this.ratingPeriodName = this.selectedRatingPeriods[0].ratingPeriodName;
      this.schoolYearId = this.selectedRatingPeriods[0].schoolYearId;
    }

    this.classroomParamsSubject.next();
    // this.updateClassroomAssignmentDropdown();
  }

  handleSite(sites: ISites | ISites[] | null): void {
    if (!!sites && !('length' in (sites))) {
      sites = [sites];
    }
    const selectedSiteIds = !!sites && sites?.length > 0 ? sites?.map((c: { id: number }) => c.id) : null;
    this.siteIds.setValue(selectedSiteIds);
    this.classroomParamsSubject.next();
    // this.updateClassroomAssignmentDropdown();
  }

  handleClassroomAssignment(event: ISelectable | ISelectable[] | null): void {
    let selectedClassrooms: ISelectable[] | null = null;
    if (!!event && !('length' in (event))) {
      selectedClassrooms = [event];
    } else if (!!event) {
      selectedClassrooms = event;
    }

    const selectedClassIds = !!selectedClassrooms && selectedClassrooms?.length > 0 ? selectedClassrooms?.map(ca => ca.id) : null;
    this.classIds.setValue(selectedClassIds);
    this.selectedClassroomAssignments = !!selectedClassrooms
      ? this.classroomAssignments
        .filter(ca => selectedClassrooms?.find(sc => sc.id === ca.classId))
      : [] as IClassroomAssignment[];
    
    if (this.includePilot && this.selectedClassroomAssignments.length > 0) 
      this.isPilotClassroom = this.selectedClassroomAssignments.some(ca => ca.isPilot);
  }

  handleAgeGroupInstrument(ageGroupInstrument: any): void {
    this.ageGradeInstrumentId.setValue(ageGroupInstrument?.id);
  }

  handleDobStart(event: any): void {
    this.dobStart.setValue(event);
  }

  handleDobEnd(event: any): void {
    this.dobEnd.setValue(event);
  }

  handleGender(event: any): void {
    this.gender.setValue(event?.map((gender: IGender) => gender.id));
  }

  handleHispanic(event: any): void {
    this.latino.setValue(event?.map((option: any) => option.id));
  }

  handleRace(event: any): void {
    this.race.setValue(event?.map((race: IEthnicity) => race.id));
  }

  handleReducedLunch(event: any): void {
    this.reducedLunch.setValue(event?.map((option: any) => option.id));
  }

  handleIep(event: any): void {
    this.iep.setValue(event?.map((option: any) => option.id));
  }

  handleProgramType(event: any): void {
    this.programType.setValue(event?.map((programs: IAgeGradeEnrollmentProgramFilter) => programs.id));
  }

  handleLanguage(event: any): void {
    this.language.setValue(event?.map((language: ILanguage) => language.id));
  }

  handleEld(event: any): void {
    this.eld.setValue(event?.map((option: any) => option.id));
  }

  search() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }

    if (Array.isArray(this.selectedRatingPeriods)) {
      if (this.requiredFields?.requireMultipleRatingPeriods && this.selectedRatingPeriods.length < 2) {
        this.toastService.error('At least two (2) selected rating periods is required to run the report.');
        return;
      }
      if (new Set(this.selectedRatingPeriods.map(term => term.schoolYearId)).size !== 1 && this.requiredFields?.requireRpInSameSchoolYear) {
        this.toastService.error('Rating Periods selected should be in the same school year.');
        return;
      }
    }

    this.showReportClicked.emit({
      ...this.searchForm.value,
      classroomIds: this.selectedClassroomAssignments.map(ca => ca.classroomId),
      schoolYearId: this.schoolYearId
    } as IReportSearch);
  }

  downloadReport(locale: string) {
    if (!this.showStudentSelect) {
      this.downloadReportClicked.emit({
        ...this.searchForm.value,
        locale: locale,
        classroomIds: this.selectedClassroomAssignments.map(ca => ca.classroomId),
        schoolYearId: this.schoolYearId,
        printPdf: true
      } as IReportSearch);
      return;
    }

    let classId = this.classIds.value ? this.classIds.value[0] : null;
    let classroomName = null;
    if (classId != null) {
      classroomName = this.classroomAssignments.find(assignment => assignment.classId === classId)?.classroomName;
    }


    const viewStudentByClassModalData: IViewStudentByClassModalData = {
      classId: this.classIds.value ? this.classIds.value[0] : null,
      classroomName: classroomName,
      ratingPeriod: this.ratingPeriodName ?? '',
      ageGradeInstrumentId: this.ageGradeInstrumentId.value,
      onlyLocked: this.onlyLocked,
      onlyActive: this.onlyActive,
      canSelectAll: this.canSelectAll,
      agencyIds: this.agencyId.value ?? null,
      siteId: this.siteIds.value ? this.siteIds.value[0] : null,
      globalRatingPeriodId: this.globalRatingPeriodIds.value[0]
    };
    const modalRef = this.modal.open(ViewStudentByClassModalComponent, { data: viewStudentByClassModalData });
    modalRef.afterClosed().subscribe(res => {
      if (!res || res.selectedStudentIds.length <= 0) {
        return;
      }

      this.downloadReportClicked.emit({
        ...this.searchForm.value,
        locale: locale,
        classroomIds: this.selectedClassroomAssignments.map(ca => ca.classroomId),
        studentIds: res.selectedStudentIds,
        schoolYearId: this.schoolYearId,
        printPdf: true
      } as IReportSearch);
    });
  }

  clear(): void {
    this.clearDropdown = true;
    this.searchForm.reset();
    this.searchForm.patchValue({
        stateId: this.user.stateId,
        agencyId: this.user.agencyId ?? null,
        agencyIds: [this.user.agencyId ?? null],
        additionalFilter: AdditionalFilters.None
    });
    if (!this.stateSelect?.disabled) this.stateId.setValue(null);
    if (!this.agencySelect?.disabled) this.agencyId.setValue(null);
    this.selectedClassroomAssignments = [];
    this.classroomAssignments = [];
    this.schoolYearId = undefined;
    this.clearClicked.emit();
  }

  handleGlobal(): void {
    this.isGlobalAgencies = !this.isGlobalAgencies;
    this.agencyId.setValue(null);
    this.agencyIds.setValue(null);
  }

  private initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: new FormControl<number | null>(null, this.requiredFields?.state ? Validators.required : null),
      agencyId: new FormControl<number | null>(null, this.requiredFields?.agency && !this.multiSelectAgencies ? Validators.required : null),
      agencyIds: new FormControl<number[] | null>(null, this.requiredFields?.agency && this.multiSelectAgencies ? Validators.required : null),
      globalRatingPeriodIds: new FormControl<number[] | null>(null, this.requiredFields?.globalRatingPeriod ? Validators.required : null),
      siteIds: new FormControl<number[] | null>(null, this.requiredFields?.site ? Validators.required : null),
      classIds: new FormControl<number[] | null>(null, this.requiredFields?.classroom ? Validators.required : null),
      ageGradeInstrumentId: new FormControl<number | null>(null, this.requiredFields?.ageGroup ? Validators.required : null),
      additionalFilter: new FormControl<number | null>(null),
      dobStart: new FormControl<string | null>(null),
      dobEnd: new FormControl<string | null>(null),
      gender: new FormControl<number[] | null>([]),
      latino: new FormControl<string[] | null>([]),
      race: new FormControl<number[] | null>([]),
      reducedLunch: new FormControl<string[] | null>([]),
      iep: new FormControl<string[] | null>([]),
      programType: new FormControl<number[] | null>([]),
      language: new FormControl<number[] | null>([]),
      eld: new FormControl<string[] | null>([]),
    });

    this.searchForm.patchValue({
      stateId: this.user.stateId,
      agencyId: this.user.agencyId,
      agencyIds: [this.user.agencyId],
      additionalFilter: AdditionalFilters.None
    });

    this.additionalFilter.valueChanges.subscribe((val: AdditionalFilters) => {
      if (val == AdditionalFilters.None) {
        this.searchForm.patchValue({
          dobStart: null,
          dobEnd: null,
          gender: null,
          latino: null,
          race: null,
          reducedLunch: null,
          iep: null,
          programType: null,
          language: null,
          eld: null
        });
      }
    });

  }

  private updateClassroomAssignmentDropdown(): void {
    this.classIds.setValue(null);
    this.classroomAssignments = [];
    const payload: any = {};
    payload.siteIds = this.siteIds.value ?? null;
    if (this.multiSelectRatingPeriods) {
      payload.ratingPeriodIds = this.globalRatingPeriodIds.value ?? null;
    } else {
      payload.ratingPeriodId = this.globalRatingPeriodIds.value[0] ?? 0;
    }
    payload.useClassId = true;

    if (payload.siteIds?.length > 0 && (payload.ratingPeriodId > 0 || payload.ratingPeriodIds?.length > 0)) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomAssignments = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.classroomId === value.classroomId)
          );
        });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
