<drdp-container additionClasses="mb-4">
  <div class="w-full">
    <h1 class="text-xl font-semibold my-2">Agency Rating Views</h1>
    <drdp-container color="lightBlue" additionClasses="p-6">
      <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-2 my-auto" [formGroup]="agencyRatingViewsSearch"
        (ngSubmit)="handleDownload()">
        <div class="lg:col-span-5 md:col-span-2 col-span-1">
          <label>
            <mat-checkbox color="primary" (change)="handleGlobal()">
            </mat-checkbox>
            Search All Agencies
          </label>
        </div>

        <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleDropdown($event, selectType.States)"
          [clear]="clearDropdowns" [required]="true">
        </drdp-select-state>

        <drdp-select-agency #agencySelect [hidden]="allAgencies" [stateId]="stateId.value"
          [required]="false" [initialAgencyId]="agencyId.value" [clear]="clearDropdowns" (agency)="handleDropdown($event, selectType.Agencies)">
        </drdp-select-agency>

        <drdp-select-age-grade #ageGroupInstrumentSelect (ageGrade)="handleDropdown($event, selectType.AgeInstrument)"
          [stateId]="stateId.value" [multiSelect]="true" [clear]="clearDropdowns" [required]="true">
        </drdp-select-age-grade>

        <drdp-select-yes-no #yesNoSelect label="Custom View" (optionSelected)="handleDropdown($event, selectType.YesNo)" [clear]="clearDropdowns" [required]="true">
        </drdp-select-yes-no>

        <div [ngClass]="btnClass" class="flex items-end justify-end  md:col-span-2">
          <div class=" mb-0 md:mb-2 lg:mb-0">
            <drdp-btn type="submit" class="lg:mr-2"
            [disabled]="!agencyRatingViewsSearch.valid">
              Download Agency Rating Views
            </drdp-btn>
          </div>
        </div>
      </form>
    </drdp-container>
  </div>
</drdp-container>

