import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableInputAction, TableColumnType } from '@core/enums/table';
import { IClassExitReason } from '@core/interfaces/iclasses';
import { ITableColumn } from '@core/interfaces/itable';
import { LookupService } from '@core/services/lookup.service';
import { StudentRequestService } from '@core/services/student-request.service';
import {
  IStudentRequest,
  IStudentRequestSearch,
} from '@core/interfaces/istudent-request';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { Observable, combineLatest, take } from 'rxjs';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IAgency } from '@core/interfaces/iagency';
import { AgencyService } from '@core/services/agency.service';
import { ISites } from '@core/interfaces/isites';
import { SiteService } from '@core/services/site.service';
import { ClassService } from '@core/services/class.service';
import { ClassroomService } from '@core/services/classroom.service';
import { SelectType } from '@core/enums/select';
import { Modal } from '@core/enums/modal';
import { mapClassResToDRDPSelect } from '@core/services/helper.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { IState } from '@core/interfaces/istate';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';

@Component({
  selector: 'drdp-pending-withdraws',
  templateUrl: './pending-withdraws.component.html',
  styleUrls: ['./pending-withdraws.component.scss'],
})
export class PendingWithdrawsComponent implements OnInit {
  @ViewChildren(FilterSelectComponent) selectComponents:
    | QueryList<FilterSelectComponent>
    | undefined;
  @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;
  @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
  @ViewChild('siteSelect') siteSelect: SelectStateComponent | undefined;

  user = this.authService.getCurrentUser();
  canViewMultipleAgencies!: boolean;

  searchDropdownOptions$!: Observable<any>;
  selectSites = SelectType.Sites;
  selectClass = SelectType.Class;
  exitReasons!: IClassExitReason[];
  agencies!: IAgency[];
  sites!: ISites[];
  classrooms!: any[];
  
  canViewSensitiveTransferInfo: boolean = this.permission.checkPermission(
    Permission.ViewSensitiveStudentTransferInfo
  );

  exitRequestSearchForm: FormGroup | any;
  get firstName() {
    return this.exitRequestSearchForm.get('firstName');
  }
  get lastName() {
    return this.exitRequestSearchForm.get('lastName');
  }
  get stateId() {
    return this.exitRequestSearchForm.get('stateId');
  }
  get agencyId() {
    return this.exitRequestSearchForm.get('agencyId');
  }
  get classroomId() {
    return this.exitRequestSearchForm.get('classroomId');
  }
  get fromSiteId() {
    return this.exitRequestSearchForm.get('fromSiteId');
  }

  searchPayload!: IStudentRequestSearch;

  initialAgencyId?: number | undefined = !this.canViewMultipleAgencies
    ? this.user.agencyId
    : undefined;
  stateSearchId? = this.user.stateId;
  clearDropdowns = false;

  studentRequestPayload: IStudentRequest[] = [];
  originalData: IStudentRequest[] = [];
  existingData?: any;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  tableColumns: ITableColumn[] = [
    {
      columnDef: 'studentName',
      header: 'Child Name',
      type: TableColumnType.text,
    },
    ...(this.canViewSensitiveTransferInfo ? [
      {
        columnDef: 'dob',
        header: 'DOB',
        type: TableColumnType.date,
      },
      {
        columnDef: 'fromSiteName',
        header: 'Site',
        type: TableColumnType.text,
      },
      {
        columnDef: 'fromClassroomName',
        header: 'Classroom',
        type: TableColumnType.text,
      }
    ] : []),
    {
      columnDef: 'classExitReasonName',
      header: 'Withdraw Reason',
      type: TableColumnType.textToSelect,
      action: TableInputAction.optionSelect,
    },
    {
      columnDef: 'exitDate',
      header: 'Withdraw Date',
      type: TableColumnType.datepicker,
      action: TableInputAction.dateSelect,
    },
    ...(this.canViewSensitiveTransferInfo ? [
      {
        columnDef: 'requestedBy',
        header: 'Requested By',
        type: TableColumnType.text,
      }
    ] : []),
    {
      columnDef: 'comment',
      header: 'Requester Comment',
      type: TableColumnType.text,
    },
    {
      columnDef: 'fromRatingPeriod',
      header: 'Rating Period',
      type: TableColumnType.text,
    },
    {
      columnDef: 'adminComment',
      header: 'Admin Comment',
      type: TableColumnType.textToInput,
      action: TableInputAction.inputChange,
      maxLength: 150,
    },
    {
      columnDef: 'edit',
      header: 'Edit',
      type: 'edit',
    },
    {
      columnDef: 'decline',
      header: 'Decline',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox',
    },
    {
      columnDef: 'approve',
      header: 'Accept',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox',
    },
  ];

  constructor(
    private studentRequestsService: StudentRequestService,
    private lookupService: LookupService,
    private authService: AuthService,
    private toastService: ToastService,
    private modal: MatDialog,
    public fb: FormBuilder,
    private router: Router,
    private siteService: SiteService,
    private classroomService: ClassroomService,
    private permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.setDefaultPayload();
    this.initializeSearchForm();
    this.getExitReasons();

    if (this.user.stateId) {
      this.searchPayload.stateId = this.user.stateId;
    }
    if (this.user.agencyId) {
      this.searchPayload.agencyId = this.user.agencyId;
    }
    this.canViewMultipleAgencies =
      this.authService.isSuperAdmin() || this.authService.isCustomerService();

    this.getPendingExits();

    this.stateId.setValue(this.stateSearchId);
    this.agencyId.setValue(this.initialAgencyId);
  }

  setDefaultPayload(): void {
    this.searchPayload = {
      requestReasonId: StudentRequestReason.Drop,
    };
  }

  initializeSearchForm(): void {
    this.exitRequestSearchForm = this.fb.group({
      stateId: [null],
      agencyId: [null],
      classroomId: [null],
      fromSiteId: [null],
      firstName: [null, [Validators.maxLength(100)]],
      lastName: [null, [Validators.maxLength(100)]],
    });
  }

  getPendingExits(): void {
    this.studentRequestsService
      .getByReasonAndAgency(this.searchPayload)
      .subscribe((res: any) => {
        if (res) {
          if (!this.existingData) {
            this.originalData = res.map(
              (record: IStudentRequest): IStudentRequest => {
                return {
                  adminComment: record.adminComment,
                  classExitReasonId: record.classExitReasonId,
                  classExitReasonName: record.classExitReasonName,
                  exitDate: record.exitDate,
                  fromClassId: record.fromClassId,
                  id: record.id,
                  requestReasonId: record.requestReasonId,
                  studentId: record.studentId,
                  accept: record.accept,
                };
              }
            );

            res = res.map((data: any) => ({
              ...data,
              isSelect: false,
              approve: null,
              decline: null,
              items: this.exitReasons,
            }));
          }

          this.dataSource = new MatTableDataSource(this.existingData ?? res);
          this.tableData = this.existingData ?? res;
        }
      });
  }

  onIconClick(event: any): void {
    switch (event.action) {
      case TableInputAction.dateSelect:
        this.tableData[event.index].exitDate = new Date(
          event.value
        ).toISOString();
        break;
      case TableInputAction.optionSelect:
        this.tableData[event.index].classExitReasonName = event.data.exitReason;
        this.tableData[event.index].classExitReasonId = event.data.id;
        break;
      case TableInputAction.inputChange:
        this.tableData[event.index].adminComment = event.data.target.value;
        break;
      default:
        this.tableData[event.index].isSelect =
          !this.tableData[event.index].isSelect;
    }
  }

  checkChanges() {
    this.tableData.forEach((data: any, index: number) => {
      this.setByStatus(data, index);
    });
  }

  onCancel(): void {
    this.studentRequestPayload = [];
    this.checkChanges();

    if (!this.studentRequestPayload.length) {
      this.router.navigateByUrl('/dashboard');
    } else {
      var event = { data: { modalInfo: {} } };
      event.data.modalInfo = {
        title: 'Discard all unsaved changes?',
        message: `You will lose all unsaved changes and return to the dashboard. Do you wish to proceed?`,
        primaryBtnClass: 'red',
        primaryBtnText: Modal.Confirm,
      };

      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe((res) => {
        if (!res) {
          this.modal.closeAll();
        } else {
          this.toastService.success(
            'All changes discarded. Returning to dashboard.'
          );
          this.router.navigateByUrl('/dashboard');
        }
      });
    }
  }

  onSubmit(): void {
    this.studentRequestPayload = [];

    const isEditing = this.tableData.find((row: any) => {
      return row.isSelect == true;
    });

    if (isEditing) {
      this.toastService.error(
        "Oops, you're currently editing a drop request. Please save before submitting."
      );
      return;
    }

    this.checkChanges();

    if (!this.studentRequestPayload.length) {
      this.toastService.error('No changes detected.');
      return;
    }

    this.studentRequestsService
      .processBulkUpdates(
        this.studentRequestPayload,
        'Drop requests have been updated.'
      )
      .subscribe((res: any) => {
        if (res) {
          this.existingData = null;
          this.getPendingExits();
        }
      });
  }

  setByStatus(data: any, index: number): void {
    var requestUpdate = <IStudentRequest>{
      adminComment: data.adminComment,
      classExitReasonId: data.classExitReasonId,
      classExitReasonName: data.classExitReasonName,
      exitDate: data.exitDate,
      fromClassId: data.fromClassId,
      id: data.id,
      requestReasonId: data.requestReasonId,
      studentId: data.studentId,
      accept: data.accept,
    };

    if (
      JSON.stringify(requestUpdate) === JSON.stringify(this.originalData[index])
    ) {
      return;
    }

    this.studentRequestPayload.push(requestUpdate);
  }

  requestStatusUpdateHandler(event: any): void {
    if (event.property == 'decline') {
      this.tableData[event.index].approve = null;
      if (this.tableData[event.index].decline == true) {
        this.tableData[event.index].decline = null;
      } else {
        this.tableData[event.index].decline = true;
        this.tableData[event.index].approve = false;
      }
    } else if (event.property == 'approve') {
      this.tableData[event.index].decline = null;
      if (this.tableData[event.index].approve == true) {
        this.tableData[event.index].approve = null;
      } else {
        this.tableData[event.index].decline = false;
        this.tableData[event.index].approve = true;
      }
    }

    this.tableData[event.index].accept = this.tableData[event.index].approve;
  }

  getExitReasons() {
    this.lookupService.getClassExitReasons().subscribe((res: any) => {
      if (res) {
        res = res.map((data: any) => ({
          ...data,
          name: data.exitReason,
        }));
        this.exitReasons = res;
      }
    });
  }

  search(): void {
    this.searchPayload.firstName = this.firstName?.value;
    this.searchPayload.lastName = this.lastName?.value;
    this.searchPayload.agencyId = this.agencyId?.value;
    this.searchPayload.fromSiteId = this.fromSiteId?.value;
    this.searchPayload.classroomId = this.classroomId?.value;
    this.searchPayload.stateId = this.stateId?.value;
    this.getPendingExits();
  }

  handleState(state: IState) {
    if (!state) {
      this.stateId.setValue(null);
      this.agencyId.setValue(null);
      this.clearDropdownOptions();
    } else if (state.id == this.stateId.value) {
      return;
    } else {
      this.stateId.setValue(state.id);
    }
  }

  handleAgency(agency: IAgency) {
    if (!agency) {
      this.agencyId.setValue(null);
      this.clearDropdownOptions();
    } else if (agency.id == this.agencyId.value) {
      return;
    } else {
      this.agencyId.setValue(agency.id);
      this.searchDropdownOptions$ = combineLatest({
        sites: this.siteService.getSitesByAgencyId(agency.id),
        classrooms: this.classroomService.getClassroomsByAgency(agency.id),
      });
      this.searchDropdownOptions$.pipe(take(1)).subscribe((res: any) => {
        this.sites = res['sites'];
        this.classrooms = mapClassResToDRDPSelect(res['classrooms']);
      });
    }
  }

  handleSites(site: any): void {
    if (!site || site == undefined) {
      this.classrooms = [];
      this.fromSiteId.setValue(null);
      this.classroomId.setValue(null);

      return;
    }

    this.fromSiteId.setValue(site.id);
    this.classroomService
      .getClassroomsBySite(site.id)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.classrooms = mapClassResToDRDPSelect(res, true);
      });
  }

  handleClassroom(classroom: any): void {
    this.classroomId.setValue(null);
    if (!classroom) {
      return;
    }
    this.classroomId.setValue(classroom.id);
  }

  clearDropdownOptions(): void {
    this.sites = [];
    this.fromSiteId?.setValue(null);
    this.classrooms = [];
    this.classroomId?.setValue(null);
  }
  
  onClear(): void {
    this.clearDropdowns = !this.clearDropdowns;
    this.clearDropdownOptions();
    this.agencySelect?.clearSelected();
    this.stateSelect?.clearSelected();
    this.siteSelect?.clearSelected();
    this.firstName?.setValue(null);
    this.lastName?.setValue(null);

    this.stateSearchId = undefined;
    this.initialAgencyId = undefined;

    this.setDefaultPayload();
    this.getPendingExits();
  }
}

