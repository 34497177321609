<div>
    <div>
        <div class="flex justify-between">
            <drdp-title title="{{formStep === step.StepOne ? 'Add Evidence' : 'Preliminary Ratings'}}"></drdp-title>
            <drdp-btn color="outline" (click)="back()">Go Back</drdp-btn>
        </div>
        <h1 class="mb-2 mt-0 text-1xl leading-tight"><b>Rating Period:</b> {{ params && params.ratingPeriodName }}
        </h1>
        <h2 class="mb-2 mt-0 text-1xl leading-tight"><b>Classroom:</b> {{ params && params.classroomName }}</h2>
        <h2 class="mb-2 mt-0 text-1xl leading-tight"><b>Age Group/Instrument:</b> {{params &&
            params.ageGradeInstrumentName }}</h2>
        <h2 class="mb-2 mt-0 text-1xl leading-tight"><b>Child/Children:</b> {{ studentNames }}</h2>
        <div *ngIf="formStep === step.StepOne && !params?.isPilot" class="mb-2 mt-0 text-1xl text-end">
            <mat-slide-toggle color="primary" class="pr-1" (change)="toggleFutureRatingPeriod()"
            [ngModelOptions]="{ standalone: true }" aria-label="Slider Toggle">
        </mat-slide-toggle>Save to evidence library for future rating period
        </div>
    </div>
    <drdp-container *ngIf="formStep === step.StepOne">
        <div class="p-2 w-full">
            <h1 class="mb-2 font-bold mt-0 text-2xl leading-tight">Choose Evidence</h1>
            <div class="flex justify-evenly">
                <div *ngFor="let evidence of evidences; let i = index" class="w-1/6 my-2">
                    <div class="tiles mx-auto px-4 pb-4 text-center mb-4" (click)="onTilesClick(i, evidence.type)"
                        [class.active]="selectedIndex === i">
                        <i class="text-[60px] {{evidence.icon}}"></i>
                        <p class="text-[15px]">{{ evidence.name }}</p>
                    </div>
                    <span *ngIf="evidence.type != PortfolioEvidenceType.Note" class="text-center">
                      <p>Allowed type:</p>
                      <p>{{evidence.allowedType}}</p>
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="selectedIndex != null">

            <ng-container *ngIf="selectedEvidenceType != PortfolioEvidenceType.Note">
                <h1 class="mb-2 font-bold pt-5 mt-0 text-1xl leading-tight">Add {{ uploadSettings.uploadText
                    }}<b></b>
                </h1>
                <label for="images" class="drop-container" (drop)="fileDropHandler($event)">
                    <i class="text-[60px] {{ uploadSettings.uploadIcon }}"></i>
                    <span class="drop-title">SELECT {{ selectedEvidenceType === PortfolioEvidenceType.Audio ? 'AN' :
                        'A'
                        }} {{ uploadSettings.uploadText.toUpperCase() }} FILE TO
                        UPLOAD</span>
                    <span class="text-drdpblue-300 text-[15px] underline">Or drag and drop it here</span>
                    <input class="invisible" #addPhotosFileInput type="file" id="images" (change)="onSelectFile($event)"
                        accept="{{acceptedFiles}}" [multiple]="allowMultiple" onclick="this.value = null" (click)="onFileClick($event)">
                </label>

                <h1 *ngIf="url" class="mb-2 font-bold pt-5 mt-0 text-1xl leading-tight">Uploaded {{
                    uploadSettings.uploadText }}<b></b>
                </h1>
                <h1 *ngIf="isFileUploading" class="mb-2 font-bold pt-5 mt-0 text-1xl leading-tight">Uploading: {{
                    progress.toFixed(2) }}%<b></b>
                </h1>
                <mat-progress-bar *ngIf="isFileUploading" mode="determinate"
                    value="{{progress.toFixed(2)}}"></mat-progress-bar>

                <div *ngIf="url" class="w-full">
                    <drdp-video *ngIf="selectedEvidenceType === PortfolioEvidenceType.Video"
                        [url]="displayUrl"></drdp-video>
                    <drdp-audio *ngIf="selectedEvidenceType === PortfolioEvidenceType.Audio"
                        [url]="displayUrl"></drdp-audio>
                        <div class="grid grid-cols-2 gap-4">

                          <div *ngFor="let links of files">
                            <div class="w-fit">
                              <img *ngIf="selectedEvidenceType === PortfolioEvidenceType.Photo" [src]="links.displayUrl"
                              class="w-full" />
                              <div class="flex flex-row justify-between">
                                <p class=" text-xs">
                                    {{ links.fileName }}
                                </p>
                                <a class=" text-sm text-red-800 cursor-pointer" (click)="onFileRemove(links)">
                                    Remove
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                    <div *ngIf="selectedEvidenceType != PortfolioEvidenceType.Photo" class="flow-root ">
                        <p class="float-left text-xs">
                            {{ displayFileName }}
                        </p>
                        <a class="float-right text-sm text-red-800 cursor-pointer" (click)="onFileRemove()">
                            Remove
                        </a>
                    </div>
                </div>
            </ng-container>

            <ng-container>
                <h1 class="mb-2 font-bold pt-8 mt-0 text-1xl leading-tight">Add Notes<b></b></h1>
                <textarea rows="10" [(ngModel)]="notes" class="portfolio-textarea w-full" matInput></textarea>
            </ng-container>

            <ng-container>
                <h1 class="mb-2 font-bold pt-5 mt-0 text-1xl leading-tight">Assign Evidence<b></b></h1>
                <div class="grid grid-cols-2 md:grid-cols-8 mt-4">
                    <ng-container *ngIf="!isFutureRatingPeriod; else futureDomains">
                        <div *ngFor="let domain of templateDomains" class="col-span-1">
                            <h4 class="text-[16px] font-bold mb-2">{{domain.domainCode}}</h4>
                            <mat-checkbox class="font-medium" [checked]="domain.isAllSelected"
                                [(ngModel)]="domain.isAllSelected"
                                (change)="onDomainSelectChange($event.checked, domain)"
                                [ngModelOptions]="{standalone: true}">
                                {{domain.domainCode}}
                            </mat-checkbox>
                            <div class="ml-4">
                                <div *ngFor="let measure of domain.measures">
                                    <mat-checkbox class="font-medium"
                                        (change)="onMeasureSelectChange($event.checked, domain)"
                                        [(ngModel)]="measure.isSelected" [ngModelOptions]="{standalone: true}">
                                        {{measure.code}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #futureDomains>
                        <div *ngFor="let domain of masterDomains" class="col-span-1">
                            <h4 class="text-[16px] font-bold mb-2">{{domain.domainCode}}</h4>
                            <mat-checkbox class="font-medium" [checked]="domain.isAllSelected"
                                [(ngModel)]="domain.isAllSelected"
                                (change)="onFutureDomainSelectChange($event.checked, domain)"
                                [ngModelOptions]="{standalone: true}">
                                {{domain.domainCode}}
                            </mat-checkbox>
                            <div class="ml-4">
                                <div *ngFor="let measure of masterMeasures | filter: 'masterDomainId': domain.id">
                                    <mat-checkbox class="font-medium"
                                        (change)="onMeasureSelectChange($event.checked, domain)"
                                        [(ngModel)]="measure.isSelected" [ngModelOptions]="{standalone: true}">
                                        {{measure.measureCode}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                </div>
            </ng-container>

            <div class="w-1/3">
                <drdp-date-picker
                    label="Date of Collected Evidence"
                    [isRequired]="true"
                    [isReadOnly]="false"
                    [minDate]="minStartDate"
                    [maxDate]="today"
                    (emitDate)="handleDate($event)">
              </drdp-date-picker>
            </div>
        </div>
    </drdp-container>

    <drdp-container *ngIf="formStep === step.StepTwo" additionClasses="min-h-[2rem] mt-4 p-4">
        <h1 class="mb-2 font-bold mt-0 text-2xl leading-tight">Assigned Evidence <b></b></h1>
        <p class="helper-text">A Preliminary Rating is not required.  Please check the box/boxes of the measures you would like to save your evidence to and click on the save button prior to leaving this page.</p>
        <div class="grid grid-cols-3 gap-4">
            <div>
                <label class="font-bold"> Selected Students</label>
            </div>
            <div>
                <label class="font-bold"> {{uploadSettings.uploadText}} Evidence</label>
            </div>
            <div>
                <label class="font-bold"> Preliminary Rating</label>
            </div>
        </div>

        <div *ngIf="!isFutureRatingPeriod">
            <div *ngFor="let item of addPortfolioModel.evidences; let isLast = last" class="grid grid-cols-3 gap-4"
                [ngClass]="{'border-b': !isLast}">
                <div>
                    <div>{{item.name}}</div>
                </div>
                <div>
                    <div *ngFor="let measure of item.measures">
                        <mat-checkbox class="font-medium my-3" [(ngModel)]="measure.isSelected"
                            [ngModelOptions]="{standalone: true}">
                            {{measure.code}}
                        </mat-checkbox>
                    </div>
                </div>
                <div>
                    <div *ngFor="let measure of item.measures" class="mt-1 mb-3">
                        <drdp-btn color="outline" (click)="openInputRatingModal(measure)">
                            Rate {{measure.code}} <i
                                class="text-drdpblue-300 text-xl uil uil-check-circle {{measure.measureValue ? 'text-green-800' : 'text-gray-500'}}"></i></drdp-btn>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="isFutureRatingPeriod" class="p-4">
            <div *ngFor="let item of addPortfolioModel.evidences; let isLast = last" 
                 class="grid grid-cols-3 gap-4 h-full" [ngClass]="{'border-b': !isLast}">
                <div class="flex flex-col justify-start space-y-2 h-full">
                    <div>{{item.name}}</div>
                </div>
                
                <div class="flex flex-col justify-start space-y-2 h-full">
                    <div *ngFor="let measure of item.measures" class="my-2">
                        <mat-checkbox class="my-2" [(ngModel)]="measure.isSelected" 
                            [ngModelOptions]="{standalone: true}">
                            {{measure.measureCode}}
                        </mat-checkbox>
                    </div>
                </div>
                
                <div class="flex flex-col justify-between space-y-2 h-full">
                    <div *ngFor="let measure of item.measures" class="my-2">
                        <drdp-btn [color]="measure.measureId ? 'outline' : 'outlineRed'" 
                            (click)="measure.measureId && openInputRatingModal(measure)">
                            <span *ngIf="measure.measureId; else unavailableLevel">
                                Rate {{measure.measureCode}}
                                <i class="text-drdpblue-300 text-md uil uil-check-circle" 
                                   [ngClass]="{'text-green-800': measure.measureValue, 'text-gray-200': !measure.measureValue}"></i>
                            </span>
                            <ng-template #unavailableLevel>{{measure.measureCode}} not available for age grade</ng-template>
                        </drdp-btn>
                    </div>
                </div>
            </div>
        </div>
        
    </drdp-container>

    <div class="mt-5 mb-20 flex justify-end">
        <drdp-btn *ngIf="formStep === step.StepTwo" class="pr-4" (click)="cancel()" color="cancel" type="button">
            Cancel
        </drdp-btn>
        <drdp-btn type="submit" [disabled]="disableSave" class="float-right" (click)="onSaveEvidence()">{{formStep === step.StepOne
            ? 'Next' :
            'Save'}}
        </drdp-btn>
    </div>
</div>
