<div class="p-5">
  <div>
    <drdp-title title="DRDP 2025 Teacher Survey Results"></drdp-title>
  </div>
  <drdp-container additionClasses="mb-4">
    <div class="w-full">
      <drdp-container color="lightBlue" additionClasses="p-6">
        <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-2 my-auto"
          [formGroup]="searchForm" (ngSubmit)="handleDownload()">
          <div class="lg:col-span-5 md:col-span-2 col-span-1">
            <mat-checkbox color="primary" (change)="handleAll()"></mat-checkbox>
            <label class="inline">Search All Agencies</label>
          </div>

          <drdp-select-state [clear]="clearDropdown" [initialStateId]="stateId.value" [disabled]="true"
            (state)="handleDropdown($event, selectType.States)" [required]="true">
          </drdp-select-state>

          <drdp-select-agency [clear]="clearDropdown" [hidden]="allAgencies" [stateId]="stateId.value" [initialAgencyId]="agencyId.value"
            [isPilot]="true" [required]="true" (agency)="handleDropdown($event, selectType.Agencies)">
          </drdp-select-agency>

          <drdp-select-school-year (schoolYear)="handleDropdown($event, selectType.SchoolYear)"
            [required]="true">
          </drdp-select-school-year>

          <drdp-select-global-rating (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)"
            [schoolYearId]="schoolYearId.value" [required]="true">
          </drdp-select-global-rating>

          <drdp-select-site [hidden]="allAgencies" (site)="handleDropdown($event, selectType.Sites)" [agencyId]="agencyId.value"
            [clear]="clearDropdown" [isPilot]="true" [multiSelect]="true" [multiSelectInitVals]="false" [required]="true">
          </drdp-select-site>

          <drdp-select-class [clear]="clearDropdown" [hidden]="allAgencies" (classroom)="handleDropdown($event, selectType.Class)"
            [showPilotClasses]="true" [globalRatingPeriodId]="ratingPeriodId.value" [siteIds]="siteIds.value">
          </drdp-select-class>

          <div class="flex items-end justify-end" [ngClass]="{'lg:col-span-4': !allAgencies}">
            <div>
              <drdp-btn type="submit" [disabled]="searchForm.invalid">
                Download Teacher Survey Results
              </drdp-btn>
            </div>
          </div>
        </form>
      </drdp-container>
    </div>
  </drdp-container>
</div>
