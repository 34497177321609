import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IClassStudent } from '@core/interfaces/iclasses';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { take } from 'rxjs';
import { isDateBetween } from '@core/services/helper.service';
import { ConfirmationSpedExitDateComponent } from '../confirmation-sped-exit-date/confirmation-sped-exit-date.component';
@Component({
  selector: 'app-batch-sped-exit-date',
  templateUrl: './batch-sped-exit-date.component.html',
  styleUrls: ['./batch-sped-exit-date.component.css']
})
export class BatchSpedExitDateComponent implements OnInit {
  spedExitDateForm: FormGroup | any;
  students: any;
  isProcessing = false;
  minSpedEntryDate?: Date;
  gpMinStartDate?: Date;
  gpMaxEndDate?: Date;

  constructor(
    public toastService: ToastService,
    private fb: FormBuilder,
    public modal: MatDialog,
    public dialogRef: MatDialogRef<BatchSpedExitDateComponent>,
    public studentService: StudentService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.students = this.editData;
    // Find the minimum spedEntryDate using reduce
    this.minSpedEntryDate = this.students.reduce((minDate: Date, student: IClassStudent) => {
    if (!minDate || (student.spedEntryDate ?? new Date) < minDate) {
        return student.spedEntryDate;
    }
    return minDate;
    }, undefined);
    this.initializeForms();
  }

  initializeForms(): void {
    this.spedExitDateForm = this.fb.group({
      spedExitDate: [null, Validators.required],
      classId: [this.students[0]?.classId || 0, Validators.required],
      schoolYearId:  [this.students[0]?.schoolYearId || 0, Validators.required],
    });
  }

  handleDate(event: any) {
    this.gpMinStartDate = new Date(this.students[0].gpMinStartDate);
    this.gpMaxEndDate = new Date(this.students[0].gpMaxEndDate);
    const selectedDate = new Date(event);
    const isBeforeSpedEntryDate = this.students
      .filter((student: IClassStudent) => student.spedEntryDate) // Filter out students without spedEntryDate
      .some((student: IClassStudent) => {
        if (student.spedEntryDate === null) return false;
        const spedEntryDate = student.spedEntryDate ? new Date(student.spedEntryDate) : new Date();
        return selectedDate < spedEntryDate;
      });

    if (isBeforeSpedEntryDate) {
      this.toastService.error('SPED Exit Date cannot be before SPED Entry Date');
      this.spedExitDateForm.get('spedExitDate').setValue(null);
    } else if (!isDateBetween(this.gpMinStartDate, this.gpMaxEndDate, selectedDate) ) {
      this.toastService.error(`The Exit Date must fall within the current enrollment Rating Period.
      If the Exit Date needs to be a past date that falls within a past Rating Period,
      please go to that Rating Period Classroom's page.`);
      this.spedExitDateForm.get('spedExitDate').setValue(null);
    } else {
      this.spedExitDateForm.get('spedExitDate').setValue(event);
    }
  }

  onSubmit() {
    if (this.spedExitDateForm.invalid) {
      this.toastService.error('SPED Exit Date cannot be before SPED Entry Date');
      return;
    }
    this.isProcessing = true;
    const formValues = this.spedExitDateForm.value;
    const payload = {
      studentIds: this.students.map((student: IClassStudent) => student.id),
      spedExitDate: formValues.spedExitDate,
      classId: formValues.classId,
      schoolYearId: formValues.schoolYearId
    };
    this.studentService.updateStudentSpedDates(payload)
    .pipe(take(1))
      .subscribe((res: any) => {
        this.isProcessing = false;
        if (res) {
          this.dialogRef.close({ success: true });
          this.modal.open(ConfirmationSpedExitDateComponent, {
            data: {
              isSuccess: res.isSuccess,
              incompleteCOSStudents: res.incompleteCOSStudents,
              successfulStudents: res.successfulStudents,
              missingScoresStudents: res.missingScoresStudents
            }
          });
        }
      });
  }

}
