import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IReportType } from '@core/interfaces/ireport-type';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-select-report-type',
  templateUrl: './select-report-type.component.html',
  styleUrls: ['./select-report-type.component.scss']
})
export class SelectReportTypeComponent implements OnInit {
  @Input() label: string = 'Report Name';
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() clear: boolean = false;
  @Input() required: boolean = false;
  @Input() isReportTracking?: boolean = false;
  @Output() onSelected = new EventEmitter<IReportType | null>();

  reportTypeOptions: IReportType[] = [];
  public select = SelectType;

  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getReportTypes();
  }

  getReportTypes(): void {
    this.lookupService.getReportTypes().pipe(take(1)).subscribe((res: IReportType[]) => {
      if (this.isReportTracking) res = res.filter((report) => report.isReportTracking);
      this.reportTypeOptions = res;
    });
  }

  emitSelectedReportType(selectedReport: IReportType): void {
    this.onSelected.emit(selectedReport);
  }
}
