<div *ngIf="!isAssign && !isCopy" class="flex flex-row gap-14 pb-5">

  <div *ngIf="evidences && evidences.length < 1">
    <label for="No evidence">No Videos available.</label>
  </div>

  <div *ngFor="let video of evidences;  let i = index" class="flex flex-col w-7/12">
    <div class="grid grid-cols-3 cursor-pointer pt-4" (click)="toggleVideo()">
      <div class=" col-span-1">
        <label [for]="video.fileName">Name:</label>
        <p class="break-words">{{video.fileName}}</p>
      </div>
      <div class=" col-span-1">
        <label for="createdDate" class=" text-right">Upload Date:</label>
        <p class=" text-right">{{video.createdOn | localDate | date: "MM/dd/yy"}}</p>
      </div>
      <div class=" flex col-span-1 justify-end align-middle">
        <i [ngClass]="{'uil uil-angle-down text-xl': show,
                         'uil uil-angle-right text-xl': !show}">
        </i>
      </div>
    </div>

    <div *ngIf="show && video.id == video.id">
      <drdp-video [edit]="edit" [url]="generateUrlWithSasToken(video.url)"></drdp-video>
    </div>

    <div *ngIf="!edit">
      <label>Notes</label>
      <p class="break-words">{{video.notes}}</p>
      <div *ngIf="video.selectedLevel"
        class="flex items-center">
        <label class="font-normal text-sm">{{video.levelName}}</label>
        <img class="w-5 h-5 ml-2 -mt-1"
          src="assets/images/portfolio/{{video.levelIcon}}-{{video.selectedLevel ? video.selectedLevel: 1}}.svg" />
      </div>
    </div>

    <div *ngIf="edit">
      <label>Edit Notes</label>
      <textarea class=" w-full h-64 border-2 border-black p-3" [(ngModel)]="video.notes"
        (keyup)="emitUpdates(video)"></textarea>
    </div>
    <div class="my-2 text-sm">
      {{video.collectedOn | date : "MM/dd/yyyy"}}
    </div>
    <div class="my-2"> <strong>Uploaded By:</strong> {{video.uploadedBy}}</div>
    <div *ngIf="isFutureEvidence && canEdit">
      <drdp-btn color="outline" (click)="openAssignModal(video)">
        Assign Enrollment </drdp-btn>
    </div>

    <div *drdpCheckPermission="permission.EditObservations">
      <div *ngIf="isPortfolio && !isFutureEvidence">
        <drdp-btn *ngIf="!isDomain && !edit" color="outline" (click)="ratingUpdates(video)">
          Rate {{video.measureCode}} <i
            class="text-drdpblue-300 text-xl uil uil-check-circle {{video.measureValue ? 'text-green-800' : 'text-gray-500'}}"></i></drdp-btn>
        <drdp-btn *ngIf="!edit && isDomain" class="text-sm pt-2" color="outline" (click)="toggleAssign(video)">Assign
          Evidence</drdp-btn>
        <div class="mb-6" *ngIf="edit && !isFutureEvidence">
          <drdp-btn class="text-sm pt-2" color="fill" (click)="toggleCopy(video)">Copy Evidence</drdp-btn>
        </div>
        <button *ngIf="edit" type="button" class=" text-red-600 pt-2 justify-start text-sm"
          (click)="deleteEvidence(video, i)"><i class="uil uil-times-circle mr-1"></i>Remove Evidence</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAssign || isCopy" class="flex flex-row gap-14 pb-5">
  <div class="flex flex-col w-full">
    <label>{{ evidenceLabel }}</label>
    <drdp-video [edit]="isAssign" [url]="generateUrlWithSasToken(currentAssignment?.url)"></drdp-video>
    <label>Notes</label>
    <p class="break-words">{{currentAssignment?.notes}}</p>
    <label>{{ assignmentLabel }}</label>
    <span *ngIf="isCopy">Make a copy of existing evidence to move to another domain/measure.</span>
    <div class="flex flex-row gap-14">
      <div *ngFor="let domain of domainGrouping">
        <div class="flex flex-col">
          <label>{{domain.domainCode}}</label>
          <ng-container *ngIf="isCopy; else isAssign">
            <mat-checkbox
              *ngIf="isDomain"
              class="font-medium"
              [checked]="domain.isAllSelected"
              [(ngModel)]="domain.isAllSelected"
              (change)="onDomainSelectChange($event.checked, domain)"
              [ngModelOptions]="{standalone: true}">
              {{domain.domainCode}}
            </mat-checkbox>
            <div class="ml-4">
              <div *ngFor="let measure of domain.measures">
                <mat-checkbox class="font-medium"
                  (change)="onMeasureSelectChange($event.checked, domain, measure.id)"
                  [(ngModel)]="measure.isSelected"
                  [ngModelOptions]="{standalone: true}">
                  {{measure.code}}
                </mat-checkbox>
              </div>
            </div>
          </ng-container>
          <ng-template #isAssign>
            <div *ngFor="let measure of domain.measures">
              <mat-checkbox color="primary" (click)="handleCheckbox(measure.id)">{{measure.code}}</mat-checkbox>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="isAssign" class="self-end">
      <drdp-btn class="text-sm pt-2 mr-3" color="cancel" (click)="toggleAssign(null)">Cancel</drdp-btn>
      <drdp-btn class="text-sm pt-2" color="outline" (click)="assignEvidence()">Assign Evidence</drdp-btn>
    </div>
    <div *ngIf="isCopy">
      <drdp-btn class="text-sm pt-2 mr-3" color="cancel" (click)="toggleCopy(null)">Cancel</drdp-btn>
      <drdp-btn class="text-sm pt-2" color="outline" (click)="copyEvidence()">Copy Evidence</drdp-btn>
    </div>
  </div>
</div>
