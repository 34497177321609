import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-request-status-type',
  templateUrl: './select-request-status-type.component.html',
  styleUrls: ['./select-request-status-type.component.scss']
})
export class SelectRequestStatusTypeComponent implements OnInit {
  @ViewChild('requestStatusType') requestStatusType: FilterSelectComponent | undefined;
  @Input() label: string = 'Request Status Type';
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() clear: boolean = false;
  @Input() initialRequestStatusTypeId?: number | null;
  @Output() onSelected = new EventEmitter<string | null>();

  requestStatusTypeOptions: any[] = [];
  public select = SelectType;

  constructor(private requestReasonService: LookupService) { }

  ngOnInit(): void {
    this.getRequestStatusType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clear'] && changes['clear'].currentValue === true) {
      this.clearDropdown();
    }
  }

  clearDropdown(): void {
    if (!this.disabled) this.requestStatusType?.clearSelected();
  }

  getRequestStatusType(): void {
    this.requestReasonService.getRequestStatusType().pipe(take(1)).subscribe((res) => {
      this.requestStatusTypeOptions = res;
    })
  }

  emitSelectedRequestStatusType(selectedReason: any): void {
    this.onSelected.emit(selectedReason);
  }
}
