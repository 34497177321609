import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsDescriptionComponent } from './news-description/news-description.component';
import { Subscription, take, tap } from 'rxjs';
import { NewsItemService } from '@core/services/news-item.service';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.scss'],
})
export class LatestNewsComponent implements OnInit, OnDestroy {
  showModal: boolean = false;
  newsItems: any[] = [];
  permissionService = inject(PermissionService);
  canDelete = this.permissionService.checkPermission(Permission.DeleteNewsItem);

  constructor(
    public modal: MatDialog,
    public newsItemService: NewsItemService
  ) {}
  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.getRecentNewsItems();
  }

  getRecentNewsItems() {
    this.subscriptions.add(
      this.newsItemService.getUserActiveNewsItem(15).subscribe((res: any) => {
        this.newsItems = res;
      })
    );
  }

  toggleModal(data: any): void {
    const modalRef = this.modal.open(NewsDescriptionComponent, {
      data: data ? data : undefined,
    });
  }

  deleteNewsItem(news: any) {
    let modal = { data: { modalInfo: {} } };
    modal.data.modalInfo = {
      title: `Delete News Item`,
      message: `Are you sure you want to want to delete <span class="font-bold">${news.newsItemTitle}</span>?`,
      name: news.newsItemTitle,
      primaryBtnClass: 'red',
      primaryBtnText: Modal.Delete,
      showCancel: true,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: modal,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.newsItemService
          .deleteNewsItem(news.id, 'News Item Removed')
          .pipe(
            take(1),
            tap((res: boolean) => {
              if (res) {
                this.getRecentNewsItems();
              }
            })
          )
          .subscribe();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
