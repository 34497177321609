<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-1/2">
      <drdp-title title="User Account"></drdp-title>
    </div>
    <div class="basis-1/2">
      <div class="flex justify-end items-end" *ngIf="canCreateAccount">
        <drdp-btn (click)="openModalAdd()">
          <i class="uil uil-plus"></i>
          Add New User
        </drdp-btn>
      </div>
    </div>
  </div>

  <drdp-container [additionClasses]="'min-h-[44rem]'">
    <div class="w-full">
      <drdp-container color="lightBlue">
        <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-8" [formGroup]="userSearch"
          (ngSubmit)="searchAccounts()">
          <div class="self-start my-auto">
            <label class="font-bold">Search by:</label>
            <div class="border-b border-black">
              <input matInput placeholder="Name or Email" formControlName="searchStr"
                class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300" />
            </div>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleState($event)" [clear]="clearDropdowns">
            </drdp-select-state>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-agency #agencySelect [initialAgencyId]="agencyId.value" (agency)="handleAgency($event)"
              [stateId]="stateId.value" [clear]="clearDropdowns">
            </drdp-select-agency>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-site [initialSiteIds]="siteIds.value" (site)="handleSites($event)" [agencyId]="agencyId.value"
              [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearDropdowns">
            </drdp-select-site>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-status [initialStatusId]="userStatusIds.value" [disabled]="false" (status)="handleStatus($event)" [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearDropdowns">
            </drdp-select-status>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-role label="Role" [initialRolesIds]="roleIds.value" [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearDropdowns" (role)="handleroles($event)">
            </drdp-select-role>
          </div>

          <div class="lg:col-span-2 flex flex-row md:flex-col-1 gap-5 lg:flex-row lg:justify-start lg:items-end">
            <div class="mb-0 md:mb-2 lg:mb-0">
              <drdp-btn type="submit" class="lg:mr-2">Search</drdp-btn>
            </div>
            <div>
              <drdp-btn color="outline" type="button" (click)="onClear()">Clear</drdp-btn>
            </div>
          </div>
        </form>
      </drdp-container>
      <drdp-add-edit-table tableId="userAccount" [dataSource]="dataSource" [tableData]="tableData"
        [columns]="tableColumns" viewIcon="uil uil-user text-drdpblue-300" (editActionItem)="openEditModal($event)"
        (viewActionItem)="openViewModal($event)">
      </drdp-add-edit-table>
    </div>
  </drdp-container>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
  </mat-paginator>
</div>