<div>
    <h1 class="text-2xl font-medium">Child Upload Template</h1>
    <div class="flex flex-col pt-5">
        <div class="col-span-1">
            <label>All Instrument Upload Template:</label>
            <span class="pl-0 sm:pl-4 whitespace-nowrap"><i class="uil uil-file-download text-drdpblue-300 text-xl"></i>
                <button class="pl-1" (click)="downloadFile()">Download File</button>
            </span>
            <span class="pl-4 whitespace-nowrap"><i class="uil uil-upload text-drdpblue-300 text-xl"></i>
                <input type="file" class="file-input"(change)="onFileSelected($event)" #fileUpload>
                <button class="pl-1" (click)="fileUpload.click()">Upload File</button>
            </span>
        </div>
    </div>
</div>