<div class="bg-drdpblue-200 px-5">
  <div class="min-w-full flex place-content-between place-items-center">
    <drdp-title class="mt-4" title="Welcome"> {{ user.userName }}</drdp-title>
    <img *ngIf="canViewPortfolioMessages" class="cursor-pointer" [src]="icon" alt="Message Board"
      routerLink="/portfolio-messages">
  </div>

  <div>
    <mat-checkbox [checked]="isSignedUp" (change)="onCheckboxChange()">
      <span>Please add me to the weekly distribution of DRDP Online updates that will be sent to me via the email used for my DRDP Online account.</span>
    </mat-checkbox>
  </div>

  <div>
    <div *ngIf="canViewNewsItem">
      <drdp-news-item class="flex place-content-between"></drdp-news-item>
    </div>



    <h2 class="text-[20px] font-bold mt-5 mb-4">My Dashboard</h2>
    <div class="grid lg:grid-cols-4 md:grid-cols-3 gap-4">

      <drdp-container *ngIf="canViewLockedChildren" color="whiteDash" additionClasses="justify-center">
        <a class="flex-col text-center px-4 pb-4 h-36" [routerLink]="['/total-locked-children']">
          <p class="text-drdpblue-300 text-[60px]">
            {{ ratingCount }}
          </p>
          <p class="text-[15px]">Total Locked Children</p>
        </a>
      </drdp-container>



      <ng-container *ngFor="let info of myDashboard">
        <drdp-container *drdpCheckPermission="info.access" color="whiteDash" additionClasses="justify-center">
          <a class="grid grid-cols-1 text-center p-4 w-full h-36" [routerLink]="[info.link]">
            <div *ngIf="info.hasPendingRequest" class="flex justify-end">
              <div class="rounded-full py-1 bg-drdpblue-300 text-white text-xs w-24">Items Pending</div>
            </div>
            <i *ngIf="info.icon" class="text-drdpblue-300 text-[60px] {{ info.icon }} flex justify-center"></i>
            <p class="text-[15px] flex justify-center">{{ info.label }}</p>
          </a>
        </drdp-container>
      </ng-container>
    </div>

    <div *ngIf="canViewLanguage || canEditLanguage">
      <h2 class="text-[20px] font-bold mt-5 mb-2">Language Settings</h2>
      <div class="flex flex-row">
        <div class="basis-full md:basis-1/2 bg-white rounded-[30px] shadow-md px-5 pt-4 pb-3">
          <div *ngFor="let info of languageSettingsInfo" class="mb-1">
            <mat-slide-toggle [disabled]="!canEditLanguage" color="primary" (change)="toggleSwitchAction(info.label)">
            </mat-slide-toggle>
            <span> {{info.label}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
