import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IPermission } from '@core/interfaces/ipermission'

@Component({
  selector: 'drdp-features-add-edit',
  templateUrl: './features-add-edit.component.html',
  styleUrls: ['./features-add-edit.component.scss']
})
export class FeaturesAddEditComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  featureForm: FormGroup | any;
  headerName = "Add New Feature";

  get permissionName() { return this.featureForm.get('permissionName') };
  get permissionDescription() { return this.featureForm.get('permissionDescription') };

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FeaturesAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.initializeForms();
  }

  initializeForms(): void {
    this.featureForm = this.fb.group({
      permissionName: [null, Validators.required],
      permissionDescription: [null, Validators.required],
    });

    if (this.editData) {
      const edit = this.editData.data
      this.headerName = "Edit Feature";
      this.permissionName.setValue(edit.permissionName)
      this.permissionDescription.setValue(edit.permissionDescription)
    };
  }

  onSubmit() {
    let payload: IPermission = this.featureForm.value;

    if (this.editData) {
      const { id } = this.editData.data;
      payload.id = id;
    }

    return {
      formValid: true,
      payload: payload
    };
  }
}
