<div class="p-5">
  <div class="flex flex-row">
    <drdp-title title="Pending Child Re-Enrollment"></drdp-title>
  </div>

  <drdp-container additionClasses="w-full">
    <drdp-container color="lightBlue">
      <div class="p-3 flex grid md:grid-cols-5 gap-2 flex-row content-between">
        <drdp-classroom-rating-period-params
          (searchValues)="handleSearch($event)"
          [search]="true"
          class="contents"
        >
        </drdp-classroom-rating-period-params>
      </div>
    </drdp-container>
    <div>
      <drdp-add-edit-table
        [dataSource]="dataSource"
        [tableData]="tableData"
        [columns]="tableColumns"
        (selectActionItem)="handleChangeRequest($event)"
        (toggleSwitchActionItem)="handleApprovalRequest($event)"
        (editActionItem)="handleEditRequest($event)"
      >
      </drdp-add-edit-table>
    </div>
    <div class="spacer"></div>
    <div class="flex flex-row justify-end">
      <drdp-btn color="cancel" class="mr-3" mat-button (click)="handleCancel()">
        Cancel
      </drdp-btn>
      <drdp-btn color="blue" (click)="submit()"> Save </drdp-btn>
    </div>
  </drdp-container>
</div>
