import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Modal } from '@core/enums/modal';
import { Permission } from '@core/enums/permissions';
import { PortfolioEvidenceType } from '@core/enums/portfolio';
import { IMasterDomain } from '@core/interfaces/imaster-domain';
import { IModalEmitData } from '@core/interfaces/imodal';
import { IPortfolioAssignMeasureEvidence, IPortfolioEvidenceResponse } from '@core/interfaces/iportfolio';
import { addHTTPS } from '@core/services/helper.service';
import { PermissionService } from '@core/services/permission.service';
import { PortfolioService } from '@core/services/portfolio.service';
import { ToastService } from '@core/services/toast.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import { ITemplateMeasure } from '@views/user/configuration/rating-view-setup/interfaces/itemplate-measure';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-evidence-photos',
  templateUrl: './evidence-photos.component.html',
  styleUrls: ['./evidence-photos.component.scss']
})
export class EvidencePhotosComponent implements OnInit {
  @Output() evidenceAction = new EventEmitter();
  @Output() ratingAction = new EventEmitter();
  @Output() refreshEvidences = new EventEmitter();
  @Output() toggleAssignModal = new EventEmitter<IPortfolioEvidenceResponse>();
  @Input() edit = false;
  @Input() domainGrouping?: any;
  @Input() evidences?: IPortfolioEvidenceResponse[];
  @Input() domainId?: number;
  @Input() measureId?: number;
  @Input() sasToken?: any;
  @Input() isPortfolio = false;
  @Input() isDomain = false;
  @Input() isFutureEvidence: boolean = false;
  currentAssignment?: IPortfolioEvidenceResponse | null;
  isAssign = false;
  measureIds: number[] = [];
  domainIds: number[] = [];
  isCopy = false;
  evidenceLabel: string = 'Assign Image';
  assignmentLabel: string = '';
  canEdit = this.permissionService.checkPermission(
    Permission.EditObservations
  );
  public get permission() {return Permission }
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    public modal: MatDialog,
    private permissionService: PermissionService) { }

  ngOnChanges()
  {
    if (this.evidences) {
      this.evidences = this.evidences.filter((photo: IPortfolioEvidenceResponse) => photo.evidenceTypeID === PortfolioEvidenceType.Photo && photo.domainId === this.domainId &&  (this.measureId == null || photo.measureId === this.measureId));
    }
    if (this.edit) this.isAssign = false;
  }

  ngOnInit(): void {
    if (this.evidences) {
      this.evidences = this.evidences.filter((photo: IPortfolioEvidenceResponse) => photo.evidenceTypeID === PortfolioEvidenceType.Photo && photo.domainId === this.domainId &&  (this.measureId == null || photo.measureId === this.measureId));
    }
  }

  generateUrlWithSasToken(blobUrl?: string) {
    if (this.sasToken) {
      const urlWithSasToken = `${addHTTPS(blobUrl)}${this.sasToken.value}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
    }
    return '';
  }

  handleCheckbox(id: number): void {
    if (!this.measureIds.includes(id)) {
      this.measureIds.push(id);
    } else {
      let index = this.measureIds.indexOf(id);
      this.measureIds.splice(index, 1);
    }
  }

  assignEvidence(): void {
    if (this.measureIds.length < 1) {
      return this.toastService.warn('Choose measures to assign.');
    }

    if (this.currentAssignment) {
      let payload: IPortfolioAssignMeasureEvidence = {
        id: this.currentAssignment.id,
        portfolioId: this.currentAssignment.portfolioId,
        evidenceId: this.currentAssignment.evidenceId,
        selectedLevel: this.currentAssignment.selectedLevel,
        measureIds: this.measureIds
      }

      this.portfolioService.assignEvidence(payload, 'Evidence assigned to measures.').pipe(
        take(1),
        tap((res: boolean) => {
          if (res) {
            if (this.evidences) {
              const index = this.evidences?.findIndex((video: IPortfolioEvidenceResponse) => video?.id === this.currentAssignment?.id);
              this.evidences?.splice(index, 1);
              this.isAssign = false;
            }
          }
        })
      ).subscribe();
    }
  }

  deleteEvidence(photo: IPortfolioEvidenceResponse, index: number): void {
    let modal: IModalEmitData = {
      data: photo,
      index: index
    }
    modal.data.modalInfo = {
    title: `Delete ${photo.fileName}`,
    message: `Are you sure you want to want to delete <span class="font-bold">${photo.fileName}</span>?`,
    name: photo.fileName,
    primaryBtnClass: 'red',
    primaryBtnText: Modal.Delete
  };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
    data: modal,
  });

    modalRef.afterClosed().subscribe((res) => {
    if (res) {
      this.portfolioService.deleteEvidence(photo.id, 'Evidence Removed').pipe(
        take(1),
        tap((res: boolean) => {
          if (res) {
            if (this.evidences) {
              const index = this.evidences?.findIndex((evidence: IPortfolioEvidenceResponse) => evidence.id === photo.id);
              this.evidences.splice(index, 1);
              this.refreshEvidences.emit();
            }
          }
        })
      ).subscribe();
    }
  });
}

  onDomainSelectChange(value: boolean, domain: IBaseTemplateDomain) {
    if (value) domain.measures.forEach((m) => (m.isSelected = false));
  }

  onMeasureSelectChange(value: boolean, domain: IBaseTemplateDomain | IMasterDomain, id: number) {
    if (value) domain.isAllSelected = false;
  }

  copyEvidence(): void {
    this.domainGrouping.forEach((domain: IBaseTemplateDomain) => {
      if (domain.isAllSelected) this.domainIds.push(domain.id);
      domain.measures.forEach((measure: ITemplateMeasure) => {
        if (measure.isSelected) this.measureIds.push(measure.id);
      });
    });

    if (this.domainIds.length < 1 && this.measureIds.length < 1) {
      return this.toastService.warn('No domains or measures to copy.');
    }

    if (this.currentAssignment) {
      let payload: IPortfolioAssignMeasureEvidence = {
        id: this.currentAssignment.id,
        portfolioId: this.currentAssignment.portfolioId,
        evidenceId: this.currentAssignment.evidenceId,
        selectedLevel: this.currentAssignment.selectedLevel,
        measureIds: this.measureIds,
        domainIds: this.domainIds,
        measureLevelName: this.currentAssignment.levelName
      };

      const ageGradeInstrumentId = this.route.snapshot.paramMap.get('ageGradeId');
      if (ageGradeInstrumentId) payload.ageGradeInstrumentId = +ageGradeInstrumentId;

      this.portfolioService.copyEvidence(payload).pipe(
        take(1),
        tap(res => {
          if (res.isSaved && this.evidences) {
            if (!res.ratingCopied) this.toastService.warn('Evidence copied. Could not copy rating.');
            else this.toastService.success('Evidence copied.');
          }
          this.refreshEvidences.emit();
        })
      ).subscribe();
    }
  }

  emitUpdates(event: IPortfolioEvidenceResponse): void{
    this.evidenceAction.emit(event);
  }

  ratingUpdates(event: IPortfolioEvidenceResponse): void{
    this.ratingAction.emit(event);
  }

  toggleAssign(evidence: IPortfolioEvidenceResponse | null): void {
    this.currentAssignment = evidence;
    this.isAssign = !this.isAssign;
  }

  openAssignModal(evidence: IPortfolioEvidenceResponse) {
    this.toggleAssignModal.emit(evidence);
  }

  toggleCopy(evidence: IPortfolioEvidenceResponse | null):void {
    this.currentAssignment = evidence;
    this.isCopy = !this.isCopy;
    this.evidenceLabel = this.isCopy ? 'Copy Evidence' : 'Assign Image';
    this.assignmentLabel = this.isCopy ? 'Copy Evidence to Another Domain or Measure' : 'Assign Evidence'
  }
}
