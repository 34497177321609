import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IRoles } from '@core/interfaces/iroles';
import { RoleService } from '@core/services/role.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss']
})
export class SelectRoleComponent implements OnInit {
  @ViewChild('roleSelect') roleSelect: FilterSelectComponent | undefined;
  @Output() role = new EventEmitter<any>();
  @Input() label = 'User Role';
  @Input() initialRolesIds?: number[] | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = true;
  @Input() multiSelectInitVals: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  roleOptions?: IRoles[] = [];
  public get select() {return SelectType }
  constructor(private roleService: RoleService) { }

  ngOnInit(): void {
    this.getRoles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.roleSelect?.clearSearch(true);
    };
  }

  getRoles(): void {
    this.roleService.getAllByOrder()
      .pipe(
        take(1)
      )
      .subscribe((roles: IRoles[]) => this.roleOptions = roles);
  }

  onAgencySelect(event: IRoles): void {
    this.role.emit(event);
  }
}