<div class="dropdown">
  <div class="flex items-center relative" role="button">
    <div class="flex-col">
      <p class="text-xl p-0 m-0 font-bold">{{ vm?.label | uppercase }}</p>
      <p class="text-drdpgrey-400 text-xs">{{ vm?.labelTwo }}</p>
    </div>

    <i class="uil uil-angle-down text-2xl pr-1 down"></i>
    <i class="uil uil-angle-up text-2xl pr-1 hidden up"></i>
  </div>

  <div
    class="bg-white absolute place-content-center py-1 content-center drop-shadow-lg rounded-md shadow-inner p-1 z-10 hidden dropdown-content">
    <ul class="bg-white">
      <li *ngFor="let sublink of sites; let i = index">
        <a class="text-xs">
          <span>{{ sublink.site.siteName }}</span>
        </a>
      </li>
      <li *ngIf="!sites || sites.length == 0">
        <a class="text-xs">
          <span>No assigned sites available</span>
        </a>
      </li>
    </ul>
  </div>
</div>