<div class="flex flex-row bg-drdpblue-300">
    <div *ngIf="logoSrc" 
        class="basis-full md:basis-5/12 flex items-center justify-center md:justify-end">
        <img src={{logoSrc}} class="h-fit" alt={{logoAlt}}>
    </div>
    <div *ngIf="imgSrc" 
        class="basis-0 md:basis-1/2">
        <img src={{imgSrc}} class="h-fit" alt={{imgAlt}}>
    </div>
    <div *ngIf="img2Src" 
        class="basis-0 md:basis-2/12 flex items-center justify-center pr-4">
        <img src={{img2Src}}  alt={{img2Alt}} class="h-18 xxl:h-auto">
    </div>
</div>
