import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Permission } from '@core/enums/permissions';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { IVendor } from '@core/interfaces/ivendor';
import { Router } from '@angular/router';

@Component({
  selector: 'drdp-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  hasFullAccess?: boolean;

  tableColumns = [
    { columnDef: 'name', header: 'Vendor Name', type: 'text' },
    { columnDef: 'id', header: 'Vendor ID', type: 'text' },
    { columnDef: 'edit', header: 'Edit', type: 'edit' },
  ];
  public get Permission() {
    return Permission;
  }
  constructor(
    private lookupService: LookupService,
    public modal: MatDialog,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.hasFullAccess =
      this.authService.isSuperAdmin() || this.authService.isCustomerService();
    this.loadTableData();
  }

  getVendorList(): void {
    this.lookupService
      .getVendors()
      .pipe(take(1))
      .subscribe((res: IVendor[]) => {
        if (res) {
          this.initializeTableData(res);
        }
      });
  }

  initializeTableData(res: IVendor[]): void {
    this.tableData = res;
    this.dataSource = new MatTableDataSource(res);
  }

  loadTableData() {
    this.getVendorList();
  }

  addVendor(): void {
    this.router.navigateByUrl('/config/integrations/add-vendor');
  }

  editVendor(event: any): void {
    const { data } = event;
    this.router.navigateByUrl(`/config/integrations/edit-vendor/${data.id}`);
  }
}
