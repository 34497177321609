<div class="w-full">
  <div class="hidden md:block md:static ">
    <div *ngIf="classId !== null"><a class="text-sm cursor-pointer underline md:absolute md:right-32 md:mt-6" (click)="goBack()">
      <i class="uil uil-angle-left-b text-base"></i>Back to View Class</a></div>
    <drdp-btn class="hidden md:block md:absolute md:right-8 md:mt-4" (click)="editChild()"
      *ngIf="canEdit && activeTab === ViewChildTabs.Demographics">
      <i *ngIf="!isEdit" class="uil uil-pen cursor-pointer"></i>
      {{ buttonText }}
    </drdp-btn>
  </div>
  <div class="tabs-container mt-2 md:mt-0">
    <ul
      class="tabs flex overflow-x-auto list-none flex-nowrap md:flex-wrap md:pt-3 flex-row text-md font-medium text-gray-700">
      <li class="flex flex-col justify-end md:mr-2 last:mr-0 text-center rounded-t-lg cursor-pointer">
        <a class="px-2 py-2 md:px-5 md:py-3 rounded-t-lg leading-normal cursor-pointer"
          (click)="toggleTabs(ViewChildTabs.Demographics)" [ngClass]="{
                        'text-white': activeTab !== ViewChildTabs.Demographics,
                        'text-white bg-drdpblue-300': activeTab === ViewChildTabs.Demographics
                    }">
          Demographics
        </a>
      </li>
      <li class="flex flex-col justify-end md:mr-2 last:mr-0 text-center rounded-t-lg cursor-pointer">
        <a class="p-2 md:px-5 md:py-3 rounded-t-lg leading-normal cursor-pointer"
          (click)="toggleTabs(ViewChildTabs.ClassEnrollment)" [ngClass]="{
                        'text-white': activeTab !== ViewChildTabs.ClassEnrollment,
                        'text-white bg-drdpblue-300': activeTab === ViewChildTabs.ClassEnrollment
                    }">
          Class Enrollment
        </a>
      </li>
      <li class="flex flex-col justify-end  md:mr-2 last:mr-0 text-center rounded-t-lg cursor-pointer">
        <a class="p-2 md:px-5 md:py-3 rounded-t-lg leading-normal cursor-pointer"
          (click)="toggleTabs(ViewChildTabs.CompletedAssessments)" [ngClass]="{
                        'text-white': activeTab !== ViewChildTabs.CompletedAssessments,
                        'text-white bg-drdpblue-300': activeTab === ViewChildTabs.CompletedAssessments
                    }">
          Completed Assessments
        </a>
      </li>
      <li class="flex flex-col justify-end  md:mr-2 last:mr-0 text-center rounded-t-lg cursor-pointer"
        *ngIf="canViewSharedChild">
        <a class="p-2 md:px-5 md:py-3 rounded-t-lg leading-normal cursor-pointer"
          (click)="toggleTabs(ViewChildTabs.ShareChild)" [ngClass]="{
                        'text-white': activeTab !== ViewChildTabs.ShareChild,
                        'text-white bg-drdpblue-300': activeTab === ViewChildTabs.ShareChild
                    }">
          Share Child
        </a>
      </li>
    </ul>

  </div>
  <div
    class="relative flex flex-col rounded-tr-none md:rounded-tr-3xl min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-b-[30px] min-h-[44rem]">
    <div class="flex-auto">
      <div class="tab-content tab-space">
        <div class="pb-6">
          <div *ngIf="activeTab == ViewChildTabs.Demographics && !isEdit" class="static block md:hidden">
            <div *ngIf="classId !== null"><a class="text-sm cursor-pointer underline absolute right-28" (click)="goBack()">
              <i class="uil uil-angle-left-b text-base"></i>Back to View Class</a></div>
            <drdp-btn class=" absolute right-5" (click)="editChild()"
              *ngIf="canEdit && activeTab === ViewChildTabs.Demographics">
              <i *ngIf="!isEdit" class="uil uil-pen cursor-pointer"></i>
              {{ buttonText }}
            </drdp-btn>


          </div>
          <div *ngIf="activeTab == ViewChildTabs.Demographics && !isEdit">
            <drdp-add-view-edit-child [componentType]="ComponentTypeTable.View" [childData]="childData">
            </drdp-add-view-edit-child>
          </div>
          <div *ngIf="activeTab == ViewChildTabs.Demographics && isEdit">
            <drdp-add-view-edit-child [componentType]="ComponentTypeTable.Edit" [childData]="childData"
              (returnToView)="backToView($event)">
            </drdp-add-view-edit-child>
          </div>
          <div *ngIf="activeTab == ViewChildTabs.ClassEnrollment">
            <drdp-view-class-enrollments></drdp-view-class-enrollments>
          </div>
          <div *ngIf="activeTab == ViewChildTabs.CompletedAssessments">
            <drdp-view-child-assessments></drdp-view-child-assessments>
          </div>
          <div *ngIf="activeTab == ViewChildTabs.ShareChild">
            <drdp-view-child-shared-providers></drdp-view-child-shared-providers>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>