import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@core/services/state.service';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { ToastService } from '@core/services/toast.service';
import { IState } from '@core/interfaces/istate';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ISearchInput } from '@core/interfaces/isearch-input';
import { Router } from '@angular/router';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss'],
})
export class StatesComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizeOptions = [5, 10, 25];
  pageSize = 10;
  totalData = 0;
  tableData?: any;
  searchTerm?: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  canEditState = false;
  tableColumns = [
    { columnDef: 'stateName', header: 'State Name', type: 'text' },
    { columnDef: 'stateCode', header: 'State Code', type: 'text' },
  ];

  searchInput: ISearchInput = {
    searchName: true,
  };

  constructor(
    private stateService: StateService,
    public toast: ToastService,
    private route: Router,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.canEditState = this.permissionService.checkPermission(
      Permission.EditStates
    );
    if (this.canEditState) {
      this.tableColumns.push({
        columnDef: 'isSelectable',
        header: 'Active',
        type: 'toggleSwitch',
      });
      this.tableColumns.push({
        columnDef: 'edit',
        header: 'Edit',
        type: 'edit',
      });
    } else {
      this.tableColumns.push({
        columnDef: 'isActive',
        header: 'Active',
        type: 'text',
      });
    }

    this.getStates();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  getStates(): void {
    this.stateService.getAllStates().subscribe((res: any) => {
      this.tableData = res;
      this.initializeTableData(res);
    });
  }

  initializeTableData(res: any): void {
    this.dataSource.data = res;
    this.totalData = res.length;
  }

  searchTable(search: any) {
    const originalStateList = this.tableData;
    if (search.SearchName) {
      const filter = originalStateList.filter((item: IState) => {
        return item.stateName
          .toLowerCase()
          .includes(search.SearchName.toLowerCase());
      });
      this.initializeTableData(filter);
    } else {
      this.initializeTableData(originalStateList);
    }
  }

  changeActiveStatus($event: any) {
    const stateInfo: IState = {
      id: $event.data.id,
      stateCode: $event.data.stateCode,
      stateName: $event.data.stateName,
      isSelectable: ($event.data.isSelectable = !$event.data.isSelectable),
    };

    this.stateService.editStates(stateInfo).subscribe();
  }

  editConfiguration(state: any): void {
    let { data } = state;
    this.route.navigateByUrl(`config/states-configurations/${data.id}`, {
      state: data,
    });
  }
}
