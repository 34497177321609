<div class="sm:container sm:mx-auto">
  <div class="flex flex-row-reverse">
    <drdp-btn
      *ngIf="activeTab === FeatureTable.Feature"
      (click)="featureAction()"
    >
      <i class="uil uil-plus"></i>
      Add New Feature
    </drdp-btn>
    <drdp-btn
      *ngIf="activeTab === FeatureTable.FeatureHierarchy"
      (click)="addSuperFeature()"
    >
      <i class="uil uil-plus"></i>
      Create New Super Feature
    </drdp-btn>
  </div>
  <ul
    class="flex list-none flex-wrap pb-2 flex-row text-md font-medium text-gray-700"
  >
    <li class="-mb-px mr-2 last:mr-0 text-center rounded-t-lg cursor-pointer">
      <a
        class="px-5 py-3 rounded-t-lg leading-normal cursor-pointer"
        (click)="toggleTabs(FeatureTable.Feature)"
        [ngClass]="{
          'text-white': activeTab !== FeatureTable.Feature,
          'text-white bg-drdpblue-300': activeTab === FeatureTable.Feature
        }"
      >
        Features
      </a>
    </li>
    <li class="-mb-px mr-2 last:mr-0 text-center rounded-t-lg cursor-pointer">
      <a
        class="px-5 py-3 rounded-t-lg leading-normal cursor-pointer"
        (click)="toggleTabs(FeatureTable.FeatureHierarchy)"
        [ngClass]="{
          'text-white': activeTab !== FeatureTable.FeatureHierarchy,
          'text-white bg-drdpblue-300':
            activeTab === FeatureTable.FeatureHierarchy
        }"
      >
        Feature Hierarchy
      </a>
    </li>
  </ul>
  <drdp-container>
    <div class="w-full">
      <drdp-add-edit-table
        [dataSource]="dataSource"
        [tableData]="
          activeTab == FeatureTable.Feature
            ? featureTableData
            : supFeatureTableData
        "
        [columns]="
          activeTab == FeatureTable.Feature
            ? featureTableColumns
            : subFeatureTableColumns
        "
        (editActionItem)="
          activeTab == FeatureTable.Feature
            ? featureAction($event)
            : editSupFeatures($event)
        "
        (deleteActionItem)="
          activeTab == FeatureTable.Feature
            ? deleteFeature($event)
            : deleteSupFeature($event)
        "
      >
      </drdp-add-edit-table>
    </div>
  </drdp-container>
</div>
