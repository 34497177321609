<drdp-container color="lightBlue" additionClasses="min-h-[6rem] w-full">
  <form
    class="grid grid-cols-1 md:grid-cols-5 gap-3"
    [formGroup]="searchForm"
    (ngSubmit)="searchEmitter()"
  >
    <div *ngIf="searchInput?.searchName" class="col-span-1">
      <label
        [ngClass]="{extraMargOnLabel:
          searchInput?.searchYear || searchInput?.searchSite
            , required: requiredFields?.name}
        "
        for="searchBar"
        >Search by:</label
      >
      <div class="basis-1/2 border-b border-black">
        <input
          matInput
          [placeholder]="textInputPlaceholder"
          formControlName="SearchName"
          [required]="requiredFields?.name || false"
          class="w-full bg-drdpblue-200 border-b-2 border-black focus:outline-none focus:border-drdpblue-300"
        />
      </div>
    </div>

    <div *ngIf="searchInput?.searchState" class="col-span-1">
      <drdp-select-state
        #stateSelect
        (state)="onDropDownSelect($event, select.States)"
        [clear]="clearDropdowns"
        [initialStateId]="StateId.value"
        [required]="requiredFields?.state || false"
      >
      </drdp-select-state>
    </div>

    <div *ngIf="searchInput?.searchAgency" class="col-span-1">
      <drdp-select-agency
        #agencySelect
        (agency)="onDropDownSelect($event, select.Agencies)"
        [stateId]="StateId.value"
        [initialAgencyId]="AgencyId.value"
        [clear]="clearDropdowns"
        [required]="requiredFields?.agency || false"
      >
      </drdp-select-agency>
    </div>

    <div *ngIf="searchInput?.searchSite" class="col-span-1">
      <drdp-select-site
        #siteSelect
        (site)="onDropDownSelect($event, select.Sites)"
        [agencyId]="AgencyId.value"
        [clear]="clearDropdowns"
        [multiSelect]="searchMultiSite"
        [multiSelectInitVals]="searchMultiSite"
        [reloadSites]="reload"
        [required]="requiredFields?.site || false"
      >
      </drdp-select-site>
    </div>

    <div *ngIf="searchInput?.searchSiteType" class="col-span-1">
      <drdp-select-type
        #typeSelect
        (type)="onDropDownSelect($event, select.SiteType)"
        [initialTypeId]="Type.value"
        [clear]="clearDropdowns"
        [required]="requiredFields?.siteType || false"
      ></drdp-select-type>
    </div>

    <div *ngIf="searchInput?.searchYear" class="col-span-1">
      <drdp-select-school-year
        #schoolYearSelect
        (schoolYear)="onDropDownSelect($event, select.SchoolYear)"
        [initialYearId]="SchoolYearId.value"
        [clear]="clearDropdowns"
        [required]="requiredFields?.year || false"
      ></drdp-select-school-year>
    </div>

    <div *ngIf="searchInput?.searchRatingPeriod" class="col-span-1">
      <drdp-select-rating-period
        #ratingPeriodSelect
        (ratingPeriod)="onDropDownSelect($event, select.RatingPeriods)"
        [initialRatingPeriodId]="RatingPeriodId.value"
        [agencyId]="AgencyId.value"
        [schoolYear]="SchoolYearId.value"
        [clear]="clearDropdowns"
        [required]="requiredFields?.period || false"
      ></drdp-select-rating-period>
    </div>

    <div *ngIf="searchInput?.searchTeacher" class="col-span-1">
      <drdp-select-teacher
        #teacherSelect
        (teacher)="onDropDownSelect($event, select.Teacher)"
        [initialTeacherId]="TeacherId.value"
        [agencyId]="AgencyId.value"
        [clear]="clearDropdowns"
        [allTeachers]="allTeachers"
        [teacherType]="teacherType"
        [required]="requiredFields?.teacher || false"
      ></drdp-select-teacher>
    </div>

    <div *ngIf="searchInput?.searchTeacherOfRecord" class="col-span-1">
      <drdp-select-teacher
        #teacherOfRecordSelect
        (teacherOfRecord)="onDropDownSelect($event, select.TeacherOfRecord)"
        [initialTeacherId]="TeacherOfRecordId.value"
        [agencyId]="AgencyId.value"
        [clear]="clearDropdowns"
        [allTeachers]="false" 
        [teacherType]="role.TeacherOfRecord"
        [required]="false"
      ></drdp-select-teacher>
    </div>

    <div
      class="col-span-1 md:col-start-5 self-end justify-center md:justify-self-end"
    >
      <span class="flex justify-center">
        <drdp-btn additionalClasses="mr-4">Search</drdp-btn>
        <drdp-btn color="outline" (click)="onClear()" type="button"
          >Clear</drdp-btn
        >
      </span>
    </div>
  </form>
</drdp-container>
