<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-full">
      <h1 class="pb-2 text-3xl">Manage News</h1>
    </div>
  </div>

  <drdp-container>
    <div *ngIf="newsItems?.length == 0">
      No news item to manage.
    </div>
    <div class="item py-3 px-2 flex justify-between" *ngFor="let news of newsItems">
      <div class="flex">
        <drdp-pill class="order-2 sm:order-1 justify-self-end pr-0 sm:pr-3" *ngIf="news.newsItemType.newsItemTypeName"
          [pillClass]="news.newsItemType.newsItemTypeName">
          {{ news.newsItemType.newsItemTypeName }}
        </drdp-pill>
        <p class="font-semibold self-start md:justify-end order-2 sm:order-2 w-24 text-left">
          {{ news.newsItemDate | date: 'MM/dd/yyyy'}}
        </p>
        <p class="mr-auto order-1 sm:order-3 w-9/12 sm:w-max cursor-pointer font-bold text-drdpblue-400 hover:underline"
          (click)='toggleModal(news)'>
          {{ news.newsItemTitle }}
        </p>
      </div>
      <div (click)="deleteNewsItem(news)" class="cursor-pointer" *ngIf="canDelete">
        <i class="uil uil-trash-alt text-xl text-drdpred-200"></i>
      </div>
    </div>
  </drdp-container>
</div>