<drdp-container additionClasses="mb-4">
  <div class="w-full">
    <h1 class="text-xl font-semibold my-2">Child Ratings</h1>
    <drdp-container color="lightBlue" additionClasses="p-6">
      <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-2 my-auto" [formGroup]="childRatingsSearch"
        (ngSubmit)="handleDownload()">
        <div class="lg:col-span-5 md:col-span-2 col-span-1">
          <label>
            <mat-checkbox color="primary" (change)="handleGlobal()">
            </mat-checkbox>
            Search All Agencies
          </label>
        </div>

        <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleDropdown($event, selectType.States)"
          [clear]="clearDropdowns" [required]="true">
        </drdp-select-state>

        <drdp-select-agency #agencySelect [hidden]="allAgencies" [stateId]="stateId.value" [initialAgencyId]="agencyIds.value"
          [required]="true" [clear]="clearDropdowns" (agency)="handleDropdown($event, selectType.Agencies)">
        </drdp-select-agency>

        <drdp-select-school-year #schoolYearSelect (schoolYear)="handleDropdown($event, selectType.SchoolYear)"
          [clear]="clearDropdowns" [startingSchoolYear]="startingSchoolYear" [required]="true">
        </drdp-select-school-year>

        <drdp-select-global-rating (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)"
          [clear]="clearDropdowns" [schoolYearId]="schoolYearId.value">
        </drdp-select-global-rating>

        <div [ngClass]="btnClass" class="flex items-end justify-end  md:col-span-2">
          <div class=" mb-0 md:mb-2 lg:mb-0">
            <drdp-btn type="submit" class="lg:mr-2"
              [disabled]="!childRatingsSearch.valid">
              Download Child Ratings
            </drdp-btn>
          </div>
        </div>
      </form>
    </drdp-container>
  </div>
</drdp-container>
