import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { LookupService } from '@core/services/lookup.service';
import { ToastService } from '@core/services/toast.service';
import { AuthService } from '@core/services/auth.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { UserService } from '@core/services/user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'drdp-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss'],
})
export class EditUserProfileComponent implements OnInit {
  editUserForm: FormGroup | any;
  submitAttempt = false;
  private subscriptions = new Subscription();

  @ViewChild(AddEditComponent) table?: AddEditComponent;
  dropDownForm: FormGroup | any;
  user: any;
  userInfo = {} as ILoginResponse;

  constructor(
    public lookup: LookupService,
    private authService: AuthService,
    private userService: UserService,
    private updateUserService: UserService,
    private toast: ToastService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  get emailName() {
    return this.editUserForm.get('emailName');
  }
  get firstName() {
    return this.editUserForm.get('firstName');
  }
  get lastName() {
    return this.editUserForm.get('lastName');
  }
  get middleName() {
    return this.editUserForm.get('middleName');
  }
  get jobTitle() {
    return this.editUserForm.get('jobTitle');
  }
  get suffix() {
    return this.editUserForm.get('suffix');
  }

  ngOnInit(): void {
    this.initializeForms();
    this.getUserData();
  }

  initializeForms() {
    this.editUserForm = this.fb.group({
      emailName: [
        '',
        [Validators.email, Validators.required, Validators.maxLength(100)],
      ],
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.maxLength(50)]],
      middleName: ['', [Validators.maxLength(1)]],
      jobTitle: [''],
      suffix: [''],
    });
  }

  invalid(control: AbstractControl) {
    if (
      control === undefined ||
      control == null ||
      typeof control === 'undefined'
    ) {
      return false;
    } else {
      return (
        (!control.valid && control.touched) ||
        (!control.valid && control.untouched && this.submitAttempt)
      );
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getUserData() {
    this.subscriptions.add(
      this.userService
        .getUserById(this.authService.getCurrentUser().userId)
        .subscribe((res) => {
          this.user = res;
          this.editUserForm.controls['firstName'].setValue(this.user.firstName);
          this.editUserForm.controls['emailName'].setValue(this.user.emailName);
          this.editUserForm.controls['middleName'].setValue(
            this.user.middleName
          );
          this.editUserForm.controls['lastName'].setValue(this.user.lastName);
          this.editUserForm.controls['jobTitle'].setValue(this.user.jobTitle);
          this.editUserForm.controls['suffix'].setValue(this.user.suffix);
        })
    );
  }

  handleSuffix(event: any) {
    this.suffix.setValue(event?.name);
  }

  onSubmit() {
    if (this.editUserForm.invalid) {
      this.toast.error('Form not complete');
      return;
    }
  
    this.submitAttempt = true;
  
    const updateUserInfo = {
      UserId: this.authService.getCurrentUser().userId,
      FirstName: this.editUserForm.get('firstName').value,
      EmailName: this.editUserForm.get('emailName').value,
      LastName: this.editUserForm.get('lastName').value,
      MiddleName: this.editUserForm.get('middleName').value,
      JobTitle: this.editUserForm.get('jobTitle').value,
      Suffix: this.editUserForm.get('suffix').value,
    };
  
    this.subscriptions.add(
      this.updateUserService.updateUserDemographics(updateUserInfo)
        .subscribe((success: boolean) => {
          if (success) {
            this.toast.success('User profile updated successfully.');
            this.router.navigateByUrl('/user-profile');
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
