import { AfterViewInit, Component, Inject, inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take, tap } from 'rxjs';
import { IAllStudentsByPermissionRequest, IViewClassStudentsRequest } from '@core/interfaces/iclasses';
import { ITableColumn } from '@core/interfaces/itable';
import { TableColumnType } from '@core/enums/table';
import { setIconDisplayObject } from '@core/services/helper.service';
import { ClassService } from '@core/services/class.service';
import { IViewStudentByClassModalData } from '@views/user/reports';
import { AgencyService } from '@core/services/agency.service';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { IAgency } from '@core/interfaces/iagency';

interface ViewStudentsByClassReportData {
  isSelected: boolean;
  firstName: string;
  lastName: string;
  class: string;
  ratingPeriod: string;
  classEnrollmentDate: Date;
  classExitDate?: Date;
  daysEnrolled: number;
  isLocked: boolean;
}

@Component({
  selector: 'drdp-view-student-by-class-modal',
  templateUrl: './view-student-by-class-modal.component.html',
  styleUrls: ['./view-student-by-class-modal.component.scss']
})
export class ViewStudentByClassModalComponent implements OnInit, AfterViewInit {
  private selectedStudents: number[] = [];
  agencyIds?: number[];
  siteId?: number;
  classId?: number;
  globalRatingPeriodId?: number;
  private readonly ageGradeInstrumentId: number;
  private readonly ratingPeriod: string;
  private readonly onlyLocked: boolean;
  private readonly onlyActive: boolean;

  canSelectAll: boolean;
  classroomName?: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData!: ViewStudentsByClassReportData[];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalData: number = 0;
  pageSize: number = 10;
  pageIndex: number = 1;
  isSelectAll = false;
  selectAllPagesSet: Set<number> = new Set<number>();
  tableColumns: ITableColumn[] = [
    {
      columnDef: 'isSelected',
      header: 'Select',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox'
    },
    {
      columnDef: 'firstName',
      header: 'First Name',
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'lastName',
      header: 'Last Name',
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'ageGradeInstrumentName',
      header: 'Age Group',
      type: TableColumnType.text,
      isSortable: false
    },
    {
      columnDef: 'class',
      header: 'Class',
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'ratingPeriod',
      header: 'Rating Period',
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'classEnrollmentDate',
      header: 'Classroom Start Date',
      type: TableColumnType.date,
      isSortable: true
    },
    {
      columnDef: 'classExitDate',
      header: 'Classroom End Date',
      type: TableColumnType.date,
      isSortable: true
    },
    {
      columnDef: 'daysEnrolled',
      header: 'Days Enrolled',
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'isLocked',
      header: 'Is Locked',
      type: TableColumnType.boolIcon,
      truthyClass: 'text-drdpGreen-300',
      truthyIconUrl: 'assets/images/misc/lock-alt.svg',
      falsyClass: 'text-drdpgrey-200',
      falsyIconUrl: 'assets/images/misc/padlock.svg',
      isSortable: true
    }
  ];

  classService: ClassService = inject(ClassService);
  agencyService = inject(AgencyService);
  authService = inject(AuthService);
  permissionService = inject(PermissionService);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  hasAccessToAssignedChildren = this.permissionService.checkPermission(
    Permission.AccessToAssignedChildrenReporting
  );

  constructor(public dialogRef: MatDialogRef<ViewStudentByClassModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ageGradeInstrumentId = data.ageGradeInstrumentId;
    this.ratingPeriod = data.ratingPeriod;
    this.classroomName = data.classroomName ? `in ${data.classroomName}`  : '';
    this.onlyLocked = data.onlyLocked;
    this.onlyActive = data.onlyActive;
    this.canSelectAll = data.canSelectAll ?? false;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.preparePayload();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  isStudentSelected(): boolean {
    return this.selectedStudents.length > 0
  }

  onToggleSwitchActionItem(event: any): void {
    const studentIndex = this.selectedStudents.findIndex(cs => cs === event.data.id);
    if (studentIndex === -1) {
      this.selectedStudents.push(event.data.id);
    } else {
      this.selectedStudents.splice(studentIndex, 1);
    }
  }

  paginate(event: PageEvent): void {
    this.getClassStudents(event.pageSize, event.pageIndex + 1);
  }

  selectStudents(): void {
    const selectedStudentIds = this.selectedStudents.map(cs => cs);
    this.clearSelectedStudents();
    this.dialogRef.close({ selectedStudentIds: selectedStudentIds });
  }

  selectAllStudents(): void {
    if (this.selectAllPagesSet.has(this.paginator.pageIndex)) {
      this.isSelectAll = false;
      this.selectAllPagesSet.delete(this.paginator.pageIndex);
    } else {
      this.isSelectAll = true;
    }

    if (this.tableData) {
      this.tableData.forEach((student: any) => {
        student.isSelected = this.isSelectAll;
      });

      if (this.isSelectAll) {
        this.tableData.forEach((student: any) => {
          const index = this.selectedStudents.findIndex(
            (s: any) => s === student.id
          );
          if (index === -1) {
            this.selectedStudents.push(student.id);
          }
          this.selectAllPagesSet.add(this.paginator.pageIndex);
        });
      } else {
        this.selectedStudents = this.selectedStudents.filter((student: any) =>
          !this.tableData.some((tb: any) => tb.id == student)
        );
      }
    }
  }

  getLinkedAgencyIds(): any {
    const user = this.authService.getCurrentUser();
    this.agencyService.getLinkedAgencies(user.userId).pipe(take(1)).subscribe((res: IAgency[]) => {
      if (res) {
        this.agencyIds =  res?.map((s: any) => s.id);
        this.getClassStudents(this.pageSize, this.pageIndex)
      }
    })
  }

  preparePayload() {
    this.classId = this.data.classId;
    this.siteId = this.data.siteId;
    this.globalRatingPeriodId = this.data.globalRatingPeriodId;
    if (this.data.agencyIds == null) {
      this.getLinkedAgencyIds();
      return;
    }
    if (this.data.agencyIds) this.agencyIds = [this.data.agencyIds];
    this.getClassStudents(this.pageSize, this.pageIndex);
  }

  private clearSelectedStudents(): void {
    this.selectedStudents = [];
  };

  private getClassStudents(pageSize: number, pageIndex: number): void {
    let payload: IAllStudentsByPermissionRequest;
    payload = {
      ageGradeInstrumentId: this.ageGradeInstrumentId,
      classId: this.classId, 
      onlyActive: this.onlyActive,
      onlyLocked: this.onlyLocked,
      pageIndex: pageIndex,
      itemsPerPage: pageSize,
      agencyIds: this.agencyIds,
      globalRatingPeriodId: this.globalRatingPeriodId,
      siteId: this.siteId
    };

    this.classService.getAllClassStudentsByPermissions(payload)
    .pipe(
      take(1),
      tap((res: any) => {
        if (res) {
          res.items = res.items.map((data: any) => {
            const studentIndex = this.selectedStudents.findIndex(cs => cs === data.id);
            const daysEnrolledTicks = !!data.classExitDate
              ? new Date(data.classExitDate).getTime() - new Date(data.classEnrollmentDate).getTime()
              : (Date.now() - new Date(data.classEnrollmentDate).getTime());
            return {
              ...data,
              isSelected: studentIndex !== -1,
              class: data.classroomName,
              ratingPeriod: this.ratingPeriod,
              daysEnrolled: Math.round(daysEnrolledTicks / (1000 * 60 * 60 * 24)),
              displayIcons: setIconDisplayObject(this.tableColumns, data),
            }
          })
          this.dataSource = new MatTableDataSource(res.items);
          this.tableData = res.items;
          this.totalData = res.totalData;
        }
      })
    )
    .subscribe();
  }
}
