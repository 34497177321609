import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ClassService } from '@core/services/class.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditClassesComponent } from './add-edit-classes/add-edit-classes.component';
import { IModalEmitData } from '@core/interfaces/imodal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IClass, IClassSearchRequest, ITeacherAssignment } from '@core/interfaces/iclasses'
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { AuthService } from '@core/services/auth.service';
import { Subscription, take } from 'rxjs';
import { SelectType } from '@core/enums/select';
import { IRatingPeriod, ISchoolYear } from '@core/interfaces/iratingperiod';
import { IUser } from '@core/interfaces/iuser';
import { Permission } from '@core/enums/permissions';
import { PermissionService } from '@core/services/permission.service';
import { MatPaginator } from '@angular/material/paginator';
import { Modal } from '@core/enums/modal';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { IDropdownSearchRequestDto } from '@core/interfaces/idropdown';
import { ISearchInput } from '@core/interfaces/isearch-input';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { ToastService } from '@core/services/toast.service';
import { IRequiredFields } from '@core/interfaces/irequired-fields';
import { LookupService } from '@core/services/lookup.service';


@Component({
  selector: 'drdp-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss'],
})

export class ClassesComponent implements OnInit, OnDestroy {
  constructor(
    private classService: ClassService,
    public modal: MatDialog,
    private fb: FormBuilder,
    private authService: AuthService,
    public PermissionService: PermissionService,
    private toast: ToastService,
    private classEnrollmentService: StudentClassEnrollmentService,
    private lookupService: LookupService) {}

  public get Permission() { return Permission }
  private subscriptions = new Subscription();
  @ViewChild(AddEditClassesComponent) table?: AddEditClassesComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('schoolYearSelect') schoolYearSelect: FilterSelectComponent | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect: FilterSelectComponent | undefined;
  @ViewChild('teacherSelect') teacherSelect: FilterSelectComponent | undefined;
  @ViewChild('teacherOfRecordSelect') teacherOfRecordSelect: FilterSelectComponent | undefined;
  user = this.authService.getCurrentUser();
  classSearch: FormGroup | any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  receivedPayload?: any;
  payload: IClassSearchRequest = {
    itemsPerPage: 10
  };
  dropdownPayload: IDropdownSearchRequestDto = {
    agencyId: this.user.agencyId
  }
  isAdmin = this.PermissionService.checkPermission(Permission.DeleteClassrooms);
  canEdit = this.PermissionService.checkPermission(Permission.EditClassrooms);
  canDelete = this.PermissionService.checkPermission(Permission.DeleteClassrooms);
  isSuperUser = this.authService.isSuperUser();

  schoolYearList: ISchoolYear[] | undefined = [];
  ratingPeriodList: IRatingPeriod[] | undefined = [];
  classTeacherList: IUser[] | any = [];

  selectSchoolYear = SelectType.SchoolYear;
  selectRatingPeriod = SelectType.RatingPeriods;
  selectTeacher = SelectType.Teacher;
  selTeacherOfRecord = SelectType.TeacherOfRecord;

  initialRatingPeriodId: any | undefined;
  initialSchoolYearId: any | undefined;
  initialTeacherId: any | undefined;
  currentSchoolYearId: number | undefined;

  searchInput: ISearchInput = {
    searchName: true,
    searchAgency: true,
    searchState: true,
    searchYear: true,
    searchRatingPeriod: true,
    searchTeacher: true,
    searchTeacherOfRecord: true
  }

  requiredFields: IRequiredFields = {
    name: false,
    teacher: false,
    siteType: false,
    period: false,
    site: false,
    state: true,
    agency: true,
    year: true,
    teacherOfRecord: false
  }

  get search() { return this.classSearch.get('search') }

  tableColumns = [
    { columnDef: 'name', header: 'Classroom', type: 'text' },
    { columnDef: 'siteName', header: 'Site', type: 'text' },
    { columnDef: 'ratingPeriodName', header: 'Rating Period', type: 'text' },
    { columnDef: 'teacherOfRecordName', header: 'Teacher of Record', type: 'text' },
    { columnDef: 'teachers', header: 'Teacher Names', type: 'bulletList' },
  ];

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  ngOnInit(): void {
    if (this.canEdit) {
      this.tableColumns.push({ columnDef: 'edit', header: 'Edit', type: 'edit' })
    }
    if (this.canDelete) {
      this.tableColumns.push({ columnDef: 'delete', header: 'Delete', type: 'delete' })
    }
    this.initializeForms();
    this.lookupService.getCurrentSchoolYear().pipe(take(1)).subscribe((res: any) => {
      if (res) this.currentSchoolYearId = res.id;
    });
  }

  initializeForms(): void {
    this.classSearch  = this.fb.group({
      search: ['', Validators.required],
    })
  }

  checkStudentEnrollment(classId: number) {
     return this.classEnrollmentService.checkStudentEnrollment(classId).subscribe();
  }

  searchTable(event: any): void {
    const payload = this.createSearchPayload(true, event);
    this.classService.search(payload).pipe(take(1)).subscribe((res: any) => {
      if (res) {
        this.initializeTableData(res);
      }
    })
  }

  openModal(editData?: IModalEmitData): void {
    if (editData?.data?.schoolYearId && editData?.data?.schoolYearId != this.currentSchoolYearId) {
      this.toast.error('Classroom Assignment cannot be edited.  School Year has ended.');
      return;
    }
    const dialogRef = this.modal.open(AddEditClassesComponent, {
      data: editData ? editData : undefined });

    dialogRef.afterClosed().subscribe(() => {
      this.initializeTable();
    });
  }


  deleteModal(event: IModalEmitData): void {
    const selectedClassId = event.data.id;
    this.classEnrollmentService.checkStudentEnrollment(selectedClassId).subscribe((res: boolean) => {
      if (res) {
        event.data.modalInfo = {
          title: `${event.data.name} cannot be deleted`,
          message: `There is one or more children currently enrolled in this classroom.  The classroom can not be deleted.  For assistance, please contact WestEd at 1-800-770-6339 or email DRDPOnline@wested.org.`,
          name: event.data.name,
          primaryBtnClass: 'blue',
          primaryBtnText: Modal.Confirm
        }
        const modalRef = this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
        modalRef.afterClosed().subscribe();
      } else {
        event.data.modalInfo = {
          title: `Delete ${event.data.name}`,
          message: `Are you sure you want to want to delete <span class="font-bold">${event.data.name}</span>?`,
          name: event.data.name,
          primaryBtnClass: 'red',
          primaryBtnText: Modal.Delete,
          showCancel: true
        }
        const modalRef = this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
        modalRef.afterClosed().subscribe(res => {
          if (res) {
            this.removeClass(selectedClassId, event.data.name);
          }
        });
      }
    })
  }

  removeClass(classId: any, classroomName: string): void {
    this.subscriptions.add(this.classService.deleteAll(classId, `Deleted ${classroomName} Assignments and Terms`).subscribe((res: boolean) => {
      this.initializeTable();
    }))
  }

  initializeTable(): void {
    this.payload.stateId =  this.payload.stateId ?? this.user.stateId;
    this.payload.agencyId = this.payload.agencyId ?? this.user.agencyId;
    this.payload.itemsPerPage = this.payload.itemsPerPage ?? this.paginator.pageSize;
    this.subscriptions.add(this.classService.search(this.payload).subscribe((res: IClass) => {
      if (res) this.initializeTableData(res);
    }))
  }


  initializeTableData(res: any): void {
    const { items, totalData } = res.classes;
    this.getTeacherNames(items);
    this.dataSource = new MatTableDataSource(items);
    this.totalData = totalData;
    this.tableData = items;
  }

  onClear(): void {
    this.payload = {};
    this.paginator.pageSize = 10;
    this.clearTable();
    this.initializeForms();
  }

  clearTable(): void {
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
  }

  getTeacherNames(res: any): void {
    res.map((classroom: any) => {
      classroom.teachers = classroom.teachers?.split('|') ?? [];
      classroom.teacherIds = classroom.teacherIds?.split('|').map(Number) ?? [];
    })
    return res;
  }

  createSearchPayload(resetPage: boolean, event: any): IClassSearchRequest {
    this.payload.search = event.SearchName ?? "";
    this.payload.agencyId = this.isSuperUser ? event.AgencyId ?? "" : event.AgencyId ?? this.user.agencyId;
    this.payload.schoolYearId = event.SchoolYearId ?? "";
    this.payload.ratingPeriodId = event.RatingPeriodId ?? "";
    this.payload.teacherId = event.TeacherId ?? "";
    this.payload.teacherOfRecordId = event.TeacherOfRecordId ?? "";
    this.payload.stateId = this.isSuperUser ? event.StateId ?? "" : event.StateId ?? this.user.stateId;
    resetPage ? this.payload.pageIndex = 0 : this.payload.pageIndex = this.paginator.pageIndex + 1;
    resetPage ? this.payload.itemsPerPage = 10 : this.payload.itemsPerPage = this.paginator.pageSize;
    return this.payload;
  }

  paginateTable(resetPage: boolean): void {
    resetPage ? this.payload.pageIndex = 0 : this.payload.pageIndex = this.paginator.pageIndex + 1;
    resetPage ? this.payload.itemsPerPage = 10 : this.payload.itemsPerPage = this.paginator.pageSize;
    this.classService.search(this.payload).pipe(take(1)).subscribe((res: any) => {
      if (res) {
        if (resetPage) this.paginator.firstPage();
        this.initializeTableData(res);
        if (res.classes.totalData > 25) this.pageSizeOptions.push(res.classes.totalData);
      }
    })
  }

  ngOnDestroy():void {
    this.subscriptions.unsubscribe();
  }

}
