<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-full">
      <drdp-title
        [title]="
          'Viewing Students in ' +
          (classroomName ?? '(Classroom Name Not Found)')
        "
      ></drdp-title>
      <h2 class="pb-5">
        Rating Period: {{ ratingPeriodName ?? "(Rating Period Not Found)" }}
      </h2>
      <div class="flex justify-end py-3">
        <drdp-btn
          color="cancel"
          class="mr-3"
          mat-button
          type="button"
          (click)="onCancel()"
        >
          Cancel
        </drdp-btn>
        <drdp-btn mat-button type="submit" (click)="onSave()" cdkFocusInitial>
          Withdraw Batch
        </drdp-btn>
      </div>
    </div>
  </div>

  <drdp-container>
    <div class="w-full">
      <div class="text-blue-600 cursor-pointer" (click)="selectAll()">
        Select All
      </div>
      <drdp-add-edit-table
        (toggleSwitchActionItem)="handleSelectedChild($event)"
        [dataSource]="dataSource"
        [tableData]="tableData"
        [columns]="tableColumns"
      >
      </drdp-add-edit-table>
    </div>
  </drdp-container>
</div>

<mat-paginator
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="pageSize"
  [length]="totalData"
  [pageIndex]="pageIndex"
  (page)="paginate()"
>
</mat-paginator>
