<div class="p-5">
  <div>
    <drdp-title title="OSEP Exception Report"></drdp-title>
  </div>

  <drdp-container additionClasses="w-full mt-4">
    <drdp-container color="lightBlue">
      <form [formGroup]="searchForm">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-3">
          <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleState($event)" 
            [required]="true"></drdp-select-state>
          
          <drdp-select-school-year #schoolYearSelect (schoolYear)="handleSchoolYear($event)" 
            [required]="true" [startingSchoolYear]="startingSchoolYear"></drdp-select-school-year>
          
          <div class="lg:col-span-2 flex items-end justify-end mt-2">
            <drdp-btn type="button" (click)="handleDownload()" [disabled]="searchForm.invalid">
              Download Exception Report
            </drdp-btn>
          </div>
        </div>
      </form>
    </drdp-container>
  </drdp-container>
</div>
