import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { IRatingViewEnrollment } from '@views/user/configuration/rating-view-setup/interfaces/irating-view-enrollment';

@Injectable({
  providedIn: 'root',
})
export class AgeGradeTemplateEnrollmentService {
  constructor(private data: DataService) {}

  getBaseTemplateEnrollmentByAgency(
    agencyId: number
  ): Observable<IRatingViewEnrollment[]> {
    return this.data.get(`ageGradeTemplateEnrollment/base/${agencyId}`);
  }

  getCustomTemplateEnrollmentById(
    id: number
  ): Observable<IRatingViewEnrollment[]> {
    return this.data.get(`ageGradeTemplateEnrollment/custom/${id}`);
  }

  saveBaseTemplateEnrollment(request: any): Observable<any> {
    return this.data.post(
      `ageGradeTemplateEnrollment/base-templates`,
      request,
      'Ratings View Template successfully saved!'
    );
  }

  saveTemplateAssignments(request: any): Observable<any> {
    return this.data.post(`ageGradeTemplateEnrollment/assignments`, request);
  }
}
