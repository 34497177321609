<div class="md:p-5 sm:container sm:mx-auto">
  <div class="grid grid-cols-2">
    <div class="col-span-2 lg:col-span-1">
      <drdp-title title="Teacher Assignments and Rating Periods" additionalClasses="text-2xl"></drdp-title>
    </div>
    <div class="col-span-2 lg:col-span-1 justify-self-center lg:justify-self-end self-baseline mb-5 lg:mb-0">
      <drdp-btn (click)="openModal()"
                *drdpCheckPermission="Permission.CreateClassrooms">
                <i class="uil uil-plus"></i>
                Add Teacher Assignments and Rating Periods
      </drdp-btn>
    </div>
  </div>

  <drdp-container [additionClasses]="'min-h-[44rem]'">
    <div class="w-full">
      <drdp-search
        *ngIf="true"
        [searchInput]="searchInput"
        [requiredFields]="requiredFields"
        [allTeachers]="true"
        (clear)="onClear()"
        (search)="searchTable($event)">
      </drdp-search>
      <drdp-add-edit-table
          [dataSource]="dataSource"
          [tableData]="tableData"
          [columns]="tableColumns"
          (editActionItem)="openModal($event)"
          (deleteActionItem)="deleteModal($event)">
      </drdp-add-edit-table>
    </div>
  </drdp-container>
  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="totalData"
    [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
  </mat-paginator>
</div>
