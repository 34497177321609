import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectType } from '@core/enums/select';
import { ISearchInput } from '@core/interfaces/isearch-input';
import { LookupService } from '@core/services/lookup.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { AuthService } from '@core/services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ISites } from '@core/interfaces/isites';
import { SelectStateComponent } from '../dropdowns/select-state/select-state.component';
import { SelectAgencyComponent } from '../dropdowns/select-agency/select-agency.component';
import { SelectSiteComponent } from '../dropdowns/select-site/select-site.component';
import { SelectTypeComponent } from '../dropdowns/select-type/select-type.component';
import { SelectSchoolYearComponent } from '../dropdowns/select-school-year/select-school-year.component';
import { SelectRatingPeriodComponent } from '../dropdowns/select-rating-period/select-rating-period.component';
import { SelectTeacherComponent } from '../dropdowns/select-teacher/select-teacher.component';
import { ToastService } from '@core/services/toast.service';
import { IRequiredFields } from '@core/interfaces/irequired-fields';
import { Role } from '@core/enums/roles';

@Component({
  selector: 'drdp-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
  @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;
  @ViewChild('siteSelect') siteSelect: SelectSiteComponent | undefined;
  @ViewChild('typeSelect') typeSelect: SelectTypeComponent | undefined;
  @ViewChild('schoolYearSelect') schoolYearSelect:
    | SelectSchoolYearComponent
    | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | SelectRatingPeriodComponent
    | undefined;
  @ViewChild('teacherSelect') teacherSelect: SelectTeacherComponent | undefined;
  @ViewChild('teacherOfRecordSelect') teacherOfRecordSelect: SelectTeacherComponent | undefined;
  @Output() search = new EventEmitter();
  @Output() clear = new EventEmitter<any>();
  @Input() textInputPlaceholder: string = 'Name';
  @Input() searchInput?: ISearchInput;
  @Input() requiredFields?: IRequiredFields;
  @Input() allTeachers?: boolean;
  @Input() teacherType?: number | null;
  @Input() reload = false;

  searchForm: FormGroup | any;
  siteForm: FormGroup | any;
  ratingForm: FormGroup | any;

  clearDropdowns = false;
  currentSchoolYearId: number = 0;

  yearOptions$?: Observable<any>;
  siteTypeOptions$?: Observable<any>;
  chosenSites: number[] = [];
  searchMultiSite: boolean = false;

  user: any = this.authService.getCurrentUser();
  initStateId: number = 0;
  initAgencyId: number = 0;

  get SearchName() {
    return this.searchForm.get('SearchName');
  }
  get Type() {
    return this.searchForm.get('Type');
  }
  get SchoolYearId() {
    return this.searchForm.get('SchoolYearId');
  }
  get StateId() {
    return this.searchForm.get('StateId');
  }
  get AgencyId() {
    return this.searchForm.get('AgencyId');
  }
  get SiteIds() {
    return this.searchForm.get('SiteIds');
  }
  get RatingPeriodId() {
    return this.searchForm.get('RatingPeriodId');
  }
  get TeacherId() {
    return this.searchForm.get('TeacherId');
  }

  get TeacherOfRecordId() {
    return this.searchForm.get('TeacherOfRecordId');
  }

  public get select() {
    return SelectType;
  }

  get role() { return Role; }
  private subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    public lookup: LookupService,
    private authService: AuthService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.initStateId = this.user.stateId ?? null;
    this.initAgencyId = this.user.agencyId ?? null;
    this.searchMultiSite = Boolean(this.searchInput?.searchMultiSite);
    this.initializeForms();
  }

  initializeForms(): void {
    this.searchForm = this.fb.group({
      SearchName: ['', this.requiredFields?.name? Validators.required : null],
      Type: [0, this.requiredFields?.siteType ? Validators.required : null],
      SchoolYearId: [0, this.requiredFields?.year ? Validators.required : null],
      StateId: [null, this.requiredFields?.state ? Validators.required : null],
      AgencyId: [null, this.requiredFields?.agency ? Validators.required : null],
      SiteIds: [0, this.requiredFields?.site ? Validators.required : null],
      RatingPeriodId: [null, this.requiredFields?.period ? Validators.required : null],
      TeacherId: [null, this.requiredFields?.teacher ? Validators.required : null],
      TeacherOfRecordId: [null, this.requiredFields?.teacherOfRecord ? Validators.required : null],
    });

    if (this.initStateId && this.initAgencyId) {
      this.searchForm.patchValue({
        StateId: this.initStateId,
        AgencyId: this.initAgencyId,
      });
    }
  }

  searchEmitter(): void {
    if (this.searchForm.invalid) {
      this.toastService.error("Please enter all required fields.");
      return;
    }
    let payload = {
      SearchName: this.SearchName.value,
      Type: this.Type.value ? +this.Type.value : 0,
      SchoolYearId: this.SchoolYearId.value
        ? +this.SchoolYearId.value
        : this.currentSchoolYearId,
      StateId: this.StateId.value ? +this.StateId.value : '',
      AgencyId: this.AgencyId.value ? +this.AgencyId.value : '',
      SiteIds: this.SiteIds.value ? this.SiteIds.value : '',
      RatingPeriodId: this.RatingPeriodId.value
        ? this.RatingPeriodId.value
        : '',
      TeacherId: this.TeacherId.value ? this.TeacherId.value : '',
      TeacherOfRecordId: this.TeacherOfRecordId.value || ''
    };
    this.search.emit(payload);
  }

  onClear(): void {
    this.clearDropdowns = true;
    this.SearchName.setValue('');
    let payload = {
      SearchName: '',
      Type: '',
      SchoolYearId: '',
      StateId: '',
      AgencyId: '',
      SiteIds: '',
      RatingPeriodId: '',
      TeacherId: '',
      TeacherOfRecordId: ''
    };
    if (this.stateSelect?.disabled) {
      payload.StateId = this.StateId.value;
    }

    if (this.agencySelect?.disabled) {
      payload.AgencyId = this.AgencyId.value;
    }
    this.clearAllDropdowns();
    this.clear.emit(payload);
  }

  clearAllDropdowns() {
    if (this.searchInput?.searchState) this.stateSelect?.clearSelected();

    if (this.searchInput?.searchAgency) this.agencySelect?.clearSelected();

    if (this.searchInput?.searchSite) {
      this.siteSelect?.clearSelected();
      this.SiteIds.setValue(null);
    }

    if (this.searchInput?.searchSiteType) this.typeSelect?.clearSelected();

    if (this.searchInput?.searchYear) this.schoolYearSelect?.clearSelected();

    if (this.searchInput?.searchRatingPeriod)
      this.ratingPeriodSelect?.clearSelected();

    if (this.searchInput?.searchTeacher) this.teacherSelect?.clearSelected();

    if (this.searchInput?.searchTeacherOfRecord) this.teacherOfRecordSelect?.clearSelected();
  }

  onDropDownSelect(event: any, selectType: string) {
    this.clearDropdowns = false;
    switch (selectType) {
      case this.select.States:
        this.StateId.setValue(event?.id);
        break;
      case this.select.Agencies:
        this.AgencyId.setValue(event?.id);
        if (this.searchInput?.searchSite) this.SiteIds.setValue(null);
        if (this.searchInput?.searchRatingPeriod) this.RatingPeriodId.setValue(null);
        if (this.searchInput?.searchTeacher) this.TeacherId.setValue(null);
        if (this.searchInput?.searchTeacherOfRecord) this.TeacherOfRecordId.setValue(null);
        break;
      case this.select.Sites:
        this.handleChosenSites(event);
        break;
      case this.select.SiteType:
        this.Type.setValue(event?.id);
        break;
      case this.select.SchoolYear:
        this.SchoolYearId.setValue(event?.id);
        break;
      case this.select.RatingPeriods:
        this.RatingPeriodId.setValue(event?.id);
        break;
      case this.select.Teacher:
        this.TeacherId.setValue(event?.id);
        break;
      case this.select.TeacherOfRecord:
        this.TeacherOfRecordId.setValue(event?.id);
        break;
      default:
        break;
    }
  }

  handleChosenSites(event: any): void {
    if (this.searchMultiSite) {
      this.SiteIds.setValue(event.map((s: ISites) => s.id).join(', '));
    } else {
      this.SiteIds.setValue(event?.id);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
