import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-reassign-modal',
  templateUrl: './reassign-modal.component.html',
  styleUrls: ['./reassign-modal.component.scss']
})
export class ReassignModalComponent implements OnInit {
  reassignForm: FormGroup | any;
  @Output() formData = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public reassignData: any,
    private fb: FormBuilder,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.reassignForm = this.fb.group({
      title: [null, [Validators.required, Validators.maxLength(250)]],
      description: [this.reassignData.description, [Validators.required, Validators.maxLength(250)]],
    });
  }

  onSubmit(): void {
    if (this.reassignForm.invalid) {
      this.toast.error('Please enter all required fields.');
      return;
    }
    this.formData.emit(this.reassignForm.value);
  }
}
