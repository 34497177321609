<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold pr-5">
      Special Education Dates
    </h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
		<p class="mt-2">The Exit Date must fall within the current enrollment Rating Period. If the Exit Date needs to be a  <br> past date  that falls within a past Rating Period, please go to that Rating Period Classroom's page.</p>
    <form class="grid grid-cols-2 gap-4" [formGroup]="spedExitDateForm">
      <div class="col-span-1 mt-2">
        <label class="font-normal mb-2" for="childName">Child's Name</label>
        <p>
          {{ child.firstName + " " + child.lastName }}
        </p>
      </div>

      <div class="col-span-1 mt-2">
        <drdp-date-picker label="Special Education Entry Date"
          [initialDate]="spedExitDateForm.get('spedEntryDate').value" [isRequired]="true"
          (emitDate)="handleEntryDate($event)">
        </drdp-date-picker>
      </div>


      <div class="col-span-1 mt-2">
        <drdp-date-picker label="Special Education Exit Date" [initialDate]="spedExitDateForm.get('spedExitDate').value"
          [isRequired]="true" [minDate]="spedExitDateForm.get('spedEntryDate').value" (emitDate)="handleExitDate($event)">
        </drdp-date-picker>
      </div>

    </form>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end">
  <drdp-btn color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
  <drdp-btn class="ml-3" mat-button [disabled]="spedExitDateForm.invalid || isProcessing || !validDate" cdkFocusInitial
    (click)="onSubmit()">Save
  </drdp-btn>
</div>
