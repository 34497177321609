import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IInitialAssessmentToolType } from '@core/interfaces/iselectable';

@Component({
  selector: 'drdp-select-initial-assessment-tool-type',
  templateUrl: './select-initial-assessment-tool-type.component.html',
  styleUrls: ['./select-initial-assessment-tool-type.component.scss'],
})
export class SelectInitialAssessmentToolTypeComponent implements OnInit {
  @ViewChild('initialAssessmentToolTypeSelect') initialAssessmentToolTypeSelect: FilterSelectComponent | undefined;
  @Output() optionSelected = new EventEmitter<any>();
  @Input() initialId?: number | null;
  @Input() initialIds: number[] | null = null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = 'Initial Assessment Tool';
  @Input() additionalClasses: string = 'pb-12';
  tooltip: string = 'What was the assessment system used before DRDP?';
  selectOptions?: IInitialAssessmentToolType[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getInitialAssessmentToolTypeOptions();
  }

  getInitialAssessmentToolTypeOptions() {
    this.lookupService
      .getInitialAssessmentToolTypeOptions()
      .pipe(take(1))
      .subscribe((options: IInitialAssessmentToolType[]) => {
        this.selectOptions = options;
      });
  }

  onSelect(event: IInitialAssessmentToolType): void {
    this.optionSelected.emit(event);
  }
}
