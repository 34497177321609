import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAvailableEvidence, IEvidenceRequest } from '@core/interfaces/iportfolio';
import { PortfolioService } from '@core/services/portfolio.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-request-evidence',
  templateUrl: './request-evidence.component.html',
  styleUrls: ['./request-evidence.component.scss']
})
export class RequestEvidenceComponent {
  isProcessing: boolean = false;
  comment: string | undefined = undefined;

  constructor(
    private portfolioService: PortfolioService,
    public dialogRef: MatDialogRef<RequestEvidenceComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: IAvailableEvidence
  ) { }

  onSubmit(): void {
    if (!this.editData) return;

    this.isProcessing = true;
    const payload: IEvidenceRequest  = {
      fromStudentClassEnrollmentID: this.editData.fromStudentClassEnrollmentID,
      toStudentClassEnrollmentID: this.editData.toStudentClassEnrollmentID ?? 0,
      studentId: this.editData.studentId ?? 0,
      comment: this.comment
    };

    const successMessage = 'Request has been sent.';
    this.portfolioService.processEvidenceRequest(payload, successMessage)
      .pipe(take(1)).subscribe((res: boolean) => { 
        if (res) {
          this.isProcessing = false; 
          this.dialogRef.close();
        }
      });
  }
}
