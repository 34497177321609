<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-1/2">
      <drdp-title title="Classroom"></drdp-title>
    </div>
    <div class="basis-1/2 mt-2">
      <div class="flex justify-end items-end">
        <drdp-btn (click)="openModal()" *drdpCheckPermission="Permission.CreateClassrooms"><i class="uil uil-plus"></i>
          Add New Classroom</drdp-btn>
      </div>
    </div>
  </div>

  <drdp-container>
    <drdp-search textInputPlaceholder="Classroom Name" [searchInput]="searchInput" (search)="searchTable($event)"
      (clear)="onClear()"></drdp-search>
    <div class="p-5 w-full">
      <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
        (editActionItem)="openModal($event)" (deleteActionItem)="deleteModal($event)">
      </drdp-add-edit-table>
    </div>
  </drdp-container>
</div>
<mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
  (page)="paginateTable(false)" class="mb-12">
</mat-paginator>