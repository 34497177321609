<div class="flex flex-row p-5">
  <h1 class="userProfile font-semibold">Edit User Profile</h1>
</div>
<div class="place-content-center mr-5 ml-2">
  <drdp-container>
    <div class="
        lg:flex
        place-content-between place-items-center
        border-b border-drdpgrey-100
        self-center
        hidden
      ">
      <h1 class="information font-semibold mt-1">User Profile Details</h1>
    </div>
    <div class=""></div>
    <div class="w-full">
      <!-- ! Form Begins ! -->
      <div mat-dialog-content>
        <form [formGroup]="editUserForm">
          <div class="pt-4">
            <div class="grid grid-cols-3 gap-8">
              <div>
                <label for="firstName">Work Email<span class="required"></span></label>
                <mat-form-field class="bg-white w-full" appearance="fill">
                  <input matInput type="text" maxlength="100" formControlName="emailName" required />
                </mat-form-field>
                <span class="required" *ngIf="emailName.invalid && emailName.touched">Email Required</span>
              </div>

              <div>
                <label></label>
                <input class="blank" matInput type="text" maxlength="1" />
              </div>

              <div>
                <label></label>
                <input class="blank" matInput type="text" maxlength="1" />
              </div>
              <div>
                <label for="firstName">First Name<span class="required"></span></label>
                <mat-form-field class="bg-white w-full" appearance="fill">
                  <input matInput type="text" maxlength="50" formControlName="firstName" required />
                </mat-form-field>
                <span class="required" *ngIf="firstName.invalid && firstName.touched">First Name Required</span>
              </div>

              <div>
                <label>M.I.</label>
                <mat-form-field class="bg-white w-full" appearance="fill">
                  <input matInput type="text" maxlength="1" formControlName="middleName" />
                </mat-form-field>
              </div>

              <div>
                <label>Last Name<span class="required"></span></label>
                <mat-form-field class="bg-white w-full" appearance="fill">
                  <input matInput type="text" maxlength="50" formControlName="lastName" required />
                </mat-form-field>
                <span class="required" *ngIf="lastName.invalid && lastName.touched">Last Name Required</span>
              </div>
              <div>
                <drdp-select-suffix [initialSuffix]="suffix.value" (suffix)="handleSuffix($event)">
                </drdp-select-suffix>
              </div>
              <div>
                <label>Work Title</label>
                <mat-form-field class="bg-white w-full mt-2" appearance="fill">
                  <input matInput type="text" maxlength="50" formControlName="jobTitle"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="flex flex-row-reverse">
        <!-- Validators are 8 characters min, All inputs required and passwords have to match -->
        <drdp-btn type="submit" (click)="onSubmit()">Save Changes</drdp-btn>

        <drdp-btn color="cancel" class="mr-6" routerLink="/user-profile">Cancel</drdp-btn>
      </div>
    </div>
  </drdp-container>
</div>