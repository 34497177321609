import { Component, OnInit } from '@angular/core';
import { StudentService } from '@core/services/student.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { TableColumnType } from '@core/enums/table';
import { IModalEmitData } from '@core/interfaces/imodal';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { take } from 'rxjs';
import { DropChildComponent } from '@views/user/manage-enrollment/drop-child/drop-child.component';
import { EditClassEnrollmentDate } from './edit-class-enrollment/edit-class-enrollment-date.component';
@Component({
  selector: 'drdp-view-class-enrollments',
  templateUrl: './view-class-enrollments.component.html',
  styleUrls: ['./view-class-enrollments.component.scss'],
})
export class ViewClassEnrollmentsComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;
  canDelete = this.permissionService.checkPermission(
    Permission.DeleteEnrollments
  );
  canEdit = this.permissionService.checkPermission(
    Permission.EditStudents
  );
  classId: number = 0;
  constructor(
    private studentService: StudentService,
    private studentClassEnrollmentService: StudentClassEnrollmentService,
    private permissionService: PermissionService,
    public modal: MatDialog,
    private route: ActivatedRoute
  ) {}

  tableColumns = [
    { columnDef: 'agencyName', header: 'Agency Name', type: 'text' },
    {
      columnDef: 'enrollmentStartDate',
      header: 'Agency Enrollment',
      type: 'date',
    },
    {
      columnDef: 'enrollmentEndDate',
      header: 'Agency Withdrawal',
      type: 'date',
    },
    {
      columnDef: 'ageGroup',
      header: 'Age Grade/Instrument',
      type: 'text',
    },
    { columnDef: 'ratingPeriodName', header: 'Rating Period', type: 'text' },
    { columnDef: 'name', header: 'Classroom', type: 'text' },
    {
      columnDef: 'classEnrollmentDate',
      header: 'Classroom Start Date',
      type: 'date',
    },
    {
      columnDef: 'classExitDate',
      header: 'Classroom Withdraw Date',
      type: 'date',
    },
    { columnDef: 'siteName', header: 'Site', type: 'text' },
    this.canEdit ?
    {
      columnDef: 'edit',
      header: 'Edit',
      type: 'edit'
    }: null,
  ].filter(Boolean);

  ngOnInit(): void {
    this.classId = Number(this.route.snapshot.paramMap.get('id'));
    if (this.canDelete) {
      this.tableColumns.push({
        columnDef: 'delete',
        header: 'Delete',
        type: TableColumnType.delete,
      });
    }
    this.getEnrollmentDetails();
  }

  getEnrollmentDetails() {
    this.studentService
      .getClassroomEnrollmentDetails(this.classId)
      .subscribe((res: any) => {
        this.dataSource = new MatTableDataSource(res);
        this.tableData = res;
      });
  }

  deleteModal(event: IModalEmitData): void {
    const isActiveEnrollment = event.data?.classExitDate == undefined;
    const isLastActive =
      isActiveEnrollment &&
      this.tableData.filter(
        (item: any) =>
          item.classExitDate == undefined && item.id != event.data?.id
      ).length == 0;
    let confirmationMessage = isActiveEnrollment
      ? 'Are you sure you want to delete and Withdraw child from this class enrollment?'
      : 'Are you sure you want to delete the child enrollment?';

    if (isLastActive)
      confirmationMessage +=
        ' This will also remove the child from the agency.';

    let modal: IModalEmitData = { data: {}, index: 0 };
    modal.data.modalInfo = {
      title: `Delete Enrollment`,
      message: confirmationMessage,
      primaryBtnText: Modal.OK,
      showCancel: true,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: modal,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        if (isActiveEnrollment) {
          this.openUnenrollModal(event);
        } else {
          this.deleteEnrollment(event.data?.id ?? 0);
        }
      }
    });
  }

  openModel(editData: IModalEmitData) {
    const modalRef = this.modal.open(EditClassEnrollmentDate, {
      data: editData ? editData : undefined,
    });

    modalRef.afterClosed().subscribe((response) => {
      if (response.success) {
        this.getEnrollmentDetails();
      }
    });
  }

  deleteEnrollment(id: number) {
    this.studentClassEnrollmentService
      .deleteStudentClassEnrollment(id, 'Enrollment successfully deleted.')
      .pipe(take(1))
      .subscribe((success) => {
        if (success) {
          this.getEnrollmentDetails();
        }
      });
  }

  openUnenrollModal(data: IModalEmitData) {
    const enrollment = data.data;
    if (enrollment) {
      const modalData = {
        data: {
          id: enrollment.studentId,
          classId: enrollment.classId,
          siteId: enrollment.siteId,
          firstName: enrollment.firstName,
          lastName: enrollment.lastName,
          studentClassEnrollmentId: enrollment.id,
        },
      };
      const modalRef = this.modal.open(DropChildComponent, {
        data: modalData,
      });
      modalRef.afterClosed().subscribe((success) => {
        if (success) {
          this.deleteEnrollment(data.data?.id ?? 0);
        }
      });
    }
  }
}
