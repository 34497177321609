<div class="flex flex-row p-2">
  <h1 class="userProfile font-semibold">Change Password</h1>
</div>
<div class="place-content-center mr-5">
  <drdp-container>
    <div class="w-full">
      <div
      class="
      lg:flex
      place-content-between place-items-start
      border-b border-drdpgrey-100
      self-center
      hidden
    ">
    <h1 class="information font-semibold mb-2">User Password</h1>
  </div>

      <form
        [formGroup]="passwordForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="pt-5">
          <label>Current Password <span class="required"></span> </label>
          <div class="grid grid-rows-1 h-10">
            <div class="grid grid-cols-2">
              <div class="border-b-1 border-gray-500">
                <mat-form-field class="bg-white w-full" appearance="fill">
                  <input
                    matInput
                    autocomplete="off"
                    type="password"
                    formControlName="oldPassword"
                    [maxlength]="50"
                    required
                    InputText
                    [type]="hidden ? 'password' : 'text'"
                  />
                </mat-form-field>
              </div>
              <div class="items-center flex">
                <i
                  class="mx-1 uil"
                  (click)="hidden = !hidden"
                  [ngClass]="{ 'uil-eye-slash': hidden, 'uil-eye': !hidden }"
                >
                </i>
              </div>
            </div>
          </div>

          <mat-error class="errorMsg z-10 relative" *ngIf="invalid(passwordForm.get('oldPassword'))"
            >Current Password is required</mat-error
          >

          <div class="pt-10">
            <div class="grid-cols-1">
              <label>New Password <span class="required"></span></label>
              <div class="grid grid-rows-1 h-10">
                <div class="grid grid-cols-2">
                  <div class="border-b-1 border-gray-500">
                    <mat-form-field class="bg-white email w-full" appearance="fill">
                      <input
                        matInput
                        autocomplete="off"
                        type="password"
                        formControlName="newPassword"
                        [maxlength]="50"
                        required
                        InputText
                        [type]="confirmHidden ? 'password' : 'text'"
                      />
                    </mat-form-field>
                  </div>
                  <div class="items-center flex">
                    <i
                      class="mx-1 uil"
                      (click)="confirmHidden = !confirmHidden"
                      [ngClass]="{ 'uil-eye-slash': confirmHidden, 'uil-eye': !confirmHidden }"
                    >
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <mat-error class="errorMsg z-10 relative" *ngIf="invalid(passwordForm.get('newPassword'))">
              New Password must be a minimum of 8 alpha-numeric characters with
              at least 1 special character.
            </mat-error>
          </div>

          <div class="pt-10">
            <label>Confirm New Password <span class="required"></span></label>
            <div class="grid grid-rows-1 h-10">
              <div class="grid grid-cols-2">
                <div class="border-b-.5 border-gray-500">
                  <mat-form-field class="bg-white email w-full" appearance="fill">
                    <input
                      matInput
                      autocomplete="off"
                      type="password"
                      formControlName="confirmPassword"
                      [maxlength]="50"
                      required
                      pInputText
                      [type]="hiddenConfirm ? 'password' : 'text'"
                    />
                  </mat-form-field>
                </div>
                <div class="items-center flex">
                  <i
                    class="mx-1 uil"
                    (click)="hiddenConfirm = !hiddenConfirm"
                    [ngClass]="{ 'uil-eye-slash': hiddenConfirm, 'uil-eye': !hiddenConfirm }"
                  >
                  </i>
                </div>
              </div>
            </div>
            <mat-error class="errorMsg z-10 relative" *ngIf="invalid(passwordForm.get('confirmPassword'))">
              New Password and Confirm Password do not match.
            </mat-error>
          </div>
        </div>

        <div class="flex justify-end mt-4">
          <drdp-btn type="submit">Change Password</drdp-btn>
          <drdp-btn color="cancel" class="ml-3 mr-6" routerLink="/user-profile"
            >Cancel</drdp-btn>
        </div>

      </form>

    </div>
  </drdp-container>
</div>
