<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
        #suffixSelect
        label="Suffix" 
        [multiSelect]="multiSelect" 
        [options]="suffixOptions" 
        [selectType]="select.Suffix"
        [required]="required" 
        [isValid]="isValid"
        (onSelected)="onStatusSelect($event)"
        [initialValues]="suffixId">
    </drdp-filter-select>
</div>