import { Component, ViewChild, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { LookupService } from '@core/services/lookup.service';
import { IRegionType } from '@core/interfaces/iregion-type';
import { States } from '@core/enums/states';
import { RegionType } from '@core/enums/data-type';
import { ISelectableGroups } from '@core/interfaces/iselectable';

@Component({
  selector: 'drdp-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss']
})

export class GroupSelectComponent implements OnInit {
  private eventsSubscription?: Subscription;
  @ViewChild('select', { read: ElementRef }) selectElement: ElementRef | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() label = 'Entity';
  @Input() multiSelect: boolean = false;
  @Input() options?: ISelectableGroups;
  @Input() initialValues?: any = this.multiSelect ? [] : null;
  @Input() required = false;
  @Input() disabled = false;
  @Input() isValid = true;
  @Output() onSelected = new EventEmitter();
  @Output() onNewPage = new EventEmitter();
  selected: number[] = [];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData: number = 0;
  groupForm: FormGroup | any;
  filterText: string = '';
  isFilter: boolean = false;
  nhOptions: IRegionType[] = [];
  paginatedFilteredOptions: any;
  public get selectLabel() {
    if (this.multiSelect) {
      if (this.initialValues && this.initialValues.length > 0) {
        return `Selected ${this.label}: ${this.initialValues.length}`;
      }
      return `Select ${this.label}`;
    }
    return `Select a ${this.label}`
  }
  get optionControl() { return this.groupForm.get('optionControl') }
  constructor(private fb: FormBuilder, private lookupService: LookupService) {}

  ngOnInit(): void {
    this.groupForm = this.fb.group({
      optionControl: [this.initialValues]
    });
  }

  getNHRegionTypes(): void {
    this.lookupService.getNHRegionTypes().subscribe((regionTypes: IRegionType[]) => {
        this.nhOptions = regionTypes;
      this.paginateData(this.nhOptions);
    });
  }

  canFilter(data: any) {
    return data.some((item: any) => {
      return item.options && item.options.some((option: any) => {
        return option.regionGroup && option.regionGroup.stateId === States.newHampshire;
      });
    });
  }

  ngOnChanges(): void {
    if (this.options) {
        this.isFilter = this.canFilter(this.options.data);
        if (this.isFilter) this.getNHRegionTypes();
        this.totalData = this.options.totalData;
      if (this.initialValues) {
        this.selected = this.initialValues;
        this.optionControl.setValue(this.selected);
      }
    }
  }

  compareWith(a: unknown, b: unknown): boolean {
    if (!a || !b) return false;
    return JSON.stringify(a) === JSON.stringify(b);
  }

  page(event: any): void {
    if (this.isFilter) {
        this.pageIndex = this.paginator.pageIndex;
    } else {
        this.pageIndex = event.pageIndex + 1;
        this.onNewPage.emit({pageIndex: this.pageIndex, pageSize: this.pageSize});
    }
  }

  handleOption(item: any) {
    if (this.options) {
      if (this.multiSelect) {
        const itemIndex = this.selected.indexOf(item.id);
        if (itemIndex !== -1) {
          this.selected.splice(itemIndex, 1);
        } else {
          this.selected.push(item.id);
        }
      } else {
        this.selected = item;
      }
      this.optionControl.setValue(this.selected);
      this.onSelected.emit(this.selected);
    }
  }

  onFilterChange(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    this.filterOptions(inputValue);
  }

  filterOptions(value: string): void {
    this.pageIndex = 0;
    const keyword = value.toUpperCase();
    const filteredOptions = this.nhOptions.filter((x: any) =>
      x.name && x.name.toUpperCase().includes(keyword.toUpperCase())
    );
    this.totalData = filteredOptions.length;
    this.paginateData(filteredOptions);
  }

  paginateData(data: IRegionType[]): any {
    const totalData = data.length;
    const totalPages = Math.ceil(totalData / this.pageSize);
    const paginatedData: any = [];
    let paged: any = [];

    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      const startIdx = (pageIndex)  * this.pageSize;
      const endIdx = (pageIndex + 1) * this.pageSize;
      const pageData = data.slice(startIdx, endIdx);

      const paginationResult = {
        name: RegionType.SAU,
        options: pageData,
        pageIndex,
        totalData,
        totalPages,
      };
      paged.push(paginationResult);
    }
    paginatedData.data = paged;

    this.paginatedFilteredOptions = paginatedData;
    return paginatedData;
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }
}


