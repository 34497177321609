import {
  Component,
  inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DropdownType } from '@core/enums/pending-requests';
import { SelectType } from '@core/enums/select';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { TableColumnType, TableInputAction } from '@core/enums/table';
import { IAgency } from '@core/interfaces/iagency';
import { IClass, IClassSearchRequest } from '@core/interfaces/iclasses';
import { IBatchEvidenceRequest, IBatchEvidenceResult, IPortfolioEvidenceResponse, IPrevPortfolioEvidenceResponse } from '@core/interfaces/iportfolio';
import { IRatingPeriod, ISchoolYear } from '@core/interfaces/iratingperiod';
import { ISelectable } from '@core/interfaces/iselectable';
import { ISites } from '@core/interfaces/isites';
import { IState } from '@core/interfaces/istate';
import {
  IStudentRequestSearch,
  IStudentRequestSearchResponse,
} from '@core/interfaces/istudent-request';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { mapClassResToDRDPSelect } from '@core/services/helper.service';
import { PortfolioService } from '@core/services/portfolio.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { SiteService } from '@core/services/site.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import {
  combineLatest,
  concatMap,
  forkJoin,
  map,
  Observable,
  Subscription,
  take,
} from 'rxjs';
import { InvalidRequestModalComponent } from './invalid-request-modal/invalid-request-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ITemplateMeasure } from '@views/user/configuration/rating-view-setup/interfaces/itemplate-measure';

@Component({
  selector: 'drdp-pending-portfolio-evidences',
  templateUrl: './pending-portfolio-evidences.component.html',
  styleUrls: ['./pending-portfolio-evidences.component.scss'],
})
export class PendingPortfolioEvidencesComponent implements OnInit {
  @ViewChildren(FilterSelectComponent) selectComponents:
    | QueryList<FilterSelectComponent>
    | undefined;
  @ViewChild('stateSelect') stateSelect!: SelectStateComponent;
  @ViewChild('agencySelect') agencySelect!: SelectAgencyComponent;
  private subscriptions = new Subscription();
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  getSelectData$: Observable<any> | undefined;
  getSearchSelectData$: Observable<any> | undefined;
  user = this.authService.getCurrentUser();
  isAgencyAdmin?: boolean = this.authService.isAgencyAdmin();
  isLeadAgencyAdmin?: boolean = this.authService.isLeadAgencyAdmin();
  viewOnlyOwnAgency: boolean | undefined = false;
  currentDate = new Date();
  isCurrentlyEditing: boolean = false;
  acceptOrDeclineChecked: boolean = false;
  activeSchoolYear?: any;
  tableData?: any;
  selectedSiteId: number[] = [];

  selectClass = SelectType.Class;
  selectSites = SelectType.Sites;
  selectRatingPeriods = SelectType.RatingPeriods;

  ratingPeriodsOptions?: IRatingPeriod[];
  classOptions?: IClass[];
  fromClassroomList: IClass[] = [];
  toClassroomList: IClass[] = [];
  toRatingPeriodList: IRatingPeriod[] | undefined = [];
  fromClassId?: number | undefined;
  toClassId?: number | undefined;

  initialAgencyId?: number | undefined;
  stateSearchId?: number | null;
  agencySearchId?: number | null;
  clearDropdowns = false;
  doneEditing: boolean = false;

  searchPayload: IStudentRequestSearch | any = {};

  router = inject(Router);
  toastService = inject(ToastService);
  portfolioService = inject(PortfolioService);
  tableColumns: any = [
    { columnDef: 'studentName', header: 'Child Name', type: 'text' },
    {
      columnDef: 'fromSiteName',
      header: 'Previous Site',
      type: 'text',
    },
    {
      columnDef: 'fromClassroomName',
      header: 'Previous Classroom',
      type: 'text',
    },
    {
      columnDef: 'ratingPeriodName',
      header: 'Rating Period',
      type: 'text',
    },
    {
      columnDef: 'siteName',
      header: 'Current Site',
      type: 'text',
    },
    {
      columnDef: 'classroomName',
      header: 'Current Classroom',
      type: 'text',
    },
    {
      columnDef: 'requestedBy',
      header: 'Requested By',
      type: 'text',
    },
    {
      columnDef: 'comment',
      header: 'Comment',
      type: TableColumnType.text,
    },
    {
      columnDef: 'decline',
      header: 'Decline',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox',
    },
    {
      columnDef: 'approve',
      header: 'Accept',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox',
    },
  ].filter(Boolean);

  constructor(
    private requestService: StudentRequestService,
    private authService: AuthService,
    private ratingPeriodService: RatingPeriodService,
    private sitesService: SiteService,
    private classService: ClassService,
    public modal: MatDialog,
  ) {}

  ngOnInit(): void {
    this.stateSearchId = this.user.stateId;
    this.searchPayload.stateId = this.stateSearchId;
    this.agencySearchId = this.user.agencyId;
    this.searchPayload.agencyId = this.user.agencyId;
    if (this.viewOnlyOwnAgency) {
      this.searchPayload.stateId = this.user.stateId;
      this.searchPayload.agencyId = this.user.agencyId;
      this.initialAgencyId = this.user.agencyId;
      this.setTableData();
    }
    if (this.user.agencyId) this.getRatingPeriods(this.user.agencyId);
  }

  setTableData(): void {
    this.subscriptions.add(
      this.requestService
        .getStudentEvidenceRequests(this.searchPayload)
        .subscribe((res: IStudentRequestSearchResponse[]) => {
          if (res) {
            res = res.map((data: IStudentRequestSearchResponse) => ({
              ...data,
              isSelect: false,
              approve: null,
              decline: null,
              classOptions: [],
              siteOptions: [],
              ratingPeriodOptions: [],
            }));
          }
          this.dataSource = new MatTableDataSource(res);
          this.tableData = res;
        })
    );
  }

  initializeRowDropdowns(index: number): void {
    this.getSelectData$ = this.ratingPeriodService.getActiveSchoolYear().pipe(
      concatMap((year: ISchoolYear) =>
        combineLatest({
          ratingPeriods: this.ratingPeriodService.getBySchoolYearAndAgency(
            year.id,
            this.tableData[index].agencyId
          ),
          sites: this.sitesService.getSitesByAgencyId(
            this.tableData[index].agencyId
          ),
          classes: this.classService.getClassesByAgency(
            this.tableData[index].agencyId
          ),
        }).pipe(map((data) => data))
      )
    );

    this.subscriptions.add(
      this.getSelectData$.subscribe((res: any) => {
        this.tableData[index].ratingPeriodOptions = res.ratingPeriods;
        this.tableData[index].siteOptions = res.sites;
      })
    );

    this.updateClassroomDropdowns(
      {
        siteIds: this.tableData[index].siteId,
        ratingPeriodId: this.tableData[index].ratingPeriodId,
      },
      DropdownType.tableDropdown,
      index
    );
  }

  updateClassroomDropdowns(
    params: IClassSearchRequest,
    dropdownType: string,
    index?: number
  ): void {
    const payload: IClassSearchRequest = {};
    payload.siteIds = params.siteIds
      ? Array.isArray(params.siteIds)
        ? params.siteIds
        : [params.siteIds]
      : null;
    payload.ratingPeriodId = params.ratingPeriodId ?? null;
    payload.itemsPerPage = 100;

    this.subscriptions.add(
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .subscribe((res: any) => {
          if (res) {
            let mappedRes = mapClassResToDRDPSelect(res);
            switch (dropdownType) {
              case DropdownType.tableDropdown:
                this.tableData[index!].classOptions = mappedRes;
                let includesChosenClass = mappedRes
                  .map((data: any) => data.id)
                  .includes(this.tableData[index!].toClassId);
                if (!includesChosenClass) {
                  this.tableData[index!].toClassId =
                    this.tableData[index!].classOptions[0]?.id;
                  this.tableData[index!].classroomName =
                    this.tableData[index!].classOptions[0]?.classroomName;
                }
                break;
              case DropdownType.fromDropDown:
                this.fromClassroomList = mappedRes;
                break;
              case DropdownType.toDropdown:
                this.toClassroomList = mappedRes;
                break;
              default:
                break;
            }
          }
        })
    );
  }

  handleRequest(event: any): void {
    var i = event.index;
    var prop = event.property;

    this.tableData[i][prop] = !this.tableData[i][prop];
    if (prop == 'approve') this.tableData[i].decline = false;
    if (prop == 'decline') this.tableData[i].approve = false;
    if (
      this.tableData[i].approve == false &&
      this.tableData[i].decline == false
    ) {
      this.tableData[i].decline = null;
      this.tableData[i].approve = null;
    }

    this.acceptOrDeclineChecked = this.tableData.some((row: any) => {
      return row.decline == true || row.approve == true;
    });
  }

  handleSubmit(): void {
    const dataToUpdate = this.tableData.filter((data: any) => data.approve !== undefined && data.approve !== null);

    if (dataToUpdate.length == 0) {
      this.toastService.error('No request to be updated.');
      return;
    }
    this.createRequestPayload(dataToUpdate);
  }

  createRequestPayload(dataToUpdate: any): void {
    let requests = dataToUpdate.map((data: any) => ({
      id: data.id,
      studentId: data.studentId,
      fromStudentClassEnrollmentId: data.fromStudentClassEnrollmentId,
      toStudentClassEnrollmentId: data.toStudentClassEnrollmentId,
      comment: data.comment,
      ageGradeInstrumentId: data.ageGradeInstrumentId,
      isApproved: data.approve,
      isDeclined: data.decline,
      createdBy: data.createdBy,
      minStartDate: data.minStartDate,
      maxEndDate: data.maxEndDate,
      studentName: data.studentName
    }));
    let payload: IBatchEvidenceRequest = {
      evidenceRequests: requests,
      userId: this.user.userId
    }
    this.portfolioService.updateEvidenceRequests(payload).subscribe((res: IBatchEvidenceResult) => {
      if (res.isSuccess) {
        this.toastService.success('Request(s) updated successfully.');
        this.setTableData();
      } else {
        this.openInvalidRequestModal(res);
      }
    });
  }

  openInvalidRequestModal(invalidRequests: IBatchEvidenceResult): void {
    this.modal.open(InvalidRequestModalComponent, {
      data: invalidRequests ? invalidRequests : undefined,
    });
  }

  isCurrentRatingPeriod(minDate: Date | string, maxDate: Date | string): boolean {
    const current = new Date();
    const currentWithoutTime = new Date(current.getFullYear(), current.getMonth(), current.getDate());
    return currentWithoutTime >= new Date(minDate) && currentWithoutTime <= new Date(maxDate);
  }

  getRatingPeriods(agencyId: number): void {
    this.ratingPeriodService
      .getByAgency(agencyId)
      .pipe(take(1))
      .subscribe((res) => {
        this.toRatingPeriodList = res;
      });
  }

  handleState(state: IState): void {
    if (state) {
      this.searchPayload.stateId = state.id ?? null;
      this.stateSearchId = this.searchPayload.stateId;
    }
    this.selectComponents?.forEach((selectComponent: FilterSelectComponent) =>
      selectComponent?.clear()
    );
  }

  handleAgency(agency: IAgency): void {
    if (agency) {
      this.selectComponents?.forEach((selectComponent: FilterSelectComponent) =>
        selectComponent?.clear()
      );
      this.searchPayload.agencyId = agency == null ? null : agency.id;
      this.agencySearchId = this.searchPayload.agencyId;

      this.getRatingPeriods(agency.id);
    }
  }

  handleFromSites(site: ISites): void {
    this.searchPayload.fromSiteId = site == null ? null : site.id;

    if (this.searchPayload.fromSiteId && this.searchPayload.toRatingPeriodId)
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(site.id!),
          ratingPeriodId: this.searchPayload.toRatingPeriodId,
        },
        DropdownType.fromDropDown
      );
  }

  handleFromClassroom(classId: ISelectable): void {
    this.searchPayload.fromClassId = classId == null ? null : classId.id;
  }

  handleFromRatingPeriod(ratingPeriod: IRatingPeriod) {
    this.searchPayload.fromRatingPeriodId =
      ratingPeriod == null ? null : ratingPeriod.id;

    if (
      this.searchPayload.fromRatingPeriodId &&
      this.searchPayload.fromSiteId
    ) {
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(this.searchPayload.fromSiteId!),
          ratingPeriodId: this.searchPayload.fromRatingPeriodId,
        },
        DropdownType.fromDropDown
      );
    }
  }

  handleToSites(site: ISites): void {
    this.searchPayload.toSiteId = site == null ? null : site.id;

    if (site)
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(site.id!),
          ratingPeriodId: this.searchPayload.toRatingPeriodId,
        },
        DropdownType.toDropdown
      );
  }

  handleToClassroom(classId: any): void {
    this.searchPayload.toClassId = classId == null ? null : classId.id;
  }

  handleToRatingPeriod(ratingPeriod: IRatingPeriod): void {
    this.searchPayload.toRatingPeriodId =
      ratingPeriod == null ? null : ratingPeriod.id;

    if (this.searchPayload.toRatingPeriodId && this.searchPayload.toSiteId) {
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(this.searchPayload.toSiteId!),
          ratingPeriodId: this.searchPayload.toRatingPeriodId,
        },
        DropdownType.toDropdown
      );
    }
  }

  getSelectedSite(site: number): number[] {
    this.selectedSiteId = [];
    this.selectedSiteId.push(site);
    return this.selectedSiteId;
  }

  search(): void {
    this.resetBools();
    this.setTableData();
  }

  isSubmitBtnEnabled(): boolean {
    return (
      (this.doneEditing && this.acceptOrDeclineChecked) ||
      (this.doneEditing && !this.acceptOrDeclineChecked) ||
      (!this.doneEditing && this.acceptOrDeclineChecked)
    );
  }

  onClear(): void {
    this.resetBools();
    this.clearDropdowns = true;
    this.searchPayload = {};

    if (!this.stateSelect?.disabled) {
      this.stateSearchId = null;
    }

    if (!this.agencySelect?.disabled) {
      this.agencySearchId = null;
    }

    this.selectComponents?.forEach((selectComponent: FilterSelectComponent) =>
      selectComponent?.clear()
    );
    this.searchPayload = {
      stateId: this.stateSearchId ?? this.user.stateId,
      agencyId: this.agencySearchId ?? this.user.agencyId,
    };
    this.dataSource = new MatTableDataSource();
  }

  handleCancel(): void {
    this.router.navigateByUrl('/dashboard');
  }

  resetBools(): void {
    this.doneEditing = false;
    this.acceptOrDeclineChecked = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
