import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPortfolioMessage } from '@core/interfaces/iportfolio-message';
import { IPortfolioUser } from '@core/interfaces/iportfolio-user';
import { PortfolioMessageService } from '@core/services/portfolio-message.service';
import { ToastService } from '@core/services/toast.service';
import { UserService } from '@core/services/user.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-portfolio-message-box',
  templateUrl: './portfolio-message-box.component.html',
  styleUrls: ['./portfolio-message-box.component.scss']
})
export class PortfolioMessageBoxComponent implements OnInit {
  messageForm: FormGroup | any;

  get recipients() {
    return this.messageForm.get('recipients');
  }
  get message() {
    return this.messageForm.get('message');
  }

  constructor(
    private portfolioMessageService: PortfolioMessageService,
    private fb: FormBuilder,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<PortfolioMessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initializeForms();
  }

  initializeForms(): void {
    this.messageForm = this.fb.group({
      recipients: [null, Validators.required],
      message: [null, [Validators.required]],
    });

    if (this.data.recipientId) {
      this.recipients.setValue([this.data.recipientId]);
    }
  }

  onSend() { 
    if (this.messageForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }

    const payload: IPortfolioMessage = {
      portfolioId: this.data.portfolioId,
      studentClassEnrollmentId: this.data.enrollmentId,
      message: this.message.value,
      messageRecipients: this.recipients.value.map((userId: number) => ({ userId })),
    }

    this.portfolioMessageService.addNewMessage(
      payload, 
      'Message successfully sent.')
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (res) this.dialogRef.close();
        else this.toastService.error('Unable to send message.');
      })

  }

  handleRecipientSelection(event: any): void {
    if (event.length > 10) {
      this.toastService.error('Selected number of recipients cannot exceed 10.');
      return;
    }
    const recipients = event?.map((recipient: IPortfolioUser) => recipient.id);
    this.recipients.setValue(recipients);
  }
}
