import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ISites } from '@core/interfaces/isites';
import { Subscription } from 'rxjs';
import { ISelectable } from '@core/interfaces/iselectable';
import { UserService } from '@core/services/user.service';
import { IModalEmitData } from '@core/interfaces/imodal';
import { ViewUserComponent } from '../user-account/view-user/view-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { setIconDisplayObject } from '@core/services/helper.service';
import { AuthService } from '@core/services/auth.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { IAdminSearchUsersRequest } from '@core/interfaces/iuser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IRoles } from '@core/interfaces/iroles';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-global-admin',
  templateUrl: './global-admin.component.html',
  styleUrls: ['./global-admin.component.scss']
})
export class GlobalAdminComponent implements OnInit {
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;
  @ViewChild('statusSelect') statusSelect: FilterSelectComponent | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private subscriptions = new Subscription();

  title = "Global Admin"
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex:number = 0;
  pageSize:number = 10;
  totalData = 0;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  userSearch: FormGroup | any;
  clearSharedDropdowns = false;
  siteList: ISites[] = [];
  searchString: string = '';
  searchObj: Partial<IAdminSearchUsersRequest> = {};
  user = this.auth.getCurrentUser();

  tableColumns = [
    { columnDef: 'firstName', header: 'First Name', type: 'text' },
    { columnDef: 'lastName', header: 'Last Name', type: 'text' },
    { columnDef: 'emailName', header: 'Email', type: 'text' },
    { columnDef: 'roleName', header: 'Roles', type: 'text' },
    { columnDef: 'statusTypeName', header: 'Account Status', type: 'text' },
    { columnDef: 'preview',  icon: 'user', type: 'icon', colorClass: 'text-drdpblue-300', cursorClass: 'cursor-pointer' },
    { columnDef: 'editAccount',  icon: 'pen', type: 'icon', colorClass: 'text-drdpblue-300', cursorClass: 'cursor-pointer' },
    { columnDef: 'resetPassword', icon: 'key-skeleton', type: 'icon', colorClass: 'text-drdpblue-300', cursorClass: 'cursor-pointer' }
  ];

  get searchStr() {
    return this.userSearch.get('searchStr');
  }
  get agencyId() {
    return this.userSearch.get('agencyId');
  }
  get stateId() {
    return this.userSearch.get('stateId');
  }
  get siteIds() {
    return this.userSearch.get('siteIds');
  }
  get userStatusIds() {
    return this.userSearch.get('userStatusIds');
  }
  get roleIds() {
    return this.userSearch.get('roleIds');
  }

  constructor(
    public modal: MatDialog,
    private users: UserService,
    private auth: AuthService,
    private fb: FormBuilder,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = ''
  }

  initializeForm(): void {
    this.userSearch = this.fb.group({
      searchStr: [null],
      agencyId: [null],
      stateId: [null],
      siteIds: [null],
      userStatusIds: [null],
      roleIds: [null]
    });

    this.userSearch.patchValue({
      stateId: this.auth.getUserStateId(),
      agencyId: this.auth.getCurrentUser().agencyId,
      siteIds: this.auth.getCurrentUser().sites.map((site) => site.siteId)
    });

    this.searchObj = this.userSearch.value;
    this.searchObj.pageIndex = 1;
    this.searchObj.itemsPerPage = 10;
  }

  getUsers(): void {
    this.subscriptions.add(this.users.globalSearch(this.searchObj as IAdminSearchUsersRequest).subscribe((res: any) => {
      res.items = res.items.map((data: any) => ({
        ...data,
        displayIcons: setIconDisplayObject(this.tableColumns, data)
      }));
      this.dataSource = new MatTableDataSource(res.items);
      this.totalData = res.totalData
      this.tableData = res.items;
    }));
  }

  chosenState(state: ISelectable): void {
    this.stateId.setValue(state?.id);
  }

  chosenAgency(agency: ISelectable): void {
    this.agencyId.setValue(agency?.id);

    if (this.auth.getCurrentUser().agencyId !== this.agencyId.value) {
      this.siteIds.setValue(null);
    }
  }

  chosenSites(sites: ISites[]): void {
    if (!sites) {
      this.siteIds.setValue(null);
      return;
    }
    this.siteIds.setValue(sites.map((e: ISites) => e.id));
  }

  handleStatus(event: any): void {
    if (!event.length) {
      this.userStatusIds.setValue(null);
      return;
    }
    this.userStatusIds.setValue(event.map((e: any) => e.id));
  }

  handleroles(event: IRoles[]): void {
    if (!event.length) {
      this.roleIds.setValue(null);
      return;
    }
    this.roleIds.setValue(event.map((e: any) => e.id));
  }

  clear(): void {
    this.clearSharedDropdowns = true;
    this.dataSource = new MatTableDataSource();
    this.userSearch.reset();

    if (!this.stateSelect?.disabled) {
      this.stateId.setValue(null);
    }

    if (!this.agencySelect?.disabled) {
      this.agencyId.setValue(null);
    }
  }

  search(): void {
    this.searchObj = this.userSearch.value;
    this.searchObj.itemsPerPage = this.paginator.pageSize;
    this.searchObj.pageIndex = this.paginator.pageIndex;
    this.getUsers();
  }

  pageChange(): void {
    if (this.totalData > 0) {
      if (this.pageSize !== this.paginator.pageSize) {
        this.pageSize = this.paginator.pageSize;
        this.searchObj.itemsPerPage = this.pageSize;
      }

      if (this.pageIndex !== this.paginator.pageIndex) {
        this.pageIndex = this.paginator.pageIndex;
        this.searchObj.pageIndex = this.pageIndex + 1;
      }

      this.getUsers();
    }
  }

  onIconClick(event:any): void {
    switch(event.type) {
      case 'preview':
        this.openPreviewModal(event);
        break;
      case 'editAccount':
        this.openEditModal(event);
        break;
      default:
        this.openResetPasswordModal(event.data.id);
        break;
    }
  }

  openPreviewModal(event: IModalEmitData): void {
    const modalRef = this.modal.open(ViewUserComponent, { data: event ? event : undefined });
    this.subscriptions.add(modalRef.afterClosed().subscribe());
  }

  openEditModal(event: any): void {
    if (this.user.accessLevel < event.data.accessLevel) {
      this.toast.error("You do not have permission to edit this user's information. Please contact your Lead Agency Administrator for assistance.");
      return;
    }
    else {
      const modalRef = this.modal.open(EditUserComponent, { data: event.data ? event.data : undefined});
      this.subscriptions.add(modalRef.afterClosed().subscribe((res) => {
        if (Object.keys(res).length > 0) {
          Object.entries(res).map(([key, val]) => {
            this.tableData[event.index][key] = res[key];
          })
          this.dataSource = new MatTableDataSource(this.tableData);
        }
      }));
    }
  }

  openResetPasswordModal(userId: number): void {
    const modalRef = this.modal.open(ResetPasswordComponent, { data: userId ? userId : undefined });
    this.subscriptions.add(modalRef.afterClosed().subscribe());
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
