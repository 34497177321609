<div class="flex flex-col w-96" id="completeEnrollmentDialog">
  <div class="flex flex-row justify-between border-b">
    <h1 class="text-[25px] font-bold">
      <span>
        Complete Enrollment
      </span>
    </h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <form class="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-x-10 my-8" [formGroup]="assignStudentForm">
      <drdp-classroom-rating-period-params (formValues)="handleDropdownSelection($event)" [isDropdownRequired]="isDropdownRequired"
        class="contents" [omitFutureRps]="true">
      </drdp-classroom-rating-period-params>
      <div class="self-end z-30">
        <drdp-date-picker label="Classroom Enrollment Date"
                          [initialDate]="classEnrollmentDate?.value"
                          labelClasses="pb-1"
                          [isRequired]="true"
                          (emitDate)="handleDate($event)">
        </drdp-date-picker>
        <mat-error *ngIf="classEnrollmentDate.hasError('dateRange')" class="text-xs">
          Date must be between rating period's min and max dates.
        </mat-error>

        <mat-error *ngIf="classEnrollmentDate.hasError('agencyDate')" class="text-xs">
          Date cannot be before the agency enrollment date.
        </mat-error>


      </div>
    </form>
    <hr>
    <div class="mt-10 mb-2 flex justify-end">
      <drdp-btn class="pr-4" color="cancel" mat-dialog-close type="button">
        Cancel
      </drdp-btn>
      <drdp-btn type="submit" (click)="onSubmit()" [disabled]="!assignStudentForm.valid" cdkFocusInitial>
        Save & Enroll
      </drdp-btn>
    </div>
  </div>
</div>
