import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table';
import { IPermission, IPermissionGroupAssignmentRequest } from '@core/interfaces/ipermission'
import { PermissionService } from '@core/services/permission.service';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-super-features-add',
  templateUrl: './super-features-add.component.html',
  styleUrls: ['./super-features-add.component.scss']
})
export class SuperFeaturesAddComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  featureGroupForm: FormGroup | any;
  modalTitle = 'Add a new Super Feature';
  uncategorizedTableData?: any;
  uncategorizedDataSource: MatTableDataSource<any> = new MatTableDataSource();
  newSubTableData: any = [];
  newSubDataSource: MatTableDataSource<any> = new MatTableDataSource();
  hasChosenSuperFeature = false;

  removeUncategorizedSubColumns = [
    { columnDef: 'permissionName', header: 'Name', type: 'text' },
    { columnDef: 'permissionDescription', header: 'Description', type: 'text' },
    { columnDef: 'delete', header: 'Delete', type: 'delete' },
  ];

  addUncategorizedSubColumns = [
    { columnDef: 'permissionName', header: 'Name', type: 'text' },
    { columnDef: 'permissionDescription', header: 'Description', type: 'text' },
    { columnDef: 'add', header: 'Add', type: 'add' },
  ];

  get superPermissionId() { return this.featureGroupForm.get('superPermissionId') };
  get subPermissionIds() { return this.featureGroupForm.get('subPermissionIds') };

  constructor(
    private fb: FormBuilder,
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<SuperFeaturesAddComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any,
  ) { }

  ngOnInit(): void {
    this.initializeForms();
    this.getUncategorizedFeatures();
  }

  initializeForms(): void {
    this.featureGroupForm = this.fb.group({
      superPermissionId: [null, Validators.required],
      subPermissionIds: [[]]
    });
  }

  initializeNewSubTableData(newSubFeatures?: IPermission[]): void {
    if (newSubFeatures) {
      this.newSubDataSource = new MatTableDataSource(newSubFeatures);
      this.table?.reRender(newSubFeatures);
    }
  }

  initializeUncategorizedSubTableData(uncategorizedFeatures?: IPermission[]): void {
    if (uncategorizedFeatures) {
      this.uncategorizedDataSource = new MatTableDataSource(uncategorizedFeatures);
      this.table?.reRender(uncategorizedFeatures);
    }
  }

  getUncategorizedFeatures(): void {
    this.permissionService.getUncategorizedPermission().pipe(
      take(1),
      tap((permissions: IPermission[]) => {
        this.uncategorizedTableData = permissions;
        this.uncategorizedDataSource = new MatTableDataSource(this.uncategorizedTableData);
        this.table?.reRender();
      })
    ).subscribe();

  }

  addSuperFeature(event: any): void {
    const {data, index} = event;
    this.superPermissionId.setValue(data.id);
    this.uncategorizedTableData?.splice(index, 1);
    this.modalTitle = data.permissionName;
    this.hasChosenSuperFeature = true;
  }

  addSubFeature(event: any): void {
    const {data, index} = event;
    this.newSubTableData.push(data);
    this.subPermissionIds.setValue(this.newSubTableData.map((permission: IPermission) => permission.id));
    this.uncategorizedTableData?.splice(index, 1);
    this.sortTableData();
    this.initializeNewSubTableData(this.newSubTableData);
    this.initializeUncategorizedSubTableData(this.uncategorizedTableData);
  }

  removeSubFeature(event: any): void {
    const {data, index} = event;
    this.uncategorizedTableData.push(data);
    this.newSubTableData?.splice(index, 1);
    this.subPermissionIds.setValue(this.newSubTableData.map((permission: IPermission) => permission.id));
    this.sortTableData();
    this.initializeNewSubTableData(this.newSubTableData);
    this.initializeUncategorizedSubTableData(this.uncategorizedTableData);
  }

  sortTableData(): void {
    this.uncategorizedTableData = this.uncategorizedTableData?.sort((a: IPermission, b: IPermission) => a.permissionName.localeCompare(b.permissionName));
    this.newSubTableData = this.newSubTableData?.sort((a: IPermission, b: IPermission) => a.permissionName.localeCompare(b.permissionName));
  }

  onSubmit() {
    let payload: IPermissionGroupAssignmentRequest = this.featureGroupForm.value;
    return {
      formValid: true,
      payload: payload
    };
  }
}
