<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
        #statusSelect
        label="Status" 
        [multiSelect]="multiSelect" 
        [options]="statusOptions" 
        [selectType]="select.Status"
        [required]="required" 
        [isValid]="isValid"
        (onSelected)="onStatusSelect($event)"
        [initialValues]="initialStatusId">
    </drdp-filter-select>
</div>