<div class="p-5">
  <div class="flex flex-row">
      <div class="basis-1/2">
          <h1 class="pb-3 text-3xl font-semibold">School Years</h1>
      </div>
      <div class="basis-1/2">
        <div class="flex justify-end items-end">
          <drdp-btn (click)="openModal()"><i class="uil uil-plus"></i> Add School Year</drdp-btn>
        </div>
      </div>
    </div>

  <drdp-container>
    <div class="pl-1 xl:w-1/5 lg:w-1/4 md:w-1/3">
      <h1 class="text-xl font-bold border-b">Active School Year</h1>
      <drdp-add-edit-table [dataSource]="dataSource"
                          [tableData]="tableData"
                          [columns]="tableColumns"
                          (toggleSwitchActionItem)="chooseActiveYear($event)">
      </drdp-add-edit-table>
    </div>
    <div class="flex justify-end items-en mt-5">
      <drdp-btn (click)="updateActiveSchoolYear()">Save</drdp-btn>
    </div>
  </drdp-container>
</div>