<div class="p-5">
    <div class="flex justify-between">
      <drdp-title title="Integration Management"></drdp-title>
      <span>
        <drdp-btn *drdpCheckPermission="Permission.IntegrationManagement" (click)="addVendor()"><i class="uil uil-plus"></i> 
          Add New Vendor</drdp-btn>
      </span>
    </div>
  
    <drdp-container [additionClasses]="'min-h-[44rem]'">
      <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
        [isAdmin]="hasFullAccess" (editActionItem)="editVendor($event)">
      </drdp-add-edit-table>
    </drdp-container>
  </div>
