<div class="fix-action-row modal-body" id="addFeatureDialog">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">Batch Re-Enroll Confirmation</h1>
    <i
      mat-button
      mat-dialog-close
      class="text-drdpblue-300 cursor-pointer uil uil-times-circle"
    ></i>
  </div>

  <div *ngIf="displayResult" mat-dialog-content>
    <div *ngIf="ratingOptions.length != 0; else noRatings" class="content py-3">
      <p class="w-96">
        <em
          >You must have the new enrollment classroom and rating period created
          prior to submitting the re-enroll.</em
        >
      </p>
      <div class="col-span-2 mt-2">
        <label class="font-normal mb-2" for="childName"
          >Selected Children</label
        >
        <ul>
          <li *ngFor="let child of students">
            {{ child.firstName + " " + child.lastName }}
          </li>
        </ul>
      </div>
      <form [formGroup]="reEnrollmentForm">
        <div class="flex flex-row">
          <div class="mr-12">
            <label class="font-normal">Classroom</label>
            <p>{{ data.name }}</p>
          </div>
          <div class="w-64">
            <drdp-select-rating-period
              [initialRatingPeriodId]="ratingPeriodId.value"
              label="Rating Period"
              (ratingPeriod)="handleRatingPeriod($event)"
              [agencyId]="agencyId"
              [required]="true"
              [omitFutureRps]="true"
             >
          </drdp-select-rating-period>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-4 items-end">
          <div class="relative">
            <div class="col-span-1">
              <drdp-date-picker label="Classroom End Date"
                [initialDate]="classExitDate?.value"
                labelClasses="pb-1"
                [isRequired]="true"
                (emitDate)="handleDate($event, 'classExitDate')">
              </drdp-date-picker>
            </div>
            <div class="absolute top-0 left-full mt-1 ml-[-2px]">
              <i class="uil uil-info-circle text-xs" [matTooltip]=endDateInfo></i>
            </div>
          </div>
          <div class="relative">
            <div class="col-span-1">
              <drdp-date-picker label="Classroom Enrollment Date"
                [initialDate]="classEnrollmentDate?.value"
                labelClasses="pb-1"
                [isRequired]="true"
                (emitDate)="handleDate($event, 'classEnrollmentDate')">
              </drdp-date-picker>
            </div>
            <div class="absolute top-0 left-full mt-1 ml-[-2px]">
              <i class="uil uil-info-circle text-xs" [matTooltip]=enrollmentDateInfo></i>
            </div>
          </div>
        </div>
        <div class="w-96">
          <mat-error *ngIf="classEnrollmentDate.hasError('dateNotWithinRatingPeriod')">
            Invalid date. Date must be between rating period's start and end dates.
          </mat-error>
          <mat-error *ngIf="reEnrollmentForm.hasError('dateMismatch')">
            Invalid date range. The End Date cannot be after the Enrollment
            Date.
          </mat-error>
        </div>
      </form>
    </div>
    <ng-template #noRatings>
      <div class="flex flex-row mt-2">
        <p>No rating periods left to re-enroll for this classroom.</p>
      </div>
    </ng-template>
  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions class="flex flex-row justify-end">
    <drdp-btn color="cancel" class="mr-3" mat-button mat-dialog-close>
      Cancel
    </drdp-btn>
    <drdp-btn
    *ngIf="ratingOptions.length > 0"
      color="blue"
      [disabled]="
        ratingOptions.length == 0 || isProcessing || reEnrollmentForm.invalid
      "
      mat-button
      (click)="submit()"
    >
      {{ submitBtnText }}
    </drdp-btn>
  </div>
</div>
