<div class="p-5">
  <div class="flex flex-row">
    <drdp-title title="Message Board"></drdp-title>
  </div>

  <drdp-container additionClasses="w-full" *ngIf="canSearchPortfolioMessages">
    <drdp-container color="lightBlue">
      <div class="p-3 flex grid md:grid-cols-5 gap-2 flex-row content-between">
        <drdp-classroom-rating-period-params
          (searchValues)="handleSearch($event)"
          [search]="true"
          class="contents">
        </drdp-classroom-rating-period-params>
      </div>
    </drdp-container>
  </drdp-container>
  <div *ngFor="let message of portfolioMessages">
    <drdp-message-tile [message]="message" (selectedMessage)="handleSelectedMessage($event)"></drdp-message-tile>
  </div>

  <div>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [length]="totalData"
      [pageIndex]="pageIndex"
      (page)="paginateTable(false)">
    </mat-paginator>
  </div>
</div>
