<div id="classroom" class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{ label }}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle ml-8"></i>
  </div>

  <div mat-dialog-content>
    <form class="grid md:grid-cols-2 grid-cols-1 gap-4 gap-x-4 p-3 grid-cust" [formGroup]="classroomForm">
      <div *ngIf="canViewDropdowns" class="col-span-1 items-center w-[100%]">
        <drdp-select-state [initialStateId]="selectedState" (state)="handleDropdown($event, selectType.States)"
          [required]="true" [isValid]="!stateId.touched || stateId.valid">
        </drdp-select-state>
      </div>

      <div *ngIf="canViewDropdowns" class="col-span-1 items-center w-[100%]">
        <drdp-select-agency [initialAgencyId]="selectedAgency" (agency)="handleDropdown($event, selectType.Agencies)"
          [stateId]="selectedState" [required]="true" [isValid]="!agencyId.touched || agencyId.valid" [clear]="clearDropdown">
        </drdp-select-agency>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required" for="name">Classroom</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input placeholder="" matInput type="text" formControlName="name" maxlength="100">
        </mat-form-field>
        <mat-error *ngIf="name.hasError('invalidPilotName')">Invalid pilot classroom name.</mat-error>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-site [initialSiteId]="selectedSiteId" [agencyId]="selectedAgency" [required]="true"
          (site)="handleDropdown($event, selectType.Sites)" [isValid]="!siteId.touched || siteId.valid" [clear]="clearDropdown">
        </drdp-select-site>
      </div>

      <div *ngIf="canCreate2025Classroom && selectedState == states.california" class="col-span-1 items-center w-[100%]">
        <label class="font-normal" for="isPilot">2025 Pilot Classroom</label>
        <mat-checkbox color="primary" formControlName="isPilot" [disabled]="editData"></mat-checkbox>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <div class="flex justify-end align-bottom">
      <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close>Cancel</drdp-btn>
      <drdp-btn type="submit" mat-button (click)="onSubmit()" [disabled]="classroomForm.invalid || isProcessing"
        cdkFocusInitial>Save</drdp-btn>
    </div>
  </div>
</div>
