import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { ISelectBoolean } from '@core/interfaces/iselectable';

@Component({
  selector: 'drdp-select-yes-no',
  templateUrl: './select-yes-no.component.html',
  styleUrls: ['./select-yes-no.component.scss'],
})
export class SelectYesNoComponent implements OnInit {
  @ViewChild('yesNoSelect') yesNoSelect: FilterSelectComponent | undefined;
  @Output() optionSelected = new EventEmitter<any>();
  @Input() initialId?: number | null;
  @Input() initialIds: number[] | null = null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = 'Yes or No?';
  @Input() additionalClasses: string = '';
  selectOptions?: ISelectBoolean[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getYesNoOptions();
  }

  getYesNoOptions() {
    this.lookupService
      .getBasicSelects()
      .pipe(take(1))
      .subscribe((options: ISelectBoolean[]) => {
        this.selectOptions = options;
      });
  }

  onSelect(event: ISelectBoolean): void {
    this.optionSelected.emit(event);
  }
}
