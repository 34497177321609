import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { LookupService } from '@core/services/lookup.service';
import { StateService } from '@core/services/state.service';
import { ToastService } from '@core/services/toast.service';
import { CustomValidators } from '@core/validators/custom.validator';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-school-year-target',
  templateUrl: './school-year-target.component.html',
  styleUrls: ['./school-year-target.component.scss']
})
export class SchoolYearTargetComponent implements OnInit {
  targetForm: FormGroup | any;
  title: string = '';
  schoolYears: ISchoolYear[] = [];
  invalidDecimal: boolean = false;

  indicatorKeys: string[] = ['indicator7a1', 'indicator7a2', 'indicator7b1', 'indicator7b2', 'indicator7c1', 'indicator7c2'];

  get schoolYearId() { return this.targetForm.get('schoolYearId'); }

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private stateService: StateService,
    public dialogRef: MatDialogRef<SchoolYearTargetComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public modal: any
  ) { }

  ngOnInit(): void {
    this.title = this.modal.editData ? 'Edit School Year OSEP Target' : 'Add School Year OSEP Target';
    this.initializeForms();
    this.getSchoolYears();
    this.observeFormChanges();
  }

  initializeForms(): void {
    this.targetForm = this.fb.group({
      id: [0],
      stateId: [this.modal.stateId],
      schoolYearId: [null, Validators.required],
      indicator7a1: [null, [Validators.required, CustomValidators.decimalValidator]],
      indicator7a2: [null, [Validators.required, CustomValidators.decimalValidator]],
      indicator7b1: [null, [Validators.required, CustomValidators.decimalValidator]],
      indicator7b2: [null, [Validators.required, CustomValidators.decimalValidator]],
      indicator7c1: [null, [Validators.required, CustomValidators.decimalValidator]],
      indicator7c2: [null, [Validators.required, CustomValidators.decimalValidator]],
    });

    if (this.modal.editData) {
      this.targetForm.patchValue(this.modal.editData);
      this.roundNumericFields();
    }
  }

  private roundNumericFields(): void {
    this.indicatorKeys.forEach(key => { 
      this.targetForm.controls[key].setValue(this.modal.editData[key].toFixed(2));
    });
  }

  observeFormChanges(): void {
    this.targetForm.valueChanges.subscribe(() => {
      this.invalidDecimal = this.indicatorKeys.some(key => {
        const control = this.targetForm.get(key);
        return control && control.errors && control.errors.invalidDecimal;
      });
    });
  }

  getSchoolYears(): void {
    this.lookupService.getAllSchoolYears().pipe(
      take(1)
    ).subscribe((res: ISchoolYear[]) => {
      this.schoolYears = res;
    });
  }
  
  onDropDownSelect(event: any): void {
    this.schoolYearId.setValue(event?.id ?? null);
  }

  onSubmit(): void {
    if (this.targetForm.invalid) {
      this.toastService.error('Please fill out all required fields.');
      return;
    } 

    if (this.modal.editData) {
      this.stateService.updateStateSchoolYearTarget(this.targetForm.value, `School Year ${this.modal.editData.schoolYearName} OSEP Target Updated.`).pipe(
        take(1)
      ).subscribe((res: boolean) => {
        if (res) this.dialogRef.close(res);
      });
    } else {
      this.stateService.saveStateSchoolYearTarget(this.targetForm.value).pipe(
        take(1)
      ).subscribe((res: boolean) => {
        if (res) this.dialogRef.close(res);
      });
    }
  }
}
