<div class="p-5 mt-5">
  <div class="flex flex-row">
    <div class="basis-1/2">
      <drdp-title [title]="title"></drdp-title>
      <div *ngIf="hasMeasures"><a class="text-blue-700" *drdpCheckPermission="Permission.CreateRatingSpanish">Spanish
          version of Input Ratings</a></div>
    </div>
    <div class="basis-full" *ngIf="hasMeasures && !isPilotEnrollment">
      <div *drdpCheckPermission="Permission.CreateRating" class="flex justify-end items-end">
        <a class="text-blue-700 py-2 px-4 cursor-pointer" (click)="toggleView()">
          {{link}}
        </a>
      </div>
    </div>
  </div>

  <drdp-student-info [studentInfo]="studentInfo" (actionClicked)="onActionClick($event)" [canLock]="canLock"
    [hasMeasures]="hasMeasures" [isScantron]="isScantron"></drdp-student-info>

  <drdp-container additionClasses=" min-h-[2rem] mt-4 p-4" *ngIf="!hasMeasures">
    No Rating View has been chosen for this specific Age/Grade Instrument by your Agency Administrator. If you need
    assistance, please contact WestEd at 1-800-770-6339 or email DRDPOnline@wested.org.
  </drdp-container>


  <ng-container *ngIf="!isScantron">
    <drdp-rating-card [studentDomains]="studentDomains" [studentInfo]="studentInfo"
      (actionClicked)="selectRatingModal($event)" (editClicked)="openTeacherSurvey($event)" (viewClicked)="viewTeacherSurvey($event)"></drdp-rating-card>
  </ng-container>


  <ng-container *ngIf="isScantron" class="mt-4">
    <drdp-scantron [studentDomains]="studentDomains" [studentInfo]="studentInfo" [carReasons]="carReasons"
      [isKinder]="isKinder" (measureUpdated)="processMeasureUpdate($event)"></drdp-scantron>
  </ng-container>

</div>
