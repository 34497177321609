<div class="pl-5">
  <div class="flex justify-between items-center">
    <h1 class="text-2xl pb-3"> {{ isFutureEvidence ? "Review Evidence in Evidence Library"  : "Review Evidence" }}</h1>
    <drdp-btn *ngIf="!isFutureEvidence" color="outline" (click)="onDownloadPdf()" type="button">
      <i class="text-drdpblue-300 text-xl uil uil-print"></i>
      Print Existing Evidence
    </drdp-btn>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div>
      <p class="global-font-bold" *ngIf="!isFutureEvidence">Rating Period:
        <span class="global-font-normal">
          {{ classDetails?.ratingPeriodName }}
        </span>
      </p>
      <ng-container *ngIf="!isFutureEvidence">
        <p class="global-font-bold">Classroom: <span class="global-font-normal">{{classDetails?.classroomName}}</span></p>
        <p class="global-font-bold">Age Group/Instrument: <span class="global-font-normal">{{ageGroupInstrumentName}}</span></p>
      </ng-container>
      <p class="global-font-bold">Child/Children: <span class="global-font-normal">{{studentDetails?.firstName! + ' ' +
          studentDetails?.lastName!}}</span></p>
    </div>
    <div *ngIf="!isFutureEvidence" class="text-right mt-3">
      <drdp-btn color="outline" (click)="addToMessageBoard()" type="button">
        <i class="text-drdpblue-300 text-xl uil uil-envelope"></i>
        Add to Message Board</drdp-btn>
    </div>
  </div>
</div>

<div class="pl-5 my-5 flex flex-row justify-between gap-3">
  <div class="flex gap-3">
    <drdp-btn [color]="domainBg" type="button" (click)="handleDomain()">Domains</drdp-btn>
    <drdp-btn [color]="measuresBg" type="button" (click)="handleMeasures()">Measures</drdp-btn>
  </div>
  <a class="self-center text-sm order-last cursor-pointer" (click)="goBack()"><i
      class="uil uil-angle-left-b text-base"></i>Back to View Children</a>
</div>

<div *ngIf="showDomain">
  <div class="py-4" *ngFor="let domain of measures">
    <div class=" rounded-3xl bg-white p-3 drop-shadow-md">
      <div class="flex justify-between items-center cursor-pointer" (click)="changeDomainShow(domain.domainCode)">
        <div class="flex">
          <div class=" pl-1.5">
            <p class="text-lg font-semibold">{{domain?.domainCode}}</p>
          </div>
          <i [ngClass]="{'uil uil-angle-down text-xl': !show || selectedDomain !== domain.domainCode,
                             'uil uil-angle-right text-xl': show && selectedDomain === domain.domainCode}">
          </i>
        </div>
        <p class="text-sm justify-self-end">{{getEvidenceCount(domain, measureType.Domain)}}</p>
      </div>

      <div *ngIf="show && selectedDomain === domain.domainCode" class="flex flex-col">
        <div class=" relative">
          <drdp-portfolio-evidence-management [domainId]="domain.id" [domainGroupings]="measures" [isFutureEvidence]="isFutureEvidence"
            [evidences]="domainEvidence" [isDomain]="true" [isPortfolio]="true" (refreshEvidences)="updateEvidence()" [availableClasses]="studentActiveEnrollments" 
            [assignmentPayload]="assignmentPayload" [hasStructuredPrompt]="domain.isStructuredPrompt" [isPilot]="isPilot">
          </drdp-portfolio-evidence-management>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!showDomain">
  <div class="py-4" *ngFor="let domain of measures">
    <div class="flex items-center">
      <img [src]="'assets/images/domains/' + domain.iconName" [alt]="domain.domainCode" class="mr-2 w-8 h-8">
      <h1 class="global-font-bold">{{ domain.domainCode }}</h1>
    </div>
    <div class="py-4" *ngFor="let measure of domain.measures">
      <div class=" rounded-3xl bg-white p-3 drop-shadow-md">
        <div class="flex justify-between items-center cursor-pointer" (click)="changeMeasureShow(measure.code)">
          <div class="flex">
            <div class=" pl-1.5">
              <p class="text-lg font-semibold">{{measure.code}}</p>
            </div>
            <i [ngClass]="{'uil uil-angle-down text-xl': !show || selectedMeasure !== measure.code,
                             'uil uil-angle-right text-xl': show && selectedMeasure === measure.code}">
            </i>
          </div>
          <div class="flex flex-col w-44">
            <p class="text-sm justify-self-end">{{getEvidenceCount(measure, measureType.Measure)}}</p>
            <div *ngFor="let measureRating of measurePrelimRatings" class="flex items-center">
              <div *ngIf="measureRating.selectedLevel && measure.id === measureRating.measureId"
                class="flex items-center">
                <label class="font-normal text-sm">{{measureRating.levelName}}</label>
                <img class="w-5 h-5 ml-2 -mt-1"
                  src="assets/images/portfolio/{{measureRating.levelIcon}}-{{measureRating.selectedLevel ? measureRating.selectedLevel: 1}}.svg" />
              </div>

            </div>
          </div>

        </div>

        <div *ngIf="show && selectedMeasure === measure.code" class="flex flex-col">
          <div class=" relative">
            <drdp-portfolio-evidence-management [measureId]="measure.id" [domainGroupings]="measures" [isFutureEvidence]="isFutureEvidence"
              [evidences]="measureEvidence" [ageGradeId]="ageGradeId" [isDomain]="false" [isPortfolio]="true"
              [description]="measure.description" (prelimRatingChange)="updateEvidencePrelimRating($event)" (refreshEvidences)="updateEvidence()" [availableClasses]="studentActiveEnrollments" 
              [assignmentPayload]="assignmentPayload" [hasStructuredPrompt]="measure.isStructuredPrompt" [isPilot]="isPilot">
            </drdp-portfolio-evidence-management>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
