import { inject, Injectable } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { IAgencyRatingReviewsRequest, IChildRatingsRequest, IReportUsageRequest } from '@views/user/reports/interfaces/iadmin-report-tracking';
import { IRatingCompletionChildrenSearch, IRatingCompletionSearch } from '@views/user/reports/interfaces/irating-completion-search';
import { IDistrictOsepSearch, IReportSearch, IStateOsepSearch } from '@views/user/reports/interfaces/ireport-search';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _dataService = inject(DataService);

  generateParentProgressReport(payload: IReportSearch) {
    return this._dataService.post(`reports/parent-progress?culture=${payload.locale}`, payload);
  }

  generateParentProgressReportPdf(payload: IReportSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/parent-progress/download?culture=${payload.locale}`, payload, options);
  }

  generateChildProgressReport(payload: IReportSearch) {
    return this._dataService.post(`reports/child-progress?culture=${payload.locale}`, payload);
  }

  generateChildProgressReportPdf(payload: IReportSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/child-progress/download?culture=${payload.locale}`, payload, options);
  }

  generateClassPlanningReport(payload: IReportSearch) {
    return this._dataService.post(`reports/class-planning?culture=${payload.locale}`, payload);
  }

  generateClassPlanningReportPdf(payload: IReportSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/class-planning/download?culture=${payload.locale}`, payload, options);
  }

  generateGroupDetailReport(payload: IReportSearch) {
    return this._dataService.post(`reports/group-detail?culture=${payload.locale}`, payload);
  }

  generateGroupDetailReportPdf(payload: IReportSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/group-detail/download?culture=${payload.locale}`, payload, options);
  }

  generateGroupReport(payload: IReportSearch) {
    return this._dataService.post(`reports/group?culture=${payload.locale}`, payload);
  }

  generateGroupReportPdf(payload: IReportSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/group/download?culture=${payload.locale}`, payload, options);
  }

  generateCohortProgressReport(payload: IReportSearch) {
    return this._dataService.post(`reports/cohort-progress?culture=${payload.locale}`, payload);
  }

  generateCohortProgressReportPdf(payload: IReportSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/cohort-progress/download?culture=${payload.locale}`, payload, options);
  }

  generateRatingCompletionReport(payload: IRatingCompletionSearch) {
    return this._dataService.post(`reports/rating-completion?`, payload);
	}

  getRatingCompletionChildren(payload: IRatingCompletionChildrenSearch) {
    return this._dataService.post(`reports/rating-completion-children?`, payload);
  }

  getReportUsage(payload: IReportUsageRequest) {
    return this._dataService.postWithOptions(`reports/report-usage?`, payload, { responseType: 'blob' });
  }

  getAgencyRatingViews(payload: IAgencyRatingReviewsRequest) {
    return this._dataService.postWithOptions(`reports/agency-rating-views?`, payload, {
      observe: 'response',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    });
  }

  getChildRatings(payload: IChildRatingsRequest) {
    return this._dataService.postWithOptions(`reports/child-ratings?`, payload, {
      observe: 'response',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    });
  }

  generateDistrictOsepReport(payload: IDistrictOsepSearch) {
    return this._dataService.post(`reports/district-osep?culture=${payload.locale}`, payload);
  }

  generateDistrictOsepReportPdf(payload: IStateOsepSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/district-osep/download?culture=${payload.locale}`, payload, options);
  }

  generateStateOsepReport(payload: IDistrictOsepSearch) {
    return this._dataService.post(`reports/state-osep?culture=${payload.locale}`, payload);
  }

  generateStateOsepReportPdf(payload: IStateOsepSearch) {
    const options = { responseType: 'blob' };
    return this._dataService.postWithOptions(`reports/state-osep/download?culture=${payload.locale}`, payload, options);
  }

  generateStateOsepReportDetail(payload: IStateOsepSearch) {
    return this._dataService.postWithOptions(`reports/state-detail?culture=${payload.locale}`, payload, {
      observe: 'response',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    });
  }

  getTeacherSurveyResults(payload: IReportSearch) {
    return this._dataService.postWithOptions(`reports/teacher-survey-results`, payload, {
      observe: 'response',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    });
  }

  saveStateGroupDetailRequest(payload: IReportSearch): Observable<boolean> {
    return this._dataService.post(`reports/state-group-detail`, payload);
  }

  saveStateCohortRequest(payload: IReportSearch): Observable<boolean> {
    return this._dataService.post(`reports/state-cohort`, payload);
  }

  generateOSEPExceptionReport(payload: IStateOsepSearch) {
    return this._dataService.postWithOptions(`reports/osep-exception`, payload, {
      observe: 'response',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    });  
  }
}
