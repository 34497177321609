import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from '@core/enums/modal';
import { Permission } from '@core/enums/permissions';
import { IUser } from '@core/interfaces/iuser';
import { IVendorRequest, IVendor } from '@core/interfaces/ivendor';
import { ToastService } from '@core/services/toast.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { map, take, tap } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { VendorService } from '@core/services/vendor.service';

@Component({
  selector: 'drdp-add-edit-integration',
  templateUrl: './add-edit-integration.component.html',
  styleUrls: ['./add-edit-integration.component.scss'],
})
export class AddEditIntegrationComponent implements OnInit {
  vendorForm: FormGroup | any;
  currentVendor?: IVendor;
  currentUser?: IUser;
  label = 'Add Vendor';
  API_Key: string | null = '';
  public get Permission() {
    return Permission;
  }

  get name() {
    return this.vendorForm.get('name');
  }
  get id() {
    return this.vendorForm.get('id');
  }
  get isVisible() {
    return this.vendorForm.get('isVisible');
  }
  get emailName() {
    return this.vendorForm.get('emailName');
  }
  get phoneNumber() {
    return this.vendorForm.get('phoneNumber');
  }
  get permissions_hasSingleScore() {
    return this.vendorForm.get('permissions_hasSingleScore');
  }
  get permissions_hasAgencyExtract() {
    return this.vendorForm.get('permissions_hasAgencyExtract');
  }
  constructor(
    private fb: FormBuilder,
    private vendorService: VendorService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    public modal: MatDialog,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(): void {
    this.vendorForm = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(50)]],
      id: [null],
      isVisible: [null],
      emailName: [
        null,
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      phoneNumber: [null, Validators.maxLength(12)],
      permissions_hasSingleScore: [null],
      permissions_hasAgencyExtract: [null],
    });

    this.route.paramMap.subscribe((params) => {
      const currentId = params.get('id');
      if (currentId) {
        this.label = 'Edit Vendor';
        this.getVendorById(+currentId);
      }
    });
  }

  getVendorById(id: number): void {
    this.vendorService
      .getVendor(id)
      .pipe(
        take(1),
        tap((res: IVendor) => {
          this.currentVendor = res;
          this.vendorForm.patchValue({
            ...res,
          });

          if (this.currentVendor.user) {
            this.vendorForm.patchValue({
              emailName: this.currentVendor.user.emailName,
              phoneNumber: this.currentVendor.user.phoneNumber,
            });
          }
        })
      )
      .subscribe();
  }

  generateAPIKey(): void {
    if (this.vendorForm.invalid) {
      this.toastService.error(
        'Please complete form before generating api key.'
      );
      return;
    }
    if (this.currentVendor) {
      Object.assign(this.currentVendor, this.vendorForm.value);
      const payload: IVendorRequest = {
        ...this.currentVendor,
      };
      this.vendorService
        .generateKey(payload)
        .pipe(
          take(1),
          map((res: any) => {
            const { rawKey } = res;
            this.API_Key = rawKey;
            return res;
          }),
          tap((res: any) => {
            var event = { data: { modalInfo: {} } };
            event.data.modalInfo = {
              title: 'Vendor API Key',
              message: `Here is your key. Please copy this key now. <br> <br> <p class="underline">${this.API_Key}</p>`,
              primaryBtnClass: 'blue',
              primaryBtnText: Modal.Copy,
            };

            const modalRef = this.modal.open(ConfirmationModalComponent, {
              data: event ? event : undefined,
            });
            modalRef.afterClosed().subscribe((res: any) => {
              if (res && this.API_Key) {
                this.clipboard.copy(this.API_Key);
                this.toastService.success('API Key copied to Clipboard');
              }
            });
          })
        )
        .subscribe();
    }
  }

  submit(): void {
    if (this.vendorForm.invalid) {
      this.toastService.error('Please complete form.');
      return;
    }

    let payload: IVendorRequest = {
      ...this.vendorForm.value,
    };

    if (this.id.value) {
      payload.id = this.id.value;
    }

    this.vendorService
      .saveVendor(payload, 'Vendor Saved.')
      .pipe(
        (take(1),
        tap((res: boolean) => {
          this.router.navigate(['/config/integrations']);
        }))
      )
      .subscribe();
  }
}
