import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { IIepPlan } from '@core/interfaces/iiep-plan';
@Component({
  selector: 'drdp-select-iep-plan',
  templateUrl: './select-iep-plan.component.html',
  styleUrls: ['./select-iep-plan.component.scss'],
})
export class SelectIepPlanComponent implements OnInit {
  @ViewChild('iepSelect') iepSelect: FilterSelectComponent | undefined;
  @Output() optionSelected = new EventEmitter<any>();
  @Input() initialId?: number | null;
  @Input() initialIds?: number[] | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string =
    'Does this child have an Individualized Education Plan (IEP) or an Individual Family Service Plan (IFSP)?';
  selectOptions?: IIepPlan[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private lookupService: LookupService) {}
  ngOnInit(): void {
    this.getIepPlans();
  }
  getIepPlans() {
    this.lookupService
      .getIepPlans()
      .pipe(take(1))
      .subscribe((options: IIepPlan[]) => {
        this.selectOptions = options;
      });
  }
  onSelect(event: IIepPlan): void {
    this.optionSelected.emit(event);
  }
}
