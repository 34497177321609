<div class="modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">Create Name and Description</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle ml-8"></i>
  </div>
  <div class="mat-dialog-content">
    <form [formGroup]="reassignForm">
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required" for="title">Title</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input placeholder="" matInput type="text" formControlName="title">
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required" for="description">Description</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input placeholder="" matInput type="text" formControlName="description">
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <div class="flex justify-end align-bottom">
      <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close>Cancel</drdp-btn>
      <drdp-btn type="submit" mat-button (click)="onSubmit()" [disabled]="reassignForm.invalid"
        cdkFocusInitial>Save</drdp-btn>
    </div>
  </div>
</div>