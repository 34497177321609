<div class="p-5">
  <div>
    <drdp-title title="Input Ratings"></drdp-title>
    <div>
      <p>Always refer to the DRDP when rating the measures. </p>
    </div>
  </div>

  <drdp-container additionClasses="w-full mt-4">
    <drdp-container color="lightBlue">
      <form [formGroup]="searchForm" #ngForm="ngForm">
        <div
          class="flex grid grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-cols-5 p-2 gap-2 md:gap-3 flex-row content-center">
          <div class="col-span-1 self-end">
            <drdp-select-state [initialStateId]="stateId.value" (state)="handleState($event)" [required]="true"
              [clear]="clearDropdown" [disabled]="!isSuperUser" [invalid]="true" #stateSelect>
            </drdp-select-state>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-agency (agency)="handleChosenAgency($event)" [stateId]="stateId.value"
              [initialAgencyId]="initAgencyId" [required]="true" [clear]="clearDropdown" [disabled]="!isSuperUser" #agencySelect>
            </drdp-select-agency>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-rating-period [initialRatingPeriodId]="ratingPeriodId.value" (ratingPeriod)="handleChosenRatingPeriod($event)" [agencyId]="agencyId.value"
              [clear]="clearDropdown" [required]="true" [omitFutureRps]="true">
            </drdp-select-rating-period>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-site [initialSiteId]="siteId.value" (site)="handleChosenSite($event)" [agencyId]="agencyId.value" [multiSelect]="false"
              [required]="true" [multiSelectInitVals]="false" [clear]="clearDropdown" #sitesSelect>
            </drdp-select-site>
          </div>

          <div class="col-span-1 self-end">
            <drdp-filter-select #classroomSelect [initialValues]="classId.value" label="Classroom" [options]="classroomList" [selectType]="select.Class"
              [required]="true" (onSelected)="handleChosenClassroom($event)">
            </drdp-filter-select>
          </div>

          <div class="col-span-1 self-end">
            <drdp-select-age-grade #ageGroupInstrumentSelect [initialageGradeId]="ageGradeInstrumentId.value" [stateId]="stateId.value" label="Age Group/Instrument"
              [multiSelect]="false" [required]="true" [isPilot]="isPilot" (ageGrade)="handleAgeGroupInstrument($event)" [clear]="clearDropdown">
            </drdp-select-age-grade>
          </div>
          <div class="hidden lg:block lg:col-span-3 xl:col-span-3 self-end"></div>
          <div class="flex flex-row justify-evenly lg:col-span-2 xl:col-span-1 md:self-end xl:my-auto mt-2 md:mt-0">
            <div class="col-span-1">
              <drdp-btn type="submit">
                <span class="px-2" (click)="search()">Search</span>
              </drdp-btn>
            </div>
            <div class="col-span-1">
              <drdp-btn color="outline" (click)="clear()"> Clear </drdp-btn>
            </div>
          </div>
        </div>
      </form>
    </drdp-container>
    <div class="flex justify-end mt-4">
      <div>
        <drdp-btn *ngIf=" canLock && canLockRatings" (click)="lockClass()" color="outline"> <i
            class="text-drdpblue-300 text-xl uil uil uil-lock"></i>Lock</drdp-btn>

        <drdp-btn *ngIf="canUnlock && canUnlockRatings" (click)="unlockClass()" color="outline"> <i
            class="text-drdpblue-300 text-xl uil uil uil-unlock"></i>
          Unlock</drdp-btn>
      </div>
    </div>

    <div>
      <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
        (actionIconClicked)="onIconClick($event)">
      </drdp-add-edit-table>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData"
        [pageIndex]="pageIndex" (page)="paginateRatings()">
      </mat-paginator>
    </div>
  </drdp-container>
</div>
