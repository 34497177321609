import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {  Subscription, switchMap, take, tap } from 'rxjs';
import { SelectRatingModalComponent } from './components/select-rating-modal/select-rating-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { RatingService } from '@core/services/rating.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Permission } from '@core/enums/permissions';
import { IRatingDomainDto } from './interfaces/irating-domain';
import { IStudentEnrollmentInfo } from './interfaces/istudent-enrollment-info';
import { IRatingMeasureDto } from './interfaces/irating-measure';
import { ToastService } from '@core/services/toast.service';
import { LookupService } from '@core/services/lookup.service';
import { AssessmentDateModalComponent } from './components/assessment-date-modal/assessment-date-modal.component';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Modal } from '@core/enums/modal';
import { AgeGradeInstrumentCode } from '@core/enums/age-grade-instruments';
import { MeasureCondition } from '@core/enums/measure-condition';
import { IRatingReason } from './interfaces/irating-reason';
import { InputRatingAction } from '@core/enums/input-rating-action';
import { CanComponentDeactivate } from '@core/guards/can-deactivate.guard';
import { ITeacherSurvey, ITeacherSurveyRequest } from './interfaces/iteacher-survey';
import { TeacherSurveyModalComponent } from './components/teacher-survey-modal/teacher-survey-modal.component';
@Component({
  selector: 'drdp-input-rating',
  templateUrl: './input-rating.component.html',
  styleUrls: ['./input-rating.component.scss'],
})
export class InputRatingComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private subscriptions = new Subscription();

  id = 0;
  studentInfo!: IStudentEnrollmentInfo;
  studentDomains: IRatingDomainDto[] = [];
  hasMeasures = false;
  canLock = false;
  isKinder = false;
  touchedMeasures: IRatingMeasureDto[] = [];
  carReasons: IRatingReason[] = [];
  previousIndex!: number;
  nextIndex!: number;
  studentIds?: number[];
  isScantron = false;
  title = 'Rating Record';
  link = 'Go to Input Ratings';
  isFormDirty = false;
  isSaving = false;
  isPilotEnrollment = false;
  age: number = 0;

  public get Permission() {
    return Permission;
  }

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private studentClassEnrollmentService: StudentClassEnrollmentService,
    private ratingService: RatingService,
    private lookupService: LookupService,
    private toast: ToastService
  ) {}


  canDeactivate() {
    return !this.isFormDirty;
  }


  ngOnInit(): void {
    this.subscriptions.add(
      this.route.params.subscribe((params: any) => {
        this.id = Number(params.id);
        this.initializeData();
      })
    );
  }

  initializeData() {
    this.studentClassEnrollmentService
      .getStudentClassInfo(this.id)
      .pipe(
        take(1),
        switchMap((result: IStudentEnrollmentInfo) => {
          this.studentInfo = result;
          this.calculateStudentAge();
          this.isPilotEnrollment = this.studentInfo.isPilotEnrollment;
          this.studentInfo.templateUrl =
            result.templateLink == null
              ? null
              : this.sanitizer.bypassSecurityTrustUrl(
                  result.templateLink || ''
                );

          const templateId = this.studentInfo.isCustom
            ? this.studentInfo.parentTemplateId
            : this.studentInfo.templateId;

          this.isKinder =
            this.studentInfo.ageGradeInstrumentCode ==
            AgeGradeInstrumentCode.kindergarten;

          if (this.isKinder) {
            this.getReasons();
          }

          return this.ratingService.getStudentMeasuresAndRatingsScantron(
            result.templateId,
            templateId,
            this.id,
            result.carId
          );
        }),
        take(1)
      )
      .subscribe((data: IRatingDomainDto[]) => {
        this.setPreviousAndNext(this.id);
        this.studentDomains = data;
        this.hasMeasures = data && data.length > 0;
        this.canLockRatings();
      });
  }

  calculateStudentAge(): void {
    if (this.studentInfo.dob && this.studentInfo?.enrollmentDate) {
      const dob = new Date(this.studentInfo.dob);
      const enrollmentDate = new Date(this.studentInfo.enrollmentDate);
      let age = enrollmentDate.getFullYear() - dob.getFullYear();
      const month = enrollmentDate.getMonth() - dob.getMonth();
      const day = enrollmentDate.getDate() - dob.getDate();

      if (month < 0 || (month === 0 && day < 0)) {
        age--;
      }
      this.age = age;
    }
  }

  setPreviousAndNext(enrollmentId: number) {
    this.studentIds = this.ratingService.getStudentResultIds();
    if (!this.studentIds) {
      this.getStudentResults(enrollmentId);
      return;
    }
    const currentIndex = this.studentIds.indexOf(enrollmentId);
    if (currentIndex > -1) {
      this.previousIndex = currentIndex - 1;
      this.nextIndex = currentIndex + 1;
      this.ratingService.setStudentNextIndex(this.nextIndex);
      this.ratingService.setStudentPreviousIndex(this.previousIndex);
    } else {
      this.getStudentResults(enrollmentId);
    }
  }

  getStudentResults(enrollmentId: number) {
    const payload = {
      classId: this.studentInfo.classId,
      agencyId: this.studentInfo.agencyId,
      ageGradeInstrumentId: this.studentInfo.ageGradeInstrumentId,
      pageIndex: 0,
      itemsPerPage: 10,
    };

    this.ratingService.getClassRatings(payload).subscribe((res: any) => {
      if (res) {
        this.ratingService.setStudentResultIds(res.allItemIds);
        this.setPreviousAndNext(enrollmentId);
      }
    });
  }

  getReasons() {
    this.lookupService
      .getCarReasons()
      .pipe(take(1))
      .subscribe((reasons: IRatingReason[]) => (this.carReasons = reasons));
  }

  selectRatingModal(data: any) {
    this.selectRating(data.domain, data.measure, data.teacherSurveyReq ?? null);
  }

  selectRating(domain: IRatingDomainDto, measure: IRatingMeasureDto, teacherSurvey?: ITeacherSurveyRequest): void {
    if (this.studentInfo.isLocked) return;
    measure.templateId = this.studentInfo.isCustom
      ? this.studentInfo.parentTemplateId
      : this.studentInfo.templateId;
    measure.reasons = this.carReasons;
    measure.ageGradeInstrumentId = this.studentInfo.ageGradeInstrumentId;
    measure.ageGradeInstrumentCode = this.studentInfo.ageGradeInstrumentCode;
    measure.enrollmentId = this.id;
    measure.isPilotEnrollment = this.isPilotEnrollment;
    measure.age = this.age;
    const modalRef = this.dialog.open(SelectRatingModalComponent, {
      data: measure,
    });

    modalRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isFormDirty = true;
        measure.ratingValue =
          result?.measure?.selectedValue ??
          (result.measure.measureConditionId > 0 ? null : measure.ratingValue);

        if (teacherSurvey && this.isPilotEnrollment) {
          this.openTeacherSurvey(teacherSurvey, domain, measure);
        } else {
          this.saveRating(false, measure);
          this.calculateDomainTotal(domain);
          this.canLockRatings();
        }
      }
    });
    
  }
  
  openTeacherSurvey(data: ITeacherSurveyRequest, domain?: IRatingDomainDto, measure?: IRatingMeasureDto,) {
    const modalRef = this.dialog.open(TeacherSurveyModalComponent, {
      data,
      disableClose: true
    });
    
    modalRef.afterClosed().subscribe((request: any) => {
      const { payload } = request;
      if (payload) {
        if (domain && measure) {
          this.saveRating(false, measure, payload);
          this.calculateDomainTotal(domain);
          this.canLockRatings();
        } else {
          this.ratingService.saveTeacherSurvey(payload).pipe(
            take(1),
            tap((success: boolean) => {
              if (success) {
                this.toast.success('Teacher Survey successfully saved!');
              }
            })
          ).subscribe();
        }
      }
    });
  }

  viewTeacherSurvey(data: ITeacherSurveyRequest, domain?: IRatingDomainDto, measure?: IRatingMeasureDto,) {
    data.viewOnly = true;
    const modalRef = this.dialog.open(TeacherSurveyModalComponent, {
      data
    });
  }

  processMeasureUpdate(event: {
    domain: IRatingDomainDto;
    measure: IRatingMeasureDto;
  }) {
    this.isFormDirty = true;
    const index = this.touchedMeasures.findIndex(
      (item) => item.id === event.measure.id
    );
    if (index !== -1) {
      this.touchedMeasures.splice(index, 1);
    }
    this.touchedMeasures.push(event.measure);

    this.calculateDomainTotal(event.domain);
    this.canLockRatings();
  }

  openLockModal() {
    const data = {
      assessmentDate: this.studentInfo.assessmentDate,
      ratingPeriodId: this.studentInfo.ratingPeriodId,
      minDate: this.studentInfo.minRatingPeriodDate,
      maxDate: this.studentInfo.maxRatingPeriodDate,
    };
    const modalRef = this.dialog.open(AssessmentDateModalComponent, {
      data: data,
    });

    modalRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.studentInfo.assessmentDate = result.assessmentDate;
        this.saveRating(true);
      }
    });
  }

  calculateDomainTotal(domain: IRatingDomainDto) {
    domain.measureRatingCount = domain.measures.filter(
      (m) => !!m.ratingValue || !!m.measureConditionId
    ).length;
    domain.domainTotal = `${domain.measureRatingCount}/${domain.measureTotalCount}`;
  }

  canLockRatings() {
    this.canLock =
      this.studentDomains.filter(
        (d) => d.measureRatingCount != d.measureTotalCount
      ).length == 0;
  }

  unlockRating() {
    let event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: `Unlock Rating`,
      message: `Are you sure you want to Unlock the ratings?`,
      primaryBtnClass: 'blue',
      primaryBtnText: Modal.Confirm,
      showCancel: true,
    };
    const modalRef = this.dialog.open(ConfirmationModalComponent, {
      data: event,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res && this.studentInfo.carId) {
        this.ratingService
          .unlockRating(this.studentInfo.carId)
          .pipe(take(1))
          .subscribe((_) => {
            this.studentInfo.isLocked = false;
            this.studentInfo.lockingDate = null;
          });
      }
    });
  }

  isValidRatings(measure?: IRatingMeasureDto | null) {
    const measures: IRatingMeasureDto[] = measure ? [measure] : [...this.touchedMeasures];

    if (measures.length > 0) {
      const errorEmerging = measures.find(
        (m) =>
          m.ratingValue == null &&
          m.measureConditionId == MeasureCondition.Emerging
      );
      if (errorEmerging) {
        this.toast.error(
          `A level is required for ${errorEmerging.code} if choosing Emerging. `
        );
        return false;
      }

      if (this.isKinder) {
        const unrateableMeasure = measures.find(
          (m) =>
            m.childAssessmentRatingReasonId == null &&
            m.measureConditionId == MeasureCondition.Unratable
        );

        if (unrateableMeasure) {
          this.toast.error(
            `Please choose the reason for measures that are marked as "unable to rate.".`
          );
          return false;
        }
      }
    }
    return true;
  }


  saveRating(isLocked?: boolean, measure?: IRatingMeasureDto, teacherSurvey?: ITeacherSurvey): void {
    if (this.isSaving) return;
    if (!measure && this.touchedMeasures.length == 0 && !isLocked) {
      this.toast.error("There's no ratings to save.");
      return;
    }

    if (this.isValidRatings(measure)) {
      this.isSaving = true;
      const measures: IRatingMeasureDto[] = measure ? [measure] : [...this.touchedMeasures];
      const shapedMeasures: any[] = measures.map((m) => ({
        id: m.id,
        ratingValue: m.ratingValue,
        measureConditionId: m.measureConditionId,
        comment: m.comment,
        childAssessmentRatingReasonId: m.childAssessmentRatingReasonId,
      }));
      const request = {
        studentClassEnrollmentId: this.id,
        templateId: this.studentInfo.templateId,
        carId: this.studentInfo.carId,
        measures: shapedMeasures,
        assessmentDate: this.studentInfo.assessmentDate,
        isLocked: isLocked || false,
        measureRevisionId: measure?.measureRevisionId,
        isPilotEnrollment: this.isPilotEnrollment,
        teacherSurvey: teacherSurvey
      };

      const message = isLocked
        ? 'Ratings successfully saved and locked for this child!'
        : 'Ratings successfully saved for this child!';
      this.ratingService
        .saveChildRatings(request, message)
        .pipe(take(1))
        .subscribe((id) => {
          this.isSaving = false;
          this.isFormDirty = false;
          if (this.id) {
            this.studentInfo.carId = id;
            this.touchedMeasures.length = 0;
            if (isLocked) {
              this.studentInfo.isLocked = true;
              this.studentInfo.lockingDate = new Date();
            }
          }
        });
    }
  }

  toggleView() {
    this.isScantron = !this.isScantron;
    this.title = this.isScantron ? 'Input Ratings' : 'Rating Record';
    this.link = this.isScantron ? 'Go to Rating Record' : 'Go to Input Ratings';
  }

  onActionClick(type: InputRatingAction) {
    switch (type) {
      case InputRatingAction.Lock:
        this.lockRating();
        break;
      case InputRatingAction.Save:
        this.saveRating();
        break;
      case InputRatingAction.Unlock:
        this.unlockRating();
        break;
      case InputRatingAction.Next:
        this.goToNextStudent();
        break;
      case InputRatingAction.Previous:
        this.goToPreviousStudent();
        break;
      case InputRatingAction.Print:
        this.confirmPdfPrint();
        break;
      default:
        break;
    }
  }

  lockRating() {
    if (!this.isValidRatings()) return;
    else if (!this.canLock) {
      this.toast.error('Please enter all required ratings before locking.');
      return;
    } else if (!this.studentInfo.isRatingViewLocked) {
      let event = { data: { modalInfo: {} } };
      event.data.modalInfo = {
        title: `Error`,
        message: `Unable to lock Child Rating(s) until Agency has locked its Ratings View.`,
        primaryBtnClass: 'blue',
        primaryBtnText: Modal.OK,
      };
      this.dialog.open(ConfirmationModalComponent, {
        data: event,
      });
      return;
    }
    this.openLockModal();
  }

  generatePdf() {
    this.ratingService
      .generatePdf(this.id)
      .pipe(take(1))
      .subscribe((res) => {
        const file = new Blob([res], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        let fileName =
          this.studentInfo.studentName + ' ' + this.studentInfo.classroomName;
        link.download = `${fileName.replace(/ /g, '_')}.pdf`;
        link.click();
      });
  }

  confirmPdfPrint() {
    if (this.touchedMeasures.length == 0) {
      this.generatePdf();
    } else {
      let modal = { data: { modalInfo: {} } };
      modal.data.modalInfo = {
        title: `Confirmation`,
        message: `There are unsaved ratings.  To include them in your print request, please click cancel and then save the ratings prior to printing. `,
        primaryBtnClass: 'blue',
        primaryBtnText: 'Print',
        showCancel: true,
      };

      const modalRef = this.dialog.open(ConfirmationModalComponent, {
        data: modal,
      });

      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          this.generatePdf();
        }
      });
    }
  }

  goToNextStudent() {
    if (this.studentIds) {
      const nextStudent = this.studentIds[this.nextIndex];
      if (nextStudent) {
        this.router.navigateByUrl(`input-ratings/${nextStudent}`);
      } else {
        this.toast.error('No next student available. End of list reached. ');
      }
    }
  }

  goToPreviousStudent() {
    if (this.studentIds) {
      const prevStudent = this.studentIds[this.previousIndex];
      if (prevStudent) {
        this.router.navigateByUrl(`input-ratings/${prevStudent}`);
      } else {
        this.toast.error(
          'No previous student available. Beginning of list reached. '
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
