<div class="p-5">
  <div class="flex justify-between items-center">
    <drdp-title title="Upload Templates"></drdp-title>
  </div>

  <div class="flex flex-wrap gap-4 py-3">
    <drdp-container color="whiteDash" class="h-31 w-40">
      <button (click)="downloadTemplate(uploadTypes.user)" class="w-full pb-4">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-download"></i>
        <p class="text-[15px]" title="Download Users Templates">Upload Users</p>
        <p class="text-drdpblue-300 text-[15px]">Download</p>
      </button>
    </drdp-container>

    <drdp-container color="whiteDash" class="h-31 w-40">
      <button (click)="downloadTemplate(uploadTypes.class)" class="w-full pb-4">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-download"></i>
        <p class="text-[15px]" title="Download Class Templates">Upload Class</p>
        <p class="text-drdpblue-300 text-[15px]">Download</p>
      </button>
    </drdp-container>

    <drdp-container color="whiteDash" class="h-31 w-40">
      <button (click)="downloadTemplate(uploadTypes.student)" class="w-full pb-4">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-download"></i>
        <p class="text-[15px]" title="Download Child Templates">Upload Child</p>
        <p class="text-drdpblue-300 text-[15px]">Download</p>
      </button>
    </drdp-container>
  </div>
</div>
