import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IGlobalRatingPeriod } from '@core/interfaces/iratingperiod';
import { SelectType } from '@core/enums/select';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { take } from 'rxjs';
import { ISelectable } from '@core/interfaces/iselectable';
import { isArray } from 'lodash';
import { LookupService } from '@core/services/lookup.service';

@Component({
  selector: 'drdp-select-global-rating',
  templateUrl: './select-global-rating.component.html',
  styleUrls: ['./select-global-rating.component.scss'],
})
export class SelectGlobalRatingComponent implements OnInit {
  @ViewChild('globalRatingPeriodSelect') globalRatingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @Output() ratingPeriod = new EventEmitter<any>();
  @Input() initialRatingPeriodId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = 'Rating Period';
  @Input() schoolYearId?: number | null;
  @Input() showPilotRps?: boolean = false;
  @Input() showAll = false;
  ratingPeriodOptions?: IGlobalRatingPeriod[] = [];

  public get select() {
    return SelectType;
  }
  constructor(private ratingPeriodService: RatingPeriodService, private lookupService: LookupService) {}

  ngOnInit(): void {
    this.setDropdown();
  }

  setDropdown(): void {
    if (this.showAll) {
      this.ratingPeriodService
        .getAllGlobalRatingPeriods()
        .pipe(take(1))
        .subscribe((ratingPeriod: IGlobalRatingPeriod[]) => {
          if (this.showPilotRps) {
            ratingPeriod = ratingPeriod.filter((option:any) => option.isPilotEnabled);
          }
          this.ratingPeriodOptions = ratingPeriod;
        });
    }
    else if(!this.schoolYearId) {
      let currentSchoolYear: number;
      this.lookupService
      .getCurrentSchoolYear()
      .pipe(take(1))
      .subscribe((year: any) => {
        currentSchoolYear = year.id;
        this.getBySchoolYear(currentSchoolYear);
      });
    }
    else this.getFutureGlobalRatingPeriods();
  }

  clearSelected() {
    if (!this.disabled) this.globalRatingPeriodSelect?.clearSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.globalRatingPeriodSelect?.clearSearch(true);
    }
    else if (changes['showPilotRps']) {
      this.setDropdown();
    }
    else if (changes['schoolYearId'] && this.schoolYearId) {
      this.getBySchoolYear(this.schoolYearId);
    }
  }

  getBySchoolYear(currentSchoolYear: number): void {
    this.ratingPeriodService.getBySchoolYear(currentSchoolYear).pipe(take(1)).subscribe((ratingPeriod: any) => {
      if (this.showPilotRps) {
        ratingPeriod = ratingPeriod.filter((option:any) => option.isPilotEnabled);
      }
      this.ratingPeriodOptions = ratingPeriod;
    })
  }

  getFutureGlobalRatingPeriods(): void {
    this.ratingPeriodService
      .getFutureGlobalRatingPeriods()
      .pipe(take(1))
      .subscribe((ratingPeriod: IGlobalRatingPeriod[]) => {
        if (this.showPilotRps) {
          ratingPeriod = ratingPeriod.filter(option => option.isPilotEnabled);
        }
        this.ratingPeriodOptions = ratingPeriod;
      });
  }

  onRatingPeriodSelect(event: ISelectable | ISelectable[] | null): void {
    if (!!event && isArray(event)) {
      this.ratingPeriod.emit(this.ratingPeriodOptions?.filter(rp => event?.find(sc => sc.id === rp.id)));
    } else if (!!event) {
      const matchingOptions = this.ratingPeriodOptions?.filter(rp => event.id === rp.id) ?? [];
      this.ratingPeriod.emit(matchingOptions?.length > 0 ? matchingOptions[0] : null);
    } else {
      this.ratingPeriod.emit(null);
    }
  }
}
