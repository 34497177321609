
<div class="mx-auto flex" style="max-width:1420px!important">
    <div class="flex-col lg:flex-col lg:w-max lg:block hidden bg-drdpblue-200 m-[1%]" >
        <drdp-side-menu></drdp-side-menu>
    </div>
    <div class="flex-1 overflow-x-auto lg:flex-col lg:w-full relative">
        <div class="flex-1 h-20 ">
            <drdp-header></drdp-header>
        </div>
        <div class="flex-1 mb-5">
            <router-outlet></router-outlet>  
           
        </div>
        <div class="pt-5 lg:pt-28 -z-10">
            <drdp-footer [loggedIn]="true" class="pt-5 lg:absolute lg:inset-x-0 xs:fixed bottom-0"></drdp-footer>
        </div>
    </div>
</div>

<drdp-spinner></drdp-spinner>
