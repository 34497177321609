import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewChildTabs } from '@core/enums/table';
import { ComponentTypeTable } from '@core/enums/table';
import { Permission } from '@core/enums/permissions';
import { PermissionService } from '@core/services/permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentService } from '@core/services/student.service';
import { take } from 'rxjs';
@Component({
  selector: 'drdp-view-child-tabs',
  templateUrl: './view-child-tabs.component.html',
  styleUrls: ['./view-child-tabs.component.scss'],
})
export class ViewChildTabsComponent implements OnInit {
  activeTab?: number;
  public get Permission() {
    return Permission;
  }
  isEdit?: boolean = history.state.isEdit ?? false;
  canViewSharedChild = this.permissionService.checkPermission(
    Permission.ViewSharedChild
  );
  canEdit = false;
  @Input() childData?: any;
  buttonText: string = this.isEdit ? 'Cancel' : 'Edit';
  @Output() returnToView = new EventEmitter();
  classId: number | null = null;
  studentId: number | null = null;

  public get ViewChildTabs() {
    return ViewChildTabs;
  }
  public get ComponentTypeTable() {
    return ComponentTypeTable;
  }

  constructor(
    private permissionService: PermissionService, 
    private router: Router,
    private route: ActivatedRoute,
    private studentService: StudentService,) {}

  ngOnInit(): void {     
    const classId = this.route.snapshot.paramMap.get('classId');
    if (classId !== null) this.classId = +classId;

    const studentId = this.route.snapshot.paramMap.get('id');
    if (studentId !== null) {
      this.studentId = +studentId;
      this.getEnrollmentDetails();
    } 
    this.toggleTabs(ViewChildTabs.Demographics);
  }

  getEnrollmentDetails() {
    if (this.studentId) {
      this.studentService
      .getClassroomEnrollmentDetails(this.studentId)
      .pipe(
        take(1)
      )
      .subscribe((res: any) => {
        this.canEditChild(res);
      });
    }
  }

  canEditChild(enrollments: any) {
    const permissions = {
      edit: this.permissionService.checkPermission(Permission.EditStudents),
      bypass: this.permissionService.checkPermission(Permission.PowerUserViewDualEnrollments),
      viewDual: this.permissionService.checkPermission(Permission.ViewDualEnrollments)
    };

    if (permissions.edit && permissions.bypass) {
      this.canEdit = true;
    } else if (!permissions.edit && !permissions.bypass) { 
      this.canEdit = false;
    } else if (permissions.edit || permissions.viewDual) {
      const primaryEnrollments = enrollments.filter((item: any) => item.isDualEnrollment == false);
      this.canEdit = primaryEnrollments.length > 0 ? true : false;
    } else {
      this.canEdit = false;
    }
  }

  toggleTabs(tab: number) {
    this.activeTab = tab;
  }

  editChild() {
    this.isEdit = !this.isEdit;
    this.buttonText = !this.isEdit ? 'Edit' : 'Cancel';
  }

  backToView(event: any): void {
    this.returnToView.emit();
    this.editChild();
  }

  goBack(): void {
    this.router.navigate(['/view-students/' + this.classId]);
  }
}
