import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Modal } from '@core/enums/modal';
import { FeatureTable } from '@core/enums/table';
import { IModalEmitData } from '@core/interfaces/imodal';
import { IPermission, IPermissionGroupAssignment, IPermissionGroupAssignmentRes } from '@core/interfaces/ipermission';
import { PermissionService } from '@core/services/permission.service';
import { ToastService } from '@core/services/toast.service';
import { switchMap, take, tap } from 'rxjs';
import { ConfirmationModalComponent } from '../../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { AddEditComponent } from '../../../../../shared/components/drdp-tables/add-edit/add-edit.component';
import { FeaturesAddEditComponent } from '../features-add-edit/features-add-edit.component';
import { SuperFeaturesAddComponent } from '../super-features-add/super-features-add.component';
import { SuperFeaturesEditComponent } from '../super-features-edit/super-features-edit.component';

@Component({
  selector: 'drdp-features-tabs',
  templateUrl: './features-tabs.component.html',
  styleUrls: ['./features-tabs.component.scss'],
})
export class FeaturesTabsComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  featureTableData?: IPermission[];
  supFeatureTableData: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  uncategorized?: IPermission[];
  activeTab?: number;
  public get FeatureTable() {
    return FeatureTable;
  }

  featureTableColumns = [
    { columnDef: 'permissionName', header: 'Name', type: 'text', isSortable: true },
    { columnDef: 'permissionDescription', header: 'Description', type: 'text' },
    { columnDef: 'edit', header: 'Edit', type: 'edit' },
    { columnDef: 'delete', header: 'Delete', type: 'delete' }
  ];

  subFeatureTableColumns = [
    { columnDef: 'groupName', header: 'Super Feature', type: 'text' },
    { columnDef: 'featureList', header: 'Sub Feature', type: 'bulletList' },
    { columnDef: 'edit', header: 'Edit', type: 'edit' },
    { columnDef: 'delete', header: 'Delete', type: 'delete' }
  ];

  constructor(
    private permissionService: PermissionService,
    private toastService: ToastService,
    public modal: MatDialog
  ) {}

  ngOnInit(): void {
    this.toggleTabs(FeatureTable.Feature);
  }

  getFeatureList(): void {
    this.permissionService.getPermission().subscribe((res: IPermission[]) => {
      this.featureTableData = res.sort((a, b) =>
        a.permissionName > b.permissionName ? 1 : -1
      );

      this.initializeTableData(this.featureTableData);
    });
  }

  getFeatureGroup(): void {
    this.permissionService
      .getSuperPermissionGroups()
      .pipe(
        take(1),
        tap((res) => {
          this.supFeatureTableData = [];
          res.forEach((group: IPermissionGroupAssignmentRes) => {
            this.filterFeatures(group);
          });
        }),
        tap(() => {
          this.initializeTableData(this.supFeatureTableData);
        })
      ).subscribe();
  }

  initializeTableData(res: any): void {
    this.dataSource = new MatTableDataSource(res);
  }

  featureAction(editData?: IModalEmitData): void {
    const modalRef = this.modal.open(FeaturesAddEditComponent, {
      data: editData ? editData : undefined,
    });

    modalRef.afterClosed().subscribe((result) => {
      if (!editData && result.formValid) {
        this.addFeature(result);
      }

      if (editData && result.formValid) {
        this.updateFeature(result, editData.index);
      }
    });
  }

  editSupFeatures(editData?: IModalEmitData): void {
    const superFeatureModalData = {
      nonGroupFeatures: this.uncategorized,
      groupId: editData?.data.groupId,
    };

    const modalRef = this.modal.open(SuperFeaturesEditComponent, {
      data: superFeatureModalData ? superFeatureModalData : undefined,
    });

    modalRef.afterClosed().subscribe((result) => {
      if (editData) {
        let data = {
          groupId: editData.data.groupId,
          groupName: editData.data.groupName,
          featureList: result?.map(
            (x: { permissionName: any }) => x.permissionName
          ),
        };

        this.supFeatureTableData[editData.index] = data;
        this.initializeTableData(this.supFeatureTableData);
      }
    });
  }

  addFeature(featuresService: any): void {
    this.permissionService
      .addPermission(featuresService.payload, 'Feature Added')
      .pipe(
        switchMap((_) =>
          this.permissionService.getPermission().pipe(
            tap((permissions) => {
              this.featureTableData = permissions;
              this.initializeTableData(this.featureTableData);
            })
          )
        )
      )
      .subscribe();
  }

  addSuperFeature(): void {
    const modalRef = this.modal.open(SuperFeaturesAddComponent);

    modalRef.afterClosed().subscribe((result) => {
      if (result.formValid) {
        this.permissionService.addPermissionGroupAssignment(result.payload, 'Super Feature Added')
        .pipe(
          take(1),
          tap((res: boolean) => {
            if (res) this.getFeatureGroup();
            else this.toastService.warn('Must add sub-features.')
          })
        ).subscribe();
      }
    });
  }

  updateFeature(feature: any, index: number): void {
    this.permissionService
      .updatePermission(feature.payload, 'Updated Feature')
      .subscribe((res: boolean) => {
        if (res && this.featureTableData) {
          this.featureTableData[index] = feature.payload;
          this.table?.reRender(this.featureTableData);
        }
      });
  }

  deleteFeature(event: IModalEmitData): void {
    event.data.modalInfo = {
      title: `Delete ${event.data.permissionName}`,
      message: `Are you sure you want to want to delete <span class="font-bold">${event.data.permissionName}</span>?`,
      name: event.data.permissionName,
      primaryBtnClass: 'red',
      primaryBtnText: Modal.Delete
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.permissionService
          .deletePermission(event.data.id, 'Feature Removed')
          .pipe(
            tap((res) => {
              this.featureTableData?.splice(event.index, 1);
              this.initializeTableData(this.featureTableData);
            })
          )
          .subscribe();
      }
    });
  }

  deleteSupFeature(event: IModalEmitData): void {
    event.data.modalInfo = {
      title: `Delete ${event.data.groupName}`,
      message: `Are you sure you want to want to delete <span class="font-bold">${event.data.groupName}</span>?`,
      name: event.data.groupName,
      primaryBtnClass: 'red',
      primaryBtnText: Modal.Delete,
      showCancel: true
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        const {data} = event;
        const payload: IPermissionGroupAssignment = {
          superPermissionId: data.groupId
        };

        this.permissionService
          .deletePermissionGroupAssignment(payload, 'Super Feature Removed')
          .pipe(
            tap(() => {
              this.supFeatureTableData?.splice(event.index, 1);
              this.initializeTableData(this.supFeatureTableData);
            })
          )
          .subscribe();
      }
    });
  }

  filterFeatures(permissionGroup: IPermissionGroupAssignmentRes): void {
    let tableData = {
      groupId: permissionGroup.superPermission.id,
      groupName: permissionGroup.superPermission.permissionName,
      featureList: permissionGroup.subPermissions?.map((x) => x.permissionName),
    };
    this.supFeatureTableData.push(tableData);
  }

  toggleTabs(tab: number) {
    this.activeTab = tab;
    if (this.activeTab === FeatureTable.Feature) {
      this.getFeatureList();
    } else if (this.activeTab === FeatureTable.FeatureHierarchy) {
      this.getFeatureGroup();
    }
  }
}
