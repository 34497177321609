<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">
      Batch Special Education Exit Date
    </h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <div class="mt-4"> The Special Education Exit Date can only be added for children with locked ratings, is <br>
      noted as a child with an IEP and with a Special Education Entry Date, and has a District <br> 
      of Liability chosen on child demographics.  If the Exit Date has already been chosen and <br> 
      processed, you are unable to reset. The Exit Date must fall within the current enrollment <br>
      Rating Period. If the Exit Date needs to be a past date that falls within a past Rating Period, <br>
      please go to that Rating Period Classroom's page. <br>
      Only the following children will be updated: </div>
    <div class="mt-2">
      <ul>
        <li *ngFor="let student of students">
          - <strong>{{ student.firstName }} {{ student.lastName }}</strong> - (SPED Entry Date: {{ student.spedEntryDate
          ? (student.spedEntryDate | date: 'MM/dd/yyyy') : 'N/A'}})
        </li>
      </ul>
    </div>
    <form [formGroup]="spedExitDateForm">
      <div class="relative">
        <div class="mt-2">
          <drdp-date-picker label="Special Education Exit Date" [isRequired]="true" [minDate]="minSpedEntryDate"
            (emitDate)="handleDate($event)">
          </drdp-date-picker>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions class="flex justify-end">
  <drdp-btn color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
  <drdp-btn class="ml-3" mat-button [disabled]="spedExitDateForm.invalid || isProcessing" cdkFocusInitial
    (click)="onSubmit()">Save
  </drdp-btn>
</div>
