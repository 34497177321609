import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectType } from '@core/enums/select';
import { UserService } from '@core/services/user.service';
import { take } from 'rxjs';
import { IPortfolioUser } from '@core/interfaces/iportfolio-user';

@Component({
  selector: 'drdp-select-portfolio-users',
  templateUrl: './select-portfolio-users.component.html',
  styleUrls: ['./select-portfolio-users.component.scss']
})
export class SelectPortfolioUsersComponent implements OnInit {
  @ViewChild('portfolioUsersSelect') portfolioUsersSelect: FilterSelectComponent | undefined;
  @Output() portfolioUsers = new EventEmitter<any>();
  @Input() sceId: number = 0;
  @Input() initialPortfolioUserId?: number | null;
  @Input() initialPortfolioUserIds?: number[] | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() isValid = true;
  @Input() required = false;
  @Input() label = 'Recipient';
  portfolioUsersOptions?: IPortfolioUser[] = [];
  public get select() {
    return SelectType;
  }

  constructor(
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.sceId && this.sceId > 0)
      this.getPortfolioMessageUsers(this.sceId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.portfolioUsersSelect?.clearAll();
      } else {
        this.portfolioUsersSelect?.clearSearch(true);
      }
    }

    if (this.sceId) {
      this.getPortfolioMessageUsers(this.sceId);
    }
  }

  clearSelected() {
    this.portfolioUsersSelect?.clearSearch(true);
  }

  getPortfolioMessageUsers(sceId: number): void {

    this.userService.getPortfolioMessageUsers(sceId).pipe(take(1)).subscribe((users: IPortfolioUser[]) => {
      this.portfolioUsersOptions = users;
      if (this.initialPortfolioUserId) {
        const selected = this.portfolioUsersOptions!.filter((user: IPortfolioUser) =>
          this.initialPortfolioUserId === user.id
        );
        this.portfolioUsers.emit(selected);
      }
    });
  }

  onPortfolioUserSelect(event: IPortfolioUser): void {
    this.portfolioUsers.emit(event);
  }
}
