import { Component, OnInit, Inject, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RatingService } from '@core/services/rating.service';
import { take, tap } from 'rxjs';
import { IRatingMeasureDto } from '../../interfaces/irating-measure';
import { MeasureCondition } from '@core/enums/measure-condition';
import {
  IPortfolioEvidenceResponse,
  IPortfolioRatingSheet,
} from '@core/interfaces/iportfolio';
import { PortfolioService } from '@core/services/portfolio.service';
import { PortfolioEvidenceManagementComponent } from '@shared/components/portfolio-evidence-management/portfolio-evidence-management.component';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'drdp-select-rating-modal',
  templateUrl: './select-rating-modal.component.html',
  styleUrls: ['./select-rating-modal.component.scss'],
})
export class SelectRatingModalComponent implements OnInit {
  @ViewChild('evidenceManagement') evidenceManagement: PortfolioEvidenceManagementComponent | undefined;
  ratingSheet: IPortfolioRatingSheet = {
    levelCount: 0,
    measureRatingLevel: null,
    canEmerging: false,
    measureConditionId: null,
    measureConditionName: null,
    selectedValue: null,
    reasonId: null,
    comment: null,
    showComment: false,
  };
  measureEvidence?: IPortfolioEvidenceResponse[];
  ageGradeId?: number;
  dialog = inject(MatDialog);

  constructor(
    private ratingService: RatingService,
    private portfolioService: PortfolioService,
    public dialogRef: MatDialogRef<SelectRatingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IRatingMeasureDto
  ) {}

  public get measureCondition() {
    return MeasureCondition;
  }

  ngOnInit(): void {
    this.getMeasureEvidence();
    this.ratingSheet.selectedValue =
      this.dialogData.ratingValue || this.dialogData.preRatingValue;
    this.ratingSheet.measureConditionId = this.dialogData.measureConditionId;
    this.ratingSheet.measureConditionName =
      this.dialogData.measureConditionName;
    this.ratingSheet.reasonId = this.dialogData.childAssessmentRatingReasonId;
    this.ratingSheet.comment = this.dialogData.comment;
    this.ratingSheet.showComment =
      this.dialogData.reasons?.find((r) => r.id == this.ratingSheet.reasonId)
        ?.reasonComment == 'Other';

    this.ratingService
      .getMeasureLevels(this.dialogData.templateId, this.dialogData.id)
      .pipe(
        take(1),
        tap((results) => {
          this.ratingSheet.measureRatingLevel = results;
          this.ratingSheet.levelCount = results?.levels?.length || 0;
          if (this.ratingSheet.selectedValue) {
            this.ratingSheet.canEmerging =
              this.ratingSheet.selectedValue !=
              (this.ratingSheet.measureRatingLevel?.maxLevel ?? 0);
          } else if (this.ratingSheet.measureConditionId) {
            this.ratingSheet.canEmerging =
              this.ratingSheet.measureConditionId == 0 ||
              this.ratingSheet.measureConditionId == MeasureCondition.Emerging;
          }

          if (this.ratingSheet.levelCount > 0) {
            this.ratingSheet.measureRatingLevel.conditionLevels =
              results.levels.filter((l) => l.isCondition);
            this.ratingSheet.measureRatingLevel.developmentalLevels =
              results.levels.filter((l) => !l.isCondition);

            this.ratingSheet.measureRatingLevel.EM =
              this.ratingSheet.measureRatingLevel.conditionLevels.find(
                (c) => c.id == MeasureCondition.Emerging
              );

            this.ratingSheet.measureRatingLevel.notYet =
              this.ratingSheet.measureRatingLevel.conditionLevels.find(
                (c) => c.id == MeasureCondition.NotYet
              );

            this.ratingSheet.measureRatingLevel.conditional =
              this.ratingSheet.measureRatingLevel.conditionLevels.find(
                (c) => c.id == MeasureCondition.Conditional
              );

            this.ratingSheet.measureRatingLevel.UR =
              this.ratingSheet.measureRatingLevel.conditionLevels.find(
                (c) => c.id == MeasureCondition.Unratable
              );
          }
        })
      )
      .subscribe();
  }

  getMeasureEvidence(): void {
    this.measureEvidence = [];
    if (this.dialogData) {
      this.portfolioService
        .getMeasureEvidences(
          this.dialogData.enrollmentId,
          this.dialogData.ageGradeInstrumentId
        )
        .pipe(
          take(1),
          tap(
            (res: IPortfolioEvidenceResponse[]) => (this.measureEvidence = res)
          )
        )
        .subscribe();
    }
  }

  onSubmit(event: any): any {
    this.dialogData = event;
    this.dialogRef.close({ measure: this.dialogData });
  }

  saveRating(): void {
    this.evidenceManagement?.emitSave();
  }
}
