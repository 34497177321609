<div class="p-5">
  <div class="flex justify-between">
    <drdp-title [title]="pageTitle"></drdp-title>
  </div>
  <drdp-container additionClasses="mt-5">
    <div>
      <h2 class="text-[20px] font-bold mb-2">Add A New Base Template</h2>
      <hr>
    </div>
    <form [formGroup]="customViewForm" #ngForm="ngForm">
      <div class="form-input-label-group">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="title">Title</label>
        <mat-form-field appearance="fill">
          <input class="display" placeholder="Title" matInput type="text" [readonly]="isView" formControlName="title"
            required>
          <mat-error *ngIf="title.invalid">Title is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="form-input-label-group">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="description">Description</label>
        <mat-form-field appearance="fill">
          <input class="display" placeholder="Description" matInput type="text" [readonly]="isView"
            formControlName="description" required>
          <mat-error *ngIf="description.invalid">Description is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="form-input-label-group" *ngIf="!isView">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="ageGradeInstrumentId">Age Group</label>
        <mat-form-field appearance="fill" class="drdp-input">
          <mat-select formControlName="ageGradeInstrumentId" placeholder="Select a base template" required
            [ngClass]="{'invalid' : ageGradeInstrumentId.invalid && ngForm.submitted }">
            <mat-option *ngFor="let agi of ageGradeInstruments" [value]="agi.id">
              {{ agi.ageGradeInstrumentName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="ageGradeInstrumentId.invalid">Age Group required.</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="isView" class="self-start">
        <label class="font-normal" for="ageGradeInstrumentId">Age Group</label>
        <mat-form-field appearance="fill">
          <input class="display" matInput [readonly]="isView" [value]="customTemplate?.ageGradeInstrument" type="text"
            formControlName="ageGradeInstrumentId" required>
        </mat-form-field>
      </div>

      <div class="form-input-label-group mt-4" *ngIf="!isView">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="ageGradeTemplateId">Base Template:
        </label>
        <mat-form-field appearance="fill" class="drdp-input">
          <mat-select formControlName="ageGradeTemplateId" placeholder="Select an age group" required
            [ngClass]="{'invalid' : ageGradeInstrumentId.invalid && ngForm.submitted }">
            <mat-option *ngFor="let abt of ageGradeBaseTemplates" [value]="abt.id">
              {{ abt.ageGradeInstrumentCode }} - {{ abt.description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="ageGradeTemplateId.invalid">Base Template is required.</mat-error>
        </mat-form-field>
        <small>This will be used to create the developmental levels so choose properly. </small>
      </div>
      <div *ngIf="isView" class="self-start">
        <label class="font-normal" for="ageGradeTemplateId">Base Template</label>
        <mat-form-field appearance="fill">
          <input matInput class="display" [readonly]="isView" [value]="customTemplate?.ageGradeTemplate" type="text"
            formControlName="ageGradeTemplateId" required>
        </mat-form-field>
      </div>


      <div class="mt-4">
        <h3 class="text-[18px] font-bold mb-2">Choose Domains</h3>
        <span *ngIf="!displayMeasures"> Choose a domain to include in your base template. Measures will be automatically
          selected. </span>
        <div class="grid grid-cols-2 md:grid-cols-8 mt-4">
          <div *ngFor="let domain of templateDomains">
            <div class="col-span-1">
              <h4 class="text-[16px] font-bold mb-2">{{domain.domainCode}}</h4>
              <mat-checkbox class="font-medium" (change)="checkDomainSelected(domain)" [checked]="domain.isAllSelected"
                [disabled]="isView" [(ngModel)]="domain.isAllSelected" [ngModelOptions]="{standalone: true}">
                {{domain.domainCode}}
              </mat-checkbox>
              <div class="ml-2 hidden">
                <div *ngFor="let measure of domain.measures">
                  <mat-checkbox class="font-medium" (change)="checkDomainAllSelected(domain)"
                    [checked]="measure.isSelected" [disabled]="true" [(ngModel)]="measure.isSelected"
                    [ngModelOptions]="{standalone: true}">
                    {{measure.code}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 mb-2 flex justify-end">
        <drdp-btn (click)="submitForm()"> Save </drdp-btn>
      </div>
    </form>
  </drdp-container>
</div>