import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ToastService } from '@core/services/toast.service';
import { Observable, take } from 'rxjs';
import { Permission } from '@core/enums/permissions';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'drdp-drop-child',
  templateUrl: './drop-child.component.html',
  styleUrls: ['./drop-child.component.scss'],
})
export class DropChildComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  dropForm: FormGroup | any;
  exitReasons$?: Observable<any>;
  selectedExitReason?: any;
  isProcessing = false;
  child: any;
  isAdmin: boolean = false;
  isBatch: boolean = false;
  withdrawDateInfo = 'The day the child has been withdrawn from the agency.';
  
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DropChildComponent>,
    private lookup: LookupService,
    private toastService: ToastService,
    private studentRequestService: StudentRequestService,
    private permissionService: PermissionService,
    public modal: MatDialog,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.isAdmin = this.permissionService.checkPermission(
      Permission.AdminEnrollmentByPass
    );
    this.getExitReasons();
    this.initializeForm();
  }

  initializeForm(): void {
    this.dropForm = this.fb.group({
      classExitReasonId: [null, Validators.required],
      exitDate: [null, Validators.required],
      comment: [''],
    });

    this.isBatch = this.editData?.isBatch;

    if (this.isBatch) {
      this.child = this.editData.students;
    } else {
      this.child = this.editData.data;
    }
  }

  getExitReasons() {
    this.exitReasons$ = this.lookup.getClassExitReasons();
  }

  getSelectedExitReason(option: any) {
    this.selectedExitReason = option;
  }

  processBatchUnEnrollment() {
    if (this.child.length < 1) {
      this.toastService.error('No students to withdraw.');
      return;
    }

    let studentBatchRequestPayload: any = {
      studentRequestDtos: [],
      requestReasonId: StudentRequestReason.Drop,
      siteId: this.child[0].siteId,
    };

    for (let item of this.child) {
      let payload: any = {
        studentId: item.id,
        fromClassId: item.classId,
        requestReasonId: StudentRequestReason.Drop,
        siteId: item.siteId,
        studentName: `${item.firstName} ${item.lastName}`,
        ...this.dropForm.value, // Merge the values from dropForm into the payload
      };
      studentBatchRequestPayload.studentRequestDtos.push(payload);
    }

    const successMessage = this.isAdmin
      ? 'Student successfully exited.'
      : 'Exit request has been sent.';

    this.studentRequestService.checkDuplicateBatch(studentBatchRequestPayload)
    .pipe(take(1))
    .subscribe((duplicates) => {
      if(duplicates && duplicates.length > 0) {
        this.dialogRef.close({ success: false, duplicates: duplicates });
      }
      else {
        this.addBatchStudentRequest(studentBatchRequestPayload, successMessage);
      }
    });
  }

  addBatchStudentRequest(payload: any, successMessage: string) {
    this.studentRequestService
    .addBatchStudentRequests(payload, successMessage)
    .pipe(take(1))
    .subscribe((r) => {
      this.isProcessing = false;
      if (r) this.dialogRef.close({ success: true });
    });
  }

  handleDate(event: any) {
    this.dropForm.get('exitDate').setValue(event);
  }

  onSubmit() {
    if (this.dropForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }

    if (this.isProcessing) {
      this.toastService.error('Existing request still in progress.');
      return;
    }

    this.isProcessing = true;

    if (this.isBatch) {
      this.processBatchUnEnrollment();
      return;
    }

    let payload: any = this.dropForm.value;
    payload.studentId = this.child.id;
    payload.fromClassId = this.child.classId;
    payload.requestReasonId = StudentRequestReason.Drop;
    payload.siteId = this.child.siteId;
    payload.studentClassEnrollmentId = this.child.studentClassEnrollmentId;

    const successMessage = this.isAdmin
      ? 'Student successfully exited.'
      : 'Exit request has been sent.';

    this.studentRequestService
      .addStudentRequest(payload, successMessage)
      .pipe(take(1))
      .subscribe((r) => {
        this.isProcessing = false;
        this.dialogRef.close({ success: true });
      });
  }
}
