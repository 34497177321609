<div id="assessment-modal" class="rounded-lg fix-action-row modal-body">
  <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle ml-auto"></i>
  <div mat-dialog-content>
    <label class="text-lg">
      Please confirm the assessment date of
    </label>

    <div class="relative">
      <mat-form-field appearance="standard">
        <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" [max]="today" placeholder="mm/dd/yyyy">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div class="absolute top-0 left-full mt-4 ml-[-2px]">
        <i class="uil uil-info-circle text-xs" [matTooltip]=assessmentDateInfo></i>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <div class="flex justify-end align-bottom">
      <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close>Cancel</drdp-btn>
      <drdp-btn color="save" class="mr-2" (click)="onSubmit()">Confirm</drdp-btn>
    </div>
  </div>
</div>