<div *ngIf="user">
  <div class="flex flex-row p-5">
    <h1 class="userProfile font-semibold">User Profile: {{ userInfo?.firstName }} {{ userInfo?.lastName}}</h1>
    <drdp-btn color="update" routerLink="/update-password">Update Password</drdp-btn>
  </div>
  <div class="place-content-center mr-5 ml-2">
    <drdp-container>
      <div class="w-full">
        <div class="flex flex-row justify-between">
          <h1 class="information font-semibold mt-1">Information</h1>
          <drdp-btn class="editBtn mt-3" routerLink="/edit-profile">Edit Profile</drdp-btn>

        </div>
        <p class="info">
          <span class="font-bold">Name:</span> {{ userInfo?.firstName }} {{ userInfo?.middleName }} {{
          userInfo?.lastName }} {{userInfo?.suffix}}
        </p>
        <p class="info"> <span class="font-bold">Work Title:</span> {{ userInfo?.jobTitle }}</p>
        <p class="info"><span class="font-bold">Email Address:</span> {{ userInfo?.emailName }}</p>

        <div>
          <h2 class="information font-semibold mt-3">User Roles</h2>
          <button class="px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpblue-400 bg-drdpblue-500 text-sm hover:bg-drdpPurple-100 transition duration-300 ease w-fit"
            *ngFor="let role of user.roleAssignments">
            {{ role.role.roleName }}
          </button>
          <button class="px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpblue-400 bg-drdpblue-500 text-sm hover:bg-drdpPurple-100 transition duration-300 ease w-fit"
            *ngIf="user.roleAssignments.length < 1">
            {{ 'No Assigned Roles Available '}}
          </button>

          <h2 class="information font-semibold mt-4">My Sites</h2>
          <button class="px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpPurple-300 bg-drdpPurple-200 text-sm hover:bg-drdpPurple-100 transition duration-300 ease w-fit"
            *ngFor="let site of user.sites">
            {{ site.site.siteName }}
          </button>
          <button class="px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpPurple-300 bg-drdpPurple-200 text-sm hover:bg-drdpPurple-100 transition duration-300 ease w-fit"
            *ngIf="user.sites.length < 1">
            {{ 'No Assigned Sites Available '}}
          </button>
        </div>
      </div>
    </drdp-container>
  </div>
</div>