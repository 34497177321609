<div class="p-5">
  <div>
    <drdp-title title="Cohort Progress Report"></drdp-title>
    <div>
      <p>
        Select at least two (2) rating periods and age group to create your cohort/group report. Site, Cohort Classroom(s), and additional filters are optional. All classes are automatically included based on the sites selected. To select a subset of classes, click on the appropriate classes in the drop down menu. The report will show all domain scaled scores for the selected group within the current year (to date).
      </p>
    </div>
  </div>
  <drdp-report-search [multiSelectClassrooms]="true" [multiSelectSites]="true" [hasSubfilters]="true" [multiSelectRatingPeriods]="true"
    (showReportClicked)="showReport($event)" (downloadReportClicked)="downloadReport($event)"
    (clearClicked)="handleClear()" [requiredFields]="requiredFields"></drdp-report-search>
  <div *ngIf="!!reportData" [innerHtml]="reportData! | safeHtml"></div>
</div>
