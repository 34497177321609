<div class="p-5">
  <div class="flex justify-between items-center">
    <drdp-title title="Upload Center"></drdp-title>
  </div>

  <div class="flex flex-wrap gap-4 py-3">

    <drdp-container (drop)="uploadUserDropHandler($event)" color="whiteDash" class="h-62 w-80">
      <div class="w-full pb-4 text-center">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-upload"></i>
        <p class="text-[15px]" title="Upload Users">Upload Users</p>

        <p class="text-[15px] pt-11">Drag and drop here</p>
        <p class="text-[15px]">OR</p>
        <div class="form-group">
          <label class="text-drdpblue-300 text-[15px]" (click)="userFileInput.click()">Browse File</label>
          <input class="invisible" #userFileInput (change)="onFileChangeUser($event)" type="file" id="userfileUpload">
        </div>
      </div>
    </drdp-container>

    <drdp-container (drop)="uploadClassDropHandler($event)" color="whiteDash" class="h-62 w-80">
      <div class="w-full pb-4 text-center">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-upload"></i>
        <p class="text-[15px]" title="Upload Class">Upload Class</p>

        <p class="text-[15px] pt-11">Drag and drop here</p>
        <p class="text-[15px]">OR</p>
        <div class="form-group">
          <label class="text-drdpblue-300 text-[15px]" (click)="classFileInput.click()">Browse File</label>
          <input class="invisible" #classFileInput (change)="onFileChangeClass($event)" type="file" id="classfileUpload">
        </div>
      </div>
    </drdp-container>


    <!-- <drdp-container (drop)="uploadChildDropHandler($event)" color="whiteDash" class="h-62 w-80">
      <div class="w-full pb-4 text-center">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-upload"></i>
        <p class="text-[15px]" title="Upload All Instrument Child">Upload All Instrument Child</p>
        <p class="text-[15px]">(100 or less children)</p>

        <p class="text-[15px] pt-5">Drag and drop here</p>
        <p class="text-[15px]">OR</p>
        <div class="form-group">
          <label class="text-drdpblue-300 text-[15px]" (click)="childFileInput.click()">Browse File</label>
          <input class="invisible" #childFileInput (change)="onFileChange($event)" type="file" id="childfileUpload">
        </div>
      </div>
    </drdp-container> -->

    <drdp-container (drop)="uploadMoreChildDropHandler($event)" color="whiteDash" class="h-62 w-80">
      <div class="w-full pb-4 text-center">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-upload"></i>
        <p class="text-[15px]" title="Upload All Instrument Child">Upload All Instrument Child</p>
        <!-- <p class="text-[15px]">(101 or more children)</p> -->

        <p class="text-[15px] pt-11">Drag and drop here</p>
        <p class="text-[15px]">OR</p>
        <div class="form-group">
          <label class="text-drdpblue-300 text-[15px]" (click)="moreChildFileInput.click()">Browse File</label>
          <input class="invisible" #moreChildFileInput (change)="onFileChangeMoreChildren($event)" type="file" id="moreChildfileUpload">
        </div>
      </div>
    </drdp-container>
  </div>
</div>
