import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IGender } from '@core/interfaces/igender';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-select-gender',
  templateUrl: './select-gender.component.html',
  styleUrls: ['./select-gender.component.scss']
})
export class SelectGenderComponent implements OnInit {
  @ViewChild('genderSelect') genderSelect: FilterSelectComponent | undefined;
  @Output() gender = new EventEmitter<any>();
  @Input() initialGenderId?: number | null;
  @Input() initialGenderIds?: number[] | null;
  @Input() stateId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() isValid = true;
  @Input() required = false;
  @Input() label = 'Gender';
  @Input() reloadGender = false;
  genderOptions?: IGender[] = [];
  public get select() {
    return SelectType;
  }

  constructor(
    private lookupService: LookupService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.genderSelect?.clearAll();
      } else {
        this.genderSelect?.clearSearch(true);
      }
    }

    if (this.reloadGender) {
      if (this.stateId) {
        this.getGenders(this.stateId);
      }
    }

    if (changes['stateId']) {
      if (this.stateId) {
        this.genderSelect?.clearSearch(true);
        this.getGenders(this.stateId);
      }
      else
        this.genderOptions = [];

    }

    if (changes['initialGenderId'] && this.initialGenderId) {
      this.initialGenderIds = [];
      this.initialGenderIds.push(this.initialGenderId);
    }
  }

  clearSelected() {
    this.genderSelect?.clearSearch(true);
  }

  getGenders(stateId: number): void {

    this.lookupService.getGenders(stateId).pipe(take(1)).subscribe((genders: IGender[]) => {
      this.genderOptions = genders
      if (this.initialGenderIds) {
        const selected = this.genderOptions!.filter((gender: IGender) =>
          this.initialGenderIds?.includes(gender.id)
        );
        this.gender.emit(selected);
      }
    });
  }

  onGenderSelect(event: IGender): void {
    this.gender.emit(event);
  }
}
