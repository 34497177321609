import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Modal } from "@core/enums/modal";
import { ToastService } from "@core/services/toast.service";
import { UploadService } from "@core/services/upload.service";
import { ConfirmationModalComponent } from "@shared/components/confirmation-modal/confirmation-modal.component";
import { take } from "rxjs";

@Component({
  selector: 'drdp-upload-center',
  templateUrl: './upload-center.component.html',
  styleUrls: ['./upload-center.component.scss'],
})
export class UploadCenterComponent implements OnInit {

  @ViewChild('moreChildFileInput') moreChildFileInputRef!: ElementRef;
  isUploading: boolean = false;

  constructor(private uploadService: UploadService, private router: Router, private toast: ToastService,
    public modal: MatDialog) { }

  ngOnInit(): void {
    window.addEventListener("dragover", e => {
      e && e.preventDefault();
    }, false);
    window.addEventListener("drop", e => {
      e && e.preventDefault();
    }, false);
  }

  onFileChange(event: any) {
    if (!this.isFileAllowed(event.target.files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }

    const file = event.target.files[0];
    this.uploadService.setUploadFile(file);
    this.router.navigate(['upload-center/child']);
  }

  onFileChangeClass(event: any) {
    if (!this.isFileAllowed(event.target.files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }

    const file = event.target.files[0];
    this.uploadService.setUploadFile(file);
    this.router.navigate(['upload-center/class']);
  }


  onFileChangeUser(event: any) {
    if (!this.isFileAllowed(event.target.files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }

    const file = event.target.files[0];
    this.uploadService.setUploadFile(file);
    this.router.navigate(['upload-center/user']);
  }

  onFileChangeMoreChildren(event: any) {

    if (this.isUploading) {
      this.openFailModal();
      return;
    }

    if (!this.isFileAllowed(event.target.files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }
    this.isUploading = true;
    const file = event.target.files[0];
    this.uploadService.saveMoreChild(file).pipe(take(1)).subscribe({
      next: (res: boolean) => this.handleUploadResult(res),
      error: (error: any) => {
        this.isUploading = false;
        this.genericModal();
      }
    });
  }

  uploadMoreChildDropHandler(event: any) {
    event.preventDefault();
    event.target.classList.remove('drag-over');

    const files = event.dataTransfer.files;
    if (this.isUploading) {
      this.openFailModal();
      return;
    }

    if (!this.isFileAllowed(files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }
    this.isUploading = true;

    this.uploadService.saveMoreChild(files[0]).pipe(take(1)).subscribe({
      next: (res: boolean) => this.handleUploadResult(res),
      error: (error: any) => {
        this.isUploading = false;
        this.genericModal();
      }
    })
  }

  uploadChildDropHandler(event: any) {
    event.preventDefault();
    event.target.classList.remove('drag-over');

    const files = event.dataTransfer.files;

    if (!this.isFileAllowed(files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }

    this.uploadService.setUploadFile(files[0]);
    this.router.navigate(['upload-center/child']);
  }

  uploadClassDropHandler(event: any) {
    event.preventDefault();
    event.target.classList.remove('drag-over');

    const files = event.dataTransfer.files;

    if (!this.isFileAllowed(files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }

    this.uploadService.setUploadFile(files[0]);
    this.router.navigate(['upload-center/class']);
  }

  uploadUserDropHandler(event: any) {
    event.preventDefault();
    event.target.classList.remove('drag-over');

    const files = event.dataTransfer.files;

    if (!this.isFileAllowed(files)) {
      this.toast.error('Only xlsx files are allowed!');
      return;
    }

    this.uploadService.setUploadFile(files[0]);
    this.router.navigate(['upload-center/user']);
  }

  isFileAllowed(files: any): boolean {
    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && files[i].name.split('.').pop().toLowerCase() !== 'xlsx') {
        return false;
      }
    }
    return true;
  }

  handleUploadResult(res: any): void {
    this.isUploading = false;
    if (res) {
        this.openSuccessModal();
    } else {
        this.genericModal();
    }
    this.moreChildFileInputRef.nativeElement.value = '';
}

  openSuccessModal(): void {
    var event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: 'Success',
      message: `Your request is being processed. You will receive an email at the address associated with your account as soon as the upload is complete.`,
      primaryBtnClass: 'blue',
      showCancel: false,
      primaryBtnText: Modal.OK
    };
    const modalRef = this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
    modalRef.afterClosed().subscribe();
  }

  openFailModal(): void {
    var event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: 'Failed',
      message: `You have a pending upload that is processing.  Please wait until you have received your initial upload confirmation email before submitting another upload.`,
      primaryBtnClass: 'blue',
      showCancel: false,
      primaryBtnText: Modal.OK
    };
    const modalRef = this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
    modalRef.afterClosed().subscribe();
  }

  genericModal(): void{
    var event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: 'Failed',
      message: `Something went wrong with your submission.  Please try again..`,
      primaryBtnClass: 'blue',
      showCancel: false,
      primaryBtnText: Modal.OK
    };
    const modalRef = this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
    modalRef.afterClosed().subscribe();
  }

}
