<div class="p-5">
  <div class="flex justify-between items-center">
    <drdp-title title="Roles and Permissions"></drdp-title>
  </div>

  <div class="flex place-content-between py-3">
    <drdp-container
      color="whiteDash"
      class="h-31 w-40"
      *ngFor="let info of downloadFile"
    >
      <a class="flex-col text-center w-full pb-4" (click)="downloadReport()">
        <i class="col-md-4 text-drdpblue-300 text-[60px] {{ info.icon }}"></i>
        <p class="text-[15px]">{{ info.label }}</p>
        <p class="text-drdpblue-300 text-[15px]">{{ info.description }}</p>
      </a>
    </drdp-container>
  </div>
</div>
