import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PortfolioService } from '@core/services/portfolio.service';
import { take} from 'rxjs';
import {
  IAvailableEvidence,
  IPortfolioAddEvidenceParams,
  IPortfolioClassResponse,
  IPortfolioClassStudentsRequest,
  IPortfolioStudentInformation,
} from '@core/interfaces/iportfolio';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@core/services/toast.service';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FutureEvidenceStudentComponent } from './modals/future-evidence-student/future-evidence-student.component';
import { RequestEvidenceComponent } from './request-evidence/request-evidence.component';

@Component({
  selector: 'drdp-portfolio-class',
  templateUrl: './portfolio-class.component.html',
  styleUrls: ['./portfolio-class.component.scss'],
})
export class PortfolioClassComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  pageSizeOptions = [5, 10, 25];
  pageSize = 10;
  totalData = 0;
  tableData?: any;
  pageIndex: number = 0;

  tableColumns = [
    { columnDef: 'firstName', header: 'First Name', type: 'text',  checkClass: ''},
    { columnDef: 'lastName', header: 'Last Name', type: 'text', checkClass: '' },
    {
      columnDef: 'Review Current Evidence',
      header: 'Review Current Evidence',
      type: 'view',
      checkClass:'',
      tooltip: 'Review Current Evidence is evidence that has been assigned to the rating period.'
    }
  ];

  classroomName: string = '';
  ratingPeriodName: string = '';
  siteName: string = '';
  classId: number = 0;
  ageGradeId: number = 0;
  portfolioAddEvidenceParams!: IPortfolioAddEvidenceParams;
  isPilot: boolean = false;
  ratingPeriodId: number = 0;

  public get permission() {return Permission }
  constructor(
    private portfolioService: PortfolioService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private permissionSerive: PermissionService,
    public modal: MatDialog
  ) {}

  ngOnInit(): void {
    const canAddEvidence = this.permissionSerive.checkPermission(Permission.EditObservations);

    if(canAddEvidence) {
      this.tableColumns.unshift(
        {
          columnDef: 'select',
          header: 'Add Evidence',
          type: 'checkBox',
          checkClass: 'checkbox'
        }
      )
    }

    this.dataSource.paginator = this.paginator;
    this.initPage();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }


  initPage(): void {
    const classId = this.route.snapshot.paramMap.get('classId');
    const ageGradeId = this.route.snapshot.paramMap.get('ageGradeId');

    if (!classId || !ageGradeId) return;

    this.classId = +classId;
    this.ageGradeId = +ageGradeId;

    this.portfolioService
      .getPortfolioClass(this.classId, this.ageGradeId)
      .pipe(take(1))
      .subscribe((res: IPortfolioClassResponse) => {
        if (res) {
          this.classroomName = res.classroomName;
          this.ratingPeriodName = res.ratingPeriodName;
          this.siteName = res.siteName;
          this.ratingPeriodId = res.ratingPeriodId;
          if (!res.isPilot) {
            this.tableColumns.push({
              columnDef: 'Review Future Evidence',
              header: 'Review Future Evidence',
              type: 'view',
              checkClass: '',
              tooltip: 'Review Future Evidence is evidence that has been assigned to the next rating period.'
            });
          }
          this.portfolioAddEvidenceParams = {
            isPilot: res.isPilot,
            ageGradeInstrumentId: this.ageGradeId,
            ratingPeriodName: this.ratingPeriodName,
            classroomName: this.classroomName,
            ageGradeInstrumentName: res.ageGradeInstrumentName,
            studentsInformation: [],
          };
          this.isPilot = res.isPilot ?? false;
          this.getClassStudents();
        } else {
          this.toast.error('Error when trying to get class details');
        }
      });
  }

  paginateTable(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getClassStudents();
  }

  initializeTableData(data: any): void {
    this.dataSource = new MatTableDataSource(data.items);
    this.tableData = data.items;
    this.totalData = data.totalData;
  }

  getClassStudents(): void {
    const payload: IPortfolioClassStudentsRequest = {
      classId: this.classId,
      ageGradeInstrumentId: this.ageGradeId,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize,
    };

    this.portfolioService
      .getPortfolioClassStudents(payload)
      .pipe(take(1))
      .subscribe((res: IPortfolioClassResponse) => {
        if (res) {
          this.initializeTableData(res);
        } else {
          this.toast.error('Error when trying to get class students');
        }
      });
  }

  handleSelectedChild(event: any): void {
    const indexToRemove =
      this.portfolioAddEvidenceParams.studentsInformation.findIndex(
        (obj) =>
          obj.studentClassEnrollmentId === event.data.studentClassEnrollmentId
      );

    if (indexToRemove !== -1) {
      this.portfolioAddEvidenceParams.studentsInformation.splice(
        indexToRemove,
        1
      );
      return;
    }
    this.portfolioAddEvidenceParams.studentsInformation.push({
      studentClassEnrollmentId: event.data.studentClassEnrollmentId,
      name: event.data.fullName,
      futureEvidenceId: event.data.futureEvidenceId,
      studentId: event.data.studentId,
      ageGradeInstrumentId: this.ageGradeId,
      classId: this.classId,
      measures: [],
      domains: [],
    });
  }

  reviewEvidence(event: any): void {
    const { data, header } = event;
    if (header === 'Review Current Evidence') {
      if (data.portfolioId) {
        this.router.navigate([
          `portfolio/review-evidence/class/${this.classId}/student-enrollment/${event.data.studentClassEnrollmentId}/age-grade-instrument/${this.ageGradeId}`,
        ], {
          queryParams: {
            portfolioId: data.portfolioId
          }
        });
      } else {
        this.reviewCurrentEvidence(event);
      }
    } else {
      this.reviewFutureEvidence(data);
    }
  }

  reviewCurrentEvidence(event: any): void {
    this.portfolioService.getAvailableEvidenceToReq(event.data.studentId, this.ratingPeriodId, event.data?.studentClassEnrollmentId)
      .pipe(take(1)).subscribe((evidence: IAvailableEvidence) => {
        if (!evidence) {
          this.toast.error('No Portfolio available.');
          return;
        }

        let modalEvent = { data: { modalInfo: {} } };
          modalEvent.data.modalInfo = {
            title: 'No Portfolio available.',
            message: `If you would like to request access to child's evidence, please click next below.`,
            primaryBtnClass: 'blue',
            primaryBtnText: Modal.Next,
            showCancel: true
          };
          const modalRef = this.modal.open(ConfirmationModalComponent, {
            data: modalEvent ? modalEvent : undefined,
          });
          modalRef.afterClosed().subscribe((res) => {
            if (res) {
              evidence.toStudentClassEnrollmentID = event.data?.studentClassEnrollmentId;
              evidence.studentId = event.data?.studentId;
              evidence.classroomName = this.classroomName;
              evidence.ratingPeriodName = this.ratingPeriodName;
              evidence.siteName = this.siteName;
              this.modal.open(RequestEvidenceComponent, { data: evidence });
            }
          });
      });
  }

  reviewFutureEvidence(event: any): void {
    if (event.futureEvidenceId) {
      this.portfolioService.getFutureEvidencesById(event.futureEvidenceId!)
      .pipe(take(1))
      .subscribe(evidences=> {
        if(evidences.domainEvidences.length > 0 || evidences.measureEvidences.length > 0) {
          this.router.navigate([
            `portfolio/review-evidence/future-evidence/${event.futureEvidenceId}/student/${event.studentId}/class/${this.classId}/age-grade-instrument/${this.ageGradeId}`,
          ]);
        }
        else {
          this.toast.error('No Future Evidence.');
        }
      });
    } else {
      this.toast.error('No Future Evidence.');
    }
  }

  onNextButton(): void {
    if (this.portfolioAddEvidenceParams.studentsInformation.length < 1) {
      this.toast.error('No selected students.');
      return;
    }
    const canProceed = this.checkFutureEvidence();
    if (canProceed) {
     this.goToAddEvidence();
    }
  }

  checkFutureEvidence(): boolean {
    const selectedStudents = this.portfolioAddEvidenceParams.studentsInformation;
   // Do nothing: No selected students have future evidence
    if (selectedStudents.every(student => !student.futureEvidenceId)) {
      return true;
    } else
      this.showFutureEvidenceModal(selectedStudents);
      return false;
  }


  goToAddEvidence(): void {
    this.portfolioService.setPortfolioAddEvidenceParams(
      this.portfolioAddEvidenceParams
    );
    this.router.navigate(['portfolio/add-evidence'], {
      queryParams: { returnUrl: this.router.url, isPilot: this.isPilot },
    });
  }

  showFutureEvidenceModal(selectedStudents: IPortfolioStudentInformation[]): void {
    const futureEvidenceStudents = selectedStudents.filter(student => student.futureEvidenceId);
    const modalRef = this.modal.open(FutureEvidenceStudentComponent, {
      data: {studentList: futureEvidenceStudents},
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.goToAddEvidence();
      }
    });
  }

  onCancel(): void {
    this.router.navigate(['/portfolio']);
  }
}
