<div class="rounded-xl max-w-full max-h-min">
  <div class="flex justify-between">
    <h2 mat-dialog-title>Filter Roles</h2>
    <i mat-button 
       [mat-dialog-close]="closeModal()"
       class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <mat-dialog-content>
    <ul>
      <li class="text-sm appearance-none" (click)="toggleRoles(true)"><p class="cursor-pointer" type="button">Show All Roles</p></li>
      <li class="text-sm appearance-none" (click)="toggleRoles(false)"><p class="cursor-pointer" type="button">Hide All Roles</p></li>
      <hr>
      <div *ngFor="let role of originalRolesList">
        <li>
          <span class="text-sm">
            <i class="text-lg text-drdpblue-300 cursor-pointer" 
               [ngClass]="roleSet.has(role.id) ? 'uil uil-eye' : 'uil uil-eye-slash'" 
               (click)="updateRoleSet(role.id)"></i>
            {{role.roleName}}
            <i class="text-lg uil uil-info-circle cursor-pointer"></i>
          </span>
        </li>
      </div>
    </ul>
  </mat-dialog-content>
</div>
