import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'drdp-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit {
  @Input() pillClass: string = '';
  pillClasses: string = '';
  genericPillClass: string = 'text-drdpgrey-300 bg-drdpgrey-25';

  newsItemTypeClasses: any = {
    Agency: 'text-drdpbgrey-300 bg-drdpGreen-200',
    WestEd: 'text-drdpblue-600 bg-drdpblue-500',
  };

  constructor() {}

  ngOnInit(): void {
    this.setClass();
  }

  setClass(): void {
    this.pillClasses =
      this.newsItemTypeClasses[this.pillClass] ?? this.genericPillClass;
  }
}
