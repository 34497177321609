<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">
      {{ isBatch && "Batch" }} Withdraw Confirmation
    </h1>
    <i
      mat-button
      mat-dialog-close
      class="text-drdpblue-300 cursor-pointer uil uil-times-circle"
    ></i>
  </div>

  <div mat-dialog-content>
    <form class="grid grid-cols-2 gap-x-10" [formGroup]="dropForm">
      <div *ngIf="isBatch" class="col-span-2 mt-2">
        <label class="font-normal mb-2" for="childName"
          >Selected Children</label
        >
        <ul>
          <li *ngFor="let child of child">
            {{ child.firstName + " " + child.lastName }}
          </li>
        </ul>
      </div>

      <div *ngIf="!isBatch" class="col-span-1 mt-2">
        <label class="font-normal mb-2" for="childName">Child's Name</label>
        <p>
          {{ child.firstName + " " + child.lastName }}
        </p>
      </div>

      <div class="input-container col-span-full md:col-span-1 mt-2">
        <mat-form-field
          class="example-full-width no-underline"
          appearance="fill"
        >
          <label class="font-normal"
            >Withdraw from Agency Reason<span class="required"></span
          ></label>
          <mat-select formControlName="classExitReasonId" placeholder="Reason">
            <mat-option
              *ngFor="let reason of exitReasons$ | async"
              [value]="reason.id"
              (click)="getSelectedExitReason(reason)"
            >
              {{ reason.exitReason }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="relative">
        <div class="col-span-1 mt-2">
          <drdp-date-picker
            label="Withdraw Date"
            [initialDate]="dropForm.get('exitDate').value"
            [isRequired]="true"
            (emitDate)="handleDate($event)"
          >
          </drdp-date-picker>
        </div>
        <div class="absolute top-0 left-full mt-3 ml-[-2px]">
          <i class="uil uil-info-circle text-xs" [matTooltip]=withdrawDateInfo></i>
        </div>
      </div>

      <div class="col-span-full">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="font-normal" for="comment">Comment</label>
          <input
            matInput
            placeholder="Comments"
            autocomplete="off"
            type="text"
            formControlName="comment"
            maxlength="150"
          />
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end">
  <drdp-btn color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
  <drdp-btn
    class="ml-3"
    mat-button
    [disabled]="dropForm.invalid || isProcessing"
    cdkFocusInitial
    (click)="onSubmit()"
    >Withdraw
  </drdp-btn>
</div>
