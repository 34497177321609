<div class="px-5">
  <div class="flex justify-between pt-5">
    <drdp-title title="Pending Withdraws"></drdp-title>
  </div>
  <drdp-container class="p-5">
    <div class="rounded-lg">
      <div>
        <drdp-container color="lightBlue" additionClasses="w-full p-4">
          <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4"
            [formGroup]="exitRequestSearchForm" (ngSubmit)="search()">
            <drdp-select-state #stateSelect [initialStateId]="stateId?.value" (state)="handleState($event)" [clear]="clearDropdowns">
            </drdp-select-state>
            <drdp-select-agency #agencySelect (agency)="handleAgency($event)" [stateId]="stateId?.value"
                [initialAgencyId]="agencyId?.value" [clear]="clearDropdowns">
              </drdp-select-agency>
            <div class="col-span-1 select-input h-full grid space-between">
              <drdp-select-site
                              #siteSelect
                              (site)="handleSites($event)"
                              [agencyId]="agencyId.value"
                              [clear]="clearDropdowns">
            </drdp-select-site>
            </div>
            <div class="col-span-1 select-input h-full grid space-between">
              <drdp-filter-select #classSelect label="Classroom" [options]="classrooms" [selectType]="selectClass"
                (onSelected)="handleClassroom($event)">
              </drdp-filter-select>
            </div>
            <div class="col-span-1 h-full grid space-between">
              <label class="font-bold">First Name</label>
              <div class="basis-1/2 border-b border-black">
                <input matInput placeholder="First Name" formControlName="firstName"
                  class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300">
              </div>
            </div>
            <div class="col-span-1 h-full grid space-between">
              <label class="font-bold">Last Name</label>
              <div class="basis-1/2 border-b border-black">
                <input matInput placeholder="Last Name" formControlName="lastName"
                  class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300">
              </div>
            </div>
            <div class="flex flex-row w-full">
              <drdp-btn type="submit" class="mr-2">Search</drdp-btn>
              <drdp-btn type="button" (click)="onClear()" color="outline">Clear</drdp-btn>
            </div>
          </form>
        </drdp-container>
        <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
          (editActionItem)="onIconClick($event)" (toggleSwitchActionItem)="requestStatusUpdateHandler($event)">
        </drdp-add-edit-table>
      </div>
      <div mat-dialog-actions class="flex justify-end align-bottom pt-8">
        <drdp-btn color="cancel" class="mr-3" mat-button type="button" (click)="onCancel()">
          Cancel
        </drdp-btn>
        <drdp-btn mat-button type="submit" (click)="onSubmit()" cdkFocusInitial>
          Save and Submit
        </drdp-btn>
      </div>
    </div>
  </drdp-container>
</div>
