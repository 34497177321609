<div class="p-5">
  <div class="flex justify-between">
    <drdp-title [title]="pageTitle"></drdp-title>
  </div>
  <div *ngIf="isView" class="flex justify-end">
    <a routerLink="/config/rating-views" class="self-center text-sm order-last cursor-pointer">
      <i class="uil uil-angle-left-b text-base"></i>Back to Ratings View Setup
    </a>
  </div>
  <drdp-container additionClasses="mt-5">
    <div *ngIf="!isView">
      <h2 class="text-[20px] font-bold mb-2">Add Alternate Rating View</h2>
      <hr>
    </div>
    <form [formGroup]="customViewForm" #ngForm="ngForm">
      <div class="form-input-label-group">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="title">Title</label>
        <mat-form-field appearance="fill">
          <input class="display" placeholder="Title" matInput type="text" [readonly]="isView" formControlName="title"
            required>
          <mat-error *ngIf="title.invalid">Title is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="form-input-label-group">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="description">Description</label>
        <mat-form-field appearance="fill">
          <input class="display" placeholder="Description" matInput type="text" [readonly]="isView"
            formControlName="description" required>
          <mat-error *ngIf="description.invalid">Description is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="form-input-label-group" *ngIf="!isView">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="ageGradeInstrumentId">Age Group</label>
        <mat-form-field appearance="fill" class="drdp-input">
          <mat-select formControlName="ageGradeInstrumentId" placeholder="Select a base template" required
            [ngClass]="{'invalid' : ageGradeInstrumentId.invalid && ngForm.submitted }">
            <mat-option *ngFor="let agi of ageGradeInstruments" [value]="agi.id">
              {{ agi.ageGradeInstrumentName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="ageGradeInstrumentId.invalid">Age Group required.</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="isView" class="self-start">
        <label class="font-normal" for="ageGradeInstrumentId">Age Group</label>
        <mat-form-field appearance="fill">
          <input class="display" matInput [readonly]="isView" [value]="customTemplate?.ageGradeInstrument" type="text"
            formControlName="ageGradeInstrumentId" required>
        </mat-form-field>
      </div>
      <div class="form-input-label-group mt-4" *ngIf="!isView">
        <label class="font-normal" [ngClass]="{'required': !isView }" for="ageGradeTemplateId">Base Template</label>
        <mat-form-field appearance="fill" class="drdp-input">
          <mat-select formControlName="ageGradeTemplateId" placeholder="Select an age group" required
            [ngClass]="{'invalid' : ageGradeInstrumentId.invalid && ngForm.submitted }">
            <mat-option *ngFor="let abt of ageGradeBaseTemplates" [value]="abt.id">
              {{ abt.description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="ageGradeTemplateId.invalid">Base Template is required.</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="isView" class="self-start">
        <label class="font-normal" for="ageGradeTemplateId">Base Template</label>
        <mat-form-field appearance="fill">
          <input matInput class="display" [readonly]="isView" [value]="customTemplate?.ageGradeTemplate" type="text"
            formControlName="ageGradeTemplateId" required>
        </mat-form-field>
      </div>
      <div class="mt-4">
        <h3 class="text-[18px] font-bold mb-2">Extra Measures</h3>
        <span *ngIf="!displayMeasures"> Choose an age group and base template to see all available measures.</span>
        <div class="grid grid-cols-2 md:grid-cols-8 mt-4">
          <div *ngFor="let domain of templateDomains">
            <div class="col-span-1">
              <h4 class="text-[16px] font-bold mb-2">{{domain.domainCode}}</h4>
              <mat-checkbox class="font-medium" (change)="checkDomainSelected(domain)" [checked]="domain.isAllSelected"
                [disabled]="isView" [(ngModel)]="domain.isAllSelected" [ngModelOptions]="{standalone: true}">
                ALL {{domain.domainCode}}
              </mat-checkbox>
              <div class="ml-2">
                <div *ngFor="let measure of domain.measures">
                  <mat-checkbox class="font-medium" (change)="checkDomainAllSelected(domain)"
                    [checked]="measure.isSelected" [disabled]="measure.isRequired || isView || domain.disableMeasures"
                    [(ngModel)]="measure.isSelected" [ngModelOptions]="{standalone: true}">
                    {{measure.code}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4" *ngIf="hasAssignments">
        <h3 class="text-[18px] font-bold mb-2">Assignments</h3>

        <div class="font-bold mt-2" *ngIf="siteAssignments.length > 0">Sites </div>

        <div class="grid grid-cols-4" *ngIf="siteAssignments.length > 0">
          <div *ngFor="let site of siteAssignments"
            class="ml-2 px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpblue-400 bg-drdpblue-500 text-sm">
            {{site.siteName}}
          </div>
        </div>

        <div class="font-bold mt-4" *ngIf="classAssignments.length > 0">Classrooms </div>
        <div class="grid grid-cols-4" *ngIf="classAssignments.length > 0">
          <div *ngFor="let class of classAssignments"
            class="ml-2 px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpblue-400 bg-drdpblue-500 text-sm">
            {{class.className}}
          </div>
        </div>

        <div class="font-bold mt-4" *ngIf="studentAssignments.length > 0">Students </div>
        <div class="grid grid-cols-4" *ngIf="studentAssignments.length > 0">
          <div *ngFor="let student of studentAssignments"
            class="ml-2 px-4 py-1 mx-1 mt-3 pr-3 rounded-full text-drdpblue-400 bg-drdpblue-500 text-sm">
            {{student.studentName}}
          </div>
        </div>
      </div>
      <div class="mt-4 mb-2 flex justify-end" *ngIf="canEditRatingViews">
        <drdp-btn *ngIf="!isView" type="button" color="cancel" (click)="cancel()"> Cancel </drdp-btn>
        <drdp-btn *ngIf="!isView" (click)="submitForm()" class="mx-4"> Save </drdp-btn>
        <drdp-btn *ngIf="isView || hasAssignments" class="mx-4" (click)="reassign()">Re-Assign Rating View</drdp-btn>
        <drdp-btn *ngIf="!isView || !hasAssignments" (click)="next()"
          [type]="isView ? 'button' : 'submit'">
          {{nextLabel}} <i class="uil uil-angle-right"></i> </drdp-btn>
      </div>
    </form>
  </drdp-container>
</div>