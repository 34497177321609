<div class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">Requesting Evidence</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <div mat-dialog-content>
    <div class="grid grid-cols-2 gap-4 gap-x-12 p-3 gridCust">
      <div>
        <label class="font-normal mb-2" for="childName">Child Name</label>
        <span>{{ editData?.studentName }}</span>
      </div>
      <div>
        <label class="font-normal mb-2" for="ratingPeriod">Rating Period</label>
        <span>{{ editData?.ratingPeriodName }}</span>
      </div>
      <div>
        <label class="font-normal mb-2" for="site">Current Site</label>
        <span>{{ editData?.siteName }}</span>
      </div>
      <div>
        <label class="font-normal mb-2" for="classroom">Current Classroom</label>
        <span>{{ editData?.classroomName }}</span>
      </div>
      <div class="col-span-2">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="block font-normal mb-2" for="comment">Comment</label>
          <input matInput placeholder="Comment" type="text" maxLength="500" [(ngModel)]="comment"/>
        </mat-form-field>
      </div>
      <div mat-dialog-actions class="col-span-2 flex justify-end">
        <drdp-btn color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
        <drdp-btn mat-button cdkFocusInitial class="ml-3" (click)="onSubmit()" [disabled]="isProcessing">
          Request
        </drdp-btn>
      </div>
    </div>
  </div>
</div>