import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';
import {
  IGlobalRatingPeriod,
  IProcessedPeriod,
  ISchoolYear,
} from '@core/interfaces/iratingperiod';

@Component({
  selector: 'drdp-past-global-rating-periods',
  templateUrl: './past-global-rating-periods.component.html',
  styleUrls: ['./past-global-rating-periods.component.scss'],
})
export class PastGlobalRatingPeriodsComponent implements OnChanges {
  @Input() pastYears: ISchoolYear[] = [];
  @Input() pastPeriods: IGlobalRatingPeriod[] = [];
  processedPeriods: IProcessedPeriod[] = [];
  processedPerList: any = [];
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formTableColumns = [
    { columnDef: 'schoolYear', header: '', type: 'text' },
    { columnDef: 'globalRatingPeriodName', header: '', type: 'text' },
    { columnDef: 'defaultStartDate', header: '', type: 'text' },
    { columnDef: 'defaultEndDate', header: '', type: 'text' },
    { columnDef: 'minStartDate', header: '', type: 'text' },
    { columnDef: 'maxEndDate', header: '', type: 'text' },
  ];

  constructor() {}

  ngOnChanges(): void {
    if (this.pastYears.length > 0) {
      this.processRatingPeriodsData();
    }
  }

  processRatingPeriodsData(): void {
    let n = 0;
    for (let i in this.pastPeriods) {
      let per = this.pastPeriods[i];
      this.processedPeriods.push(<IProcessedPeriod>{
        sortOrder: this.getSortOrder(per),
        schoolYear: this.getSchoolYear(per),
        globalRatingPeriodName: per.globalRatingPeriodName.split(' ')[0],
        minStartDate: formatDate(per.minStartDate, 'M/d/yyyy', 'en-US'),
        maxEndDate: formatDate(per.maxEndDate, 'M/d/yyyy', 'en-US'),
        defaultStartDate: formatDate(per.defaultStartDate, 'M/d/yyyy', 'en-US'),
        defaultEndDate: formatDate(per.defaultEndDate, 'M/d/yyyy', 'en-US'),
      });
      n++;

      if (n == 4) {
        this.processedPeriods.sort((a, b) => a.sortOrder - b.sortOrder);
        this.processedPerList.push([...this.processedPeriods]);
        n = 0;
        this.processedPeriods.length = 0;
      }
    }
  }

  getTableDataSource(index: number): any {
    let source = new MatTableDataSource();
    source.data = this.processedPerList[index];
    return source;
  }
  getTableData(index: number): any {
    return this.processedPerList[index];
  }

  getSchoolYear(period: IGlobalRatingPeriod): string {
    let name = '';
    if (period.globalRatingPeriodName.split(' ')[0] == 'Fall') {
      const year = this.pastYears.filter((e) => e.id == period.schoolYearId);
      if (year.length > 0) {
        name = year[0].schoolYearName;
      }
    }
    return name;
  }

  getSortOrder(period: IGlobalRatingPeriod) {
    switch (period.globalRatingPeriodName.split(' ')[0]) {
      case 'Fall':
        return 1;
      case 'Winter':
        return 2;
      case 'Spring':
        return 3;
      default:
        return 4;
    }
  }
}
