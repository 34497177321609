<div class="p-5">
  <div class="mb-5 mr-3">
    <drdp-title title="Login Audits"></drdp-title>
  </div>
  <drdp-container [additionClasses]="'min-h-[44rem]'">
    <drdp-container color="lightBlue" additionClasses="min-h-[7rem] w-full">
      <form [formGroup]="searchForm"
        class="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 p-3 gap-2 md:gap-2 flex-row content-center my-auto justify-center">

        <div class="col-span-full md:col-span-1">
          <mat-form-field appearance="fill">
            <label class="global-font-bold required" for="StartDate">Start Date</label>
            <input matInput autocomplete="off" name="minDatePicker" [matDatepicker]="minPicker"
              formControlName="fromDate" aria-label="Start Date" (dateChange)="resetEndDate()" placeholder="mm/dd/yyyy">
            <mat-datepicker-toggle matSuffix [for]="minPicker"></mat-datepicker-toggle>
            <mat-datepicker #minPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-span-full md:col-span-1">
          <mat-form-field appearance="fill">
            <label class="global-font-bold required" for="EndDate">End Date</label>
            <input matInput autocomplete="off" name="maxDatePicker" aria-label="End Date" [matDatepicker]="maxPicker"
              formControlName="toDate" placeholder="mm/dd/yyyy">
            <mat-datepicker-toggle matSuffix [for]="maxPicker"></mat-datepicker-toggle>
            <mat-datepicker #maxPicker></mat-datepicker>
            <mat-error *ngIf="searchForm.controls['toDate'].errors?.['invalidDates']"></mat-error>
          </mat-form-field>
        </div>

        <div class="col-span-1">
          <drdp-select-role label="Role" [initialRolesIds]="roleIds?.value" [multiSelect]="true" [clear]="clearDropdown" (role)="handleRoles($event)" [required]="true">
          </drdp-select-role>
        </div>

        <div
          class="flex lg:flex-row md:flex-wrap lg:col-span-2 justify-evenly xl:justify-evenly lg:justify-center md:content-end lg:content-center mt-3 lg:mt-0 xl:mt-9">
          <drdp-btn [additionalClasses]="'lg:m-2 xl:m-0'" [disabled]="!searchForm.valid" (click)="submitSearch()">
            <span>Search</span>
          </drdp-btn>
          <drdp-btn color="outline" [additionalClasses]="'lg:m-2 xl:m-0'" (click)="download()"
            [disabled]="!searchForm.valid">
            Download <i class="uil uil-download-alt"></i>
          </drdp-btn>
          <drdp-btn type="button" [additionalClasses]="'lg:m-2 xl:m-0'" color="outline" (click)="clear()">
            Clear
          </drdp-btn>
        </div>
      </form>
      <span
        *ngIf="searchForm.controls['toDate'].touched && (searchForm.controls['toDate'].errors?.['invalidDates'] || searchForm.controls['toDate'].errors?.['dateRangeNotWithinOneYear'])"
        class="text-sm text-red-700">
        Starting date must be before the ending date and must not be farther than a year apart.
      </span>
    </drdp-container>
    <drdp-add-edit-table [dataSource]="dataSource" [noDataMessage]="'There are no records to display.'"
      [tableData]="tableData" [columns]="tableColumns">
    </drdp-add-edit-table>
  </drdp-container>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateAgency()">
  </mat-paginator>