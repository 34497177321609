import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@core/services/toast.service';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-edit-class-enrollment-date',
  templateUrl: './edit-class-enrollment-date.component.html',
  styleUrls: ['./edit-class-enrollment-date.component.scss']
})
export class EditClassEnrollmentDate implements OnInit {
  editForm: FormGroup | any;
  studentEnrollmentInfo: any;
  defaultDateError: string = "Please enter classroom enrollment date between rating period's start and end dates.";
  agencyDateError: string = "Please enter classroom enrollment date on or after agency enrollment date.";
  isAgencyDateError: boolean = false;

  constructor(
    public studentClassEnrollmentService: StudentClassEnrollmentService,
    public toastService: ToastService,
    private fb: FormBuilder,
    public dialog: MatDialogRef<EditClassEnrollmentDate>,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {}

  get classEnrollmentDate() {
    return this.editForm.get('classEnrollmentDate');
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.editForm = this.fb.group({
      classEnrollmentDate: [null, [Validators.required]],
    });

    if (this.editData) {
      this.studentEnrollmentInfo = this.editData.data;
      this.classEnrollmentDate.setValue(this.studentEnrollmentInfo.classEnrollmentDate);
    }
  }

  handleDate(event: any): void {
    this.classEnrollmentDate.setValue(event);
    this.isAgencyDateError = false;
    if (this.classEnrollmentDate.value && this.studentEnrollmentInfo) {
      const classEnrollmentDate = new Date(this.classEnrollmentDate.value).getTime();
      const globalRatingPeriodMinDate = new Date(this.studentEnrollmentInfo.ratingPeriodMinDate).getTime();
      const globalRatingPeriodMaxDate = new Date(this.studentEnrollmentInfo.ratingPeriodMaxDate).getTime();
      const agencyEnrollDate = new Date(this.studentEnrollmentInfo.enrollmentStartDate).getTime();
      if (classEnrollmentDate >= globalRatingPeriodMinDate &&
        classEnrollmentDate <= globalRatingPeriodMaxDate &&
        classEnrollmentDate >= agencyEnrollDate) {
        this.classEnrollmentDate.setErrors(null);
      } else {
        this.classEnrollmentDate.setErrors({invalidDates: true});
        if (classEnrollmentDate < agencyEnrollDate) this.isAgencyDateError = true;
      }
    }
    this.editForm.markAsDirty();
  }

  onSubmit(): void {
    if (this.editForm.pristine) return;

    this.handleDate(this.classEnrollmentDate.value);
    if (this.editForm.invalid) {
      this.toastService.error(this.isAgencyDateError ? this.agencyDateError : this.defaultDateError);
      return;
    }

    const payload = {
      id: this.studentEnrollmentInfo.classEnrollmentId,
      classEnrollmentDate: this.classEnrollmentDate.value
    };
    this.studentClassEnrollmentService.updateClassEnrollmentDate(payload)
      .pipe(take(1))
      .subscribe((res: boolean) => {
        this.dialog.close({ success: res});
      });
  }

}
