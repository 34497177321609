import { Component, inject } from '@angular/core';
import { IRequiredReportFields } from '../interfaces/irequired-report-fields';
import { IReportSearch } from '../interfaces/ireport-search';
import { ReportService } from '@core/services/report.service';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'drdp-state-group-detail',
  templateUrl: './state-group-detail.component.html',
  styleUrls: ['./state-group-detail.component.scss']
})
export class StateGroupDetailComponent {
  requiredFields: IRequiredReportFields = {
    state: true,
    agency: false,
    globalRatingPeriod: true,
    site: false,
    classroom: false,
    ageGroup: true,
    requireMultipleRatingPeriods: false
  };

  reportService = inject(ReportService); 
  dialog = inject(MatDialog);

  showReport(searchValue: IReportSearch): void {
    this.reportService
      .saveStateGroupDetailRequest(searchValue)
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (res) this.displaySuccessMessage();
      });
  }

  displaySuccessMessage(): void {
    const modal = {
      data: {
        modalInfo: {
          title: 'Success',
          message:
            'Your request is being processed. You will receive an email at the address associated with your account as soon as the report is ready.',
          primaryBtnClass: 'blue',
          primaryBtnText: 'OK',
          showCancel: false,
        },
      },
    };
    this.dialog.open(ConfirmationModalComponent, { data: modal });  
  }
}
