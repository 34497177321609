<div>
    <div class="flex justify-between border-b">
        <h1 class="text-[25px] font-bold">Multiple sessions detected</h1>
    </div>
    
    <div mat-dialog-content>
        <div class="content flex justify-center">
            <p class="pt-5">Only one session is allowed to be active</p>
        </div>
        <div class="flex justify-center mb-5">
            <drdp-btn color="blue" (click)="removeOtherSession()" mat-button mat-dialog-close>
                Remove Other Session
            </drdp-btn>
        </div>
    </div>
    <hr>
    <div mat-dialog-actions class="flex justify-end">
        <drdp-btn color="cancel" (click)="logout()" mat-button mat-dialog-close>
            Logout
        </drdp-btn>
    </div>
</div>

