import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@core/services/auth.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import {
  IStudentRequest,
  IStudentRequestSearch,
  IStudentRequestSearchResponse,
} from '@core/interfaces/istudent-request';
import { SelectType } from '@core/enums/select';
import { RatingPeriodService } from '@core/services/rating-period.service';
import {
  combineLatest,
  concatMap,
  map,
  Observable,
  Subscription,
  take,
} from 'rxjs';
import { IRatingPeriod, ISchoolYear } from '@core/interfaces/iratingperiod';
import { SiteService } from '@core/services/site.service';
import { ClassService } from '@core/services/class.service';
import { IClass, IClassSearchRequest } from '@core/interfaces/iclasses';
import { TableColumnType, TableInputAction } from '@core/enums/table';
import { ToastService } from '@core/services/toast.service';
import { ISites } from '@core/interfaces/isites';
import { IAgency } from '@core/interfaces/iagency';
import { mapClassResToDRDPSelect } from '@core/services/helper.service';
import { DropdownType } from '@core/enums/pending-requests';
import { ISelectable } from '@core/interfaces/iselectable';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { IState } from '@core/interfaces/istate';
import { Router } from '@angular/router';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-pending-transfers',
  templateUrl: './pending-transfers.component.html',
  styleUrls: ['./pending-transfers.component.scss'],
})
export class PendingTransfersComponent implements OnInit, OnDestroy {
  @ViewChildren(FilterSelectComponent) selectComponents:
    | QueryList<FilterSelectComponent>
    | undefined;
  @ViewChild('stateSelect') stateSelect!: SelectStateComponent;
  @ViewChild('agencySelect') agencySelect!: SelectAgencyComponent;
  private subscriptions = new Subscription();
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  getSelectData$: Observable<any> | undefined;
  getSearchSelectData$: Observable<any> | undefined;
  user = this.authService.getCurrentUser();
  isAgencyAdmin?: boolean = this.authService.isAgencyAdmin();
  isLeadAgencyAdmin?: boolean = this.authService.isLeadAgencyAdmin();
  viewOnlyOwnAgency: boolean | undefined = false;
  currentDate = new Date();
  isCurrentlyEditing: boolean = false;
  acceptOrDeclineChecked: boolean = false;
  activeSchoolYear?: any;
  tableData?: any;
  selectedSiteId: number[] = [];
  canViewSensitiveTransferInfo: boolean = this.permissionService.checkPermission(
    Permission.ViewSensitiveStudentTransferInfo
  );

  selectClass = SelectType.Class;
  selectSites = SelectType.Sites;
  selectRatingPeriods = SelectType.RatingPeriods;

  ratingPeriodsOptions?: IRatingPeriod[];
  classOptions?: IClass[];
  fromClassroomList: IClass[] = [];
  fromRatingPeriodList: IRatingPeriod[] | undefined = [];
  toClassroomList: IClass[] = [];
  toRatingPeriodList: IRatingPeriod[] | undefined = [];
  fromClassId?: number | undefined;
  toClassId?: number | undefined;

  initialAgencyId?: number | undefined;
  stateSearchId?: number | null;
  agencySearchId?: number | null;
  clearDropdowns = false;
  doneEditing: boolean = false;

  searchPayload: IStudentRequestSearch | any = {
    requestReasonId: StudentRequestReason.Transfer,
  };

  router = inject(Router);
  tableColumns: any = [
    { columnDef: 'studentName', header: 'Child Name', type: 'text' },
    {
      columnDef: 'siteName',
      header: 'Transfer To: Site',
      type: TableColumnType.textToDrdpSelect,
      selectType: this.selectSites,
      optionKey: 'siteOptions',
      initialSelectId: 'siteId',
      action: TableInputAction.drdpDropdown,
    },
    {
      columnDef: 'classroomName',
      header: 'Transfer To: Classroom',
      type: TableColumnType.textToDrdpSelect,
      selectType: this.selectClass,
      optionKey: 'classOptions',
      initialSelectId: 'toClassId',
      action: TableInputAction.drdpDropdown,
    },
    {
      columnDef: 'ratingPeriodName',
      header: 'Transfer Rating Period',
      type: TableColumnType.textToDrdpSelect,
      selectType: this.selectRatingPeriods,
      optionKey: 'ratingPeriodOptions',
      initialSelectId: 'ratingPeriodId',
      action: TableInputAction.drdpDropdown,
    },
    this.canViewSensitiveTransferInfo ?
    {
      columnDef: 'fromSiteName',
      header: 'Transfer From: Site',
      type: 'text'
    } : null,
    this.canViewSensitiveTransferInfo ?
    {
      columnDef: 'fromClassroomName',
      header: 'Transfer From: Classroom',
      type: 'text'
    } : null,
    this.canViewSensitiveTransferInfo ?
    {
      columnDef: 'requestedBy',
      header: 'Requested By',
      type: 'text'
    } : null,
    {
      columnDef: 'comment',
      header: 'Requester Comment',
      type: TableColumnType.text,
    },
    {
      columnDef: 'adminComment',
      header: 'Approver Comment',
      type: TableColumnType.textToInput,
      action: TableInputAction.inputChange,
      maxLength: 150,
    },
    {
      columnDef: 'edit',
      header: 'Edit',
      type: 'edit',
    },
    {
      columnDef: 'decline',
      header: 'Decline',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox',
    },
    {
      columnDef: 'approve',
      header: 'Accept',
      type: TableColumnType.checkBox,
      checkClass: 'checkbox',
    },
  ].filter(Boolean);

  constructor(
    private requestService: StudentRequestService,
    private authService: AuthService,
    private ratingPeriodService: RatingPeriodService,
    private sitesService: SiteService,
    private classService: ClassService,
    private toast: ToastService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit(): void {
    this.stateSearchId = this.user.stateId;
    this.searchPayload.stateId = this.stateSearchId;
    this.agencySearchId = this.user.agencyId;
    this.searchPayload.agencyId = this.user.agencyId;
    if (this.viewOnlyOwnAgency) {
      this.searchPayload.stateId = this.user.stateId;
      this.searchPayload.agencyId = this.user.agencyId;
      this.initialAgencyId = this.user.agencyId;
      this.setTableData();
    }
    if(this.user.agencyId)
      this.getRatingPeriods(this.user.agencyId);
  }

  setTableData(): void {
    this.subscriptions.add(
      this.requestService
        .getByReasonAndAgency(this.searchPayload)
        .subscribe((res: IStudentRequestSearchResponse[]) => {
          if (res) {
            res = res.map((data: IStudentRequestSearchResponse) => ({
              ...data,
              isSelect: false,
              approve: null,
              decline: null,
              classOptions: [],
              siteOptions: [],
              ratingPeriodOptions: [],
            }));
          }
          this.dataSource = new MatTableDataSource(res);
          this.tableData = res;
        })
    );
  }

  handleIconOnClick(event: any): void {
    if (event.data === null) {
      this.toast.error('Please select a value');
    } else {
      switch (event.action) {
        case TableInputAction.drdpDropdown:
          if (event.data.type == SelectType.Class) {
            if (this.tableData[event.index].toClassId != event.data.id)
              this.tableData[event.index].edited = true;
            this.tableData[event.index].classroomName = event.data.name;
            this.tableData[event.index].toClassId = event.data.id;
          }
          if (event.data.type === SelectType.Sites) {
            if (this.tableData[event.index].siteId != event.data.id)
              this.tableData[event.index].edited = true;
            this.tableData[event.index].siteName = event.data.name;
            this.tableData[event.index].siteId = event.data.id;
            this.updateClassroomDropdowns(
              {
                siteIds: event.data.id,
                ratingPeriodId: this.tableData[event.index].ratingPeriodId,
              },
              'tableDropdown',
              event.index
            );
          }
          if (event.data.type === SelectType.RatingPeriods) {
            if (this.tableData[event.index].ratingPeriodId != event.data.id)
              this.tableData[event.index].edited = true;
            this.tableData[event.index].ratingPeriodName = event.data.name;
            this.tableData[event.index].ratingPeriodId = event.data.id;
            this.updateClassroomDropdowns(
              {
                siteIds: this.tableData[event.index].siteId,
                ratingPeriodId: event.data.id,
              },
              'tableDropdown',
              event.index
            );
          }
          break;
        case TableInputAction.inputChange:
          if (
            this.tableData[event.index].adminComment != event.data.target.value
          )
            this.tableData[event.index].edited = true;
          this.tableData[event.index].adminComment = event.data.target.value;
          break;
        default:
          this.tableData[event.index].isSelect =
            !this.tableData[event.index].isSelect;
          if (this.tableData[event.index].isSelect)
            this.initializeRowDropdowns(event.index);
          this.isCurrentlyEditing = this.tableData.some((row: any) => {
            return row.isSelect == true;
          });
          this.doneEditing = this.tableData.some((row: any) => {
            return row.edited == true;
          });
          break;
      }
    }
  }

  initializeRowDropdowns(index: number): void {
    this.getSelectData$ = this.ratingPeriodService.getActiveSchoolYear().pipe(
      concatMap((year: ISchoolYear) =>
        combineLatest({
          ratingPeriods: this.ratingPeriodService.getBySchoolYearAndAgency(
            year.id,
            this.tableData[index].agencyId
          ),
          sites: this.sitesService.getSitesByAgencyId(
            this.tableData[index].agencyId
          ),
          classes: this.classService.getClassesByAgency(
            this.tableData[index].agencyId
          ),
        }).pipe(map((data) => data))
      )
    );

    this.subscriptions.add(
      this.getSelectData$.subscribe((res: any) => {
        this.tableData[index].ratingPeriodOptions = res.ratingPeriods;
        this.tableData[index].siteOptions = res.sites;
      })
    );

    this.updateClassroomDropdowns(
      {
        siteIds: this.tableData[index].siteId,
        ratingPeriodId: this.tableData[index].ratingPeriodId,
      },
      DropdownType.tableDropdown,
      index
    );
  }

  updateClassroomDropdowns(
    params: IClassSearchRequest,
    dropdownType: string,
    index?: number
  ): void {
    const payload: IClassSearchRequest = {};
    payload.siteIds =   params.siteIds ? Array.isArray(params.siteIds) ? params.siteIds : [params.siteIds]  : null;
    payload.ratingPeriodId = params.ratingPeriodId ?? null;
    payload.itemsPerPage = 100;

    this.subscriptions.add(
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .subscribe((res: any) => {
          if (res) {
            let mappedRes = mapClassResToDRDPSelect(res);
            switch (dropdownType) {
              case DropdownType.tableDropdown:
                this.tableData[index!].classOptions = mappedRes;
                let includesChosenClass = mappedRes
                  .map((data: any) => data.id)
                  .includes(this.tableData[index!].toClassId);
                if (!includesChosenClass) {
                  this.tableData[index!].toClassId =
                    this.tableData[index!].classOptions[0]?.id;
                  this.tableData[index!].classroomName =
                    this.tableData[index!].classOptions[0]?.classroomName;
                }
                break;
              case DropdownType.fromDropDown:
                this.fromClassroomList = mappedRes;
                break;
              case DropdownType.toDropdown:
                this.toClassroomList = mappedRes;
                break;
              default:
                break;
            }
          }
        })
    );
  }

  handleRequest(event: any): void {
    var i = event.index;
    var prop = event.property;

    this.tableData[i][prop] = !this.tableData[i][prop];
    if (prop == 'approve') this.tableData[i].decline = false;
    if (prop == 'decline') this.tableData[i].approve = false;
    if (
      this.tableData[i].approve == false &&
      this.tableData[i].decline == false
    ) {
      this.tableData[i].decline = null;
      this.tableData[i].approve = null;
    }

    this.acceptOrDeclineChecked = this.tableData.some((row: any) => {
      return row.decline == true || row.approve == true;
    });
  }

  handleSubmit(): void {
    if (
      this.isCurrentlyEditing ||
      (!this.doneEditing && !this.acceptOrDeclineChecked)
    ) {
      this.toast.error(
        'Please edit, accept, or decline a transfer request before submitting.'
      );
    } else {
      const requestPayload: IStudentRequest[] = [];
      this.tableData.forEach((studentRequest: any) => {
        if (
          (studentRequest.decline != null && studentRequest.approve != null) ||
          studentRequest.edited == true
        ) {
          var requestToPush = <IStudentRequest>{
            id: studentRequest.id,
            studentId: studentRequest.studentId,
            toClassId: studentRequest.toClassId,
            fromClassId: studentRequest.fromClassId,
            adminComment: studentRequest.adminComment,
            accept: studentRequest.approve,
            requestReasonId: studentRequest.requestReasonId,
            classEnrollmentDate: studentRequest.classEnrollmentDate,
          };
          requestPayload.push(requestToPush);
        }
      });
      this.subscriptions.add(
        this.requestService
          .processTransfers(
            requestPayload,
            'Transfer requests have been updated'
          )
          .subscribe((res: boolean) => {
            if (res) {
              this.setTableData();
              this.resetBools();
            }
          })
      );
    }
  }

  getRatingPeriods(agencyId: number): void {
    this.ratingPeriodService
      .getByAgency(agencyId)
      .pipe(take(1))
      .subscribe((res) => {
        this.fromRatingPeriodList = res;
        this.toRatingPeriodList = res;
      });
  }

  handleState(state: IState): void {
    if (state) {
      this.searchPayload.requestReasonId = StudentRequestReason.Transfer;
      this.searchPayload.stateId = state.id ?? null;
      this.stateSearchId = this.searchPayload.stateId;
    }
    this.selectComponents?.forEach((selectComponent: FilterSelectComponent) =>
      selectComponent?.clear()
    );
  }

  handleAgency(agency: IAgency): void {
    if (agency) {
      this.selectComponents?.forEach((selectComponent: FilterSelectComponent) =>
        selectComponent?.clear()
      );
      this.searchPayload.requestReasonId = StudentRequestReason.Transfer;
      this.searchPayload.agencyId = agency == null ? null : agency.id;
      this.agencySearchId = this.searchPayload.agencyId;

      this.getRatingPeriods(agency.id);
    }
  }

  handleFromSites(site: ISites): void {
    this.searchPayload.fromSiteId = site == null ? null : site.id;

    if (this.searchPayload.fromSiteId && this.searchPayload.fromRatingPeriodId)
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(site.id!),
          ratingPeriodId: this.searchPayload.fromRatingPeriodId,
        },
        DropdownType.fromDropDown
      );
  }

  handleFromClassroom(classId: ISelectable): void {
    this.searchPayload.fromClassId = classId == null ? null : classId.id;
  }

  handleFromRatingPeriod(ratingPeriod: IRatingPeriod) {
    this.searchPayload.fromRatingPeriodId =
      ratingPeriod == null ? null : ratingPeriod.id;

    if (
      this.searchPayload.fromRatingPeriodId &&
      this.searchPayload.fromSiteId
    ) {
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(this.searchPayload.fromSiteId!),
          ratingPeriodId: this.searchPayload.fromRatingPeriodId,
        },
        DropdownType.fromDropDown
      );
    }
  }

  handleToSites(site: ISites): void {
    this.searchPayload.toSiteId = site == null ? null : site.id;

    if (site)
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(site.id!),
          ratingPeriodId: this.searchPayload.toRatingPeriodId,
        },
        DropdownType.toDropdown
      );
  }

  handleToClassroom(classId: any): void {
    this.searchPayload.toClassId = classId == null ? null : classId.id;
  }

  handleToRatingPeriod(ratingPeriod: IRatingPeriod): void {
    this.searchPayload.toRatingPeriodId =
      ratingPeriod == null ? null : ratingPeriod.id;

    if (this.searchPayload.toRatingPeriodId && this.searchPayload.toSiteId) {
      this.updateClassroomDropdowns(
        {
          siteIds: this.getSelectedSite(this.searchPayload.toSiteId!),
          ratingPeriodId: this.searchPayload.toRatingPeriodId,
        },
        DropdownType.toDropdown
      );
    }
  }

  getSelectedSite(site: number): number[] {
    this.selectedSiteId = [];
    this.selectedSiteId.push(site);
    return this.selectedSiteId;
  }

  search(): void {
    this.resetBools();
    this.setTableData();
  }

  isSubmitBtnEnabled(): boolean {
    return (
      (this.doneEditing && this.acceptOrDeclineChecked) ||
      (this.doneEditing && !this.acceptOrDeclineChecked) ||
      (!this.doneEditing && this.acceptOrDeclineChecked)
    );
  }

  onClear(): void {
    this.resetBools();
    this.clearDropdowns = true;
    this.searchPayload = {};

    if (!this.stateSelect?.disabled) {
      this.stateSearchId = null;
    }

    if (!this.agencySelect?.disabled) {
      this.agencySearchId = null;
    }

    this.selectComponents?.forEach((selectComponent: FilterSelectComponent) =>
      selectComponent?.clear()
    );
    this.searchPayload = {
      stateId: this.stateSearchId ?? this.user.stateId,
      agencyId: this.agencySearchId ?? this.user.agencyId,
      requestReasonId: StudentRequestReason.Transfer,
    };
    this.dataSource = new MatTableDataSource();
  }

  handleCancel(): void {
    this.router.navigateByUrl('/dashboard')

  }

  resetBools(): void {
    this.doneEditing = false;
    this.acceptOrDeclineChecked = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
